import { Button, Modal } from "react-bootstrap";
import {Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ReadMoreModal = ({
    title,
    show,
    handleClickClose,
    bodyText
}: any) => {

    return (  
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>{title || "Remark"}</Modal.Title>
                <CloseIcon 
                    onClick={handleClickClose}
                />
            </Modal.Header>
            <Modal.Body>
                <Typography
                    sx={{
                        wordWrap: "break-word"
                    }}
                >
                    {bodyText}
                </Typography>
            </Modal.Body>
        </Modal>
    );
}
 
export default ReadMoreModal;