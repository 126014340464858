import { ChangeEvent } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { convertToLocaleDate } from '../../../utils/DateTimeUtil';
import { getMachineTypeIcon } from '../../../utils/MachineUtil';
import { Box, Pagination } from '@mui/material';
import { PaginationContainer } from '../../Shared/Common/Containers';
import { PAGE_LIMIT } from "../../../constants/GlobalConstants";

type PropsType = {
    rows: any[]
    onPageChange: (event : ChangeEvent<unknown>, pageValue : number) => void
    page: number
    count: number
}

export default function BasicTable(props: PropsType) {
    const {rows, onPageChange, page, count} = props
    return (
        <Box>
            <TableContainer className="hide-scroll-bar" sx={{minWidth: "1200px", marginBottom: 2, overflow: "scroll"}} component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Machine Name</TableCell>
                    <TableCell align="right">Total Weight (kg)</TableCell>
                    <TableCell align="right">Total Carbon Reduction</TableCell>
                    <TableCell align="right">Total Door Opened</TableCell>
                    <TableCell align="right">Total NonOrganic</TableCell>
                    <TableCell align="right">Last Sync Date</TableCell>
                    <TableCell align="right">Status</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row) => (
                    <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {row.machineName || "-"}
                            {getMachineTypeIcon(row.machineType)}
                        </TableCell>
                        <TableCell align="right">{row.totalWeight || 0}</TableCell>
                        <TableCell align="right">{row.carbonReduction?.toFixed(2) || 0.00}</TableCell>
                        <TableCell align="right">{row.openCount || 0}</TableCell>
                        <TableCell align="right">{row.nonOrganicCount || 0}</TableCell>
                        <TableCell align="right">{convertToLocaleDate(row.lastSyncDate) || "-"}</TableCell>
                        <TableCell align="right">
                            {
                                row.status === "offline" ?
                                <span className="badge text-bg-secondary">{row.status}</span> :
                                row.status === "online" ?
                                <span className="badge text-bg-success">{row.status}</span> :
                                "-"
                            }
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
            <PaginationContainer>
                <Pagination 
                    count={(Math.ceil(count/PAGE_LIMIT)) || 0} 
                    onChange={onPageChange} 
                    page={page} 
                    variant="outlined" 
                    shape="rounded" size="large" 
                />
            </PaginationContainer>
        </Box>
    );
}