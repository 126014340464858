import http from "../apiServices";
import { DetectionDELETERequestBody, DetectionData, DetectionGETRequestBody, DetectionUPDATERequestBody } from "../../types/DetectionType";

// * Post all Given Data to database.table

export const detectionGetListByMachineIdApi = (inputData : DetectionGETRequestBody) => {
    return http.post("/detection/get/list/by/machineId", inputData)
}

export const detectionGetListByOrgIdApi = (inputData : DetectionGETRequestBody) => {
    return http.post("/detection/get/list/by/orgId", inputData)
}

export const detectionGetExportListByOrgIdApi = (inputData : DetectionGETRequestBody, options?: any) => {
    return http.post("/detection/get/export-list/by/orgId", inputData, options)
}

export const detectionGetListByUserIdApi = (inputData : DetectionGETRequestBody) => {
    return http.post("/detection/get/list/by/userId", inputData)
} 

export const detectionGetExportListByUserIdApi = (inputData : DetectionGETRequestBody) => {
    return http.post("/detection/get/export-list/by/userId", inputData)
} 

export const detectionGetAllByMachineIdAndCaptureTime = (inputData: DetectionGETRequestBody) => {
    return http.post("/detection/get/all/by/machineId-captureTime", inputData)
}

export const detectionAddDataApi = (inputData : DetectionData) => {
    return http.post("/detection/add", inputData)
}

export const detectionUpdateDataApi = (inputData : DetectionUPDATERequestBody) => {
    return http.put(`/detection/update`, inputData)
}

export const detectionDeleteDataApi = (inputData : DetectionDELETERequestBody) => {
    return http.post(`/detection/delete`, inputData)
}
