import styled from "styled-components";
import { NodeProps } from "../../../types/CommonType";
import { Link } from "react-router-dom";

export const Nav = styled.div<NodeProps>`
  background-color: #FFFFFF;
  box-shadow: 0px 15px 10px -15px #111;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  z-index: 999;
  top: 0;
  height: ${props => props.showAlert ? "100px" : "70px"};
  width: 100%;
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const LogoContainer = styled.div<NodeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
  margin-left: ${(props) => props.marginLeft};

  #MenuIcon {
    font-size: xxx-large;
    &:hover{
      cursor: pointer;
    }
  }
`;

export const Title = styled.h3`
  @media screen and (max-width: 900px) {
    display: none;
  }
`

export const Logo = styled.div`
  overflow: hidden;
  border-radius: 5px;
  width: 100px;
  height: 50px;
  margin-right: 30px;

  @media screen and (max-width: 450px) {
    display: none;
  }
`;
export const LogoLink = styled(Link)``;

export const Image = styled.img`
  width: 100%;
  height: 100%;
`;

export const AvatarMenu = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 5px;
`;

export const NavItemContainer = styled.ul`
  list-style: none;
  color: #000;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
`;

export const NavItem = styled(Link)`
  color: #000;
  text-decoration: none;
  font-weight: bold;
  &:hover {
    // color: #98fb98;
    color: #3D90B0;
  }
`;

export const NavItemList = styled.li`
  margin-right: 20px;
  cursor: pointer;
  text-align: center;
`;
