import React, {useState} from 'react';
import { Box, Typography } from "@mui/material";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { OrganizationsData } from "../../types/OrganizationsType";
import { isAdminUser, isSuperSpecialUser, isTenantUser } from "../../utils/UserUtil";
import { MachinesData } from "../../types/MachinesType";
import { FilterContainer } from "../Shared/Common/Containers";
import { TenantsData } from "../../types/TenantsType";
import CalendarModal from '../Shared/CalendarModal/CalendarModal';

const DetectionFilterContainer = ({
    onChangeFilter,
    onChangeDateFilter,
    filterValue,
    dateFilterValue,
    orgList,
    machineList,
    tenantList,
    userInfo
}: any) => {
    const [showStartDateModal, setShowStartDateModal] = useState(false)
    const [showEndDateModal, setShowEndDateModal] = useState(false)

    const handleClickStartDate = () => {
        setShowStartDateModal(!showStartDateModal)
    }

    const handleClickEndDate = () => {
        setShowEndDateModal(!showEndDateModal)
    }

    const handleCloseDateModal = () => {
        setShowStartDateModal(false)
        setShowEndDateModal(false)
    }
    return ( 
        <FilterContainer>
            <Form.Group className="mb-3">
                <Row className="mb-3">
                    <Col xs={6} md={4} lg={2}>
                        <Box 
                            sx={(theme) => ({
                                display: "flex", 
                                gap: 1, 
                                alignItems: "center",
                                [theme.breakpoints.down('sm')]: {
                                    flexDirection: "column",
                                    justifyContent: "center",
                                },
                            })}
                        >
                            <Typography>Start Date</Typography>
                            <button type="button" className="btn btn-outline-dark" onClick={handleClickStartDate}>{dateFilterValue?.startDate || "Start Date"}</button>
                            <CalendarModal 
                                show={showStartDateModal}
                                onCloseModal={handleClickStartDate}
                                onChange={(value: Date) => {
                                    onChangeDateFilter("startDate", value)
                                    handleCloseDateModal()
                                }}
                                maxDate={new Date(dateFilterValue.endDate)}
                                defaultValue={new Date(dateFilterValue.startDate)}
                            />
                        </Box>
                    </Col>
                    <Col xs={6} md={4} lg={2}>
                        <Box 
                            sx={(theme) => ({
                                display: "flex", 
                                gap: 1, 
                                alignItems: "center",
                                [theme.breakpoints.down('sm')]: {
                                    flexDirection: "column",
                                    justifyContent: "center",
                                },
                            })}
                        > 
                            <Typography>End Date</Typography>
                            <button type="button" className="btn btn-outline-dark" onClick={handleClickEndDate}>{dateFilterValue?.endDate || "End Date"}</button>
                            <CalendarModal 
                                show={showEndDateModal}
                                onCloseModal={handleClickEndDate}
                                onChange={(value: Date) => {
                                    onChangeDateFilter("endDate", value)
                                    handleCloseDateModal()
                                }}
                                maxDate={new Date()}
                                minDate={new Date(dateFilterValue.startDate)}
                                defaultValue={new Date(dateFilterValue.endDate)}
                            />
                        </Box>
                    </Col>
                </Row>
                <Row
                >
                    <Col 
                        sm={12}
                        md={4}
                        className='mb-2'
                    >
                        <InputGroup
                            className='mb-10'
                        >
                            <InputGroup.Text className="input-user"><span>Organization</span></InputGroup.Text>
                            {
                                (isTenantUser() || isAdminUser()) ?
                                <Form.Select onChange={onChangeFilter} 
                                    name="organizationId" aria-label="organizatioinId" 
                                    value={userInfo.orgId}
                                    disabled
                                >
                                    <option value={userInfo.orgId}>
                                        {userInfo.orgName || "Organization Not Found"}
                                    </option>
                                </Form.Select> :
                                <Form.Select onChange={onChangeFilter} 
                                    name="organizationId" aria-label="organizatioinId" 
                                    value={filterValue.organizationId}
                                >
                                    <option value={0}>All</option>
                                    {
                                    (orgList && orgList?.length !== 0) && orgList.map((org:OrganizationsData) => 
                                        <option value={org.id} key={org.id}>{org.organizationName}</option>
                                    )
                                    }
                                </Form.Select>
                            }
                        </InputGroup>
                    </Col>
                    <Col
                        sm={12}
                        md={4}
                        className='mb-2'
                    >
                        <InputGroup>
                            <InputGroup.Text className="input-user"><span>Machine</span></InputGroup.Text>
                            {
                                <Form.Select onChange={onChangeFilter} 
                                name="machineId" aria-label="machineId" 
                                value={filterValue.machineId}
                                disabled={!Boolean(filterValue.organizationId && filterValue.organizationId !== '0')}
                                >
                                    <option value={0}>All</option>
                                    {
                                    (machineList && machineList?.length !== 0) && machineList.map((machine:MachinesData) => 
                                        <option value={machine.id} key={machine.id}>{machine.machineName}</option>
                                    )
                                    }
                                </Form.Select>
                            }
                        </InputGroup>
                    </Col>
                    <Col
                        sm={12}
                        md={4}
                    >
                        <InputGroup>
                            {
                                (!isTenantUser()) &&
                                <>
                                <InputGroup.Text className="input-user"><span>Tenant</span></InputGroup.Text>
                                    <Form.Select onChange={onChangeFilter} 
                                        name="tenantId" aria-label="tenantId" 
                                        value={filterValue.tenantId}
                                        disabled={!Boolean(filterValue.machineId && filterValue.machineId !== '0')}
                                    >
                                        <option value={0}>All</option>
                                        {
                                        (tenantList && tenantList?.length !== 0) && tenantList.map((tenant:TenantsData) => 
                                            <option value={tenant.id} key={tenant.id}>{tenant.tenantName}</option>
                                        )
                                        }
                                </Form.Select>
                                </>
                            }
                            </InputGroup>
                    </Col>
                </Row>
            </Form.Group>
        </FilterContainer>
     );
}
 
export default DetectionFilterContainer;