import { Box } from "@mui/material";
import { Col, Form, Row } from "react-bootstrap";
import { RolesData } from "../../../types/RoleType";
import { ChangeEvent } from "react";

type Props = {
    selectedRole: RolesData
    onChangeRolePermission: (event: ChangeEvent<HTMLInputElement>) => void
}

const AdminPermissions = (props: Props) => {
    const {selectedRole, onChangeRolePermission} = props
    // (checkSuperSpecialRole(selectedRole.id) || checkAdminRole(selectedRole.id) || selectedRole.isAdmin) &&
    return (  
        <Box>
            <Row>
                <Col>
                    <Form.Check
                        inline
                        label="View Tenant"
                        name="viewTenant"
                        value={1}
                        defaultChecked={selectedRole.viewTenant}
                        type={"checkbox"}
                        id={`view_tenant`}
                        onChange={onChangeRolePermission}
                        className="text-info"
                    />
                </Col>
                <Col>
                    <Form.Check
                        inline
                        label="View User"
                        name="viewUser"
                        defaultChecked={selectedRole.viewUser}
                        value={1}
                        type={"checkbox"}
                        id={`view_user`}
                        onChange={onChangeRolePermission}
                        className="text-info"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        inline
                        label="View LFC Machine"
                        name="viewLfcMachine"
                        value={1}
                        defaultChecked={selectedRole.viewLfcMachine}
                        type={"checkbox"}
                        id={`view_lfc_machine`}
                        onChange={onChangeRolePermission}
                        className="text-info"
                    />
                </Col>
                <Col>
                    <Form.Check
                        inline
                        label="View Machine Log"
                        name="viewMachineLog"
                        value={1}
                        defaultChecked={selectedRole.viewMachineLog}
                        type={"checkbox"}
                        id={`view_machine_log`}
                        onChange={onChangeRolePermission}
                        className="text-info"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        inline
                        label="Add User"
                        name="addUser"
                        value={1}
                        defaultChecked={selectedRole.addUser}
                        type={"checkbox"}
                        id={`add_user`}
                        onChange={onChangeRolePermission}
                        className="text-info"
                    />
                </Col>
                <Col>
                    <Form.Check
                        inline
                        label="Edit User"
                        name="editUser"
                        value={1}
                        defaultChecked={selectedRole.editUser}
                        type={"checkbox"}
                        id={`edit_user`}
                        onChange={onChangeRolePermission}
                        className="text-info"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        inline
                        label="View Pending User"
                        name="viewPendingUser"
                        value={1}
                        defaultChecked={selectedRole.viewPendingUser}
                        type={"checkbox"}
                        id={`view_pending_user`}
                        onChange={onChangeRolePermission}
                        className="text-info"
                    />
                </Col>
            </Row>
        </Box>
    );
}
 
export default AdminPermissions;