import { Typography } from "@mui/material";
import { FunctionComponent } from "react";
import { Button, Modal } from "react-bootstrap";

interface ConfirmDeleteModalProps {
    show: boolean,
    toggleShow: () => void,
    handleRemoveLocation: () => void
}
 
const ConfirmDeleteModal: FunctionComponent<ConfirmDeleteModalProps> = ({
    show,
    toggleShow,
    handleRemoveLocation
}) => {
    return (  
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Confirm Remove</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Typography>Are you sure you want to remove this location?</Typography>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={toggleShow}>Cancel</Button>
                <Button variant="danger" onClick={handleRemoveLocation}>Remove</Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default ConfirmDeleteModal;