import React, {FC, ReactNode} from "react";
import { Container } from "./CardElements";
import {NodeProps} from "../../../types/CommonType";

const Card : FC<NodeProps> = ({children, backgroundColor, width}) => {
  return (
    <>
      <Container width={width} backgroundColor={backgroundColor}>
        {children}
      </Container>
    </>
  );
}

export default Card;
