import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Box from "@mui/material/Box"
import "./Config.css"
import { convertToLocaleDate } from "../../utils/DateTimeUtil";

const EmailConfig = ({
    onChange,
    emailConfig,
    onClick
}: any) => {
    return ( 
        <Box
            sx={{minWidth: "400px"}}
        >
            <Form>
                <Form.Label as="legend">
                    Email Config
                </Form.Label>
                <div
                    className="border border-light border-3 rounded p-3 bg-light bg-gradient" 
                >
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            Hostname
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" placeholder='hostname' name="hostname" value={emailConfig?.hostname} onChange={onChange} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            Email
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="email" placeholder='email' name="email" value={emailConfig?.email} onChange={onChange} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            Password
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="password" placeholder="password" name="password" value={emailConfig?.password} onChange={onChange} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            From Email
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="email" placeholder='from email' name="fromEmail" value={emailConfig?.fromEmail} onChange={onChange} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            Token
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" placeholder="Token" name="token" value={emailConfig?.token} onChange={onChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="">
                        <Form.Label column sm="3">
                             
                        </Form.Label>
                        <Col sm="9">
                            <span style={{fontSize:13, color:'#35a612'}}>Last Update - {convertToLocaleDate(emailConfig?.updatedAt) || convertToLocaleDate(emailConfig?.createdAt)}</span>
                            <br></br>
                            <span style={{fontSize:13, color:'#35a612'}}>Updated User - {emailConfig?.updatedBy || emailConfig?.createdBy}</span>
                        </Col>
                    </Form.Group>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "right"
                        }}
                    >
                        <Button variant="primary" onClick={onClick}>Update</Button>
                    </Box>
                </div>
            </Form>
        </Box>
    );
}
 
export default EmailConfig;