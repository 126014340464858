import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserAuthContext } from "../contexts/UserAuthContext";
import { UserAuthType } from "../types/UserAuth";

const Permission = ({
    permission
}: {permission: string}) => {
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    return hasPermission(permission) ? (
        <Outlet />
    ) : (
        <Navigate to={"/not-found"} replace />
    );
};

export default Permission;
