export interface PendingUsersGETListRequest {
    searchText: string,
    offset: number,
    limit: number
}

export interface PendingUserType {
    id: number,
    username: string,
    email: string,
    remark: string,
    createdAt: string
}

export const DefaultPendingUser = {
    id: 0,
    username: "",
    email: "",
    remark: "",
    createdAt: ""
}

export interface ApproveFormDataType {
    orgId: number,
    remark?: string
}

export const ApproveFormDefaultData = {
    orgId: 0,
    remark: ""
}

export interface ApproveRequest {
    id: number,
    approvedBy: string,
}

export interface RejectRequest {
    id: number,
    rejectedBy: string
}