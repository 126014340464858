// * Timestamp conversion
import moment from "moment";
import 'moment-timezone';

export const convertToLocaleDate = (date: string | undefined) => {
    if (!date) return ""
    let dateFormat = "";
    const currentTimezone = moment.tz.guess();
    if (date) {
        dateFormat = moment(date).tz(currentTimezone).format("YYYY-MM-DD HH:mm:ss");
    }
    return dateFormat;
}

export function convertUTCHourToLocal(utcHour: number) {
    const utcDate = new Date();
    utcDate.setUTCHours(utcHour);
    
    const localHour = utcDate.getHours();
    return localHour;
}

export const getCurrentLocaleStartDate = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day} 00:00:00`;
    return formattedDate
}

export const getCurrentLocaleEndDate = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day} 23:59:59`;
    return formattedDate
}

export const getCurrentLocaleDate = (): string => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');

    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate
}

export const addMinuteToDate = (date: string, minute: number) => {
    let dateFormat = null;
    if (date) {
        const momentDate = moment(date);
        momentDate.add(minute, "minute");
        dateFormat = momentDate.format("YYYY-MM-DD HH:mm:ss");
    }
    return dateFormat;
}

export const addSecondToDate = (date: string, second: number) => {
    let dateFormat = null;
    if (date) {
        const momentDate = moment(date);
        momentDate.add(second, "second"); 
        dateFormat = momentDate.format("YYYY-MM-DD HH:mm:ss");
    }
    return dateFormat;
}

// from 2023-05-12 to 2023.05.12
export const convertDateFormat = (date: string) => {
    if (!date) return ""
    const dateObj = new Date(date)
    return `${dateObj.getDate()}.${dateObj.getMonth() + 1}.${dateObj.getFullYear()}`
}

export const convertDateToString = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const formattedDate = `${year}-${month}-${day} 23:59:59`;
    return formattedDate
}

export const getDayDifference = (startDate: string, endDate: string) => {
    if (startDate.length < 0 || endDate.length <0) return 0
    const date1 = new Date(startDate)
    const date2 = new Date(endDate)
    const differenceMs = date2.getTime() - date1.getTime()
    const differenceDays = Math.round(differenceMs / (24 * 60 * 60 * 1000));

    return differenceDays;
}

export const getMonthChoices = () => {
    const monthChoices = [
        { label: 'January', value: '1' },
        { label: 'February', value: '2' },
        { label: 'March', value: '3' },
        { label: 'April', value: '4' },
        { label: 'May', value: '5' },
        { label: 'June', value: '6' },
        { label: 'July', value: '7' },
        { label: 'August', value: '8' },
        { label: 'September', value: '9' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' },
    ];
    return monthChoices
}

export const getMonthName = (month: number) => {
    if (month < 1 || month > 12) return ""
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July',
        'August', 'September', 'October', 'November', 'December'
    ]

    return months[month - 1]
}
  
export const getYearChoices = () => {
    const currentYear = new Date().getFullYear();
    const yearChoices = [];

    for (let year = currentYear; year >= currentYear - 5; year--) {
        yearChoices.push({ label: `${year}`, value: `${year}` });
    }
    return yearChoices
}

export function getDaysListOfMonth(month: number, year: number) {
    const daysInMonth = new Date(year, month, 0).getDate();
    const daysList = [];
    for (let day = 1; day <= daysInMonth; day++) {
      daysList.push(`${day}.${month}.${year}`);
    }
    return daysList;
}

export function getStartDateAndEndDateOfAMonth(monthIndex: number, year: number) {
    const startDate = new Date(year, monthIndex, 1);
    startDate.setDate(startDate.getDate() + 1)
    const endDate = new Date(year, monthIndex + 1, 1);
  
    const startDateString = startDate.toISOString().split('T')[0];
    const endDateString = endDate.toISOString().split('T')[0];
  
    return {
      startDate: startDateString,
      endDate: endDateString
    };
}

export const getDatesBetweenStartDateAndEndDate = (startDateInput: string, endDateInput: string) => {
    const dateArray = [];
    const currentDate = new Date(startDateInput);

    while (currentDate <= new Date(endDateInput)) {
        dateArray.push(`${currentDate.getDate()}.${currentDate.getMonth() + 1}.${currentDate.getFullYear()}`);
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
}