import { Navigate, Outlet } from "react-router-dom";
import { isRootAndSuperUsers } from "../utils/UserUtil";

const SuperSpecialRoute = () => {
  return isRootAndSuperUsers() ? (
    <Outlet />
  ) : (
    <Navigate to={"/not-found"} replace />
  );
};

export default SuperSpecialRoute;
