import { ManualDetectionADDRequestBody, ManualDetectionDailyReportByOrgIdRequest, ManualDetectionDailyReportByUserIdRequest, ManualDetectionGETRequestBody, ManualDetectionUPDATERequestBody, ManualDetectionYearlyReportByOrgIdRequest, ManualDetectionYearlyReportByUserIdRequest } from "../../types/ManualDetectionType";
import http from "../apiServices";


export const manualDetectionAddApi = (inputData : ManualDetectionADDRequestBody) => {
    return http.post("/manual-detection/add", inputData)
}

export const manualDetectionDeleteApi = (inputData : {id: number, deletedBy: string}) => {
    return http.post("/manual-detection/delete", inputData)
}

export const manualDetectionUpdateApi = (inputData : ManualDetectionUPDATERequestBody) => {
    return http.post("/manual-detection/update", inputData)
}

export const manualDetectionGetListByOrgIdApi = (inputData : ManualDetectionGETRequestBody) => {
    return http.post("/manual-detection/get/list/by/orgId", inputData)
}

export const manualDetectionGetListByUserIdApi = (inputData : ManualDetectionGETRequestBody) => {
    return http.post("/manual-detection/get/list/by/userId", inputData)
} 

export const manualDetectionGetExportListByOrgIdApi = (inputData : ManualDetectionGETRequestBody, options?: any) => {
    return http.post("/manual-detection/get/export-list/by/orgId", inputData, options)
}

export const manualDetectionGetExportListByUserIdApi = (inputData : ManualDetectionGETRequestBody) => {
    return http.post("/manual-detection/get/export-list/by/userId", inputData)
} 

export const manualDetectionGetDailyReportByOrgIdApi = (inputData : ManualDetectionDailyReportByOrgIdRequest) => {
    return http.post("/manual-detection/get/daily-report/by/orgId", inputData)
}

export const manualDetectionGetDailyReportByUserIdApi = (inputData : ManualDetectionDailyReportByUserIdRequest) => {
    return http.post("/manual-detection/get/daily-report/by/userId", inputData)
}

export const manualDetectionGetYearlyReportByOrgIdApi = (inputData : ManualDetectionYearlyReportByOrgIdRequest) => {
    return http.post("/manual-detection/get/yearly-report/by/orgId", inputData)
}

export const manualDetectionGetYearReportByUserIdApi = (inputData : ManualDetectionYearlyReportByUserIdRequest) => {
    return http.post("/manual-detection/get/yearly-report/by/userId", inputData)
}