import Alert from "@mui/material/Alert";
import Box, { BoxProps } from "@mui/material/Box";
import { ChangeEvent, useEffect, useState } from 'react';
import { STATUS_CODES } from '../../constants/GlobalConstants';
import {
    emailConfigAddDataApi, emailConfigGetFirstRowApi,
    lfcTokenConfigAddDataApi,
    lfcTokenConfigGetFirstRowApi,
    smsConfigAddDataApi,
    smsConfigGetFirstRowApi,
    syncBackgroundLogAddApi, syncBackgroundLogGetFirstRowApi,
    syncLfcDataAddApi, syncLfcDataGetFirstRowApi
} from '../../services/RouteServices/ConfigApi';
import {
    EmailConfigData, EmailConfigDefault,
    LfcTokenConfigData,
    LfcTokenConfigDefault,
    SmsConfigData, SmsConfigDefault,
    SyncBackgroundLogDefault,
    SyncLfcData, SyncLfcDataDefault
} from '../../types/EmailConfigType';
import { getCurrentLocalUser } from '../../utils/UserUtil';
import Layout from '../Layout/Layout';
import { Container } from '../Shared/Common/Containers';
import { Title } from '../Shared/Common/Titles';
import "./Config.css";
import EmailConfig from './EmailConfig';
import InternalTelegramConfig from './InternalTelegramConfig';
import LfcTokenConfig from './LfcTokenConfig';
import MaintenanceConfig from './MaintenanceConfig';
import SmsConfig from './SmsConfig';
import SynBackgroundLogConfig from './SyncBackgroundLogConfig';
import SynLfcDataConfig from './SyncLfcDataConfig';
import TelegramConfig from './TelegramConfig';
import LocationMapApiKey from "./LocationMapApiKey";
import GoogleLogAuth from "./GoogleLogAuth";
import OperatorLogConfig from "./OperatorLogConfig";
import { styled } from "@mui/material";

const DivisionBox = styled(Box)<BoxProps>(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: "space-around",
    paddingBottom: "20px",
    borderBottom: '1px solid',
}));


const SectionBox = styled(Box)<BoxProps>(({ theme }) => ({
    width: "45%",
    gap: 5,
    marginTop: "20px",
    [theme.breakpoints.down('lg')]: {
        width: "100%",
        justifyContent: "space-between"
    },
}));


const Config = () => {
    const userInfo = getCurrentLocalUser()
    
    const [syncLfcData, setSyncLfcData] = useState<SyncLfcData>(SyncLfcDataDefault);
    const [lfcDataSwitch, setLfcDataSwitch] = useState<boolean>(false);
    const [backgroundLogSwitch, setBackgroundLogSwitch] = useState<boolean>(false);
    const [syncBackgroundLog, setSyncBackgroundLog] = useState<SyncLfcData>(SyncBackgroundLogDefault);
    const [emailConfigData, setEmailConfigData] = useState<EmailConfigData>(EmailConfigDefault)
    const [smsConfigData, setSmsConfigData] = useState<SmsConfigData>(SmsConfigDefault)
    const [lfcTokenConfigData, setLfcTokenConfigData] = useState<LfcTokenConfigData>(LfcTokenConfigDefault);
    const [successText, setSuccessText] = useState<String>("");
    const [errorText, setErrorText] = useState<String>("");

    useEffect(() => {
        emailConfigGetFirstRowApi()
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setEmailConfigData(res?.data?.data)
            } else {
                console.log(res?.data?.message)
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)   
        })

        smsConfigGetFirstRowApi()
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setSmsConfigData(res?.data?.data)
            } else {
                console.log(res?.data?.message)
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)   
        })

        lfcTokenConfigGetFirstRowApi()
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setLfcTokenConfigData(res?.data?.data)
            } else {
                console.log(res?.data?.message)
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)   
        })

        syncLfcDataGetFirstRowApi()
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setSyncLfcData(res?.data?.data);
                setLfcDataSwitch(!!res?.data?.data?.isSync);
            } else {
                console.log(res?.data?.message)
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)   
        })

        syncBackgroundLogGetFirstRowApi()
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setSyncBackgroundLog(res?.data?.data);
                setBackgroundLogSwitch(!!res?.data?.data?.isSync);
            } else {
                console.log(res?.data?.message)
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)   
        })
    }, [])

    const handleLfcDataSwitch = () => {
        setLfcDataSwitch(!lfcDataSwitch);
        const inputObject = {
            isSync: +!lfcDataSwitch,
            doneBy: userInfo.userName
        }
        syncLfcDataAddApi(inputObject)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setSyncLfcData(res?.data?.data)
            } else {
                setLfcDataSwitch(!lfcDataSwitch);
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message);
            setLfcDataSwitch(!lfcDataSwitch)
        })
    }

    const handleBackgroundLogSwitch = () => {
        setBackgroundLogSwitch(!backgroundLogSwitch);
        const inputObject = {
            isSync: +!backgroundLogSwitch,
            doneBy: userInfo.userName
        }
        syncBackgroundLogAddApi(inputObject)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setSyncBackgroundLog(res?.data?.data)
            } else {
                setBackgroundLogSwitch(!backgroundLogSwitch);
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message);
            setBackgroundLogSwitch(!backgroundLogSwitch)
        })
    }

    const handleChangeEmailConfigData = (event: ChangeEvent<HTMLInputElement>) => {
        setEmailConfigData(
            prevData => {
                return {
                    ...prevData,
                    [event?.target.name]: event?.target.value
                }
            }
        )
    }

    const handleChangeSmsConfigData = (event: ChangeEvent<HTMLInputElement>) => {
        setSmsConfigData(
            prevData => {
                return {
                    ...prevData,
                    [event?.target.name]: event?.target.value
                }
            }
        )
    }

    const handleChangeLfcTokenConfigData = (event: ChangeEvent<HTMLInputElement>) => {
        setLfcTokenConfigData(
            prevData => {
                return {
                    ...prevData,
                    [event?.target.name]: event?.target.value
                }
            }
        )
    }

    const handleAddEmailConfig = () => {
        setErrorText("")
        const dataLimitObject = {
            hostname: emailConfigData?.hostname?.trim() || "",
            email: emailConfigData?.email?.trim() || "",
            fromEmail: emailConfigData?.fromEmail?.trim() || "",
            password: emailConfigData?.password?.trim() || "",
            token: emailConfigData?.token?.trim() || "",
            doneBy: userInfo.userName
        }
        emailConfigAddDataApi(dataLimitObject)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setEmailConfigData(res?.data?.data)
                setSuccessText(res?.data?.message)
                setTimeout(() => {
                    setSuccessText("")
                }, 2000)
            } else {
                setErrorText(res?.data?.message)
            }
        })
        .catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }

    const handleAddSmsConfig = () => {
        setErrorText("")
        const dataLimitObject = {
            hostname: smsConfigData?.hostname?.trim() || "",
            token: smsConfigData?.token?.trim() || "",
            countryCode: smsConfigData?.countryCode?.trim() || "",
            remark: smsConfigData?.remark?.trim() || "",
            doneBy: userInfo.userName
        }
        smsConfigAddDataApi(dataLimitObject)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setSmsConfigData(res?.data?.data)
                setSuccessText(res?.data?.message)
                setTimeout(() => {
                    setSuccessText("")
                }, 2000)
            } else {
                setErrorText(res?.data?.message)
            }
        })
        .catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }

    const handleAddLfcTokenConfig = () => {
        setErrorText("")
        const dataLimitObject = {
            lfcHostname: lfcTokenConfigData?.lfcHostname?.trim() || "",
            lfcToken: lfcTokenConfigData?.lfcToken?.trim() || "",
            remark: lfcTokenConfigData?.remark?.trim() || "",
            doneBy: userInfo.userName
        }
        lfcTokenConfigAddDataApi(dataLimitObject)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setLfcTokenConfigData(res?.data?.data)
                setSuccessText(res?.data?.message)
                setTimeout(() => {
                    setSuccessText("")
                }, 2000)
            } else {
                setErrorText(res?.data?.message)
            }
        })
        .catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }

    return ( 
        <>
            <Layout>
                <Container>
                    <Title>Config</Title>
                    {successText && <Alert severity="success">{successText}</Alert>}
                    {errorText && <Alert severity="error">{errorText}</Alert>}
                    <DivisionBox>
                        <SectionBox>
                            <EmailConfig 
                                onChange={handleChangeEmailConfigData}
                                emailConfig={emailConfigData}
                                onClick={handleAddEmailConfig}
                            />
                            <SmsConfig 
                                onChange={handleChangeSmsConfigData}
                                smsConfig={smsConfigData}
                                onClick={handleAddSmsConfig}
                            />
                        </SectionBox>
                        <SectionBox>
                            <LfcTokenConfig 
                                lfcTokenConfig={lfcTokenConfigData}
                                onChange={handleChangeLfcTokenConfigData}
                                onClick={handleAddLfcTokenConfig}
                            />

                            <MaintenanceConfig 
                                handleErrorText={setErrorText}
                                handleSuccessText={setSuccessText}
                            />
                            <SynLfcDataConfig 
                                lfcDataSwitch={lfcDataSwitch}
                                handleLfcDataSwitch={handleLfcDataSwitch}
                            />
                            <SynBackgroundLogConfig 
                                backgroundLogSwitch={backgroundLogSwitch}
                                handleBackgroundLogSwitch={handleBackgroundLogSwitch}
                            />
                        </SectionBox>
                    </DivisionBox>
                    <DivisionBox>
                        <SectionBox>
                            <TelegramConfig />
                            <LocationMapApiKey />
                        </SectionBox>
                        <SectionBox>
                            <InternalTelegramConfig />
                            <GoogleLogAuth />
                        </SectionBox>
                    </DivisionBox>
                    <DivisionBox>
                        <SectionBox>
                            <OperatorLogConfig />
                        </SectionBox>
                    </DivisionBox>
                </Container>
            </Layout>
        </>
    );
}
 
export default Config;