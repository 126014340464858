import React, { ChangeEvent, useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styling/customStyle.css';
import { useNavigate } from "react-router-dom";

const InstanceRedirect: React.FC = () => {
    const navigate = useNavigate();
    const redirectToHome = () => {
        navigate("/dashboard", {replace: true});
    }

    useEffect(() => {
        redirectToHome();
    }, [])

    return (
        <>
        </>
    );
};

export default InstanceRedirect;