import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Box from "@mui/material/Box"
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import WarningIcon from '@mui/icons-material/Warning';
import "./Config.css"

const SynBackgroundLogConfig = ({
    backgroundLogSwitch,
    handleBackgroundLogSwitch
}: any) => {
    return ( 
        <Box
            className="rounded p-3 bg-light-blue bg-gradient sync-lfc-data-box" 
        >
            <FormControlLabel
                value="synLfcData"
                control={
                    <Switch color="primary" checked={backgroundLogSwitch ? true : false}
                        onChange={handleBackgroundLogSwitch}
                    />
                }
                label="Sync Background Log"
                labelPlacement="start"
                sx={{
                    "& .MuiFormControlLabel-label" : {
                        fontWeight: "bold",
                        fontSize: "1.1rem"
                    }
                }}
            />
            {
                backgroundLogSwitch ?
                <div className="p-1 border rounded bg-success text-white">
                    <CloudDoneIcon
                        sx={{
                          width: 30,
                          height: 30
                        }}
                      ></CloudDoneIcon>
                </div> :
                <>
                    <span className="p-1 text-light bg-danger blink_me">
                        <WarningIcon
                        sx={{
                            width: 20,
                            height: 20
                        }}
                        >
                        </WarningIcon>
                        OFF
                    </span>
                </>
            }
        </Box>
    );
}
 
export default SynBackgroundLogConfig;