import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Typography } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from 'swiper/react';
import { NO_IMAGE_URL } from '../../../constants/GlobalConstants';
import { convertToLocaleDate } from '../../../utils/DateTimeUtil';
import { mergeImageUrlWithBaseUrl } from '../../../utils/ImageUtil';
import "./styles.css";

const ImageModalButton = ({
    title,
    thumbnail,
    images,
    count,
    buttonType,
    handleShow,
    show,
    onOpen,
    onClose,
}: any) => {
    const handleOpenModal = () => {
        handleShow(true)
    }

    const handleCloseModal = () => {
        handleShow(false)
    }

    return ( 
        <>
            {
                buttonType === "image" && 
                <img 
                    src={thumbnail} 
                    onClick={() => {handleOpenModal(); onOpen();}} width="350px" height="250px"
                    style={{
                        borderRadius: 15,
                        objectFit: "cover",
                    }}
                />
            }
            <Modal show={show} size="lg">
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                    <CloseOutlinedIcon 
                        onClick={() => {handleCloseModal(); onClose();}}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Typography>Total Image Count: {count}</Typography>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        pagination={{
                            dynamicBullets: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="image-modal-swiper"
                    >
                        {
                            (images && images.length > 0) ?
                            images.map((image: {url: string, captureTime: string}, index: number) => 
                                <SwiperSlide key={index} className='image-modal-swiper-slide'>
                                    <img src={mergeImageUrlWithBaseUrl(image?.url) || NO_IMAGE_URL} alt={title} style={{objectFit: "cover"}} />
                                    <p>{convertToLocaleDate(image?.captureTime) || "No Date Available"}</p>
                                </SwiperSlide>
                            ) :
                            <SwiperSlide className='image-modal-swiper-slide'>
                                <img src={NO_IMAGE_URL} alt="Not Found" style={{objectFit: "cover"}} />
                            </SwiperSlide>
                        }
                    </Swiper>
                </Modal.Body>
            </Modal>
        </>
    );
}
 
export default ImageModalButton;