import * as React from 'react';
import { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Box from "@mui/material/Box"
import { convertToLocaleDate } from '../../utils/DateTimeUtil';
import { useFilterOrganizations } from '../../custom-hooks/useFilterOrganizations';
import { getCurrentLocalUser, isAdminUser, isTenantUser } from '../../utils/UserUtil';
import { OrganizationsData } from '../../types/OrganizationsType';
import { UserInfo } from '../../types/UserAuth';
import { DefaultTelegramConfig, TelegramConfigData } from '../../types/TelegramConfigType';
import { telegramConfigAddApi, telegramConfigGetByOrgIdApi, toggleSendMessageTelegramConfigApi } from '../../services/RouteServices/ConfigApi';
import { STATUS_CODES } from '../../constants/GlobalConstants';
import SyncTelegramSendMessage from './SyncTelegramSendMessage';
import { Alert } from '@mui/material';
import { sendTelegramMessageApi } from '../../services/RouteServices/TelegramApi';
import LoadingModal from '../Shared/LoadingModal/LoadingModal';

const TelegramConfig = () => {
    const userInfo: UserInfo = getCurrentLocalUser();

    const [selectedOrganization, setSelectedOrganization] = useState<number>(userInfo.orgId)
    const [telegramConfig, setTelegramConfig] = useState<TelegramConfigData>(DefaultTelegramConfig)
    const [telegramSwitch, setTelegramSwitch] = useState<boolean>(false);
    const [successText, setSuccessText] = useState<String>("");
    const [errorText, setErrorText] = useState<String>("");
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const {organizations, error: orgError} = useFilterOrganizations()

    useEffect(() => {
        setErrorText("")
        telegramConfigGetByOrgIdApi({orgId: selectedOrganization})
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setTelegramSwitch(res?.data?.data?.isSendMessage);
                setTelegramConfig(res?.data?.data)
            } else {
                console.log(res?.data?.message)
                setTelegramConfig(DefaultTelegramConfig)
                setTelegramSwitch(false)
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message) 
            setTelegramConfig(DefaultTelegramConfig)
            setTelegramSwitch(false)  
        })
    }, [selectedOrganization])

    const handleChangeOrganization = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedOrganization(+event.target.value)
    }

    const handleChangeTelegramConfig = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTelegramConfig(
            prevData => {
                return {
                    ...prevData,
                    [event.target.name]: event.target.value
                }
            }
        )
    }

    const handleChangeTelegramSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTelegramSwitch(event.target.checked)
        const inputObject = {
            orgId: selectedOrganization,
            isSendMessage: event.target.checked,
            doneBy: userInfo.userName
        }
        toggleSendMessageTelegramConfigApi(inputObject)
        .then(res => {
            if (res?.data?.status !== STATUS_CODES.SUCCESS_CODE) {
                setTelegramSwitch(!event.target.checked);
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message);
            setTelegramSwitch(!event.target.checked)
        })
    }

    const handleAddTelegramConfig = () => {
        setErrorText("")
        const dataLimitObject = {
            orgId: selectedOrganization,
            botToken: telegramConfig?.botToken?.trim() || "",
            channelId: telegramConfig?.channelId?.trim() || "",
            doneBy: userInfo.userName
        }

        telegramConfigAddApi(dataLimitObject)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setTelegramConfig(res?.data?.data)
                setSuccessText(res?.data?.message)
                setTimeout(() => {
                    setSuccessText("")
                }, 2000)
            } else {
                setErrorText(res?.data?.message)
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }

    const handleTestTelegramConfig = () => {
        setErrorText("")
        setIsLoading(true)
        const input = {
            orgId: selectedOrganization,
            botToken: telegramConfig?.botToken?.trim() || "",
            channelId: telegramConfig?.channelId?.trim() || "",
            testedBy: userInfo?.userName,
        }
        sendTelegramMessageApi(input)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setSuccessText(res?.data?.message)
                setTimeout(() => {
                    setSuccessText("")
                }, 2000)
            } else {
                setErrorText(res?.data?.message)
            }
            setIsLoading(false)
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
            setIsLoading(false)
        })
    }

    return (  
        <Box
            sx={{minWidth: "400px", marginTop: 3}}
        >
            <LoadingModal show={isLoading} />
            <Form>
                <Form.Label as="legend">
                    Telegram Config
                </Form.Label>
                {successText && <Alert severity="success" className="mb-3">{successText}</Alert>}
                {errorText && <Alert severity="error" className="mb-3">{errorText}</Alert>}
                <div
                    className="border border-light border-3 rounded p-3 bg-light bg-gradient" 
                >
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            <p className='mb-0'>
                                <span>Organization</span><span className='text-danger'>*</span>
                            </p>
                        </Form.Label>
                        <Col sm="9">
                           {
                            (isTenantUser() || isAdminUser()) ?
                                <Form.Select onChange={handleChangeOrganization} 
                                    name="organizationId" aria-label="organizatioinId" 
                                    value={selectedOrganization}
                                    disabled
                                >
                                    <option value={userInfo.orgId}>
                                        {userInfo.orgName || "Organization Not Found"}
                                    </option>
                                </Form.Select> :
                                <Form.Select onChange={handleChangeOrganization} 
                                    name="organizationId" aria-label="organizatioinId" 
                                    value={selectedOrganization}
                                >
                                    {
                                    (organizations && organizations?.length !== 0) && organizations.map((org:OrganizationsData) => 
                                        <option value={org.id} key={org.id} >{org.organizationName}</option>
                                    )
                                    }
                                </Form.Select>
                            }
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            <p className='mb-0'>
                                <span>Bot Token</span><span className='text-danger'>*</span>
                            </p>
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" name="botToken" value={telegramConfig.botToken || ""} onChange={handleChangeTelegramConfig} placeholder='6222126201:AAHoG7QI-T...' />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            <p className='mb-0'>
                                <span>Channel Id</span><span className='text-danger'>*</span>
                            </p>
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="email" placeholder='@channel_id' name="channelId" value={telegramConfig.channelId || ""} onChange={handleChangeTelegramConfig} />
                        </Col>
                    </Form.Group>
                    
                    <Form.Group as={Row} className="">
                        <Form.Label column sm="3">
                             
                        </Form.Label>
                        <Col sm="9">
                            <span style={{fontSize:13, color:'#35a612'}}>Last Update - {convertToLocaleDate(telegramConfig?.updatedAt || "") || convertToLocaleDate(telegramConfig?.createdAt || "")}</span>
                            <br></br>
                            <span style={{fontSize:13, color:'#35a612'}}>Updated User - {telegramConfig.updatedBy || telegramConfig.createdBy}</span>
                        </Col>
                    </Form.Group>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "right",
                            gap: 2,
                            marginTop: 3,
                        }}
                    >
                        <Button variant="warning" onClick={handleTestTelegramConfig}>Test</Button>
                        <Button variant="primary" onClick={handleAddTelegramConfig}>Update</Button>
                    </Box>
                    <SyncTelegramSendMessage 
                        telegramSwitch={telegramSwitch}
                        handleTelegramSwitch={handleChangeTelegramSwitch}
                    />
                </div>
            </Form>
        </Box>
    );
}
 
export default TelegramConfig;