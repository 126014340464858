import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Users.css'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from "react-bootstrap/Button"
import Alert from "@mui/material/Alert"
import { OrganizationsData } from "../../types/OrganizationsType";
import { ModalContainer } from "../Shared/Common/Containers";
import { useFilterOrganizations } from "../../custom-hooks/useFilterOrganizations";

const ApproveModal = ({
    show,
    onChange,
    onApprove,
    onClose,
    errorText,
    formData
}: any): JSX.Element => {
    const {organizations, error: orgError} = useFilterOrganizations()
    return ( 
        <Modal show={show}>
        <Modal.Header>
            <Modal.Title>Approve Pending User</Modal.Title>
        </Modal.Header>
        {errorText && <Alert severity="error">{errorText}</Alert>}
        <Modal.Body>
            <Form className="ModalForm">
                <ModalContainer>
                    <Form.Group className="mb-3">
                        <InputGroup className="mb-3">
                            <InputGroup.Text className="input-user"><span>Organization <span className="text-danger">*</span></span></InputGroup.Text>
                            <Form.Select onChange={onChange} 
                            name="orgId" aria-label="organizatioinId" 
                            value={formData.orgId}
                            >
                                <option value={0}>Choose Organization</option>
                                {
                                    (organizations && organizations?.length !== 0 && Array.isArray(organizations)) && organizations.map((org:OrganizationsData) => 
                                        <option value={org.id} key={org.id}>{org.organizationName}</option>
                                    )
                                }
                            </Form.Select> 
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <InputGroup className="mb-3">
                            <InputGroup.Text className="input-user"><span>Remark</span></InputGroup.Text>
                            <Form.Control onChange={onChange} 
                                type="text" name="remark" placeholder="Enter Remark" 
                                value={formData.remark} required
                            />
                        </InputGroup>
                    </Form.Group>
                </ModalContainer>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>Cancel</Button>
            <Button variant="success" onClick={onApprove}>Approve</Button>
        </Modal.Footer>
    </Modal>
    );
}
 
export default ApproveModal;