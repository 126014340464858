import { DashboardOverallByOrgIdRequestbody, DashboardOverallOfATenantRequestbody, DashboardOverallRequestbody } from "../../types/DashboardType";
import http from "../apiServices";

export const getOverallDataApi = (inputObj: DashboardOverallRequestbody) => {
    return http.post("/dashboard/get/overall", inputObj);
}

export const getOverallDataApiByOrgIdApi = (inputObj: DashboardOverallByOrgIdRequestbody) => {
    return http.post("/dashboard/get/overall/by/orgId", inputObj);
}

export const getOverallDataApiOfATenantApi = (inputObj: DashboardOverallOfATenantRequestbody) => {
    return http.post("/dashboard/get/overall/of/tenant", inputObj);
}

export const getMachineListByOrgIdApi = (inputObj: DashboardOverallByOrgIdRequestbody) => {
    return http.post("/dashboard/get/machine/list/by/orgId", inputObj)
}

export const getMachineListOfATenantApi = (inputObj: DashboardOverallOfATenantRequestbody) => {
    return http.post("/dashboard/get/machine/list/of/tenant", inputObj)
}

export const getMachineListByLocationIdApi = (inputObj: any) => {
    return http.post("/dashboard/get/machines/by/locationId", inputObj)
}

export const getLocationsByOrgIdApi = (inputObj: any) => {
    return http.post("/dashboard/get/locations/by/orgId", inputObj)
}

export const getTopFiveTenantsOfADayApi = (inputObj: any) => {
    return http.post("/dashboard/get/top-five-tenants-of-a-day/by/machineId", inputObj)
}