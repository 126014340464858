import { Box } from "@mui/material";
import { Button } from "react-bootstrap";
import { FunctionComponent } from "react";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { FilterValue } from "./ReportDetails";

interface ButtonsProps {
    filterValues: FilterValue,
    handlePreviewDaily: () => void
    handlePreviewMonthly: () => void
    handlePreviewChart: () => void
    handleClickDaily: () => void
    handleClickMonthly: () => void
    handleClickSpecialPdf: () => void
}
 
const ReportDetailsButtons: FunctionComponent<ButtonsProps> = ({
    filterValues, handlePreviewDaily, handlePreviewMonthly, handlePreviewChart,
    handleClickDaily, handleClickMonthly, handleClickSpecialPdf
}) => {
    return (  
        <Box sx={{
            display: "flex",
            width: "100%",
            gap: 1,
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Box sx={(theme) => ({
                width: "45%",
                borderRight: "solid",
                display: "flex",
                justifyContent: "start",
                flexWrap: "wrap",
                gap: 1,
                [theme.breakpoints.down('md')]: {
                    border: "none",
                    width: "100%",
                    gap: 2,
                    justifyContent: "center",
                    marginBottom: 3,
                },
            })}>
                <Button variant="info" onClick={handlePreviewDaily}
                    disabled={(+filterValues.machineId === 0 || +filterValues.organizationId === 0
                        || +filterValues.year === 0 || +filterValues.month === 0) ? true : false
                    } 
                >
                    Daily <RemoveRedEyeOutlinedIcon />
                </Button>
                <Button variant="info" onClick={handlePreviewMonthly}
                    disabled={(+filterValues.machineId === 0 || +filterValues.organizationId === 0
                        || +filterValues.year === 0 || +filterValues.month === 0) ? true : false
                    } 
                >
                    Monthly <RemoveRedEyeOutlinedIcon />
                </Button>
                <Button variant="info" onClick={handlePreviewChart}
                    disabled={(+filterValues.machineId === 0 || +filterValues.organizationId === 0
                        || +filterValues.year === 0 || +filterValues.month === 0) ? true : false
                    } 
                >
                    Daily Chart <RemoveRedEyeOutlinedIcon />
                </Button>
            </Box>
            <Box sx={(theme) => ({
                width: "45%",
                display: "flex",
                marginLeft: "3%",
                justifyContent: "start",
                flexWrap: "wrap",
                gap: 1,
                [theme.breakpoints.down('md')]: {
                    border: "none",
                    width: "100%",
                    gap: 2,
                    justifyContent: "center"
                }
            })}>
                <Button type="submit" onClick={handleClickDaily}
                    disabled={(+filterValues.machineId === 0 || +filterValues.organizationId === 0
                        || +filterValues.year === 0 || +filterValues.month === 0) ? true : false
                    } 
                >
                    Daily <DownloadRoundedIcon />
                </Button>
                <Button type="submit" onClick={handleClickMonthly}
                    disabled={(+filterValues.machineId === 0 || +filterValues.organizationId === 0
                        || +filterValues.year === 0 || +filterValues.month === 0) ? true : false
                    } 
                >
                    Monthly <DownloadRoundedIcon /> <PictureAsPdfOutlinedIcon/>
                </Button>
                <Button type="submit" onClick={handleClickSpecialPdf}
                    disabled={(+filterValues.machineId === 0 || +filterValues.organizationId === 0
                        || +filterValues.year === 0 || +filterValues.month === 0) ? true : false
                    } 
                >
                    Daily + Monthly <DownloadRoundedIcon /> <PictureAsPdfOutlinedIcon/>
                </Button>
            </Box>
        </Box>
    );
}
 
export default ReportDetailsButtons;