export const calculateBarWidth = (datasetLength: number) => {
    if (datasetLength === 1) {
      return 0.2;
    } else {
      return 0.7;
    }
};

export const calculateCategoryWidth = (datasetLength: number) => {
    if (datasetLength === 1) {
      return 0.4;
    } else {
      return 1;
    }
};