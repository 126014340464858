import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { MACHINE_TYPES } from '../../constants/GlobalConstants';
import { LocationsData } from '../../types/LocationsType';
import { OrganizationsData } from "../../types/OrganizationsType";
import { isSuperSpecialUser } from '../../utils/UserUtil';
import { FilterContainer } from '../Shared/Common/Containers';


const MachineFilterContainer = ({
    onChangeFilter, 
    filterValue, 
    orgList,
    locationList,
    userInfo,
} : any) : JSX.Element => {
    return (
        <FilterContainer>
            <Form.Group>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text className="input-user"><span>Organization</span></InputGroup.Text>
                            {
                                !isSuperSpecialUser() ?
                                <Form.Select onChange={onChangeFilter} 
                                    name="organizationId" aria-label="organizatioinId" 
                                    value={filterValue.organizationId}
                                    disabled
                                >
                                    <option value={userInfo.orgId}>
                                        {orgList.filter((org:OrganizationsData) => org.id === +userInfo.orgId)[0]?.organizationName}
                                    </option>
                                </Form.Select> :
                                <Form.Select onChange={onChangeFilter} 
                                name="organizationId" aria-label="organizatioinId" 
                                value={filterValue.organizationId}
                                >
                                    <option value={0}>All</option>
                                    {
                                    (orgList && orgList?.length !== 0) && orgList.map((org:OrganizationsData) => 
                                        <option value={org.id} key={org.id}>{org.organizationName}</option>
                                    )
                                    }
                                </Form.Select>
                            }
                        </InputGroup>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <InputGroup
                        >
                            <InputGroup.Text className="input-user"><span>Location</span></InputGroup.Text>
                            {
                                <Form.Select 
                                onChange={onChangeFilter} 
                                name="locationId" aria-label="type" 
                                value={filterValue.locationId}
                                disabled={!Boolean(filterValue.organizationId && filterValue.organizationId !== '0')}
                                >
                                    <option value={0}>All</option>
                                    {
                                        (locationList && locationList?.length !== 0) && locationList.map((location:LocationsData) => 
                                            <option value={location.id} key={location.id}>{location.locationName}</option>
                                        )
                                    }
                                </Form.Select>
                            }
                        </InputGroup>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <InputGroup
                        >
                            <InputGroup.Text className="input-user"><span>Machine Type</span></InputGroup.Text>
                            {
                                <Form.Select 
                                onChange={onChangeFilter} 
                                name="machineType" aria-label="type" 
                                value={filterValue.machineType}
                                >
                                    <option value="all">All</option>
                                    <option value={MACHINE_TYPES.FOOD_WASTE}>Foodwaste Machine</option>
                                    <option value={MACHINE_TYPES.GENERAL_WASTE}>General Waste Machine</option>
                                    <option value={MACHINE_TYPES.NO_TYPE}>No Machine Type</option>
                                </Form.Select>
                            }
                        </InputGroup>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <InputGroup>
                            <InputGroup.Text id="inputGroup-sizing-lg">Search</InputGroup.Text>
                            <Form.Control
                                aria-label="Large"
                                aria-describedby="inputGroup-sizing-sm"
                                value={filterValue.search}
                                onChange={onChangeFilter}
                                name="search"
                                placeholder="Name"
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </Form.Group>
        </FilterContainer>
    )
}

export default MachineFilterContainer;