import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BackupRoundedIcon from '@mui/icons-material/BackupRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import EngineeringIcon from '@mui/icons-material/Engineering';
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded';
import FitbitRoundedIcon from '@mui/icons-material/FitbitRounded';
import HistoryIcon from '@mui/icons-material/History';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PrecisionManufacturingRoundedIcon from '@mui/icons-material/PrecisionManufacturingRounded';
import PsychologyIcon from '@mui/icons-material/Psychology';
import RadarRoundedIcon from '@mui/icons-material/RadarRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import StoreRoundedIcon from '@mui/icons-material/StoreRounded';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { FaBoxOpen } from "react-icons/fa";
import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { UserAuthContext } from "../../../contexts/UserAuthContext";
import { NodeProps } from "../../../types/CommonType";
import { UserAuthType } from "../../../types/UserAuth";
import { isRootAndSuperUsers, isSuperSpecialUser } from "../../../utils/UserUtil";
import "./SideNavbar.css";
import {
  ItemsContainer,
  SideNav,
  SideNavContainer,
  SideNavItems,
} from "./SideNavbarElements";

const normalTabStyle = {
  display: "flex",
  gap: 10,
  alignItems: "center",
  width: "100%",
  padding: "15px"
}

const activeTabStyle = {
  backgroundColor: "#5C5C5C",
  display: "flex",
  gap: 10,
  alignItems: "center",
  width: "100%",
  padding: "15px"
}

const SideNavbar = ({width, innerWidth }: NodeProps) => {
  const { hasPermission } = useContext(UserAuthContext) as UserAuthType

  return (
    <SideNavContainer>
      <SideNav width={width} className="hide-scroll-bar">
        <SideNavItems>
          <h5>Main Menu</h5>
          <ItemsContainer>
            <NavLink 
              to={'/dashboard'} 
              className="nav-link"
              style = {({ isActive }) =>
              isActive ? activeTabStyle : normalTabStyle}
            >
                <DashboardRoundedIcon className="icon" />
                <h4>Dashboard</h4>
            </NavLink>
            {
              hasPermission("viewReport") &&
              <NavLink 
                to={'/report'} 
                className="nav-link"
                style = {({ isActive }) =>
                isActive ? activeTabStyle : normalTabStyle}
              >
                  <AssessmentIcon className="icon" />
                  <h4>Report</h4>
              </NavLink>
            }
          </ItemsContainer>

          {
          (hasPermission("viewManualDetection") || hasPermission("viewDetection") || 
           hasPermission("viewCapture") || hasPermission("viewWasteUpload")) &&
          <>
            <h5>Detections</h5>
            <ItemsContainer>
              {
                hasPermission("viewDetection") &&
                <NavLink 
                  to={'/detection'} 
                  className="nav-link"
                  style = {({ isActive }) =>
                  isActive ? activeTabStyle : normalTabStyle}
                >
                    <RadarRoundedIcon className="icon" />
                    <h4>Detection</h4>
                </NavLink>
              }
              {
                hasPermission("viewManualDetection") &&
                <NavLink 
                  to={'/manual-detection'} 
                  className="nav-link"
                  style = {({ isActive }) =>
                  isActive ? activeTabStyle : normalTabStyle}
                >
                    <PersonSearchIcon className="icon" />
                    <h4>Manual Detection</h4>
                </NavLink>
              }
              {
                hasPermission("viewCartonBox") &&
                <NavLink 
                  to={'/carton-box'} 
                  className="nav-link"
                  style = {({ isActive }) =>
                  isActive ? activeTabStyle : normalTabStyle}
                >
                    <FaBoxOpen className="icon fs-5" />
                    <h4>Carton Box Detection</h4>
                </NavLink>
              }
              {
                hasPermission("viewWasteUpload") &&
                <NavLink 
                  to={'/waste-upload'} 
                  className="nav-link"
                  style = {({ isActive }) =>
                  isActive ? activeTabStyle : normalTabStyle}
                >
                    <PersonSearchIcon className="icon" />
                    <h4>Waste Upload</h4>
                </NavLink>
              }
              {
                hasPermission("viewCapture") &&
                <NavLink 
                  to={'/capture'} 
                  className="nav-link"
                  style = {({ isActive }) =>
                  isActive ? activeTabStyle : normalTabStyle}
                >
                    <FitbitRoundedIcon className="icon" />
                    <h4>Capture</h4>
                </NavLink>
              }
            </ItemsContainer>
           </>
          }

          {
            hasPermission("viewLfcMachine") &&
            <>
            <h5>Machines</h5>
            <ItemsContainer>
                <NavLink 
                  to={'/machines'} 
                  className="nav-link"
                  style = {({ isActive }) =>
                  isActive ? activeTabStyle : normalTabStyle}
                  >
                    <PrecisionManufacturingRoundedIcon className="icon" />
                    <h4>Weighting Machines</h4>
                </NavLink>
            </ItemsContainer>
            </>
          }

          {
            (hasPermission("viewWeightLog") || hasPermission("viewDoorLog") 
            || hasPermission("viewMachineLog") || isRootAndSuperUsers()
            || hasPermission("viewOperatorLog")) &&
            <>
              <h5>Logs</h5>
              <ItemsContainer>
              {
                hasPermission("viewWeightLog") &&
                <NavLink 
                  to={'/weight-log'} 
                  className="nav-link"
                  style = {({ isActive }) =>
                  isActive ? activeTabStyle : normalTabStyle}
                >
                    <AlignHorizontalLeftIcon className="icon" />
                    <h4>Weight Log</h4>
                </NavLink>
              }
              {
                hasPermission("viewDischargeWeightLog") &&
                <NavLink 
                  to={'/discharge-weight-log'} 
                  className="nav-link"
                  style = {({ isActive }) =>
                  isActive ? activeTabStyle : normalTabStyle}
                >
                    <AlignHorizontalLeftIcon className="icon" />
                    <h4>Discharge Weight Log</h4>
                </NavLink>
              }
              {
                hasPermission("viewDoorLog") &&
                <NavLink 
                  to={'/door-log'} 
                  className="nav-link"
                  style = {({ isActive }) =>
                  isActive ? activeTabStyle : normalTabStyle}
                >
                    <AlignHorizontalLeftIcon className="icon" />
                    <h4>Door Log</h4>
                </NavLink>
              }
              {
                hasPermission("viewMachineLog") &&
                <NavLink 
                  to={'/machine-log'} 
                  className="nav-link"
                  style = {({ isActive }) =>
                  isActive ? activeTabStyle : normalTabStyle}
                >
                    <AlignHorizontalLeftIcon className="icon" />
                    <h4>Machine Log</h4>
                </NavLink>
              }
              {
                (isRootAndSuperUsers()) &&
                <NavLink 
                  to={'/event-log'} 
                  className="nav-link"
                  style = {({ isActive }) =>
                  isActive ? activeTabStyle : normalTabStyle}
                >
                    <AlignHorizontalLeftIcon className="icon" />
                    <h4>Event Log</h4>
                </NavLink>
              }
              {
                (hasPermission("viewOperatorLog")) &&
                <NavLink 
                  to={'/operator-log'} 
                  className="nav-link"
                  style = {({ isActive }) =>
                  isActive ? activeTabStyle : normalTabStyle}
                >
                    <AlignHorizontalLeftIcon className="icon" />
                    <h4>Operator Log</h4>
                </NavLink>
              }
              
              </ItemsContainer>
            </>
          }

          {
            (hasPermission("viewTenant") || hasPermission("viewUser") 
            || hasPermission("viewPendingUser") || isRootAndSuperUsers()) &&
            <>
              <h5>Users</h5>
              <ItemsContainer>
                {
                  hasPermission("viewTenant") &&
                  <NavLink 
                    to={'/tenants'} 
                    className="nav-link"
                    style = {({ isActive }) =>
                    isActive ? activeTabStyle : normalTabStyle}
                  >
                    <StoreRoundedIcon className="icon"/>
                    <h4>Tenants</h4>
                  </NavLink>
                }
                {
                  hasPermission("viewUser") &&
                  <NavLink 
                    to={'/users'} 
                    className="nav-link"
                    style = {({ isActive }) =>
                    isActive ? activeTabStyle : normalTabStyle}
                  >
                      <PeopleRoundedIcon className="icon"/>
                      <h4>
                        Users 
                      </h4>
                  </NavLink>
                }
                {
                  isRootAndSuperUsers() &&
                  <NavLink 
                    to={'/users-config'} 
                    className="nav-link"
                    style = {({ isActive }) =>
                    isActive ? activeTabStyle : normalTabStyle}
                  >
                    <ManageAccountsIcon className='icon'/>
                    <h4>Users' Locations Config</h4>
                  </NavLink>
                }
                {
                  hasPermission("viewPendingUser") &&
                  <NavLink 
                    to={'/pending-users'} 
                    className="nav-link"
                    style = {({ isActive }) =>
                    isActive ? activeTabStyle : normalTabStyle}
                  >
                      <HowToRegIcon className="icon"/>
                      <h4>
                        Pending Users 
                      </h4>
                  </NavLink>
                }
                {
                  isRootAndSuperUsers() &&
                  <NavLink 
                    to={'/alert-users'} 
                    className="nav-link"
                    style = {({ isActive }) =>
                    isActive ? activeTabStyle : normalTabStyle}
                  >
                      <PeopleRoundedIcon className="icon"/>
                      <h4>Alert Users</h4>
                  </NavLink>
                }
                {
                  isRootAndSuperUsers() &&
                  <NavLink 
                    to={'/roles'} 
                    className="nav-link"
                    style = {({ isActive }) =>
                    isActive ? activeTabStyle : normalTabStyle}
                  >
                      <PersonRoundedIcon className="icon"/>
                      <h4>Roles</h4>
                  </NavLink>
                }
                {
                  (isRootAndSuperUsers()) &&
                  <NavLink 
                    to={'/login-history'} 
                    className="nav-link"
                    style = {({ isActive }) =>
                    isActive ? activeTabStyle : normalTabStyle}
                  >
                      <HistoryIcon className="icon"/>
                      <h4>Login History</h4>
                  </NavLink>
                }
              </ItemsContainer>
            </>
          }

          <h5>General</h5>
          <ItemsContainer>
            {
              hasPermission("viewOrganization") &&
              <NavLink 
                to={'/organizations'} 
                className="nav-link"
                style = {({ isActive }) =>
                isActive ? activeTabStyle : normalTabStyle}
              >
                  <CorporateFareRoundedIcon className="icon" />
                  <h4>Organizations</h4>
              </NavLink>
            }
            {
              hasPermission("viewLocation") &&
              <NavLink 
                to={'/locations'} 
                className="nav-link"
                style = {({ isActive }) =>
                isActive ? activeTabStyle : normalTabStyle}
              >
                  <LocationOnRoundedIcon className="icon" />
                  <h4>Locations</h4>
              </NavLink>
            }
            {
              (hasPermission("viewLfcData") && isSuperSpecialUser()) &&
              <NavLink 
                to={'/lfcdata'} 
                className="nav-link"
                style = {({ isActive }) =>
                isActive ? activeTabStyle : normalTabStyle}
              >
                  <EqualizerRoundedIcon className="icon" />
                  <h4>LFC Data</h4>
              </NavLink>
            }
            <NavLink 
              to={'/settings'} 
              className="nav-link"
              style = {({ isActive }) =>
              isActive ? activeTabStyle : normalTabStyle}
            >
                <SettingsRoundedIcon className="icon"/>
                <h4>Settings</h4>
            </NavLink>
          </ItemsContainer>

          {
            (hasPermission("viewDataSync") || isRootAndSuperUsers()) &&
            <>
            
              <h5>Configuration</h5>
              <ItemsContainer>
                {
                  (hasPermission("viewDataSync") && isSuperSpecialUser()) &&
                  <NavLink 
                    to={'/datasync'} 
                    className="nav-link"
                    style = {({ isActive }) =>
                    isActive ? activeTabStyle : normalTabStyle}
                  >
                      <BackupRoundedIcon className="icon"/>
                      <h4>Data Sync</h4>
                  </NavLink>
                }
                {
                  (isRootAndSuperUsers()) &&
                    <NavLink 
                      to={'/config'} 
                      className="nav-link"
                      style = {({ isActive }) =>
                      isActive ? activeTabStyle : normalTabStyle}
                    >
                        <SettingsRoundedIcon className="icon"/>
                        <h4>Config</h4>
                    </NavLink>
                }
                {
                  (isRootAndSuperUsers()) &&
                    <NavLink 
                      to={'/ai-config'} 
                      className="nav-link"
                      style = {({ isActive }) =>
                      isActive ? activeTabStyle : normalTabStyle}
                    >
                        <PsychologyIcon className="icon"/>
                        <h4>Ai Config</h4>
                    </NavLink>
                }
                {
                  (isRootAndSuperUsers()) &&
                    <NavLink 
                      to={'/hardwares'} 
                      className="nav-link"
                      style = {({ isActive }) =>
                      isActive ? activeTabStyle : normalTabStyle}
                    >
                        <DeviceHubIcon className="icon"/>
                        <h4>Hardwares</h4>
                    </NavLink>
                }
                {
                  (isRootAndSuperUsers()) &&
                    <NavLink 
                      to={'/maintenance'} 
                      className="nav-link"
                      style = {({ isActive }) =>
                      isActive ? activeTabStyle : normalTabStyle}
                    >
                        <EngineeringIcon className="icon"/>
                        <h4>Maintenance</h4>
                    </NavLink>
                }
              </ItemsContainer>
            </>
          }
        </SideNavItems>
      </SideNav>
    </SideNavContainer> 
  )};

export default SideNavbar;
