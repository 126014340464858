import Box from "@mui/material/Box"
import { convertToLocaleDate } from '../../../utils/DateTimeUtil';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { TableContainer } from "./MachineDetailsElements";

const MachineDetailsTable = ({
    dataList,
    columnList,
    onOpenDeleteModal,
    onOpenUpdateModal,
}: any) => {
    const renderTable = () => {
        return(
            dataList?.length !== 0 ? dataList.map((data: any) => 
                <tr key={data?.id}>
                    <td>{data?.id}</td>
                    {columnList.includes("Machine") && <td>{data?.machineId || "-"}</td>}
                    {columnList.includes("Card") && <td>card {data?.lfcCardId || "-"}</td>}
                    {columnList.includes("Tenant") && <td>{data?.tenantName || "-"}</td>}
                    {columnList.includes("User") && <td>{data?.userId || "-"}</td>}
                    {columnList.includes("External UFC UUID") && <td>{data?.externalNfcUuid || "-"}</td>}
                    {columnList.includes("Remark") && <td>{data?.remark || "-"}</td>}
                    <td>{data?.createdBy || "-"}</td>
                    <td>{convertToLocaleDate(data?.createdAt!) || "-"}</td>
                    {/* <td>{data.updatedBy || "-"}</td>
                    <td>{convertToLocaleDate(data.updatedAt!) || "-"}</td> */}
                    {/* <td>
                            <DeleteOutlinedIcon 
                                sx={{
                                    color: "red",
                                }}
                                className="pointer"
                                onClick={() => onOpenDeleteModal(data)}
                            />
                    </td> */}
                    <td>
                        <EditOutlinedIcon 
                            sx={{
                                color: "blue",
                            }}
                            className="pointer"
                            onClick={() => onOpenUpdateModal(data)}
                        />
                    </td>
                </tr>    
            ):
            <tr>
                <td>No data found</td>
            </tr>
        )
    }

    return ( 
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <TableContainer>
                <table className="styled-table">
                    <thead className="table-header">
                    <tr>
                        {
                            columnList.map((column:String, index: number) => 
                                    <th key={index}>{column}</th>
                            )
                        }
                    </tr> 
                    </thead>
                    <tbody className="table-data">
                        {
                            renderTable()
                        }
                    </ tbody>
                </table>
            </TableContainer>
        </Box>
     );
}
 
export default MachineDetailsTable;