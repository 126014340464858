import * as React from 'react';
import { useState, useEffect } from 'react';

import { Alert, Box, Typography } from "@mui/material";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import CalendarModal from "../Shared/CalendarModal/CalendarModal";
import CloseIcon from '@mui/icons-material/Close';
import { useFilterOrganizations } from '../../custom-hooks/useFilterOrganizations';
import { useFilterMachines } from '../../custom-hooks/useFilterMachines';
import { MachinesData } from '../../types/MachinesType';
import { LFC_CORRECTION_LOG } from '../../constants/LfcContants';

const DateCorrectionModal = ({
    show,
    onClose,
    lfcCorrectionLogData,
    onChangeCorrectionDate,
    onChangeLfcCorrectionLog,
    onAddLfcCorrectionLog,
    errorText
}: any) => {
    const [showCorrectDateModal, setShowCorrectDateModal] = useState<boolean>(false)
    const {organizations, error: orgError} = useFilterOrganizations()
    const {machines, error: machineError} = useFilterMachines(lfcCorrectionLogData.orgId, [lfcCorrectionLogData.orgId])

    const handleShowCorrectDateModal = () => {
        setShowCorrectDateModal(!showCorrectDateModal)
    }

    return (  
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Date Correction</Modal.Title>
                <CloseIcon onClick={onClose} style={{cursor: "pointer"}} />
            </Modal.Header>
            <Modal.Body>
                {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}
                <p className='fw-light'>LFC Correction Log: {LFC_CORRECTION_LOG}</p>
                <Form className="ModalForm">
                    <Box sx={(theme) => ({
                        display: "flex", 
                        gap: 1, 
                        alignItems: "center",
                        marginBottom: 3,
                    })}>
                        <Typography>Correct Date</Typography>
                        <button type="button" className="btn btn-outline-dark" onClick={handleShowCorrectDateModal}>{lfcCorrectionLogData.correctionDate || "Choose Date"}</button>
                        <CalendarModal 
                            show={showCorrectDateModal}
                            onCloseModal={handleShowCorrectDateModal}
                            onChange={(value: Date) => {
                                onChangeCorrectionDate(value)
                                handleShowCorrectDateModal()
                            }}
                            defaultValue={new Date()}
                        />
                    </Box>
                    <InputGroup className="mb-3">
                        <InputGroup.Text className="input-user"><span>Organization</span></InputGroup.Text>
                        <Form.Select onChange={onChangeLfcCorrectionLog} 
                            name="orgId" aria-label="organizatioinId" 
                            value={lfcCorrectionLogData.orgId}
                        >
                            {
                                (organizations && organizations.length > 0) && 
                                organizations.map(org => (
                                    <option value={org.id} key={org.id}>{org.organizationName}</option>
                                ))
                            }
                        </Form.Select>
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Text className="input-user"><span>Machine</span></InputGroup.Text>
                        <Form.Select onChange={onChangeLfcCorrectionLog} 
                            name="machineId" aria-label="machineId" 
                            value={lfcCorrectionLogData.machineId}
                        >
                            <option value={0}>Select Machine</option>
                            {
                            (machines && machines?.length !== 0) && machines.map((machine:MachinesData) => 
                                <option value={machine.id} key={machine.id}>{machine.machineName}</option>
                            )
                            }
                        </Form.Select>
                    </InputGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button id="btnExportData" variant="success" onClick={onAddLfcCorrectionLog}>Add</Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default DateCorrectionModal;