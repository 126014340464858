import React, { useState, useRef, ChangeEvent } from 'react';
import Webcam from 'react-webcam';
import { Box } from "@mui/material";
import { Button } from 'react-bootstrap';

type Props = {
    onUploadImage: (base64Image: string) => void
}

const ImageCaptureUpload = (props: Props) => {
    const {onUploadImage} = props
    const [image, setImage] = useState<string | null>(null);
    const [isWebcamOpen, setIsWebcamOpen] = useState<boolean>(false);
    const webcamRef = useRef<Webcam>(null);

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
        const reader = new FileReader();
            reader.onload = (e) => {
                setImage(e.target?.result as string)
                onUploadImage(e.target?.result as string)
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCapture = () => {
        const imageSrc = webcamRef.current?.getScreenshot();
        onUploadImage(imageSrc || "")
        setImage(imageSrc || null);
        setIsWebcamOpen(false);
    };

    const toggleWebcam = () => {
        setIsWebcamOpen(!isWebcamOpen);
    };

    return (  
        <Box>
            <div className='d-flex flex-column align-items-center'>
                {image && <img className='mb-3 rounded' style={{objectFit: "contain"}} src={image} width="300px" height="200px" alt="Captured or Uploaded" />}
            </div>
            <div>
                <input type="file" accept="image/*" onChange={handleFileUpload} />
                {
                    isWebcamOpen ?
                    <Button variant='danger' onClick={toggleWebcam}>Close</Button> :
                    <Button onClick={toggleWebcam}>Capture</Button>
                }
            </div>
            {isWebcamOpen && (
                <div className='mt-3 d-flex flex-column align-items-center'>
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/png"
                        width={300}
                        className='rounded'
                    />
                    <Button className='mt-3' onClick={handleCapture}>Capture</Button>
                </div>
            )}
        </Box>
    );
}
 
export default ImageCaptureUpload;