import { Alert, Box } from "@mui/material";
import { Button, Col, Form, Row } from "react-bootstrap";
import SyncInternalTelegramConfig from "./SyncInternalTelegramConfig";
import { useEffect, useState } from "react";
import { convertToLocaleDate } from "../../utils/DateTimeUtil";
import { UserInfo } from "../../types/UserAuth";
import { getCurrentLocalUser } from "../../utils/UserUtil";
import { STATUS_CODES } from "../../constants/GlobalConstants";
import { internalTelegramConfigAddApi, internaltelegramConfigGetFirstRowApi, toggleSendMessageInternalTelegramConfigApi } from "../../services/RouteServices/ConfigApi";
import { DefaultInternalTelegramConfig, InternalTelegramConfigData } from "../../types/InternalTelegramConfigType";
import { sendTelegramMessageApi } from "../../services/RouteServices/TelegramApi";
import LoadingModal from "../Shared/LoadingModal/LoadingModal";

const InternalTelegramConfig = () => {
    const userInfo: UserInfo = getCurrentLocalUser();

    const [internalTelegramConfig, setInternalTelegramConfig] = useState<InternalTelegramConfigData>(DefaultInternalTelegramConfig)
    const [internalTelegramSwitch, setInternalTelegramSwitch] = useState<boolean>(false);
    const [successText, setSuccessText] = useState<String>("");
    const [errorText, setErrorText] = useState<String>("");
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        internaltelegramConfigGetFirstRowApi()
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setInternalTelegramSwitch(res?.data?.data?.isSendMessage);
                setInternalTelegramConfig(res?.data?.data)
            } else {
                setInternalTelegramConfig(DefaultInternalTelegramConfig)
                setInternalTelegramSwitch(false)
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message) 
            setInternalTelegramConfig(DefaultInternalTelegramConfig)
            setInternalTelegramSwitch(false)  
        })
    }, [])


    const handleChangeInternalTelegramConfig = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalTelegramConfig(
            prevData => {
                return {
                    ...prevData,
                    [event.target.name]: event.target.value
                }
            }
        )
    }

    const handleChangeInternalTelegramSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalTelegramSwitch(event.target.checked)
        const inputObject = {
            isSendMessage: event.target.checked,
            doneBy: userInfo.userName
        }
        toggleSendMessageInternalTelegramConfigApi(inputObject)
        .then(res => {
            if (res?.data?.status !== STATUS_CODES.SUCCESS_CODE) {
                setInternalTelegramSwitch(!event.target.checked);
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message);
            setInternalTelegramSwitch(!event.target.checked)
        })
    }

    const handleAddInternalTelegramConfig = () => {
        setErrorText("")
        const dataLimitObject = {
            botToken: internalTelegramConfig?.botToken?.trim() || "",
            channelId: internalTelegramConfig?.channelId?.trim() || "",
            doneBy: userInfo.userName
        }

        internalTelegramConfigAddApi(dataLimitObject)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setInternalTelegramConfig(res?.data?.data)
                setSuccessText(res?.data?.message)
                setTimeout(() => {
                    setSuccessText("")
                }, 2000)
            } else {
                setErrorText(res?.data?.message)
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }

    const handleTestInternalTelegramConfig = () => {
        setErrorText("")
        setIsLoading(true)
        const input = {
            orgId: 0,
            botToken: internalTelegramConfig?.botToken?.trim() || "",
            channelId: internalTelegramConfig?.channelId?.trim() || "",
            testedBy: userInfo?.userName,
        }
        sendTelegramMessageApi(input)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setSuccessText(res?.data?.message)
                setTimeout(() => {
                    setSuccessText("")
                }, 2000)
            } else {
                setErrorText(res?.data?.message)
            }
            setIsLoading(false)
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
            setIsLoading(false)
        })
    }

    return (  
        <Box
            sx={{minWidth: "400px", marginTop: 3}}
        >
            <LoadingModal show={isLoading} />
            <Form>
                <Form.Label as="legend">
                    Internal Telegram Config
                </Form.Label>
                {successText && <Alert severity="success" className="mb-3">{successText}</Alert>}
                {errorText && <Alert severity="error" className="mb-3">{errorText}</Alert>}
                <div
                    className="border border-light border-3 rounded p-3 bg-light bg-gradient" 
                >
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            <p className='mb-0'>
                                <span>Bot Token</span><span className='text-danger'>*</span>
                            </p>
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" name="botToken" value={internalTelegramConfig.botToken || ""} onChange={handleChangeInternalTelegramConfig} placeholder='6222126201:AAHoG7QI-T...' />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            <p className='mb-0'>
                                <span>Channel Id</span><span className='text-danger'>*</span>
                            </p>
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="email" placeholder='@channel_id' name="channelId" value={internalTelegramConfig.channelId || ""} onChange={handleChangeInternalTelegramConfig} />
                        </Col>
                    </Form.Group>
                    
                    <Form.Group as={Row} className="">
                        <Form.Label column sm="3">
                             
                        </Form.Label>
                        <Col sm="9">
                            <span style={{fontSize:13, color:'#35a612'}}>Last Update - {convertToLocaleDate(internalTelegramConfig?.updatedAt || "") || convertToLocaleDate(internalTelegramConfig?.createdAt || "")}</span>
                            <br></br>
                            <span style={{fontSize:13, color:'#35a612'}}>Updated User - {internalTelegramConfig.updatedBy || internalTelegramConfig.createdBy}</span>
                        </Col>
                    </Form.Group>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "right",
                            gap: 2,
                            marginTop: 3,
                        }}
                    >
                        <Button variant="warning" onClick={handleTestInternalTelegramConfig}>Test</Button>
                        <Button variant="primary" onClick={handleAddInternalTelegramConfig}>Update</Button>
                    </Box>
                    <SyncInternalTelegramConfig 
                        internalTelegramSwitch={internalTelegramSwitch}
                        handleInternalTelegramSwitch={handleChangeInternalTelegramSwitch}
                    />
                </div>
            </Form>
        </Box>
    );
}
 
export default InternalTelegramConfig;