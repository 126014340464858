import { Button, Modal } from "react-bootstrap";
import Alert from "@mui/material/Alert"
import LogoutIcon from '@mui/icons-material/Logout';

const LogoutModal = ({
    show,
    successMsg,
    errorMsg,
    data,
    onClose,
    handleLogout
}: any) => {
    return ( 
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Logout Confirmation</Modal.Title>
            </Modal.Header>
            {successMsg && <Alert severity="success">{successMsg}<br/></Alert>}
            {errorMsg && <Alert severity="error">{errorMsg}<br/></Alert>}
            <Modal.Body>
                <p>Are you sure you want to logout <strong>[{data.userName}]</strong>?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Cancel</Button>
                <Button variant="danger" onClick={handleLogout}><LogoutIcon/>&nbsp;Logout</Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default LogoutModal;