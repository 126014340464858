import CloseIcon from '@mui/icons-material/Close';
import { Button, Form, Modal } from 'react-bootstrap';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const CalendarModal = ({
    show,
    onCloseModal,
    ...props
}: any) => {
    return (  
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Calendar</Modal.Title>
                <CloseIcon 
                    onClick={onCloseModal}
                />
            </Modal.Header>
            <Modal.Body
                style={{
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Calendar
                    {...props}
                />
            </Modal.Body>
        </Modal>
    );
}
 
export default CalendarModal;