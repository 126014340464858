import styled from "styled-components";

// * Page Title
export const Title = styled.h2`
  color: #00000;
  margin: 0px;
  margin-bottom: 10px;
  min-width: 300px;
`;

export const DetailTitle = styled(Title)`
  text-align: center;
`

// * Page secondary title
export const TitleH3 = styled.h3`
  margin: 0px;
  margin-bottom: 10px;
  color: #00000;
  font-weight: 700;
`;

// * Page sub title
export const TitleH5 = styled.h5`
  margin: 0px;
  margin-bottom: 10px;
  color: #00000;
  font-style: italic;
  font-weight: 500;
`;

export const SummaryText = styled.p`
  margin: 0;
  text-align: right;
  font-weight: 500;
  font-size: 1em;
  color: #394867;
  // min-width: 300px;
`