import { Box } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { maintenanceDownloadZipFolderApi, maintenanceGetFoldersAndFilesApi, maintenanceGetFoldersWithDepthApi, maintenanceGetZipFileSizeApi } from "../../services/RouteServices/MaintenanceApi";
import { STATUS_CODES } from "../../constants/GlobalConstants";
import { FoldersAndFiles, PathAndName } from "../../types/MaintenanceType";
import PathTable from "./PathTable";
import { getCurrentLocaleDate } from "../../utils/DateTimeUtil";
import { AI_IMAGES_FOLDER, AI_IMAGES_ZIP_FOLDER, MANUAL_IMAGES_FOLDER, MANUAL_IMAGES_ZIP_FOLDER, NORMAL_FOLDER, ZIP_FOLDER } from "../../constants/MaintenanceContants";
import LoadingModal from "../Shared/LoadingModal/LoadingModal";
import styles from './Maintenance.module.css'

const DownloadDetections = () => {
    const [childrenFolders, setChildrenFolders] = useState<string[]>([]);
    const [viewChildrenFolders, setViewChildrenFolders] = useState<string[]>([])
    const [contents, setContents] = useState<FoldersAndFiles | PathAndName>()
    const [selectedFolderType, setSelectedFolderType] = useState<string>("normal")
    const [selectedRootFolder, setSelectedRootFolder] = useState<string>("");
    const [selectedChildrenFolder, setSelectedChildrenFolder] = useState<string>("");
    const [selectedDepth, setSelectedDepth] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>("")
    const [downloadedName, setDownloadedName] = useState<string>("")
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        if (selectedRootFolder !== "") {
            getChildrenFolders()
        } else {
            setChildrenFolders([])
        }
    }, [selectedFolderType, selectedRootFolder, selectedDepth])

    useEffect(() => {
        if (selectedChildrenFolder !== "") {
            if (selectedFolderType === NORMAL_FOLDER) {
                getContents()
            } else {
                getZipFileSize()
            }
        }
    }, [selectedChildrenFolder])

    useEffect(() => {
        setViewChildrenFolders(childrenFolders.filter(folder => {
            const searchTexts: string[] = searchText.split(",")
            return !searchTexts.map(text => ""+folder.includes(text.trim())).includes("false")
        }))
    }, [searchText, childrenFolders])

    const getChildrenFolders = () => {
        const input = {
            folderPath: selectedRootFolder,
            includeZipFolder: selectedFolderType === ZIP_FOLDER,
            depth: selectedDepth
        }
        maintenanceGetFoldersWithDepthApi(input)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setChildrenFolders(res?.data?.data)
            } else {
                console.log(res?.data?.message)
            }
        }).catch(error => {
            console.log(error?.message)
        })
    }

    const getContents = () => {
        const input = {
            folderPath: selectedChildrenFolder,
        }
        maintenanceGetFoldersAndFilesApi(input)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setContents(res?.data?.data)
            }
        }).catch(error => {
            console.log(error?.message)
        })
    }

    const getZipFileSize = () => {
        const input = {
            folderPath: selectedChildrenFolder,
        }
        maintenanceGetZipFileSizeApi(input) 
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setContents(res?.data?.data)
            }
        }).catch(error => {
            console.log(error?.message)
        })
    }

    const downloadZipFlolder = () => {
        const input = {
          folderPath: selectedChildrenFolder
        };
        setIsLoading(true)
        maintenanceDownloadZipFolderApi(input, { responseType: "blob" })
          .then(response => {
            const blob = new Blob([response.data], { type: 'application/zip' });
            const downloadUrl = URL.createObjectURL(blob);
      
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.download = `${downloadedName.replace(".zip", "")}__${getCurrentLocaleDate()}.zip`;
      
            document.body.appendChild(link);
            link.click();
            setIsLoading(false)
          })
          .catch(error => {
            console.log(error?.message);
            setIsLoading(false)
          });
    };

    const handleChangeFolderType = (event: ChangeEvent<HTMLSelectElement>) => {
        setChildrenFolders([])
        setSelectedRootFolder("")
        setSelectedFolderType(event.target.value)
    }

    const handleChangeRootFolder = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedRootFolder(event.target.value)
    }

    const handleChangeDepth = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedDepth(+event.target.value)
    }

    const handleChangeSeachText = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value)
    }

    const handleChangeDownloadedName = (event: ChangeEvent<HTMLInputElement>) => {
        setDownloadedName(event.target.value)
    }

    const handleClickView = (path: string) => {
        const folders = path.split("/")
        setDownloadedName(folders[folders.length - 1] || "download")
        setSelectedChildrenFolder(path)
    }


    return (  
        <Box  className={styles.donwnloadDetectionContainer}>
        <Box
            className={"border border-light border-3 rounded p-3 bg-light bg-gradient"}
        >
            <Box sx={{
                display: "flex",
                gap: 3,
                flexWrap: "wrap",
            }}>
                <Form.Select onChange={handleChangeFolderType} 
                    value={selectedFolderType}
                    style={{width: "150px"}}
                >
                    <option value={NORMAL_FOLDER}>Normal Folder</option>
                    <option value={ZIP_FOLDER}>Zip Folder</option>
                </Form.Select>
                <Form.Select onChange={handleChangeRootFolder} 
                    value={selectedRootFolder}
                    style={{width: "250px"}}
                >
                    <option value={""}>Select Root Folder</option>
                    <option value={selectedFolderType === NORMAL_FOLDER ? AI_IMAGES_FOLDER : AI_IMAGES_ZIP_FOLDER}>
                        Ai Images
                    </option>
                    <option value={selectedFolderType === NORMAL_FOLDER ? MANUAL_IMAGES_FOLDER : MANUAL_IMAGES_ZIP_FOLDER}>
                        Manual Images
                    </option>
                </Form.Select>
                {
                    (selectedFolderType === NORMAL_FOLDER) &&
                    <Form.Select onChange={handleChangeDepth} 
                        value={selectedDepth}
                        style={{width: "130px"}}
                    >
                        <option value={0}>Deepest</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                        <option value={6}>6</option>
                    </Form.Select>
                }
                <InputGroup style={{width: 400}}>
                    <InputGroup.Text id="inputGroup-sizing-lg">Search</InputGroup.Text>
                    <Form.Control
                        aria-label="Large"
                        aria-describedby="inputGroup-sizing-sm"
                        value={searchText}
                        onChange={handleChangeSeachText}
                        name="search"
                    />
                </InputGroup>
            </Box>
            <PathTable 
                currentFolder={selectedChildrenFolder}
                folderPaths={viewChildrenFolders}
                contents={contents}
                onClickView={handleClickView}
                onDownload={downloadZipFlolder}
                onChangeDownloadedName={handleChangeDownloadedName}
                downloadedName={downloadedName}
                folderType={selectedFolderType}
            />
            <LoadingModal 
                show={isLoading}
            />
        </Box>
        </Box>
    );
}
 
export default DownloadDetections;