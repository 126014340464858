import { useEffect, useState } from "react";
import { STATUS_CODES } from "../constants/GlobalConstants";
import { tenantGetAllByMachineIdApi } from "../services/RouteServices/TenantsApi";
import { TenantsData } from "../types/TenantsType";

export const useFilterTenants = (
    machineId: number,
    deps: any[]
) => {
    const [tenants, setTenants] = useState<TenantsData[]>([])
    const [error, setError] = useState("")

    useEffect(() => {
        if (machineId > 0) {
            tenantGetAllByMachineIdApi({machineId})
            .then(res => {
                if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                    setTenants(res?.data?.data?.tenantList)
                } else {
                    setError(res?.data?.message)
                }
            }).catch(error => {
                setError(error.message)
            })
        } else {
            setTenants([])
        }
    }, deps ? deps : [])

    return {tenants, error}

}