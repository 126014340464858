export interface ToggleSendMessageRequestBody {
    isSendMessage: boolean;
    doneBy: string;
}

export interface TelegramConfigData {
    botToken: string;
    channelId: string;
    createdBy?: string;
    updatedBy?: string;
    deletedBy?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}

export interface TelegramConfigReqeustBody {
    orgId: number;
    botToken?: string;
    channelId?: string;
    doneBy?: string;
}

export const DefaultTelegramConfig = {
    botToken: "",
    channelId: ""
}