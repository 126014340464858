export type TenantsData = {
    id?: number;
    organizationId: number;
    organizationName: string;
    tenantName: string;
    assignedCards: {machineName: string, cardId: number, cardUuid: string}[];
    remark: string;
    createdBy?: string;
    updatedBy?: string;
    deletedBy?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}
  
export type TenantListResponseBody = {
    data: {
      tenantList: TenantsData[],
      totalCount?: number,
    }
    status: number,
    message: string,
}
  
export type TenantResponseBody = {
    data: TenantsData,
    status: number,
    message: string,
}
  
export type TenantGETRequestBody = {
    limit: number,
    offset: number
}

export type TenantGETByOrgIdRequestBody = {
    orgId: number,
    machineId?: number,
    limit?: number,
    offset?: number
}

export type TenantGETAllByMachineIdRequestBody = {
    machineId: number,
}

export type TenantGETAllByMachineIdListRequestBody = {
    machineIdList: number[],
}

export type TenantADDRequestBody = {
    organizationId: number;
    tenantName: string,
    remark: string,
    createdBy: string,
}
  
export type TenantDELETERequestBody = {
    id: number,
    deletedBy: string,
}
  
export type TenantUPDATERequestBody = {
    id: number,
    organizationId: number;
    tenantName: string,
    remark: string,
    updatedBy: string,
}

export const TenantDefault: TenantsData = {
    id: 0,
    organizationId: 0,
    organizationName: "",
    tenantName: "",
    remark: "",
    assignedCards: [],
}
    