import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Alert from "@mui/material/Alert"
import Button from "react-bootstrap/Button";
import { InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

const UpdateModal = ({
    show,
    successText,
    errorText,
    handleClose,
    handleUpdate,
    onChange,
    remark,
    isProcessing
}: any) => {
    return ( 
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Update</Modal.Title>
            </Modal.Header>
            {successText && <Alert severity="success">{successText}<br/></Alert>}
            {errorText && <Alert severity="error">{errorText}<br/></Alert>}
            <Modal.Body>
                <Form className="ModalForm">
                    <InputGroup className="mb-3">
                        <InputGroup.Text className="input-tenant"><span>Remark</span></InputGroup.Text>
                        <Form.Control name="remark" onChange={onChange} type="remark" placeholder="Enter Remark" value={remark} required/>
                    </InputGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" onClick={handleUpdate} disabled={isProcessing}>
                    Update
                </Button>
            </Modal.Footer>
        </Modal> 
    );
}

export default UpdateModal;