import { AxiosRequestConfig } from "axios";
import { GETFolderAndFilesRequestBody } from "../../types/MaintenanceType";
import http from "../apiServices";

export const maintenanceGetFoldersWithDepthApi = (inputData : GETFolderAndFilesRequestBody) => {
    return http.post("/maintenance/get/folders-with-dept", inputData)
}

export const maintenanceGetFoldersAndFilesApi = (inputData : GETFolderAndFilesRequestBody) => {
    return http.post("/maintenance/get/folders-and-files", inputData)
}

export const maintenanceGetZipFileSizeApi = (inputData : GETFolderAndFilesRequestBody) => {
    return http.post("/maintenance/get/zip-file-size", inputData)
}

export const maintenanceDownloadZipFolderApi = (inputData : GETFolderAndFilesRequestBody, options?: AxiosRequestConfig) => {
    return http.post("/maintenance/download/zip", inputData, options)
}
