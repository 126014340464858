import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { LOG_LEVEL, PAGE_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";
import '../../styling/customStyle.css';
import Layout from "../Layout/Layout";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { machineLogGetExportListByOrgId, machineLogGetListByOrgIdAndMachineId } from "../../services/RouteServices/MachineLogApi";
import { MachineLogData, MachineLogExportListResponseBody, MachineLogListResponseBody } from "../../types/MachineLogType";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import { DateFilterValue, FilterValue } from "../../types/global/FilterValueType";
import { convertDateFormat, convertToLocaleDate, getCurrentLocaleDate } from '../../utils/DateTimeUtil';
import { getDefaultDateFilterValue, getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import { checkLocation, getAvailableLocations, getCurrentLocalUser, isSuperSpecialUser, isTenantUser } from "../../utils/UserUtil";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title } from "../Shared/Common/Titles";
import ExportButton from "../Shared/Export/Export";
import MachineLogFilterContainer from "./MachineLogFilterContainer";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import XLSX from "xlsx";
import logoImage from '../../assets/logo/env-logo.png';
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { addEventLog } from "../../utils/EventLogUtil";
import { addNormalLog } from "../../utils/LoggerUtil";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";

const loadMachineLogExportList = (dataList: MachineLogData[]) => {
    if (!dataList) return []
    return dataList.map(data => {
        return {
            "Id": data.id,
            "Organization": data.organizationName,
            "Machine": data.machineName,
            "Log": data.machineLog || "-",
            "Remark": data.remark || "-",
        }
    })
}
 
const MachineLog: React.FC = () => {
    const tableLimit = PAGE_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();
    const availableLocations = getAvailableLocations()
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const [tablePage, setTablePage] = React.useState(1);
    const [tableOffset, setTableOffset] = React.useState(0);
    const [pageCount, setPageCount] = React.useState(0);
    const [machineLogTotalCount, setMachineLogTotalCount] = React.useState(0);
    
    const [machineLogList, setMachineLogList] = useState<MachineLogData[]>([]); // * Original data object from db

    const [filterValue, setFilterValue] = useState<FilterValue>(getDefaultFilterValue());
    const [isHealthCheck, setIsHealthCheck] = useState<boolean>(false);
    const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>(getDefaultDateFilterValue())

    useEffect(() => {
        setTablePage(1)
        setTableOffset(0)
    }, [filterValue, dateFilterValue, isHealthCheck])

    useEffect(() => {
        setErrorText("")
        setSuccessText("")
        if (hasPermission("viewMachineLog") && !isTenantUser()) {
            getMachineLogDataByOrgIdAndMachineId();
        }
    }, [filterValue, dateFilterValue, isHealthCheck, tableOffset]);

    useEffect(() => {
        if (tablePage > pageCount && pageCount !== 0) {
            setTablePage(pageCount)
            const offsetValue = (tableLimit * (pageCount - 1));
            setTableOffset(offsetValue);
        }
    }, [pageCount])

    useEffect(() => {
        setFilterValue((prev) => ({
            ...prev,
            locationId: 0,
            machineId: 0,
            tenantId: 0
        }))
    }, [filterValue.organizationId])

    useEffect(() => {
        setFilterValue((prev) => ({
            ...prev,
            machineId: 0,
            tenantId: 0
        }))
    }, [filterValue.locationId])

    useEffect(() => {
        setFilterValue((prev) => ({
            ...prev,
            tenantId: 0
        }))
    }, [filterValue.machineId])

    const generatePDF = (dataList: MachineLogData[]) => {
        const doc = new jsPDF('landscape', 'mm', 'a4');

        const headers = [
            "Id", "Organization", "Machine", "Log", "Remark"
        ];
        const dataKeys = [
            "id", "organizationName", "machineName", "machineLog", "remark"
        ];

        const columnWidths = [15, 40, 40, 120, 60];
        // Generate the columnStyles object dynamically
        const columnStyles: any = {};
        for (let i = 0; i < headers.length; i++) {
          columnStyles[dataKeys[i]] = { cellWidth: columnWidths[i] };
        }

        const getTextColor = (data: any): [number, number, number] => {
            const machineLogLevel = data.row.raw.level;
            if (machineLogLevel === LOG_LEVEL.WARN ) {
                return [235, 121, 14];
            } else if (machineLogLevel === LOG_LEVEL.ALERT ) {
                return [235, 14, 21];
            } else if (machineLogLevel === LOG_LEVEL.SUCCESS) {
                return [83, 145, 101]
            }  else {
                return [0, 0, 0]
            }
        };

        autoTable(doc, {
            head: [headers],
            body: dataList,
            columns: dataKeys.map((key) => ({ dataKey: key })),
            columnStyles: columnStyles,
            margin: { top: 20, left: 10},
            didParseCell: function (data) {
                if (data.section === "head") {
                  data.cell.styles.fontSize = 9;
                  data.cell.styles.textColor = [255, 255, 255];
                  data.cell.styles.fillColor = [0, 0, 0];
                } else if (data.section === "body") {
                    const textColor = getTextColor(data)
                    data.cell.styles.textColor = textColor;
                }
            },
            didDrawPage: function (data) {
                doc.setFontSize(18);
                doc.text("Machine Log", data.settings.margin.left, 10);

                const logoWidth = 20;
                const logoHeight = 10;
                const logoX = data.settings.margin.left + 250;
                const logoY = 5;
                doc.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);
            }
        })
        doc.save(`machinelogs-${getCurrentLocaleDate()}.pdf`)
    }

    const exportToCSV = async () => { 
        let exportedData = [] 
        if (!isTenantUser()) {
            exportedData = await getExportMachineLogDataByOrgId()
        } 
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(loadMachineLogExportList(exportedData));
        XLSX.utils.book_append_sheet(workbook, worksheet, "Overall");
        XLSX.writeFile(workbook, `machinelogs-${getCurrentLocaleDate()}.xlsx`);
        const log: string = `${userInfo.userName} has downloaded machinelogs-${getCurrentLocaleDate()}.xlsx`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    };

    const exportToPdf = async () => {
        let exportedData = []
        if (!isTenantUser()) {
            exportedData = await getExportMachineLogDataByOrgId()
        }
        generatePDF(exportedData)
        const log: string = `${userInfo.userName} has downloaded machinelogs-${getCurrentLocaleDate()}.pdf`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    }

    const handleChangeFilterValue = (event: ChangeEvent<HTMLSelectElement>) => {
        setFilterValue(
            (prevValue) => {
                return {
                    ...prevValue,
                    [event.target.name]: event.target.value,
                }
            }
        )
    }

    const handleHealthCheck = (event: ChangeEvent<HTMLInputElement>) => {
        setIsHealthCheck(event.target.checked)
    }

    const handleChangeDateFilterValue = (key: "startDate" | "endDate", value: Date) => {
        const day = value.getDate().toString().padStart(2, '0');
        const month = (value.getMonth() + 1).toString().padStart(2, '0');
        const year = value.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setDateFilterValue({...dateFilterValue, [key]: formattedDate})
    }

    const getMachineLogDataByOrgIdAndMachineId = () => {
        const dataLimitsObject = {
            orgId: filterValue.organizationId,
            machineId: filterValue.machineId,
            locationId: filterValue.locationId,
            type: isHealthCheck ? "health" : null,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            searchText: filterValue.search,
            availableLocationIds: availableLocations,
            checkLocation: checkLocation(),
            limit: tableLimit,
            offset: tableOffset
        }
        machineLogGetListByOrgIdAndMachineId(dataLimitsObject)
        .then(response => {
            const resData: MachineLogListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: MachineLogData[] = resData.data?.machineLogList
                const totalCount : number = resData?.data?.totalCount;
                setMachineLogTotalCount(totalCount)
                setPageCount((Math.ceil(totalCount/tableLimit)) || 0);
                setSuccessText(resData?.message)
                setMachineLogList(dataList);
                setTimeout(() => {
                    setSuccessText("");
                }, 2000);
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }   

    const getExportMachineLogDataByOrgId = (): Promise<any> => {
        const dataLimitsObject = {
            orgId: isSuperSpecialUser()? filterValue.organizationId! : userInfo.orgId!,
            machineId: filterValue.machineId || 0,
            locationId: filterValue.locationId,
            type: isHealthCheck ? "health" : null,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            searchText: filterValue.search,
            availableLocationIds: availableLocations,
            checkLocation: checkLocation(),
        }

        return machineLogGetExportListByOrgId(dataLimitsObject)
        .then(res => {
            const resApiData : MachineLogExportListResponseBody = res?.data;
            return new Promise((resolve, reject) => {
                if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                    resolve(resApiData.data)
                else 
                    reject([])
            })   
        }).catch(error => {
            Promise.reject(error?.response?.data?.message || error?.message)
        })
    }

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };
    
    // * Render Table Data
    const renderTable = () => {
        return (
            machineLogList?.length !== 0 ? machineLogList?.map((machineLog: MachineLogData) =>  {
                let colorOfLogLevel = "";
                let logLevel = machineLog.level;
                if (logLevel === LOG_LEVEL.WARN ) {
                    colorOfLogLevel = "#eb790e";
                } else if (logLevel === LOG_LEVEL.ALERT ) {
                    colorOfLogLevel = "#eb0e15";
                } else if (logLevel === LOG_LEVEL.SUCCESS) {
                    colorOfLogLevel = "#539165"
                }   
                return (
                    <tr key={machineLog.id} style={{color: colorOfLogLevel}}>
                        <td>{machineLog.id}</td>
                        <td>{convertToLocaleDate(machineLog.createdAt!)}</td>
                        <td>{machineLog.organizationName || "-"}</td>
                        <td>{machineLog.machineName || "-"}</td>
                        <td>{machineLog.machineLog || "-"}</td>
                        <td>{machineLog.remark || "-"}</td>
                    </tr>
                    )
                }
            ): 
            <tr>
                <td>No data found</td>
            </tr>
        )
    }

    if (!hasPermission("viewMachineLog") && !isTenantUser()) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (
        <>
            <Layout>
                <Container>
                    <Box className="d-flex justify-content-between mb-2">
                        <Title>Machine Logs</Title>
                        <Box className="titleBox">
                            <ExportButton 
                                exportToCSV={exportToCSV}
                                exportToPdf={exportToPdf}
                            />
                        </Box>
                    </Box>
                    {/* {successText && <Alert severity="success">{successText}<br/></Alert>} */}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}
                    <MachineLogFilterContainer
                        onChangeFilter={handleChangeFilterValue}
                        onChangeDateFilter={handleChangeDateFilterValue}
                        filterValue={filterValue}
                        dateFilterValue={dateFilterValue}
                        userInfo={userInfo}
                        onChangeHealthCheck={handleHealthCheck}
                    />
                    
                    <SummaryText>{machineLogTotalCount} MachineLog(s) found!</SummaryText>
                    <TableContainer>
                    <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    <th>Datetime</th>
                                    <th>Organization</th>
                                    <th>Machine</th>
                                    <th>Log</th>
                                    <th>Remark</th>
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {
                                    renderTable()
                                }
                            </ tbody>
                        </table>
                    </InnterTableContainer>
                    </TableContainer>
                    <PaginationContainer>
                        <Pagination 
                            count={pageCount} 
                            onChange={handleChangePage} 
                            page={tablePage} 
                            variant="outlined" 
                            shape="rounded" size="large" 
                        />
                    </PaginationContainer>
                </Container>
            </Layout>
        </>
    );
};

export default MachineLog;