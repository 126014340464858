import { Alert, Box, Pagination } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { PAGE_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { cartonBoxDetectionGetListByOrgIdApi, cartonBoxDetectionGetListByUserIdApi } from "../../services/RouteServices/CartonBoxDetectionApi";
import { CartonBoxDetectionType } from "../../types/CartonBoxDetectionType";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import { DateFilterValue, FilterValue } from "../../types/global/FilterValueType";
import { convertDateFormat } from "../../utils/DateTimeUtil";
import { getDefaultDateFilterValue, getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import Layout from "../Layout/Layout";
import { CardsContainer, Container, PaginationContainer } from "../Shared/Common/Containers";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import CartonBoxCard from "./components/CartonBoxCard";
import CartonBoxFilter from "./components/CartonBoxFilter";
import { getCurrentLocalUser, isTenantUser } from "../../utils/UserUtil";

const CartonBox = () => {
    const userInfo: UserInfo = getCurrentLocalUser();
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType
    const [filterValue, setFilterValue] = useState<FilterValue>(getDefaultFilterValue());
    const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>(getDefaultDateFilterValue())

    const [tableOffset, setTableOffset] = useState(0);
    const [tablePage, setTablePage] = useState(1);
    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const [cartonBoxDetections, setCartonBoxDetections] = useState<CartonBoxDetectionType[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);

    useEffect(() => {
        setErrorText("")
        setSuccessText("")

        if (hasPermission("viewCartonBox")) {
            if(isTenantUser()) {
                getCartonBoxDetectionListByUserId()
            } else {
                getCartonBoxDetectionListByOrgId();
            }
        }
    }, [filterValue, dateFilterValue, tableOffset])

    useEffect(() => {
        setFilterValue(
            (prevFilterData) => {
                return  {
                    ...prevFilterData,
                    machineId: 0,
                    locationId: 0
                }
            }
        )
    }, [filterValue.organizationId])


    useEffect(() => {
        setFilterValue(
            (prevFilterData) => {
                return  {
                    ...prevFilterData,
                    machineId: 0,
                }
            }
        )
    }, [filterValue.locationId])

    const handleChangeFilterValue = (event: ChangeEvent<HTMLSelectElement>) => {
        setFilterValue(
            (prevValue) => {
                return {
                    ...prevValue,
                    [event.target.name]: event.target.value,
                }
            }
        )
    }

    const handleChangeDateFilterValue = (key: "startDate" | "endDate", value: Date) => {
        const day = value.getDate().toString().padStart(2, '0');
        const month = (value.getMonth() + 1).toString().padStart(2, '0');
        const year = value.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setDateFilterValue({...dateFilterValue, [key]: formattedDate})
    }

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (PAGE_LIMIT * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };

    const getCartonBoxDetectionListByOrgId = () => {
        const dataLimitsObject = {
            orgId: filterValue.organizationId,
            machineId: filterValue.machineId,
            locationId: filterValue.locationId,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: PAGE_LIMIT,
            offset: tableOffset,
        }
        cartonBoxDetectionGetListByOrgIdApi(dataLimitsObject)
        .then((response) => {
            const resData = response?.data
            console.log(resData)
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: CartonBoxDetectionType[] = resData.data?.cartonBoxDetections
                const totalCount : number = resData?.data?.totalCount;
                setTotalCount(totalCount)
                setCartonBoxDetections(dataList);
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
            // setIsLoading(false)
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            // setIsLoading(false)
        })
    }

    const getCartonBoxDetectionListByUserId = () => {
        const dataLimitsObject = {
            userId: userInfo.id,
            orgId: filterValue.organizationId,
            machineId: filterValue.machineId,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: PAGE_LIMIT,
            offset: tableOffset,
        }
        cartonBoxDetectionGetListByUserIdApi(dataLimitsObject)
        .then((response) => {
            const resData = response?.data
            console.log(resData)
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: CartonBoxDetectionType[] = resData.data?.cartonBoxDetections
                const totalCount : number = resData?.data?.totalCount;
                setTotalCount(totalCount)
                setCartonBoxDetections(dataList);
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
            // setIsLoading(false)
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            // setIsLoading(false)
        })
    }

    if (!hasPermission("viewCartonBox")) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (  
        <Box>
            <Layout>
                <Container>
                    <div className="row">
                        <div className="col-lg-9 col-md-12">
                            <CartonBoxFilter
                                onChangeFilter={handleChangeFilterValue}
                                onChangeDateFilter={handleChangeDateFilterValue}
                                filterValue={filterValue}
                                dateFilterValue={dateFilterValue}
                            />
                        </div>
                    </div>
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}
                    <CardsContainer className="hide-scroll-bar">
                        {
                            cartonBoxDetections?.map(cartonBox => <CartonBoxCard key={cartonBox.id} cartonBox={cartonBox} />)
                        }
                    </CardsContainer>

                    <PaginationContainer>
                        <Pagination 
                            count={(Math.ceil(totalCount/PAGE_LIMIT)) || 0} 
                            onChange={handleChangePage} 
                            page={tablePage} 
                            variant="outlined" 
                            shape="rounded" size="large" 
                        />
                    </PaginationContainer>
                </Container>
            </Layout>
        </Box>
    );
}
 
export default CartonBox;