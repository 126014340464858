import {  useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';
import { PathAndName } from "../../types/MaintenanceType";
import { Box } from "@mui/material";
import styles from './Maintenance.module.css'
import { NORMAL_FOLDER } from "../../constants/MaintenanceContants";

const PathTable = ({
    currentFolder,
    folderPaths,
    contents,
    onClickView,
    onDownload,
    onChangeDownloadedName,
    downloadedName,
    folderType
}: any) => {
    const [show, setShow] = useState<boolean>(false);
    let totalContentSizeInByte: number = 0;
    let totalContentSizeInKb: number = 0;
    let totalContentSizeInMb: number = 0;
    if (folderType === NORMAL_FOLDER) {
        const totalFolderSizeInByte: number = contents?.folders?.reduce(
            (total: number, folder: PathAndName) => total + folder.fileSize,
            0
        ) || 0
          
        const totalFileSizeInByte: number = contents?.files?.reduce(
            (total: number, file: PathAndName) => total + file.fileSize,
        0
        ) || 0
        totalContentSizeInByte = totalFileSizeInByte + totalFolderSizeInByte 
    } else {
        totalContentSizeInByte = contents?.fileSize || 0;
    }
    totalContentSizeInKb = +(totalContentSizeInByte / 1000).toFixed(1)
    totalContentSizeInMb = +(totalContentSizeInKb / 1024).toFixed(1)


    const handleShow = (path: string) => {
        onClickView(path)
        setShow(!show)
    }

    return (  
        <>
            <div className={styles.tableContainer}>
                <div className={styles.innerTableContainer}>
                    <table className="table table-sm my-5">
                        <caption>List of Folders</caption>
                        <thead>
                            <tr>
                                <th>Path</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (folderPaths && folderPaths.length > 0) && 
                                folderPaths.map((path: string, index: number) => (
                                    <tr key={index}>
                                        <td>{path}</td>
                                        <td><Button onClick={() => handleShow(path)}>View</Button></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>Contents</Modal.Title>
                    <CloseIcon onClick={() => setShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <p className="fw-lighter"><span className="fw-light">Location: </span>{currentFolder}</p>
                    <p className="text-success">
                        {`Total content's size: ${totalContentSizeInMb}MB (${totalContentSizeInKb}kB)`}
                    </p>
                    {
                        (folderType === NORMAL_FOLDER) &&
                        <>
                        <h5>Folders</h5>
                        {
                            (contents?.folders && contents?.folders?.length > 0) ?
                            <ul>
                                {
                                    contents?.folders?.map((folder: PathAndName, index: number) => (
                                        <li key={index}>{folder.fileName}</li>
                                    ))
                                }
                            </ul> :
                            <p className="text-warning">No Folders under this folder</p>
                        }
                        <h5>Files</h5>
                        {
                            (contents?.files && contents?.files?.length > 0) ?
                            <ul>
                                {
                                    
                                    contents?.files?.map((file: PathAndName, index: number) => (
                                        <li key={index}>{file.fileName}</li>
                                    ))
                                }
                            </ul> :
                            <p className="text-warning">No Files under this folder</p>
                        }
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                    }}>
                    <InputGroup style={{
                        width: "350px"
                    }}>
                        <InputGroup.Text id="inputGroup-sizing-default">
                            Download as
                        </InputGroup.Text>
                        <Form.Control
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            onChange={onChangeDownloadedName}
                            value={downloadedName.replace(".zip", "")}
                        />
                    </InputGroup>
                        <Button onClick={() => {onDownload(); setShow(false)}}>Download</Button>
                    </Box>
                </Modal.Footer>
            </Modal>
        </>
    );
}
 
export default PathTable;