import * as React from 'react';
import Button from "react-bootstrap/Button"
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import MenuItem from '@mui/material/MenuItem';
import Menu, { MenuProps } from "@mui/material/Menu";
import { styled } from "@mui/material";

const ExportButton = ({
    exportToCSV,
    exportToPdf,
    exportToPdfWithImage,
    dayRange,
}: any): JSX.Element => {

    const [anchorEl_Menu, setAnchorEl_Menu] = React.useState<null | HTMLElement>(null);

    const handleCloseMenu = () => {
        setAnchorEl_Menu(null);
    };

    const handleExportBtn = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl_Menu(event.currentTarget);
    };

    const StyledMenu = styled((props: MenuProps) => (
        <Menu
            id="nav-exportbtn-menu"
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            {...props}
        />
    ))(() => (
        {
            '& .MuiPaper-root': {
                minWidth: 95,
            }
        }
    ))

    return ( 
        <>
            <Button 
                className="pt-2 pb-2 ps-2 pe-2" 
                variant="primary" 
                onClick={handleExportBtn}
            >
                Export <KeyboardArrowDownRoundedIcon />
            </Button>
            <StyledMenu
                anchorEl={anchorEl_Menu}
                open={Boolean(anchorEl_Menu)}
                onClose={handleCloseMenu}
            >
                {exportToCSV && <MenuItem onClick={() => {exportToCSV(); handleCloseMenu()}}>CSV</MenuItem>}
                {exportToPdf && <MenuItem onClick={() => {exportToPdf(); handleCloseMenu()}}>PDF</MenuItem>}
                {exportToPdfWithImage && <MenuItem onClick={() => {exportToPdfWithImage(); handleCloseMenu()}} disabled={dayRange ? dayRange > 31 : false}>PDF With Image</MenuItem>}
            </StyledMenu>
        </>
    );
}
 
export default ExportButton;