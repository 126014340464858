import { DoorLogGETRequestBody } from "../../types/DoorLogType";
import http from "../apiServices";

export const doorLogGetListByMachineIdApi = (inputData: DoorLogGETRequestBody) => {
    return http.post("door-log/get/by/machineId", inputData)
}

export const doorLogGetCountByOrgIdApi = (inputData: DoorLogGETRequestBody) => {
    return http.post("door-log/get/count/by/orgId", inputData)
}

export const doorLogGetCountByUserIdApi = (inputData: DoorLogGETRequestBody) => {
    return http.post("door-log/get/count/by/userId", inputData)
}

export const doorLogGetListByOrgIdApi = (inputData: DoorLogGETRequestBody) => {
    return http.post("door-log/get/list/by/orgId", inputData)
}

export const doorLogGetListByUserIdApi = (inputData: DoorLogGETRequestBody) => {
    return http.post("door-log/get/list/by/userId", inputData)
}

export const doorLogGetExportListByOrgId = (inputData: DoorLogGETRequestBody) => {
    return http.post("door-log/get/export-list/by/orgId", inputData)
}

export const doorLogGetExportListByUserId = (inputData: DoorLogGETRequestBody) => {
    return http.post("door-log/get/export-list/by/userId", inputData)
}