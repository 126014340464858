export const weightReportOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: `Weight Report`,
      },
    },
    scales: {
        y: {
          title: {
            display: true,
            text: 'Weight(kg)'
          }
        },
        x: {
            title: {
                display: true,
                text: "Date"
            }
        }
    },
};


export const countReportOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: `Count Report`,
    },
  },
  scales: {
      y: {
        title: {
          display: true,
          text: 'Count'
        },
        ticks: {
          precision: 0
        }
      },
      x: {
          title: {
              display: true,
              text: "Date"
          }
      }
  },
};