import { Box, Typography } from "@mui/material";
import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { MachinesData } from '../../types/MachinesType';
import { OrganizationsData } from "../../types/OrganizationsType";
import { isAdminUser, isTenantUser } from '../../utils/UserUtil';
import CalendarModal from '../Shared/CalendarModal/CalendarModal';
import { FilterContainer } from '../Shared/Common/Containers';
import { useFilterOrganizations } from "../../custom-hooks/useFilterOrganizations";
import useFilterLocations from "../../custom-hooks/useFilterLocations";
import { useFilterMachinesByLocationIds } from "../../custom-hooks/useFilterMachinesByLocationIds";
import { useFilterTenants } from "../../custom-hooks/useFilterTenants";
import { LocationsData } from "../../types/LocationsType";


const DischargeWeightLogFilterContainer = ({
    onChangeFilter, 
    onChangeDateFilter,
    filterValue, 
    dateFilterValue,
    userInfo,
} : any) : JSX.Element => {
    const [showStartDateModal, setShowStartDateModal] = useState(false)
    const [showEndDateModal, setShowEndDateModal] = useState(false)

    const {organizations} = useFilterOrganizations()
    const {locations} = useFilterLocations(filterValue?.organizationId, [filterValue?.organizationId])
    const {machines} = useFilterMachinesByLocationIds([filterValue?.locationId], [filterValue.locationId])
    const {tenants} = useFilterTenants(filterValue?.machineId || -1, [filterValue.machineId])

    const handleClickStartDate = () => {
        setShowStartDateModal(!showStartDateModal)
    }

    const handleClickEndDate = () => {
        setShowEndDateModal(!showEndDateModal)
    }

    const handleCloseDateModal = () => {
        setShowStartDateModal(false)
        setShowEndDateModal(false)
    }
    return (
        <FilterContainer>
            <Form.Group className="mb-3">
                <Row className="mb-3">
                    <Col xs={6} md={4} lg={2}>
                        <Box 
                            sx={(theme) => ({
                                display: "flex", 
                                gap: 1, 
                                alignItems: "center",
                                [theme.breakpoints.down('sm')]: {
                                    flexDirection: "column",
                                    justifyContent: "center",
                                },
                            })}
                        >
                            <Typography>Start Date</Typography>
                            <button type="button" className="btn btn-outline-dark" onClick={handleClickStartDate}>{dateFilterValue?.startDate || "Start Date"}</button>
                            <CalendarModal 
                                show={showStartDateModal}
                                onCloseModal={handleClickStartDate}
                                onChange={(value: Date) => {
                                    onChangeDateFilter("startDate", value)
                                    handleCloseDateModal()
                                }}
                                maxDate={new Date(dateFilterValue.endDate)}
                                defaultValue={new Date(dateFilterValue.startDate)}
                            />
                        </Box>
                    </Col>
                    <Col xs={6} md={4} lg={2}>
                        <Box 
                            sx={(theme) => ({
                                display: "flex", 
                                gap: 1, 
                                alignItems: "center",
                                [theme.breakpoints.down('sm')]: {
                                    flexDirection: "column",
                                    justifyContent: "center",
                                },
                            })}
                        > 
                            <Typography>End Date</Typography>
                            <button type="button" className="btn btn-outline-dark" onClick={handleClickEndDate}>{dateFilterValue?.endDate || "End Date"}</button>
                            <CalendarModal 
                                show={showEndDateModal}
                                onCloseModal={handleClickEndDate}
                                onChange={(value: Date) => {
                                    onChangeDateFilter("endDate", value)
                                    handleCloseDateModal()
                                }}
                                maxDate={new Date()}
                                minDate={new Date(dateFilterValue.startDate)}
                                defaultValue={new Date(dateFilterValue.endDate)}
                            />
                        </Box>
                    </Col>
                </Row>
                <Row
                >
                    <Col 
                        sm={12}
                        md={4}
                        className='mb-2'
                    >
                        <InputGroup
                            className='mb-10'
                        >
                            <InputGroup.Text className="input-user"><span>Organization</span></InputGroup.Text>
                            {
                                (isTenantUser() || isAdminUser()) ?
                                <Form.Select onChange={onChangeFilter} 
                                    name="organizationId" aria-label="organizatioinId" 
                                    value={userInfo.orgId}
                                    disabled
                                >
                                    <option value={userInfo.orgId}>
                                        {userInfo.orgName || "Organization Not Found"}
                                    </option>
                                </Form.Select> :
                                <Form.Select onChange={onChangeFilter} 
                                    name="organizationId" aria-label="organizatioinId" 
                                    value={filterValue.organizationId}
                                >
                                    <option value={0} >All</option>
                                    {
                                    (organizations && organizations?.length !== 0) && organizations.map((org:OrganizationsData) => 
                                        <option value={org.id} key={org.id} >{org.organizationName}</option>
                                    )
                                    }
                                </Form.Select>
                            }
                        </InputGroup>
                    </Col>
                    {
                        !isTenantUser() &&
                        <Col
                            sm={12}
                            md={4}
                            className='mb-2'
                        >
                            <InputGroup>
                                <InputGroup.Text className="input-user"><span>Location</span></InputGroup.Text>
                                {
                                    <Form.Select onChange={onChangeFilter} 
                                    name="locationId" aria-label="locationId" 
                                    value={filterValue.locationId}
                                    disabled={!Boolean(filterValue.organizationId && filterValue.organizationId !== '0')}
                                    >
                                        <option value={0}>All</option>
                                        {
                                        (locations && locations?.length !== 0) && locations.map((location: LocationsData) => 
                                            <option value={location.id} key={location.id}>{location.locationName}</option>
                                        )
                                        }
                                    </Form.Select>
                                }
                            </InputGroup>
                        </Col>
                    }
                    <Col
                        sm={12}
                        md={4}
                        className='mb-2'
                    >
                        <InputGroup>
                            <InputGroup.Text className="input-user"><span>Machine</span></InputGroup.Text>
                            {
                                <Form.Select onChange={onChangeFilter} 
                                name="machineId" aria-label="machineId" 
                                value={filterValue.machineId}
                                disabled={
                                    !Boolean(filterValue.locationId && filterValue.locationId !== '0') && !isTenantUser()
                                }
                                >
                                    <option value={0}>All</option>
                                    {
                                    (machines && machines?.length !== 0) && machines.map((machine:MachinesData) => 
                                        <option value={machine.id} key={machine.id}>{machine.machineName}</option>
                                    )
                                    }
                                </Form.Select>
                            }
                        </InputGroup>
                    </Col>
                </Row>
            </Form.Group>
        </FilterContainer>
    )
}

export default DischargeWeightLogFilterContainer;