import { useContext, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { UserAuthContext } from "../contexts/UserAuthContext";
import { UserAuthType } from "../types/UserAuth";
import { getCurrentLocalUser, setAvailableLocations, updatePermissions } from "../utils/UserUtil";

const PrivateRoute = () => {
  const { isAuth } = useContext(UserAuthContext) as UserAuthType;
  const location = useLocation();

  useEffect(() => {
    const user = getCurrentLocalUser()
    updatePermissions(user.roleId)
    setAvailableLocations(user.id!)
  },[])

  return isAuth() ? (
    <Outlet />
  ) : (
    <Navigate to={"/login"} state={{ from: location }} replace />
  );
};

export default PrivateRoute;
