import { BASE_URL } from "../constants/ApiConstants"

export const generateImageUrl = (imagePath: string, staticPath: string) => {
    if (!imagePath || !staticPath) return ""
    return BASE_URL + imagePath?.replace(/\\/g, '/')?.replace(staticPath, "")
}


export const mergeImageUrlWithBaseUrl = (imagePath: string) => {
    if (!imagePath) return ""
    return BASE_URL + imagePath
}