import { UserADDRequestBody, UserDELETERequestBody, UserData, UserUPDATERequestBody, UserUpdatePwdRequestBody } from "../../types/UsersType";
import { RequestBodyGetTableApi } from "../../types/global/TableType";
import http from "../apiServices";

// * Post all Given Data to database.table

export const userGetForAlertApi = () => {
    return http.post("/user/get/alert-users")
}

export const userGetListByOrgIdAndRoleIdApi = (inputData : RequestBodyGetTableApi) => {
    return http.post("/user/get/by/orgId-roleId", inputData)
}

export const userGetExportListByOrgIdAndRoleIdApi = (inputData : RequestBodyGetTableApi) => {
    return http.post("/user/get/export-list/by/orgId-roleId", inputData)
}

export const userGetAllByOrgIdAndTenantIdApi = (orgId: number, tenantId: number) => {
    return http.post("/user/get/by/orgId-tenantId", {"orgId": orgId, "tenantId": tenantId})
}

export const userGetAllByOrgIdApi = (orgId: number) => {
    return http.post("/user/get/all/by/orgId", {"orgId": orgId})
}

export const userAddDataApi = (inputData : UserADDRequestBody) => {
    return http.post("/user/add", inputData)
}

export const userUpdateDataApi = (inputData : UserUPDATERequestBody) => {
    return http.put("/user/update", inputData)
}

export const userUpdatePwdApi = (inputData: UserUpdatePwdRequestBody) => {
    return http.put("/user/update/pwd", inputData)
}

export const userDeleteDataApi = (inputData : UserDELETERequestBody) => {
    return http.post("/user/delete", inputData)
}