import { useEffect, useState } from "react";
import { TenantsData } from "../types/TenantsType";
import { getAllTenantByMachineIdList } from "../services/RouteServices/CommonApi";

export const useReportFilterTenants = (
    machineIdList: number[],
    deps: any[]
) => {
    const [tenants, setTenants] = useState<TenantsData[]>([])
    const [error, setError] = useState("")

    useEffect(() => {
        if (machineIdList.length > 0) {
            getAllTenantByMachineIdList(machineIdList)
            .then(res => {
                setTenants(res)
            }).catch(error => {
                setError(error)
            })
        } else {
            setTenants([])
        }
    }, deps ? deps : [])

    return {tenants, error}

}