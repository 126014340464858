import { useEffect, useState } from "react";
import { captureGetByMachineIdAndCaptureTimeApi } from "../services/RouteServices/CaptureApi";
import { CaptureData, CaptureListResponseBody } from "../types/CaptureType";
import { STATUS_CODES } from "../constants/GlobalConstants";

const useCaptureImages = (
    dataLimitObj: {machineId: number, startDate: string, endDate: string},
    deps?: any[]
) => {
    const [images, setImages] = useState<{url: string, captureTime: string}[]>([])
    const [totalCount, setTotalCount] = useState<number>(0);
    const [error, setError] = useState<string>("")

    useEffect(() => {
        if (dataLimitObj.machineId !== 0 && dataLimitObj.startDate !== "" && dataLimitObj.endDate !== "") {
            captureGetByMachineIdAndCaptureTimeApi(dataLimitObj)
            .then(res => {
                const resData: CaptureListResponseBody = res?.data
                if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                    const dataList: CaptureData[] = resData.data.captureList
                    setImages(dataList.map(data => {return {"url": data.imageUrl, "captureTime": data.captureTime}}))
                    setTotalCount(resData.data.totalCount)
                }
                else {
                    setError(resData?.message)
                }
            }).catch(error => {
                setError(error?.message)
            })
        }
    }, deps ? deps: [])

    return {images, totalCount, error}
}

export default useCaptureImages;