import { FilterContainer } from "../Shared/Common/Containers";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { ChangeEvent, FunctionComponent } from "react";
import { FilterValue } from "../../types/global/FilterValueType";
import { getCurrentLocalUser, isSuperSpecialUser } from "../../utils/UserUtil";
import { OrganizationsData } from "../../types/OrganizationsType";
import { useFilterOrganizations } from "../../custom-hooks/useFilterOrganizations";

interface Props {
    filterValues: FilterValue,
    onChangeFilter: (event: ChangeEvent<any>) => void
}

const LocationFilterContainer: FunctionComponent<Props> = ({
    filterValues,
    onChangeFilter
}) => {
    const {organizations, error} = useFilterOrganizations()
    const userInfo = getCurrentLocalUser()
    return (  
        <FilterContainer>
            <Form.Group>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text className="input-user"><span>Organization</span></InputGroup.Text>
                            {
                                !isSuperSpecialUser() ?
                                <Form.Select onChange={onChangeFilter} 
                                    name="organizationId" aria-label="organizatioinId" 
                                    value={filterValues.organizationId}
                                    disabled
                                >
                                    <option value={userInfo.orgId}>
                                        {organizations.filter((org:OrganizationsData) => org.id === +userInfo.orgId)[0]?.organizationName}
                                    </option>
                                </Form.Select> :
                                <Form.Select onChange={onChangeFilter} 
                                name="organizationId" aria-label="organizatioinId" 
                                value={filterValues.organizationId}
                                >
                                    <option value={0}>All</option>
                                    {
                                    (organizations && organizations?.length !== 0) && organizations.map((org:OrganizationsData) => 
                                        <option value={org.id} key={org.id}>{org.organizationName}</option>
                                    )
                                    }
                                </Form.Select>
                            }
                        </InputGroup>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <InputGroup>
                            <InputGroup.Text id="inputGroup-sizing-lg">Search</InputGroup.Text>
                            <Form.Control
                                aria-label="Large"
                                aria-describedby="inputGroup-sizing-sm"
                                value={filterValues.search}
                                onChange={onChangeFilter}
                                name="search"
                                placeholder="Name"
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </Form.Group>
        </FilterContainer>
    );
}
 
export default LocationFilterContainer;