import { LfcCorrectionLogADDRequestBody, LfcDateGETReqeustBody } from "../../types/LFCDataType";
import { RequestBodyGetTableApi } from "../../types/global/TableType";
import http from "../apiServices";

// * Post all Given Data to database.table
// TODO: Create Type for inputData pageLimit and pageOffset
export const lfcDatagetDataApi = (inputData : RequestBodyGetTableApi) => {
    return http.post("/lfcData/get/list", inputData)
}

export const lfcGetExportDataApi = (inputData : LfcDateGETReqeustBody) => {
    return http.post("/lfcData/get/export-list", inputData)
}

export const addLfcCorrectionApi = (inputData : LfcCorrectionLogADDRequestBody) => {
    return http.post("/lfcData/add/correction", inputData)
}