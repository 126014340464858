import { MachineLogGETRequestBody } from "../../types/MachineLogType";
import http from "../apiServices";

export const machineLogGetListByMachineId = (inputData: MachineLogGETRequestBody) => {
    return http.post("machine-log/get/by/machineId", inputData)
}

export const machineLogGetListByOrgIdAndMachineId = (inputData: MachineLogGETRequestBody) => {
    return http.post("machine-log/get/by/orgId-machineId", inputData)
}

export const machineLogGetExportListByOrgId = (inputData: MachineLogGETRequestBody) => {
    return http.post("machine-log/get/export-list/by/orgId", inputData)
}