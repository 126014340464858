import { Box, Typography } from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Button, Form } from "react-bootstrap";
import { DropdownData } from "../../../types/global/DropDownType";
import { isAdminUser, isTenantUser } from "../../../utils/UserUtil";
import CalendarModal from "../../Shared/CalendarModal/CalendarModal";
import { getMonthChoices, getYearChoices } from "../../../utils/DateTimeUtil";
import SearchableSelect from 'react-select';
import { useFilterMachines } from "../../../custom-hooks/useFilterMachines";
import { useFilterOrganizations } from "../../../custom-hooks/useFilterOrganizations";
import { loadMachineList, loadOrganizationList, loadTenantList } from "../../../utils/LoadDataUtil";
import { FunctionComponent, useEffect, useState } from "react";
import { ManualDetectionReportDateFilterValue, ManualDetectionReportFilterValue } from "../../../types/ManualDetectionType";
import { useReportFilterTenants } from "../../../custom-hooks/useReportFilterTenants";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

interface Props {
    filterValue: ManualDetectionReportFilterValue,
    dateFilterValue: ManualDetectionReportDateFilterValue,
    selectedView: string,
    maxEndDate: string,
    handleChangeFilterValue: (name: string, value: string | number[] | DropdownData) => void,
    handleChangeDateFilterValue: (name: string, value: string | Date | number) => void,
    handleChangeSelectedView: (value: string) => void,
    exportToExcel: () => void,
    exportChartsToPdf: () => void
}


const FilterSection: FunctionComponent<Props> = ({
    filterValue,
    dateFilterValue,
    selectedView,
    maxEndDate,
    handleChangeFilterValue,
    handleChangeDateFilterValue,
    handleChangeSelectedView,
    exportToExcel,
    exportChartsToPdf
}) => {

    const [showStartDateModal, setShowStartDateModal] = useState(false)
    const [showEndDateModal, setShowEndDateModal] = useState(false)

    const {organizations, error: orgError} = useFilterOrganizations()
    const {machines, error: machineError} = useFilterMachines(filterValue?.orgId || -1, [filterValue.orgId])
    const {tenants, error: tenantError} = useReportFilterTenants(filterValue?.machineIdList || [], [filterValue.machineIdList])

    const orgList = loadOrganizationList(organizations)
    const machineList = loadMachineList(machines)
    const tenantList = [{value: "0", label: `All (${tenants.length})`}, ...loadTenantList(tenants)]

    useEffect(() => {
        if (machines.length > 0) {
            handleChangeFilterValue("machineIdList", loadMachineList(machines).map(item => +item.value))
        }
    }, [machines])

    const handleCloseDateModal = () => {
        setShowStartDateModal(false)
        setShowEndDateModal(false)
    }

    const handleClickStartDate = () => {
        setShowStartDateModal(true)
    }

    const handleClickEndDate = () => {
        setShowEndDateModal(true)
    }

    return (  
        <Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        gap: 2,
                        marginY: 3,
                        flexWrap: "wrap",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Form.Group>
                        <Form.Label className="fw-bold">Organization</Form.Label>
                        <Form.Select 
                            style={{width: "250px"}} 
                            value={filterValue.orgId}
                            name="orgId"
                            onChange={({target: {name, value}}) => {handleChangeFilterValue(name, value)}}
                            disabled={isAdminUser() || isTenantUser()}
                        >
                            {
                                (orgList && orgList.length > 0) ?
                                orgList.map((org: DropdownData) => <option value={org.value} key={org.value}>{org.label}</option>) :
                                <option value={-1}>No Organizations</option>
                            }
                        </Form.Select>
                    </Form.Group>
                    <FormControl sx={{ m: 1, width: 250 }}>
                        <InputLabel id="demo-multiple-checkbox-label">Machine</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={filterValue.machineIdList}
                            name="machineIdList"
                            onChange={({target: {name, value}}) => {
                                handleChangeFilterValue(name, typeof value === 'string' ? value.split(',').map(value => +value) : value)
                            }}
                            input={<OutlinedInput label="Machine" />}
                            MenuProps={MenuProps}
                            renderValue={(selected) => {
                                // return selected.map((value: number) => 
                                //     machineDropdownList.find((machine: DropdownData) => +machine.value! === value).label
                                // ).join(', ')
                                return `Selected Machine: ${selected.length}`
                            }}
                        >
                        {
                            (machineList && machineList.length > 0) &&
                            machineList.map((machine: DropdownData) => (
                                <MenuItem key={machine.value} value={+machine.value!} >
                                    <Checkbox checked={filterValue.machineIdList.indexOf(+machine.value!) > -1} />
                                    <ListItemText primary={machine.label} />
                                </MenuItem>
                            ))
                        }
                        </Select>
                    </FormControl>
                    {
                        (!isTenantUser()) &&
                        <Form.Group>
                            <Form.Label className="fw-bold">Tenant</Form.Label>
                            <SearchableSelect
                                value={filterValue.tenant}
                                name="tenant"
                                onChange={(item) => {
                                    handleChangeFilterValue("tenant", item!)
                                }}
                                options={tenantList}
                                isSearchable
                                className="tenant-select"
                            />
                        </Form.Group>
                    }
                </Box>
                <Box
                    sx={{
                        width: "25%",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 2,
                    }}
                >
                    <Button onClick={exportToExcel}><DownloadIcon /> </Button>
                    <Button onClick={exportChartsToPdf}><PictureAsPdfIcon /></Button>
                </Box>
            </Box>
            <Box 
                sx={{
                    display: "flex",
                    gap: 2,
                    flexWrap: "wrap",
                    alignItems: "flex-end",
                }}
            >
                <Form.Select 
                    name="view"
                    onChange={(event) => {handleChangeSelectedView(event.target.value)}}
                    value={selectedView}
                    style={{width: "100px"}} 
                >
                    <option value="1">Month</option>
                    <option value="2">Year</option>
                    <option value="3">Day</option>
                </Form.Select>
                {
                    selectedView === '1' &&
                    <Box className="d-flex gap-2">
                        <Form.Select
                            onChange={({target: {name, value}}) => {handleChangeDateFilterValue(name, value)}}
                            value={dateFilterValue.month.m_year}
                            name="m_year"
                            style={{width: "150px"}} 
                        >
                            {
                                getYearChoices().map((year) => <option value={year.value} key={year.value}>{year.label}</option>)
                            }
                        </Form.Select>
                        <Form.Select
                            name="month"
                            onChange={({target: {name, value}}) => {handleChangeDateFilterValue(name, value)}}
                            value={dateFilterValue.month.month}
                            style={{width: "150px"}} 
                        >
                            {
                                getMonthChoices().map((month) => <option value={month.value} key={month.value}>{month.label}</option>)
                            }
                        </Form.Select>
                    </Box>
                }
                {
                    selectedView === '2' &&
                    <Form.Select
                        onChange={({target: {name, value}}) => {handleChangeDateFilterValue(name, value)}}
                        value={dateFilterValue.year}
                        name="year"
                        style={{width: "150px"}} 
                    >
                        {
                            getYearChoices().map((year) => <option value={year.value} key={year.value}>{year.label}</option>)
                        }
                    </Form.Select>
                }
                {
                    selectedView === '3' &&
                    <Box
                        style={{
                            display: "flex",
                            gap: 5,
                            width: "500px",
                            flexWrap: "wrap",
                            alignItems: "flex-end",
                        }}
                    >
                        <Box>
                            <Typography>Start Date</Typography>
                            <button type="button" className="btn btn-outline-dark" onClick={handleClickStartDate} >{dateFilterValue.date.startDate || "Start Date"}</button>
                        </Box>
                        <Box>
                            <Typography>End Date</Typography>
                            <button type="button" className="btn btn-outline-dark" onClick={handleClickEndDate}>{dateFilterValue.date.endDate || "End Date"}</button>
                        </Box>
                        {/* <Form.Select
                            value={dateFilterValue.date.range}
                            name="range"
                            onChange={({target: {name, value}}) => {handleChangeDateFilterValue(name, value)}}
                            style={{width: "70px"}} 
                        >
                            <option value='7'>7</option>
                            <option value='14'>14</option>
                            <option value='30'>30</option>
                        </Form.Select> */}
                        <CalendarModal 
                            show={showStartDateModal}
                            onCloseModal={handleCloseDateModal}
                            onChange={(value: Date) => {
                                handleChangeDateFilterValue("startDate", value)
                                handleCloseDateModal()
                            }}
                            defaultValue={new Date(dateFilterValue.date.startDate)}
                        />
                        <CalendarModal 
                            show={showEndDateModal}
                            onCloseModal={handleCloseDateModal}
                            onChange={(value: Date) => {
                                handleChangeDateFilterValue("endDate", value)
                                handleCloseDateModal()
                            }}
                            maxDate={maxEndDate ? new Date(maxEndDate) : new Date()}
                            minDate={new Date(dateFilterValue.date.startDate)}
                            defaultValue={new Date(dateFilterValue.date.endDate)}
                        />
                    </Box>
                }
            </Box>
        </Box>
    );
}
 
export default FilterSection;