import { FunctionComponent } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ModalImage from 'react-modal-image';
import { CaptureData } from '../../types/CaptureType';
import { convertToLocaleDate } from '../../utils/DateTimeUtil';
import { isSuperSpecialUser } from '../../utils/UserUtil';
import { mergeImageUrlWithBaseUrl } from '../../utils/ImageUtil';
import { NO_IMAGE_URL } from '../../constants/GlobalConstants';

interface CaptureCardProps {
    capture: CaptureData
}
 
const CaptureCard: FunctionComponent<CaptureCardProps> = ({
    capture
}) => {
    return (  
        <Card sx={{ width: 350, marginX: 3, borderRadius: 3, overflow: "hidden" }} className='capture-card'>
            <CardMedia
                sx={{ height: 250, overflow: "hidden" }}
            >
                <ModalImage
                    small={mergeImageUrlWithBaseUrl(capture.imageUrl) || NO_IMAGE_URL}
                    large={mergeImageUrlWithBaseUrl(capture.imageUrl) || NO_IMAGE_URL}
                    alt="Capture Image"
                    className="card-img"
                />
            </CardMedia>
            <CardContent sx={{ maxHeight: 400, overflow: "scroll" }} className="hide-scroll-bar">
                <h4><span className="badge bg-info">Id: {capture.id}</span></h4>
                <table>
                    <tbody>
                        <tr>
                            <td className='pe-2 py-2'>Capture Time</td>
                            <td className='ps-3 border-start text-secondary'>{convertToLocaleDate(capture.captureTime) || "-"}</td>
                        </tr>
                        <tr>
                            <td className='pe-3 py-2'>Organization</td>
                            <td className='ps-3 border-start text-secondary'>{capture.organizationName || "-"}</td>
                        </tr>
                        <tr>
                            <td className='pe-3 py-2'>Tenant</td>
                            <td className='ps-3 border-start text-secondary'>{capture.tenantName || "-"}</td>
                        </tr>
                        <tr>
                            <td className='pe-3 py-2'>Machine</td>
                            <td className='ps-3 border-start text-secondary'>{capture.machineName || "-"}</td>
                        </tr>
                        <tr>
                            <td className='pe-3 py-2'>Remark</td>
                            <td className='ps-3 border-start text-secondary remark-container hide-scroll-bar'>{capture.remark || "-"}</td>
                        </tr>
                        {
                            isSuperSpecialUser() &&
                            <tr>
                                <td className='pe-3'>Created At</td>
                                <td className='ps-3 border-start text-secondary'>{convertToLocaleDate(capture.createdAt!) || "-"}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </CardContent>
        </Card>
    );
}
 
export default CaptureCard;