import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styling/customStyle.css';
import Layout from "../Layout/Layout";
import { 
    userGetListByOrgIdAndRoleIdApi, 
    userAddDataApi, 
    userDeleteDataApi, 
    userUpdateDataApi, 
    userUpdatePwdApi,
    userGetExportListByOrgIdAndRoleIdApi
} from "../../services/RouteServices/UsersApi";
import { 
    UserData, 
    UserListResponseBody, 
    UserResponseBody,  
    UserUPDATERequestBody, 
    UserDefault, 
    UserUpdatePwdRequestBody,
} from "../../types/UsersType";
import { STATUS_CODES, PAGE_LIMIT, USER_STATUS, ROLES, LOG_LEVEL } from "../../constants/GlobalConstants";
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Pagination from "@mui/material/Pagination"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LockResetIcon from '@mui/icons-material/LockReset';
import Button from "react-bootstrap/Button";
import UsersModal from './UsersModal'
import UpdatePasswordModal from './UpdatePasswordModal'
import { convertToLocaleDate, getCurrentLocaleDate } from '../../utils/DateTimeUtil';
import { getCurrentLocalUser, getUserPermissions, isRootAndSuperUsers, isSuperSpecialUser } from "../../utils/UserUtil";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import { RolesData } from "../../types/RoleType";
import { roleGetAllDataApi } from "../../services/RouteServices/RoleApi";
import UserFilerContainer from "./UserFilterContainer";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title } from "../Shared/Common/Titles";
import { FilterValue } from "../../types/global/FilterValueType";
import DeleteIconButton from "../Shared/DeleteIconButton/DeleteIconButton";
import { useFilterOrganizations } from "../../custom-hooks/useFilterOrganizations";
import { getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import ExportButton from "../Shared/Export/Export";
import XLSX from "xlsx";
import { addEventLog } from "../../utils/EventLogUtil";
import { addNormalLog } from "../../utils/LoggerUtil";
import { generatePDF } from "./generatePdf";
import { Col, Row } from "react-bootstrap";

const loadUserExportList = (users: UserData[]) => {
    if (!users) return []
    return users.map(user => {
        return {
            "Id": user.id,
            "Organization": user.organizationName || "-",
            "Username": user.userName || "-",
            "Role": user.roleName || "-",
            "Email": user.email || "-",
            "PhoneNo": user.phoneNo || "-",
            "Status": user.userStatus || "-"
        }
    })
}
 
const Users: React.FC = () => {
    const tableLimit = PAGE_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();
    const userPermissions: RolesData = getUserPermissions();
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const [tablePage, setTablePage] = React.useState(1);
    const [tableOffset, setTableOffset] = React.useState(0);
    const [userTotalCount, setUserTotalCount] = React.useState(0);
    
    const [usersList, setUsersList] = useState<UserData[]>([]); // * Original data object from db
    const [roleList, setRoleList] = useState<RolesData[]>([]);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showUpdatePwdModal, setShowUpdatePwdModal] = useState<boolean>(false);
    const [userData, setUserData] = useState<UserData>(UserDefault);
    const [modalSuccessMsg, setModalSuccessMsg] = useState<string>("");
    const [modalErrorMsg, setModalErrorMsg] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [filterValue, setFilterValue] = useState<FilterValue>(getDefaultFilterValue());

    const pageCount = (Math.ceil(userTotalCount/tableLimit)) || 0

    const {organizations, error: orgError} = useFilterOrganizations()

    useEffect(() => {
        roleGetAllDataApi()
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                setRoleList(res?.data?.data?.roleList)
            else 
                setErrorText(res?.data?.message)
        }) 
        .catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }, [])

    useEffect(() => {
        setTablePage(1)
        setTableOffset(0)
    }, [filterValue])

    useEffect(() => {
        setErrorText("")
        if (hasPermission("viewUser")) {
            getManageUsersApiHandler();
        }
    }, [filterValue, tableOffset])

    useEffect(() => {
        if (userData.organizationId !== 0 && userData.organizationId
            && userData.userName !== "" && userData.remark !== ""
            && userData.roleId !== 0 && userData.roleId
            && userData.phoneNo !== "" && userData.userStatus !== ""){
                setModalErrorMsg("")
        }
    }, [userData])

    // Input Elements
    const handleChangeUserData = (event: ChangeEvent<HTMLInputElement>) => { 
        setUserData(
            (prevuserData) => {
                return  {
                    ...prevuserData,
                    [event.target.name]: event.target.value,
                }
            }
        )
    }

    const handleChangeNewPwd = (event: ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value)
    }

    const showAlertAndRefresh = (resData: UserResponseBody, action: string) => {
        if (resData?.status === STATUS_CODES.SUCCESS_CODE){
            setModalErrorMsg("")
            setErrorText("")
            setModalSuccessMsg(resData?.message)
            setSuccessText(resData?.message)
            setTimeout(() => {
                setSuccessText("")
            }, 2000)
            setShowAddModal(false);
            setShowEditModal(false);
            setShowUpdatePwdModal(false)
            setUserData(UserDefault);
            getManageUsersApiHandler(); 
        }
        else{
            if (['add', 'update'].includes(action)) {
                setErrorText("")
                setModalErrorMsg(resData?.message)
            } else {
                setModalErrorMsg("")
                setErrorText(resData?.message)
            }
        }
    }

    const handleClose = () => {
        setShowAddModal(false);
        setShowEditModal(false);
        setShowUpdatePwdModal(false);
        setModalErrorMsg("");
        setModalSuccessMsg("");
        setUserData(UserDefault);
    }

    const openUpdatePwdModal = (userObj: UserData) => {
        setModalErrorMsg("");
        setModalSuccessMsg("");
        setShowUpdatePwdModal(true)
        setUserData(userObj);
    }

    const openAddUserModal = () => {
        setModalErrorMsg("");
        setModalSuccessMsg("");
        setShowAddModal(true);
        setUserData(
            (prevUserData) => {
                return  {
                    ...prevUserData,
                    organizationId: userInfo.orgId,
                }
            }
        )
    }
       
    const openEditUserModal = (userObj: UserData) => {
        setModalErrorMsg("");
        setModalSuccessMsg("");
        setShowEditModal(true);
        const orgIdList = organizations.map(org => org.id)
        setUserData({
            ...userObj,
            remark: userObj.remark || "",
            organizationId: (orgIdList.includes(userObj.organizationId))? userObj.organizationId: 0,
        });
    }

    const handleUpdatePwd = async () => {
        const inputObj: UserUpdatePwdRequestBody = {
            id: userData.id!, 
            password: newPassword,
            updatedBy: userInfo.userName
        }
        await userUpdatePwdApi(inputObj)
        .then(res => {
            showAlertAndRefresh(res?.data, "update")
        })
        .catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }

    const handleAddUser = async () => {
        const inputObj = {
            organizationId: userData.organizationId,
            userName: userData.userName,
            password: userData.password,
            email: userData.email,
            phoneNo: userData.phoneNo,
            remark: userData.remark || "",
            roleId: userData.roleId,
            userStatus: userData.userStatus,
            createdBy: userInfo.userName
        }
        await userAddDataApi(inputObj)
        .then(res => {
            showAlertAndRefresh(res?.data, "add")
        })
        .catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }

    const handleEditUser = async () => {
        const inputObj: UserUPDATERequestBody = {
            id: userData.id || 0, 
            organizationId: userData.organizationId,
            userName: userData.userName,
            password: userData.password,
            email: userData.email,
            phoneNo: userData.phoneNo,
            remark: userData.remark,
            roleId: userData.roleId,
            userStatus: userData.userStatus, 
            updatedBy: userInfo.userName
        }
        await userUpdateDataApi(inputObj)
        .then(res => {
            showAlertAndRefresh(res?.data, "update")
        })
        .catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        }) 
    }

    const handleDeleteUser = async (id: number) => {
        const inputData = {
            "id": id,
            "deletedBy": userInfo.userName
        }
        userDeleteDataApi(inputData)
        .then(res => {
            showAlertAndRefresh(res?.data, "delete")
        })
        .catch(error => {
            setErrorText(error.message)
        })
    }

    const exportToCSV = async () => { 
        let exportedData = await getExportUserListDataByOrgIdAndRoleId()
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(loadUserExportList(exportedData));
        XLSX.utils.book_append_sheet(workbook, worksheet, "Overall");
        XLSX.writeFile(workbook, `users-${getCurrentLocaleDate()}.xlsx`);
        const log: string = `${userInfo.userName} has downloaded users-${getCurrentLocaleDate()}.xlsx`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    };

    const exportToPdf = async () => {
        let exportedData = await getExportUserListDataByOrgIdAndRoleId()
        generatePDF(exportedData)
        const log: string = `${userInfo.userName} has downloaded users-${getCurrentLocaleDate()}.pdf`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    }

    const getExportUserListDataByOrgIdAndRoleId = async (): Promise<any> => {
        const dataLimitsObject = {
            roleId: filterValue.roleId,
            loginUserRoleId: userInfo.roleId,
            isAdmin: userPermissions?.isAdmin,
            orgId: filterValue.organizationId,
            searchText: filterValue.search || "",
        }

        return userGetExportListByOrgIdAndRoleIdApi(dataLimitsObject)
        .then(res => {
            const resData: UserListResponseBody = res?.data
            return new Promise((resolve, reject) => {
                if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                    resolve(resData?.data?.userList)
                else 
                    reject([])
            })   
        }).catch(error => {
            Promise.reject([])
        })
    }

    const getManageUsersApiHandler = () => {
        const dataLimitsObject = {
            roleId: filterValue.roleId,
            loginUserRoleId: userInfo.roleId,
            isAdmin: userPermissions?.isAdmin,
            orgId: filterValue.organizationId,
            searchText: filterValue.search || "",
            limit: tableLimit,
            offset: tableOffset
        }
        userGetListByOrgIdAndRoleIdApi(dataLimitsObject)
        .then(response => {
            const resData: UserListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: UserData[] = resData.data?.userList
                const totalCount : number = resData?.data?.totalCount;
                setUserTotalCount(totalCount)
                setUsersList(dataList);
                // for not showing empty page when delete last item
                if (totalCount != 0 && dataList.length == 0) {
                    setTableOffset(prev => prev != 0 ? prev - 10 : prev)
                    setTablePage(prev => prev != 1 ? prev -1 : prev)
                }
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }   

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };

    // Filter Change
    const handleChangeFilterValue = (event: ChangeEvent<HTMLSelectElement>) => {
        setFilterValue(
            (prevValue) => {
                return {
                    ...prevValue,
                    [event.target.name]: event.target.value,
                }
            }
        )
    }

    const checkUserStatusColor = (userStatus : string) : string => {
        return userStatus === USER_STATUS.ACTIVE ? "text-success" : "text-danger";
    }

    // * Render Table Data
    const renderTable = () => {
        return (
        usersList?.length !== 0 ? usersList?.map((user: UserData) => {          
            return (
                <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.organizationName || "-"}</td>
                    <td>
                        {user.userName || "-"}
                        {
                            (user?.roleData?.isAdmin || user?.roleId === ROLES.ADMIN_ID) &&
                            <VerifiedUserIcon 
                                className="text-success fs-6 ms-1"
                            />
                        }
                        {
                            (user?.roleData?.isOperator && !user?.roleData?.isAdmin) &&
                            <PersonPinIcon 
                                className="text-warning fs-5 ms-1"
                            />
                        }
                    </td>
                    <td>{user.email || "-"}</td>
                    <td>{user.phoneNo || "-"}</td>
                    <td>{user.roleName || "-"}</td>
                    <td className={checkUserStatusColor(user.userStatus)}>{user.userStatus || "-"}</td>
                    { isRootAndSuperUsers() &&
                        <>
                        <td>{convertToLocaleDate(user.lastLogin!) || "-"}</td>
                        <td>{user.createdBy || "-"}</td>
                        <td>{convertToLocaleDate(user.createdAt!)|| "-"}</td>
                        <td>{user.updatedBy || "-"}</td>
                        <td>{convertToLocaleDate(user.updatedAt!)|| "-"}</td>
                        </>
                    }
                    <td>{user.remark || "-"}</td>
                    {
                        hasPermission("editUser") ?
                        <td>
                            <LockResetIcon 
                                sx={{
                                    color: "orange",
                                }}
                                className="pointer"
                                onClick={() => openUpdatePwdModal(user)}
                            />
                            <EditOutlinedIcon 
                                sx={{
                                    color: "blue",
                                }}
                                className="pointer"
                                onClick={() => openEditUserModal(user)}
                            />
                            <DeleteIconButton 
                                deleteName={user.userName}
                                deleteObject="User"
                                onOpen={() => console.log("click delete")}
                                onDelete={() => handleDeleteUser(user.id!)}
                            />
                        </td> :
                        <td>"-"</td>
                    }
                </tr>
            )
        }):
            <tr>
                <td>No data found</td>
            </tr>
        )
    }

    if (!hasPermission("viewUser")) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (
        <>
            <Layout>
                <Container>
                    <Box className="d-flex justify-content-between mb-2">
                        <Title>Users</Title>
                        <Box className="titleBox">
                            <Box style={{gap: 5, display: "flex", alignItems: "center"}}>
                                <ExportButton 
                                    exportToCSV={exportToCSV}
                                    exportToPdf={exportToPdf}
                                />
                                {
                                    hasPermission("addUser") &&
                                    <Button className="pt-2 pb-2 ps-3 pe-3" 
                                    id="btnAddUser" variant="success" 
                                    onClick={openAddUserModal}>
                                        Add
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Box>
                    {successText && <Alert severity="success" sx={{marginBottom: 2}}>{successText}<br/></Alert>}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}
                    <UsersModal 
                        show={showAddModal}
                        title="Add user"
                        onChangeUserData={handleChangeUserData}
                        btnName="Add"
                        onUserForm={handleAddUser}
                        onClose={handleClose}
                        userData={userData}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        btnColor="success"
                        orgList={organizations}
                        roleList={
                            (userInfo.roleId === ROLES.ADMIN_ID || userPermissions?.isAdmin) ?
                            roleList.filter(role => role.id === userInfo.roleId || role.id === ROLES.TENANT_ID) :
                            roleList
                        }
                    />

                    <UsersModal 
                        show={showEditModal}
                        title="Edit user"
                        onChangeUserData={handleChangeUserData}
                        btnName="Edit"
                        onUserForm={handleEditUser}
                        onClose={handleClose}
                        userData={userData}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        btnColor="primary"
                        orgList={organizations}
                        roleList={
                            (userInfo.roleId === ROLES.ADMIN_ID || userPermissions?.isAdmin) ?
                            roleList.filter(role => role.id === userInfo.roleId || role.id === ROLES.TENANT_ID) :
                            roleList
                        }
                    />

                    <UpdatePasswordModal 
                        show={showUpdatePwdModal}
                        title="Overwrite Password"
                        onChangeUserData={handleChangeNewPwd}
                        btnName="Overwrite"
                        onUserForm={handleUpdatePwd}
                        onClose={handleClose}
                        userData={userData}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        btnColor="warning"
                    />

                    <UserFilerContainer
                        onChangeFilter={handleChangeFilterValue}
                        filterValue={filterValue}
                        orgList={organizations}
                        // roleList={roleList.filter(role => role.id! >= userInfo.roleId)}
                        roleList={
                            (userInfo.roleId === ROLES.ADMIN_ID || userPermissions?.isAdmin) ?
                            roleList.filter(role => role.id === userInfo.roleId || role.id === ROLES.TENANT_ID) :
                            roleList.filter(role => role.id! >= userInfo.roleId)
                        }
                        userInfo={userInfo}
                    />
                    {
                        (userInfo?.roleData?.isOperator && !userInfo?.roleData?.isAdmin) ?
                        <Box className="d-flex justify-content-between">
                            <Box className="d-flex gap-5">
                                <Box className="d-flex gap-1 align-items-center">
                                    <VerifiedUserIcon 
                                        className="text-success fs-5 ms-1"
                                    />
                                    <span>Admin</span>
                                </Box>
                                <Box className="d-flex gap-1 align-items-center">
                                    <PersonPinIcon 
                                        className="text-warning fs-4 ms-1"
                                    />
                                    <span>Operator</span>
                                </Box>
                            </Box>      
                            <SummaryText>{userTotalCount} users found!</SummaryText>
                        </Box> :
                        <SummaryText>{userTotalCount} users found!</SummaryText>
                    }
                    <TableContainer>
                        <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    <th>Organization</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Contact</th>
                                    <th>Role</th>
                                    <th>Status</th>
                                    { isRootAndSuperUsers() &&
                                        <>
                                        <th>Last Login</th>
                                        <th>Created By</th>
                                        <th>Created At</th>
                                        <th>Updated By</th>
                                        <th>Updated At</th>
                                        </>
                                    }
                                    <th>Remark</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {renderTable()}
                            </ tbody>
                        </table>
                        </InnterTableContainer>
                    </TableContainer>
                    <PaginationContainer>
                        <Pagination count={pageCount} onChange={handleChangePage} page={tablePage} variant="outlined" shape="rounded" size="large" />
                    </PaginationContainer>
                </Container>
            </Layout>
        </>
    );
};

export default Users;