import React, { ChangeEvent, useContext, useEffect, useState } from "react";
// import { NodeProps } from "../../types/CommonType";
import Alert from '@mui/material/Alert';
import { Badge, Col, Form, InputGroup, Row } from "react-bootstrap";
import { MACHINE_TYPES, PAGE_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { useFilterOrganizations } from "../../custom-hooks/useFilterOrganizations";
import { getLocationsByOrgIdApi, getMachineListOfATenantApi, getOverallDataApi, getOverallDataApiByOrgIdApi, getOverallDataApiOfATenantApi } from "../../services/RouteServices/DashboardApi";
import '../../styling/customStyle.css';
import {
  MachineListData,
  ResponseGetMachineListApiDataType,
  ResponseGetMachineListApiType, ResponseOverallApiDataType, ResponseOverallApiType,
  WeightData,
  OverAllData
} from "../../types/DashboardType";
import { OrganizationsData } from "../../types/OrganizationsType";
import { UserAuthType } from "../../types/UserAuth";
import { getCurrentLocaleEndDate, getCurrentLocaleStartDate } from "../../utils/DateTimeUtil";
import { getAvailableLocations, getCurrentLocalUser, isAdminUser, isSpecialUser, isSuperSpecialUser, isTenantUser } from "../../utils/UserUtil";
import Layout from "../Layout/Layout";
import Card from "../Shared/Card/Card";
import { Container, FilterContainer } from "../Shared/Common/Containers";
import { Title } from "../Shared/Common/Titles";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import BasicTable from "./BasicTable/BasicTable";
import CollapseTable from "./CollapseTable/CollapseTable";
import {
  CardContainer
} from "./DashboardElements";
import { Box } from "@mui/material";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { IoFastFood } from "react-icons/io5";


export type TotalLocationsData = {
  totalLocationCount: number,
  totalWeight: number,
  totalCarbonReduction: number,
  totalMachineCount: number
}

const Dashboard: React.FC = () => {
  const user = getCurrentLocalUser()
  const avaiLocations = getAvailableLocations()
  const { hasPermission } = useContext(UserAuthContext) as UserAuthType

  const [weightData, setWeightData] = useState<WeightData>({totalWeight: 0, foodWeight: 0, generalWeight: 0, carbonReduction: 0})
  const [totalDoorCount, setTotalDoorCount] = useState(0);
  const [totalMachine, setTotalMachine] = useState(0);
  const [machineList, setMachineList] = useState<MachineListData[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [totalLocationsData, setTotalLocationsData] = useState<TotalLocationsData>({} as TotalLocationsData)
  const [errorOverallText, setErrorOverallText] = useState("");
  const [successOverallText, setSuccessOverallText] = useState("");
  const [searchText, setSearchText] = useState("")
  const [machineType, setMachineType] = useState("all")
  const [selectedOrganization, setSelectedOrganization] = useState(
    isAdminUser() ? user.orgId : 0
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tablePage, setTablePage] = useState(1);
  const [tableOffset, setTableOffset] = useState(0);
  const [machineCount, setMachineCount] = useState(0)

  useEffect(() => {
    getOverallAndMachineDetailsForAllUser();
  }, [searchText, machineType, selectedOrganization, tableOffset]);

  const {organizations, error} = useFilterOrganizations()

  const handleChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const handleChangeMachineType = (event: ChangeEvent<HTMLSelectElement>) => {
    setMachineType(event.target.value)
  }

  const handleChangeOrganization = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOrganization(+event.target.value)
  }

  const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
    setTablePage(pageValue);
    if (pageValue === 1) {
        setTableOffset(0);
    }
    else{
        const offsetValue = (PAGE_LIMIT * (pageValue - 1));
        setTableOffset(offsetValue);
    }
  };

  const getOverallAndMachineDetailsForAllUser = () => {
    if (!isTenantUser()) {
      getLocationsByOrgId()
      if (isAdminUser()) {
        getOverallDataByOrgId();
      } else {
        getOverallData()
      }
    } else {
      getMachineListOfATenant();
      getOverallDataOfATenant();
    }
  }

  const getOverallData = async () => {
    const dataLimitsObject = {
      startDate: getCurrentLocaleStartDate(),
      endDate: getCurrentLocaleEndDate()
    }
    await getOverallDataApi(dataLimitsObject)
    .then((res: ResponseOverallApiType) => {
      const resApiData : ResponseOverallApiDataType = res?.data;
      if (resApiData?.status === STATUS_CODES.SUCCESS_CODE) {
        const responseData : OverAllData = resApiData?.data;
        setWeightData(responseData?.weights)
        setTotalDoorCount(responseData?.totalDoorCount || 0);
        setTotalMachine(responseData?.totalMachineCount || 0);
        setErrorOverallText("");
        setSuccessOverallText(resApiData?.message);
      } else {
        setErrorOverallText(resApiData?.message);
      }
    })
    .catch((error) => {
      setSuccessOverallText("");
      setErrorOverallText(error?.response?.data?.message || error?.message);
    })
  }

  const getOverallDataByOrgId = async () => {
    const dataLimitsObject = {
      orgId: selectedOrganization,
      availableLocationIds: avaiLocations,
      checkLocation: (!isSuperSpecialUser() && !isSpecialUser()),
      startDate: getCurrentLocaleStartDate(),
      endDate: getCurrentLocaleEndDate()
    }

    await getOverallDataApiByOrgIdApi(dataLimitsObject)
    .then((res: ResponseOverallApiType) => {
      const resApiData : ResponseOverallApiDataType = res?.data;
      if (resApiData?.status === STATUS_CODES.SUCCESS_CODE) {
        const responseData : OverAllData = resApiData?.data;
        setWeightData(responseData?.weights)
        setTotalDoorCount(responseData?.totalDoorCount || 0);
        setTotalMachine(responseData?.totalMachineCount || 0);
        setErrorOverallText("");
        setSuccessOverallText(resApiData?.message);
      } else {
        setErrorOverallText(resApiData?.message);
      }
    })
    .catch((error) => {
      setSuccessOverallText("");
      setErrorOverallText(error?.response?.data?.message || error?.message);
      console.log (error);
    })
  }

  const getOverallDataOfATenant = () => {
    const dataLimitsObject = {
      userId: user.id || 0,
      startDate: getCurrentLocaleStartDate(),
      endDate: getCurrentLocaleEndDate()
    }

    getOverallDataApiOfATenantApi(dataLimitsObject)
    .then((res: ResponseOverallApiType) => {
      const resApiData : ResponseOverallApiDataType = res?.data;
      if (resApiData?.status === STATUS_CODES.SUCCESS_CODE) {
        const responseData : OverAllData = resApiData?.data;
        setWeightData(responseData?.weights)
        setTotalDoorCount(responseData?.totalDoorCount || 0);
        setTotalMachine(responseData?.totalMachineCount || 0);
        setErrorOverallText("");
        setSuccessOverallText(resApiData?.message);
      } else {
        setErrorOverallText(resApiData?.message);
      }
    })
    .catch((error) => {
      setSuccessOverallText("");
      setErrorOverallText(error?.response?.data?.message || error?.message);
      console.log (error);
    })
  }

  const getLocationsByOrgId = () => {
    setIsLoading(true)
    const dataLimitsObject = {
      machineType: machineType,
      orgId: selectedOrganization,
      availableLocationIds: avaiLocations,
      checkLocation: (!isSuperSpecialUser() && !isSpecialUser()),
      searchText: searchText,
      startDate: getCurrentLocaleStartDate(),
      endDate: getCurrentLocaleEndDate(),
      limit: PAGE_LIMIT,
      offset: tableOffset
    }
    getLocationsByOrgIdApi(dataLimitsObject)
      .then(res => {
        if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
          const response = res?.data?.data
          setLocations(response.locations)
          setTotalLocationsData({
            totalLocationCount: response?.totalLocationCount || 0,
            totalWeight: response?.totalWeight?.toFixed(2) || 0.00, 
            totalCarbonReduction: response?.totalCarbonReduction?.toFixed(2) || 0.00,
            totalMachineCount: response?.totalMachineCount || 0,
          })
        }
        setIsLoading(false)
      }).catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  const getMachineListOfATenant = () => {
    setIsLoading(false)
    const dataLimitsObject = {
      userId: user.id || 0,
      startDate: getCurrentLocaleStartDate(),
      endDate: getCurrentLocaleEndDate(),
      searchText: searchText,
      machineType: machineType,
      limit: PAGE_LIMIT,
      offset: tableOffset
    }

    getMachineListOfATenantApi(dataLimitsObject)
      .then((res: ResponseGetMachineListApiType) => {
        const resApiData : ResponseGetMachineListApiDataType = res?.data;
        const responseData  : MachineListData [] = resApiData?.data?.machineList;
        setMachineList(responseData);
        setMachineCount(resApiData?.data?.totalCount)
        setIsLoading(false)
      })
      .catch((error : any) => {
        console.log (error);
        setIsLoading(false)
      });
  }

  if (!hasPermission("viewDashboard")) {
    return (
      <Layout>
          <Container>
            <UnauthorizedPage />
          </Container>
      </Layout>
    )
  }

  return (
    <>
      <Layout>
        <Container>
          <Title>Overall Data</Title>
          {/* {successOverallText && <Alert severity="success">{successOverallText}<br/></Alert>} */}
          {errorOverallText && <Alert severity="error" sx={{marginBottom: 2}}>{errorOverallText}</Alert>}
          <CardContainer>
              <Card backgroundColor="#4CCD99">
                <h3>{weightData?.totalWeight?.toFixed(2) || 0} KG</h3>
                <p>Total Weight Today</p>
                <Box sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 3
                }}>
                  <Badge bg="light" text="dark" className="fs-6">
                    <div style={{alignItems: "center", display: "flex", gap: 4}}>
                      <IoFastFood className="text-primary fs-4"/>
                      {weightData?.foodWeight?.toFixed(2) || 0} KG
                    </div>
                  </Badge>
                  <Badge bg="light" text="dark" className="fs-6">
                    <div style={{alignItems: "center", display: "flex", gap: 4}}>
                      <RestoreFromTrashIcon className="text-success fs-3"/>
                      {weightData?.generalWeight?.toFixed(2) || 0} KG
                    </div>
                  </Badge>
                </Box>
              </Card>
              {/* <Card>
                <h3>{totalDoorCount.toFixed(0) || 0} Times</h3>
                <p>Open Door Today</p>
              </Card> */}
              <Card>
                <h3>{(weightData?.carbonReduction / 1000).toFixed(2) || 0} tonne</h3>
                <p>Total Carbon Reduction Today</p>
                <p className="fw-light" style={{color: "#B6BBC4"}}>Calculate for food waste only</p>
              </Card>
              <Card>
                <h3>{totalMachine.toFixed(0) || 0} Machines</h3>
                <p>Total Machines</p>
              </Card>
          </CardContainer>
          {/* <TitleH3>LFC Digester</TitleH3> */}
          <FilterContainer>
            <Form.Group>
              <Row>
                <Col xs={12} md={6} lg={4}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text className="input-user"><span>Organization</span></InputGroup.Text>
                      {
                        isSuperSpecialUser() ?
                        <Form.Select onChange={handleChangeOrganization} 
                          name="organizationId" aria-label="organizatioinId" 
                          value={selectedOrganization}
                        >
                              <option value={0}>All</option>
                              {
                                (organizations && organizations?.length !== 0) && organizations.map((org:OrganizationsData) => 
                                    <option value={org.id} key={org.id}>{org.organizationName}</option>
                                )
                              }
                        </Form.Select> :
                        <Form.Select
                          value={user.orgId}
                          disabled
                        >
                            <option value={user.orgId}>
                                {organizations.filter((org:OrganizationsData) => org.id === +user.orgId)[0]?.organizationName}
                            </option>
                        </Form.Select>
                      }
                  </InputGroup>
                </Col>
                <Col xs={12} md={6} lg={4}>
                <InputGroup className="mb-3">
                  <InputGroup.Text className="input-user"><span>Machine Type</span></InputGroup.Text>
                  {
                      <Form.Select 
                        onChange={handleChangeMachineType} 
                        name="machineType" aria-label="machineId" 
                        value={machineType}
                      >
                          <option value="all">All</option>
                          <option value={MACHINE_TYPES.FOOD_WASTE}>Foodwaste Machine</option>
                          <option value={MACHINE_TYPES.GENERAL_WASTE}>General Waste Machine</option>
                      </Form.Select>
                  }
                </InputGroup>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text id="inputGroup-sizing-lg">Search</InputGroup.Text>
                    <Form.Control
                        aria-label="Large"
                        aria-describedby="inputGroup-sizing-sm"
                        value={searchText}
                        onChange={handleChangeSearchText}
                        name="search"
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Form.Group>
          </FilterContainer>   
          
          {
            isTenantUser() ?
            <BasicTable 
              rows={machineList}
              onPageChange={handleChangePage}
              page={tablePage}
              count={machineCount}
            /> :
            <CollapseTable 
              rows={locations}
              totalLocationsData={totalLocationsData}
              machineType={machineType}
              onPageChange={handleChangePage}
              page={tablePage}
            />
          }
        </Container>
      </Layout>
    </>
  );
};

export default Dashboard;
