import styled from "styled-components";
import { NodeProps } from "../../../types/CommonType";

export const Card = styled.div<NodeProps>`
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : "#3D90B0"};
  color: #000000;
  text-align: center;
  border-radius: 11px;
  padding: 25px;
  margin: 0;
  box-shadow: 1px 1px 5px grey;
  border-radius: 7px;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  white-space: nowrap;
  max-height: 400px;
  overflow-y: auto;

  padding: 5px;
  padding-top: 0;
  border-radius: 7px;
  border: 0.5px solid;
  background-color: #f4f4f3;

  height: auto;
  margin-bottom: 30px;
  margin-top: 10px;

.styled-table {
    border-radius: 22px;
    width: 100%;
    font-size: 0.9em;
    font-family: sans-serif;
    margin-bottom: 20px;
}
.table-header{
    width: 100%;
    height: 5vh;
    border-radius: 11px;
    position: sticky;
    top: 0;
    border-bottom: 2px solid #000000;
    background-color: #f4f4f3;
}
.table-header tr th{
    color: #000000;
    padding: 12px 15px;
}
.table-data{
    padding: 10px;
}
.table-data tr td{
    padding: 12px 15px;
    background-color: #f4f4f3;
}
`;