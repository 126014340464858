import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Tenants.css'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { OrganizationsData } from "../../types/OrganizationsType";
import InputGroup from 'react-bootstrap/InputGroup';
import Button from "react-bootstrap/Button";
import Alert from "@mui/material/Alert";
import { isSuperSpecialUser } from "../../utils/UserUtil";
import { ModalContainer } from "../Shared/Common/Containers";

const TenantModal = ({
    show,
    title,
    onChangeTenantData,
    btnName,
    onTenantForm,
    onClose,
    tenantData,
    successText,
    errorText,
    btnColor,
    orgList,
    userInfo,
}: any): JSX.Element => {

    return ( 
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            {successText && <Alert severity="success">{successText}</Alert>}
            {errorText && <Alert severity="error">{errorText}</Alert>}
            <Modal.Body>
                <Form className="ModalForm">
                    <ModalContainer>
                        <Form.Group className="mb-3">
                            <InputGroup className="mb-3">
                                <InputGroup.Text className="input-tenant"><span>Name <span className="text-danger">*</span></span></InputGroup.Text>
                                <Form.Control name="tenantName" onChange={onChangeTenantData} type="name" placeholder="Enter Tenant Name" value={tenantData.tenantName} required/>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text className="input-tenant"><span>Remark</span></InputGroup.Text>
                                <Form.Control name="remark" onChange={onChangeTenantData} type="remark" placeholder="Enter Remark" value={tenantData.remark} required/>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                {
                                    (isSuperSpecialUser() && btnName === "Add") ?
                                    <Form.Select onChange={onChangeTenantData} 
                                    name="organizationId" aria-label="organizatioinId" 
                                    value={tenantData.organizationId}
                                    >
                                        <option value={0}>Choose Organization</option>
                                        {
                                        (orgList && orgList?.length !== 0 && Array.isArray(orgList)) && orgList.map((org:OrganizationsData) => 
                                            <option value={org.id} key={org.id}>{org.organizationName}</option>
                                        )
                                        }
                                    </Form.Select>:
                                    <Form.Select onChange={onChangeTenantData} 
                                        name="organizationId" aria-label="organizatioinId" 
                                        value={tenantData.organizationId}
                                        disabled
                                    >
                                        <option value={tenantData.organizationId}>
                                            {
                                                (orgList && orgList?.length !== 0 && Array.isArray(orgList)) &&
                                                orgList.filter((org:OrganizationsData) => org.id === +tenantData.organizationId)[0]?.organizationName
                                            }
                                        </option>
                                    </Form.Select> 
                                }
                            </InputGroup>
                        </Form.Group>
                    </ModalContainer>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Cancel</Button>
                <Button variant={btnColor} onClick={onTenantForm}>{btnName}</Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default TenantModal;