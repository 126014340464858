import Box from "@mui/material/Box"
import {
    TableContainer,
} from "./TenantDetailsElements";
import { convertToLocaleDate } from '../../../utils/DateTimeUtil';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { isRootAndSuperUsers } from "../../../utils/UserUtil";

const TenantDetailsTable = ({
    dataList,
    columnList,
    openDeleteModal,
    openUpdateModal
}: any) => {    
    const renderTable = () => {
        return(
            dataList?.length !== 0 ? dataList.map((data: any) => 
                <tr key={data.id}>
                    <td>{data.id || "-"}</td>
                    {columnList.includes("Tenant Id") && <td>{data.tenantId || "-"}</td>}
                    {columnList.includes("Tenant") && <td>{data.tenantName || "-"}</td>}
                    {columnList.includes("Machine Id") && <td>{data.machineId || "-"}</td>}
                    {columnList.includes("Machine") && <td>{data.machineName || "-"}</td>}
                    {columnList.includes("Card") && <td>card {data.cardId || "-"}</td>}
                    {columnList.includes("User Id") && <td>{data.userId || "-"}</td>}
                    {columnList.includes("User") && <td>{data.userName || "-"}</td>}
                    {columnList.includes("Remark") && <td>{data.remark || "-"}</td>}
                    {/* <td>{data.updatedBy || "-"}</td>
                    <td>{convertToLocaleDate(data.updatedAt!) || "-"}</td> */}
                    <td>
                            <EditOutlinedIcon 
                                sx={{
                                    color: "blue",
                                }}
                                className="pointer"
                                onClick={() => openUpdateModal(data)}
                            />
                            &nbsp;&nbsp;
                            {
                                isRootAndSuperUsers() &&
                                <DeleteOutlinedIcon 
                                    sx={{
                                        color: "red",
                                    }}
                                    className="pointer"
                                    onClick={() => openDeleteModal(data)}
                                />
                            }
                    </td>
                </tr>    
            ):
            <tr>
                <td>No data found</td>
            </tr>
        )
    }

    return ( 
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <TableContainer>
                <table className="styled-table">
                    <thead className="table-header">
                    <tr>
                        {
                            columnList.map((column:String, index: number) => 
                                    <th key={index}>{column}</th>
                            )
                        }
                    </tr> 
                    </thead>
                    <tbody className="table-data">
                        {
                            renderTable()
                        }
                    </ tbody>
                </table>
            </TableContainer>
        </Box>
     );
}
 
export default TenantDetailsTable;