import { eventLogAddApi } from "../services/RouteServices/EventLogApi"
import { UserInfo } from "../types/UserAuth";
import { getCurrentLocalUser } from "./UserUtil";

export const addEventLog = (
    eventLog: string,
    eventType: string,
    eventLevel: string,
    remark?: string,
) => {
    const userInfo: UserInfo = getCurrentLocalUser();
    const input = {
        orgId: userInfo.orgId,
        userId: userInfo.id!,
        eventLog,
        eventType,
        eventLevel,
        remark,
        createdBy: userInfo.userName
    }
    eventLogAddApi(input)
    .then(res => {
        console.log(res?.data)
    })
    .catch(error => {
        console.log(error?.message)
    })
}