export const FONT_SIZE_120 = '120%';
export const FONT_SIZE_40 = '40%';

export const lightGray =  "#5C5C5C";
export const darkGray =  '#505050';

export const color_hover = '#dbe6e5';

export const color_black = '#000000';
export const color_white = '#FFFFFF';

export const color_theme_primary = '#3D90B0';
export const color_lightgreen = '#6dc573';
export const color_warn = '#EBB02D';