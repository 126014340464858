import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../Tenants.css'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from "react-bootstrap/Button";
import Alert from "@mui/material/Alert";
import { OrganizationsData } from "../../../../types/OrganizationsType";
import { UserData } from "../../../../types/UsersType";
import { checkTenantRole } from "../../../../utils/UserUtil";
import { ModalContainer } from "../../../Shared/Common/Containers";

const UserInTenantModal = ({
    show,
    title,
    onChangeData,
    onForm,
    onClose,
    data,
    successText,
    errorText,
    org,
    userList,
    isProcessing
}: any): JSX.Element => {
    return ( 
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            {successText && <Alert severity="success">{successText}</Alert>}
            {errorText && <Alert severity="error">{errorText}</Alert>}
            <Modal.Body>
                <Form className="ModalForm">
                    <ModalContainer>
                        <Form.Group className="mb-3">
                        <InputGroup className="mb-3">
                            <Form.Select  aria-label="Default select example" name="organizationId"
                                onChange={onChangeData}  
                                value={org.id}
                                disabled
                            >
                                <option value={org.id}>{org.organizationName}</option>
                            </Form.Select>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <Form.Select  aria-label="Default select example" name="userId"
                                disabled={!Boolean(data.organizationId && data.organizationId !== '0')}
                                onChange={onChangeData}  
                                value={data.userId}
                            >
                                <option value={0}>Choose User</option>
                                {
                                    (userList && userList?.length !== 0 && Array.isArray(userList)) && userList.map((user:UserData) => 
                                            (checkTenantRole(user?.roleId)) && <option value={user.id} key={user.id}>{user.userName}</option>
                                    )
                                }
                            </Form.Select>
                        </InputGroup>
                        <InputGroup className="mb-3">
                                <InputGroup.Text className="input-tenant"><span>Remark</span></InputGroup.Text>
                                <Form.Control name="remark" onChange={onChangeData} type="remark" placeholder="Enter Remark" value={data.remark} required/>
                        </InputGroup>
                        </Form.Group>
                    </ModalContainer>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Cancel</Button>
                <button 
                    className="btn btn-outline-success" 
                    type="button" onClick={onForm}
                    disabled={isProcessing}
                > 
                    Assign
                </button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default UserInTenantModal;