import { Box, FormControlLabel, Switch } from "@mui/material";
import TelegramIcon from '@mui/icons-material/Telegram';
import WarningIcon from '@mui/icons-material/Warning';

const SyncInternalTelegramConfig = ({
    internalTelegramSwitch,
    handleInternalTelegramSwitch
}: any) => {
    return (  
        <Box
            className="rounded p-3 bg-light-blue bg-gradient sync-lfc-data-box " 
        >
            <FormControlLabel
                value="sendTelegramMessage"
                control={
                    <Switch color="primary" checked={internalTelegramSwitch}
                        onChange={handleInternalTelegramSwitch}
                    />
                }
                label="Send Telegram Message"
                labelPlacement="start"
                sx={{
                    "& .MuiFormControlLabel-label" : {
                        fontWeight: "bold",
                        fontSize: "1.1rem"
                    }
                }}
            />
            {
                internalTelegramSwitch ?
                <div className="p-1 border rounded bg-success text-white">
                    <TelegramIcon
                        sx={{
                          width: 30,
                          height: 30
                        }}
                      ></TelegramIcon>
                </div> :
                <>
                    <span className="p-1 text-light bg-danger blink_me">
                        <WarningIcon
                        sx={{
                            width: 20,
                            height: 20
                        }}
                        >
                        </WarningIcon>
                        OFF
                    </span>
                </>
            }
        </Box>
    );
}
 
export default SyncInternalTelegramConfig;