import { Box } from "@mui/material";
import { Col, Form, Row } from "react-bootstrap";
import { RolesData } from "../../../types/RoleType";
import { ChangeEvent } from "react";

type Props = {
    selectedRole: RolesData
    onChangeRolePermission: (event: ChangeEvent<HTMLInputElement>) => void
}

const SystemAdminPermissions = (props: Props) => {
    const {selectedRole, onChangeRolePermission} = props
    // (checkSuperSpecialRole(selectedRole.id)) &&
    return (  
        <Box>
            <Row>
                <Col>
                    <Form.Check
                        inline
                        label="View Organization"
                        name="viewOrganization"
                        value={1}
                        defaultChecked={selectedRole.viewOrganization}
                        type={"checkbox"}
                        id={`view_organization`}
                        onChange={onChangeRolePermission}
                        className="text-danger"
                    />
                </Col>
                <Col>
                    <Form.Check
                        inline
                        label="View Location"
                        name="viewLocation"
                        value={1}
                        defaultChecked={selectedRole.viewLocation}
                        type={"checkbox"}
                        id={`view_location`}
                        onChange={onChangeRolePermission}
                        className="text-danger"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        inline
                        label="View LFC Data"
                        name="viewLfcData"
                        value={1}
                        defaultChecked={selectedRole.viewLfcData}
                        type={"checkbox"}
                        id={`view_lfc_data`}
                        onChange={onChangeRolePermission}
                        className="text-danger"
                    />
                </Col>
                <Col>
                    <Form.Check
                        inline
                        label="View Data Sync"
                        name="viewDataSync"
                        value={1}
                        defaultChecked={selectedRole.viewDataSync}
                        type={"checkbox"}
                        id={`view_data_sync`}
                        onChange={onChangeRolePermission}
                        className="text-danger"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        inline
                        label="Assign Card"
                        name="assignCard"
                        value={1}
                        defaultChecked={selectedRole.assignCard}
                        type={"checkbox"}
                        id={`assign_card`}
                        onChange={onChangeRolePermission}
                        className="text-danger"
                    />
                </Col>
                <Col>
                    <Form.Check
                        inline
                        label="Add Tenant"
                        name="addTenant"
                        value={1}
                        defaultChecked={selectedRole.addTenant}
                        type={"checkbox"}
                        id={`add_tenant`}
                        onChange={onChangeRolePermission}
                        className="text-danger"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        inline
                        label="View Operator Log"
                        name="viewOperatorLog"
                        value={1}
                        defaultChecked={selectedRole.viewOperatorLog}
                        type={"checkbox"}
                        id={`view_operator_log`}
                        onChange={onChangeRolePermission}
                        className="text-danger"
                    />
                </Col>
            </Row>
        </Box>
    );
}
 
export default SystemAdminPermissions;