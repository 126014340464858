import { RoleDefault, RolesData } from "./RoleType";

export interface UserData {
  id?: number;
  organizationId: number;
  organizationName: string;
  userName: string;
  password: string;
  email: string;
  phoneNo: string;
  userStatus: string;
  roleId: number;
  roleData: RolesData,
  roleName: string;
  lastLogin?: string;
  remark: string;
  createdBy?: string;
  updatedBy?: string;
  deletedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  [key: string]: any;
}

export interface UserFilterType {
  organizationId: number;
}

export type UserListResponseBody = {
  data: {
    userList: UserData[],
    totalCount: number,
  }
  status: number,
  message: string,
}

export type UserResponseBody = {
  data: UserData,
  status: number,
  message: string,
}

export type UserGETRequestBody = {
  limit: number,
  offset: number
}

export type UserADDRequestBody = {
  organizationId: number;
  userName: string;
  password: string;
  email: string;
  phoneNo: string;
  userStatus: string;
  roleId: number;
  remark: string;
  createdBy: string;
}

export type UserDELETERequestBody = {
  id: number,
  deletedBy: string,
}

export type UserUPDATERequestBody = {
  id: number,
  organizationId: number;
  userName: string;
  password: string;
  email: string;
  phoneNo: string;
  userStatus: string;
  roleId: number;
  remark: string;
  updatedBy: string,
}

export type UserUpdatePwdRequestBody = {
  id: number,
  password: string,
  updatedBy: string,
}

export const UserDefault = {
  organizationId: 0,
  organizationName: "",
  userName: "",
  name: "",
  password: "",
  email: "",
  phoneNo: "",
  userStatus: "Active",
  roleId: 0,
  roleData: RoleDefault,
  remark: "",
  roleName: "",
}

export const UserFilterDefault = {
  organizationId: 0,
}

