import { NOImage } from "./NoImageContant";

export const STATUS_CODES = {
    SUCCESS_CODE : 200,
    INTERNAL_SERVER_ERROR_CODE : 500,
    BAD_REQUEST_CODE : 400,
    UNAUTHORIZE_CODE : 401,
}
export const PAGE_LIMIT = 10;
export const PAGE_WITH_IMG_LIMIT = 5;
export const CARD_LIMIT = 9;

export const LOG_LEVEL = {
    SUCCESS: "success",
    INFO : "info",
    WARN : "warn",
    ALERT : "alert",
}
export const USER_STATUS = {
    ACTIVE : "Active",
    DISABLE : "DISABLE"
}
export const LFC_CARD_STATUS_TYPE = {
    SCAN : "scan",
    OPEN : "open",
    CLOSE : "close"
}
export const ROLES = {
    ADMIN_ID: 5,
    TENANT_ID: 6
}

export const NO_IMAGE_URL = `data:image/jpeg;base64,${NOImage}`

export const MACHINE_TYPES = {
    FOOD_WASTE: "food_waste",
    GENERAL_WASTE: "general_waste",
    NO_TYPE: "no_type"
}