import { Box, Typography } from "@mui/material";
import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { STATUS_CODES } from "../../../constants/GlobalConstants";
import { useFilterOrganizations } from "../../../custom-hooks/useFilterOrganizations";
import { userGetAllByOrgIdApi } from "../../../services/RouteServices/UsersApi";
import { OrganizationsData } from "../../../types/OrganizationsType";
import { UserData } from "../../../types/UsersType";
import UsersList from "./components/UsersList";

type UserFiltersType = {
    orgId: number,
}

interface UsersBoxProps {
    selectedUsers: number[]
    handleToggle: (value: number) => void
    handleAssignLocations: (userId: number, locationIds: number[]) => void
}
 
const UsersBox: FunctionComponent<UsersBoxProps> = ({
    selectedUsers,
    handleToggle,
    handleAssignLocations
}) => {

    const [userFilters, setUserFilters] = useState<UserFiltersType>({orgId: 0} as UserFiltersType);
    const [users, setUsers] = useState<UserData[]>([])
    
    const {organizations} = useFilterOrganizations()

    useEffect(() => {
        getUserByOrgId()
    }, [userFilters])


    const handleChangeUserFilters = (event: ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = event.target
        setUserFilters(prev => ({...prev, [name]: value}))
    }

    const getUserByOrgId = () => {
        userGetAllByOrgIdApi(userFilters.orgId)
        .then(res => {
            const response = res?.data
            if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                setUsers(response?.data?.userList)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    return (  
        <Box sx={{
            maxWidth: "500px"
        }}>
            <InputGroup style={{width: "400px"}}>
                <InputGroup.Text className="input-user"><span>Organization</span></InputGroup.Text>
                <Form.Select onChange={handleChangeUserFilters} 
                name="orgId" aria-label="orgId" 
                value={userFilters.orgId}
                >
                    <option value={0}>All</option>
                    {
                    (organizations && organizations?.length !== 0) && organizations.map((org:OrganizationsData) => 
                        <option value={org.id} key={org.id}>{org.organizationName}</option>
                    )
                    }
                </Form.Select>
            </InputGroup>
            <Box sx={{
                marginTop: "20px"
            }}>
                <Typography sx={{fontSize: "20px", fontWeight: 400}}>Users</Typography>
                <UsersList 
                    users={users}
                    selectedUsers={selectedUsers}
                    handleToggle={handleToggle}
                    handleAssignLocations={handleAssignLocations}
                />
            </Box>
        </Box>
    );
}
 
export default UsersBox;