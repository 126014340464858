import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { getCurrentLocaleDate } from "../../utils/DateTimeUtil";
import logoImage from '../../assets/logo/env-logo.png';
import { DischargeWeightLogData } from "../../types/DischargeWeightLogType";
import { isSpecialUser } from "../../utils/UserUtil";

export const generatePDF = (dataList: DischargeWeightLogData[], totalDischargeWeightAmount: number) => {
    const doc = new jsPDF('landscape', 'mm', 'a4');

    let headers = []
    let dataKeys = []
    if (isSpecialUser()) {
        headers = [
            "Id", "Waste Date", "Organization", "Machine",
            "Location", "Weight(kg)", "Remark",
        ];
        dataKeys = [
            "id", "wasteDate", "organizationName", "machineName",
            "locationName", "weight", "remark"
        ];
    } else {
        headers = [
            "Id", "Waste Date", "Machine",
            "Weight(kg)", "Remark",
        ];
        dataKeys = [
            "id", "wasteDate", "machineName",
            "weight", "remark"
        ];
    }


    autoTable(doc, {
        head: [headers],
        body: dataList,
        columns: dataKeys.map((key) => ({ dataKey: key })),
        margin: { top: 25, left: 5},
        didParseCell: function (data) {
            if (data.section === "head") {
              data.cell.styles.fontSize = 9;
              data.cell.styles.textColor = [255, 255, 255];
              data.cell.styles.fillColor = [0, 0, 0];
            }
          },
        didDrawPage: function (data) {
            doc.setFontSize(18);
            doc.text("Discharge Weight Log", data.settings.margin.left, 10);

            const logoWidth = 20;
            const logoHeight = 10;
            const logoX = data.settings.margin.left + 250;
            const logoY = 5;
            doc.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);

            doc.setFontSize(13);
            doc.text(`Total Discharge Weight Amount ${totalDischargeWeightAmount || 0} kg`, data.settings.margin.left, 20);
        }
    })
    doc.save(`discharge-weightlogs-${getCurrentLocaleDate()}.pdf`)
}