import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { IoIosWarning } from "react-icons/io";
import { IoFastFood } from "react-icons/io5";
import { MACHINE_TYPES } from "../constants/GlobalConstants";

export const isFoodWasteMachine = (machineType: string) => {
    return machineType === MACHINE_TYPES.FOOD_WASTE
}

export const isGeneralWasteMachine = (machineType: string) => {
    return machineType === MACHINE_TYPES.GENERAL_WASTE
}

export const getMachineTypeIcon = (machineType: string) => {
    if (machineType === MACHINE_TYPES.FOOD_WASTE) 
        return <IoFastFood className="ms-2 text-primary fs-4"/>
    else if (machineType === MACHINE_TYPES.GENERAL_WASTE)
        return <RestoreFromTrashIcon className="ms-2 text-success fs-3"/>
    else
        return <IoIosWarning className="ms-2 text-danger fs-4"/>
}