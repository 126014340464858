import React, { ChangeEvent, useEffect, useState } from "react";
import Layout from "../Layout/Layout";

import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Pagination from "@mui/material/Pagination"
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title, TitleH5 } from "../Shared/Common/Titles";
import { PAGE_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";
import { HardwareData, HardwareDefaultData, HardwareListResponseBody, HardwareResponseBody } from "../../types/HardwareType";
import { deleteHardware, hardwareGetDataApi, updateUserAssignedName } from "../../services/RouteServices/HardwareApi";
import { convertToLocaleDate } from "../../utils/DateTimeUtil";
import HardwareUpdateModal from "./HardwareUpdateModal";
import DeleteIconButton from "../Shared/DeleteIconButton/DeleteIconButton";
import HardwareFilterContainer from "./HardwareFilterContainer";
import { getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import { UserInfo } from "../../types/UserAuth";
import { getCurrentLocalUser } from "../../utils/UserUtil";
import WifiIcon from '@mui/icons-material/Wifi';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';


const Hardware = () => {
    const tableLimit = 8;
    const userInfo: UserInfo = getCurrentLocalUser();

    const [hardwares, setHardwares] = useState<HardwareData[]>([])
    const [hardwareTotalCount, setHardwareTotalCount] = useState<number>(0)
    const [successText, setSuccessText] = useState<string>("")
    const [errorText, setErrorText] = useState<string>("")
    const [modalErrorText, setModalErrorText] = useState<string>("")
    const [tablePage, setTablePage] = useState(1);
    const [tableOffset, setTableOffset] = useState(0);
    const [selectedHardware, setSelectedHardware] = useState<HardwareData>(HardwareDefaultData)
    const [filterValues, setFilterValues] = useState(getDefaultFilterValue())
    const [intervalToggle, setIntervalToggle] = useState(true)
    
    const pageCount = (Math.ceil(hardwareTotalCount/tableLimit)) || 0

    useEffect(() => {
        setTimeout(() => {
            setIntervalToggle(prev => !prev); 
        }, 5000)
    }, [intervalToggle])

    useEffect(() => {
        getHardwareList()
    }, [filterValues, tablePage, intervalToggle])

    const openEditModal = (hardware: HardwareData) => {
        setSelectedHardware(hardware)
    }

    const hideModals = () => {
        setSelectedHardware(HardwareDefaultData)
    }

    const showAlertAndRefresh = (resData: HardwareResponseBody, action: string) => {
        if (resData?.status === STATUS_CODES.SUCCESS_CODE){
            setModalErrorText("")
            setErrorText("")
            setSuccessText(resData?.message)
            setTimeout(() => {
                setSuccessText("")
            }, 2000)
            getHardwareList()  
        }
        else{
            if (['add', 'update'].includes(action)) {
                setErrorText("")
                setModalErrorText(resData?.message)
            } else {
                setModalErrorText("")
                setErrorText(resData?.message)
            }
        }
    }

    const handleChangeFilterValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterValues({
            ...filterValues,
            [event.target.name]: event.target.value
        })
    }

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };

    const handleUpdateHardware = (updatedHardware: HardwareData) => {
        const originalHardwares = hardwares
        setHardwares(hardwares.map(hardware => {
            if (hardware.id === updatedHardware.id) return updatedHardware
            return hardware
        }))
        const data = {
            id: updatedHardware.id,
            userAssignedName: updatedHardware.userAssignedName || "",
            updatedBy: userInfo.userName
        }
        updateUserAssignedName(data)
        .then(res => {
            if (res.data?.status === STATUS_CODES.SUCCESS_CODE) {
                hideModals()
                setModalErrorText("")
                setSuccessText(res.data?.message)
                setTimeout(() => {
                    setSuccessText("")
                }, 2000)
            } else {
                setModalErrorText(res.data?.message)
                setHardwares(originalHardwares)
            }
        })
        .catch(error => {
            setModalErrorText(error.message)
            setHardwares(originalHardwares)
        })
    }

    const handleDeleteHardware = (id: number) => {
        const input = {
            id: id,
            deletedBy: userInfo.userName
        }
        deleteHardware(input)
        .then(res => {
            const resData = res?.data
            showAlertAndRefresh(resData, "delete")
        }).catch(error => {
            setErrorText(error.message)
        })
    }

    const getHardwareList = () => {
        const dataLimitsObject = {
            searchText: filterValues.search,
            limit: tableLimit,
            offset: tableOffset
        }

        hardwareGetDataApi(dataLimitsObject)
        .then(res => {
            const resData: HardwareListResponseBody = res?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: HardwareData[] = resData.data?.hardwareList
                const totalCount : number = resData?.data?.totalCount;
                setHardwares(dataList)
                setHardwareTotalCount(totalCount)
            }
        }).catch(error => {
            setErrorText(error.message)
        })
    }

    const renderTable = () => {
        let prevUniqueId = ""
        let color = ""
        return (
            (hardwares && hardwares.length > 0) && hardwares.map(hardware => {
                if (prevUniqueId !== hardware.uniqueId) {
                    color = color === "#BDCDD6" ? "#DDE6ED" : "#BDCDD6"
                } 
                prevUniqueId = hardware.uniqueId
                return (<tr key={hardware.id}>
                    <td style={{backgroundColor: color}}>{hardware.id}</td>
                    <td style={{backgroundColor: color}}>{hardware.userAssignedName || "-"}</td>
                    <td style={{backgroundColor: color}}>{hardware.uniqueId}</td>
                    <td style={{backgroundColor: color}}>{hardware.ipAddress}</td>
                    <td style={{backgroundColor: color}}
                        className={hardware.healthStatus ? "text-success": "text-danger"}
                    >
                        {
                        hardware.healthStatus ? 
                            <span><WifiIcon/> online</span>: 
                            <span><WarningAmberIcon/> offline</span>
                        }
                    </td>
                    <td style={{backgroundColor: color}}>{convertToLocaleDate(hardware.lastHeartBeatDate)}</td>
                    <td style={{backgroundColor: color}}>
                        <HardwareUpdateModal 
                            show={selectedHardware === hardware}
                            title="Update"
                            onOpen={() => {openEditModal(hardware); setModalErrorText("")}}
                            onClose={hideModals}
                            onUpdate={handleUpdateHardware}
                            hardware={hardware}
                            error={modalErrorText}
                        />
                        <Box sx={{
                            backgroundColor: "white",
                            display: "inline",
                            padding: 1,
                            borderRadius: 2,
                            marginLeft: 2
                        }}>         
                            <DeleteIconButton 
                                deleteName={`${hardware.uniqueId} - ${hardware.ipAddress}`}
                                deleteObject="Hardware"
                                onOpen={() => setModalErrorText("")}
                                onDelete={() => handleDeleteHardware(hardware.id)}
                            />
                        </Box>
                    </td>
                </tr>)
            })
        )
    }

    return (  
        <>
            <Layout>
                <Container>
                    <Title>Hardwares</Title>
                    {successText && <Alert severity="success" sx={{marginBottom: 2}}>{successText}<br/></Alert>}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}
                    <HardwareFilterContainer 
                        filterValues={filterValues}
                        onChangeFilter={handleChangeFilterValue}
                    />

                    <SummaryText>{hardwareTotalCount} Hardwares found!</SummaryText>
                    <TableContainer>
                    <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    <th>User Assigned Name</th>
                                    <th>Unique Id</th>
                                    <th>Ip Address</th>
                                    <th>Health Status</th>
                                    <th>Last Heart Beat Date</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {renderTable()}
                            </ tbody>
                        </table>
                    </InnterTableContainer>
                    </TableContainer>
                    <PaginationContainer>
                        <Pagination count={pageCount} onChange={handleChangePage} page={tablePage} variant="outlined" shape="rounded" size="large" />
                    </PaginationContainer>
                </Container>
            </Layout>
        </>
    );
}
 
export default Hardware;