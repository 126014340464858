import Box from "@mui/material/Box"
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { convertToLocaleDate } from "../../utils/DateTimeUtil";

const LfcTokenConfig = ({
    lfcTokenConfig,
    onChange,
    onClick
}: any) => {
    return (  
        <Box
            sx={{minWidth: "400px"}}
        >
            <Form>
                <Form.Label as="legend">
                    Lfc Token Config
                </Form.Label>
                <div
                    className="border border-light border-3 rounded p-3 bg-light bg-gradient" 
                >
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">
                            LFC Hostname
                        </Form.Label>
                        <Col sm="8">
                            <Form.Control type="text" placeholder='lfc-api-01' name="lfcHostname" value={lfcTokenConfig.lfcHostname} onChange={onChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">
                            LFC Token
                        </Form.Label>
                        <Col sm="8">
                            <Form.Control type="text" placeholder='token' name="lfcToken" value={lfcTokenConfig.lfcToken} onChange={onChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">
                            Remark
                        </Form.Label>
                        <Col sm="8">
                            <Form.Control type="text" placeholder='remark' name="remark" value={lfcTokenConfig.remark} onChange={onChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="">
                        <Form.Label column sm="3">
                             
                        </Form.Label>
                        <Col sm="9">
                            <span style={{fontSize:13, color:'#35a612'}}>Last Update - {convertToLocaleDate(lfcTokenConfig.updatedAt) || convertToLocaleDate(lfcTokenConfig.createdAt)}</span>
                            <br></br>
                            <span style={{fontSize:13, color:'#35a612'}}>Updated User - {lfcTokenConfig.updatedBy || lfcTokenConfig.createdBy}</span>
                        </Col>
                    </Form.Group>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "right"
                        }}
                    >
                        <Button variant="primary" onClick={onClick}>Update</Button>
                    </Box>
                </div>
            </Form>
        </Box>
    );
}
 
export default LfcTokenConfig;