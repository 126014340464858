export interface RolesData extends RolePermission {
    id?: number;
    roleName: string;
    remark: string;
    isAdmin?: boolean;
    isOperator?: boolean;
    createdBy?: string;
    updatedBy?: string;
    deletedBy?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    [key: string]: boolean | number | string | undefined | null; // Index signature
}

export type RolePermission = {
  viewReport?: boolean;
  viewDetection?: boolean;
  viewManualDetection?: boolean;
  addManualDetection?: boolean;
  editManualDetection?: boolean;
  deleteManualDetection?: boolean;
  viewCapture?: boolean;
  viewTenant?: boolean;
  viewUser?: boolean;
  viewPendingUser?: boolean;
  viewOrganization?: boolean;
  viewLocation?: boolean;
  viewLfcMachine?: boolean;
  viewLfcData?: boolean;
  viewWeightLog?: boolean;
  viewDischargeWeightLog?: boolean;
  viewCartonBox?: boolean;
  viewUploadDetection?: boolean;
  viewDoorLog?: boolean;
  viewMachineLog?: boolean;
  viewOperatorLog?: boolean;
  viewDataSync?: boolean;
  viewDashboard?: boolean;
  viewWasteUpload?: boolean;
  assignCard?: boolean;
  addTenant?: boolean;
  addUser?: boolean;
  editUser?: boolean;
}
  
export type RoleListResponseBody = {
    data: {
      roleList: RolesData[],
      totalCount?: number,
    }
    status: number,
    message: string,
}

export type RoleResponseBody = {
  data: RolesData,
  status: number,
  message: string,
}

export type RoleGETRequestBody = {
  limit: number,
  offset: number
}

export type RoleADDRequestBody = {
  roleName: string,
  remark: string,
  createdBy: string,
}

export type RoleDELETERequestBody = {
  id: number,
  deletedBy: string,
}

export interface RoleUPDATERequestBody extends RolePermission {
  id: number,
  roleName: string,
  remark: string,
  updatedBy: string,
}

export const RolePermissionDefault: RolePermission = {
  viewReport: false,
  viewDetection: false,
  viewManualDetection: false,
  addManualDetection: false,
  editManualDetection: false,
  deleteManualDetection: false,
  viewCapture: false,
  viewTenant: false,
  viewUser: false,
  viewPendingUser: false,
  viewOrganization: false,
  viewLocation: false,
  viewLfcMachine: false,
  viewLfcData: false,
  viewWeightLog: false,
  viewDischargeWeightLog: false,
  viewCartonBox: false,
  viewDoorLog: false,
  viewMachineLog: false,
  viewOperatorLog: false,
  viewDataSync: false,
  viewDashboard: false,
  viewUploadDetection: false,
  viewWasteUpload: false,
  assignCard: false,
  addTenant: false,
  addUser: false,
  editUser: false,
}

export const RoleDefault: RolesData = {
  roleName: "",
  remark: "",
  isAdmin: false,
  isOperator: false,
  ...RolePermissionDefault
}
    