import { BrowserRouter, Route, Routes as Router } from "react-router-dom";
import AiConfig from "../components/AiConfig/AiConfig";
import AlertUsers from "../components/AlertUsers/AlertUsers";
import Capture from "../components/Capture/Capture";
import CartonBox from "../components/CartonBox/CartonBox";
import Config from "../components/Config/Config";
import Dashboard from "../components/Dashboard/Dashboard";
import DataSync from "../components/DataSync/DataSync";
import Detection from "../components/Detection/Detection";
import DischargeWeightLog from "../components/DischargeWeightLog/DischargeWeightLog";
import DoorLog from "../components/DoorLog/DoorLog";
import EventLog from "../components/EventLog/EventLog";
import Hardware from "../components/Hardware/Hardware";
import InstanceRedirect from "../components/InstanceRedirect/InstanceRedirect";
import LFCData from "../components/LFCData/LFCData";
import Locations from "../components/Locations/Locations";
import Login from "../components/Login/Login";
import LoginHistory from "../components/LoginHistory/LoginHistory";
import MachineLog from "../components/MachineLog/MachineLog";
import MachineDetails from "../components/Machines/Details/MachineDetails";
import Machines from "../components/Machines/Machines";
import Maintenance from "../components/Maintenance/Maintenance";
import ManualDetection from "../components/ManualDetection/ManualDetection";
import ManualDetectionReport from "../components/ManualDetection/Report/ManualDetectionReport";
import Organizations from "../components/Organizations/Organizations";
import PageNotFound from "../components/PageNotFound/PageNotFound";
import PendingUsers from "../components/PendingUsers/PendingUsers";
import Report from "../components/Report/Report";
import ReportDetails from "../components/Report/ReportDetails/ReportDetails";
import Roles from "../components/Roles/Roles";
import Setting from "../components/Setting/Setting";
import TenantDetails from "../components/Tenants/Details/TenantDetails";
import Tenants from "../components/Tenants/Tenants";
import Users from "../components/Users/Users";
import UsersConfig from "../components/UsersConfig/UsersConfig";
import WasteUpload from "../components/WasteUpload/WasteUpload";
import WeightLog from "../components/WeightLog/WeightLog";
import { getCurrentLocalUser, isTenantUser } from "../utils/UserUtil";
import Permission from "./Permission";
import PrivateRoute from "./PrivateRoute";
import SpecialRoute from "./SpecialRoute";
import SuperSpecialRoute from "./SuperSpecialRoute";
import OperatorLog from "../components/OperatorLog/OperatorLog";

function Routes() {
  const user = getCurrentLocalUser()

  return (
    <>
      <BrowserRouter>
        <Router>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/login" element={<Login />} />
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<InstanceRedirect />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route element={<Permission permission="viewDetection"/>} >
              <Route path="/detection" element={<Detection />} />
            </Route>
            <Route element={<Permission permission="viewManualDetection"/>} >
              <Route path="/manual-detection" element={<ManualDetection />} />
              <Route path="/manual-detection/report" element={<ManualDetectionReport />} />
            </Route>
            <Route element={<Permission permission="viewCartonBox"/>} >
            <Route path="/carton-box" element={<CartonBox />} />
            </Route>
            <Route element={<Permission permission="viewWasteUpload"/>} >
              <Route path="/waste-upload" element={<WasteUpload />} />
            </Route>
            <Route element={<Permission permission="viewCapture"/>} >
              <Route path="/capture" element={<Capture />} />
            </Route>
            <Route element={<Permission permission="viewUser"/>} >
              <Route path="/users" element={<Users />} />
            </Route>
            <Route element={<Permission permission="viewPendingUser"/>} >
              <Route path="/pending-users" element={<PendingUsers />} />
            </Route>
            <Route element={<Permission permission="viewTenant"/>} >
              <Route path="/tenants" element={<Tenants />} />
              <Route path="/tenants/details" element={<TenantDetails />} />
            </Route>
            <Route element={<Permission permission="viewLfcData"/>} >
              <Route path="/lfcdata" element={<LFCData />} />
            </Route>
            <Route element={<Permission permission="viewDataSync"/>} >
              <Route path="/datasync" element={<DataSync />} />
            </Route>
            <Route element={<Permission permission="viewOrganization"/>} >
              <Route path="/organizations" element={<Organizations />} />
            </Route>
            <Route element={<Permission permission="viewLocation"/>} >
              <Route path="/locations" element={<Locations />} />
            </Route>
            <Route element={<Permission permission="viewLfcMachine"/>} >
              <Route path="/machines" element={<Machines />} />
              <Route path="/machines/details" element={<MachineDetails />} />
            </Route>
            <Route element={<SpecialRoute />} >
            </Route>
            <Route element={<SuperSpecialRoute />} >
              <Route path="/roles" element={<Roles />} />
              <Route path="/login-history" element={<LoginHistory />} />
              <Route path="/config" element={<Config />} />
              <Route path="/ai-config" element={<AiConfig />} />
              <Route path="/hardwares" element={<Hardware />} />
              <Route path="/event-log" element={<EventLog />} />
              <Route path="/maintenance" element={<Maintenance />} />
              <Route path="/alert-users" element={<AlertUsers />} />
              <Route path="/users-config" element={<UsersConfig />} />
            </Route>
            <Route element={<Permission permission="viewWeightLog"/>} >
              <Route path="/weight-log" element={<WeightLog />} />
            </Route>
            <Route element={<Permission permission="viewDischargeWeightLog"/>} >
              <Route path="/discharge-weight-log" element={<DischargeWeightLog />} />
            </Route>
            <Route element={<Permission permission="viewDoorLog"/>} >
              <Route path="/door-log" element={<DoorLog />} />
            </Route>
            <Route element={<Permission permission="viewMachineLog"/>} >
              <Route path="/machine-log" element={<MachineLog />} />
            </Route>
            <Route element={<Permission permission="viewOperatorLog"/>} >
              <Route path="/operator-log" element={<OperatorLog />} />
            </Route>
            <Route element={<Permission permission="viewReport"/>} >
              <Route path="/report" element={<Report />} />
              {
                (!isTenantUser()) &&
                <Route path="/report/details" element={<ReportDetails />} />
              }
            </Route>
            <Route path="/settings" element={<Setting />} />
            <Route element={<Permission permission="viewWasteUpload"/>} >
              <Route path="/waste-upload" element={<WasteUpload />} />
            </Route>
          </Route>
        </Router>
      </BrowserRouter>
    </>
  );
}

export default Routes;
