import LoginIcon from '@mui/icons-material/Login';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { Formik } from "formik";
import { jwtDecode } from "jwt-decode";
import { FC, useContext, useState } from "react";
import { RiLockPasswordLine } from "react-icons/ri";
import { TbUserCircle } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { STATUS_CODES } from "../../constants/GlobalConstants";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { googleLoginApi, loginApi } from "../../services/RouteServices/LoginApi";
import { LoginUserValues, ResponseLoginApiDataType, ResponseLoginApiType, ResponseLoginData } from "../../types/LoginUserType";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import { setAvailableLocations, updatePermissions } from "../../utils/UserUtil";
import LogoImg from './../../assets/logo/env-logo-light.png';
import {
  BrandTitle,
  Card,
  Container,
  Button as CustomButton,
  ErrorText,
  Icon,
  Image,
  Input,
  InputContainer,
  LoginBox,
  SuccessText,
  TextTitle,
  Title,
} from "./LoginElements";

const loginSchema = Yup.object({
  userName: Yup.string().required("Username is required!"),
  password: Yup.string().required("Password is required!"),
})

const Login: FC = () => {
  const { userInfo, handleLogin } = useContext(UserAuthContext) as UserAuthType;
  let location = useLocation();
  let navigate = useNavigate();
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");

  // useEffect(() => {
  //   console.log(userInfo);
  // }, []);

  const loginHandler = async (userName :string , password: string) => {
    const loginValues : LoginUserValues = {
      userName : userName,
      password : password
    }

    // check with backend
   loginApi(loginValues)
      .then((res : ResponseLoginApiType) => {
        const resApiData : ResponseLoginApiDataType = res?.data;
        if (resApiData) {
          // console.log (resApiData);
          if (resApiData?.status === STATUS_CODES.SUCCESS_CODE) {
            setErrorText("");
            setSuccessText(resApiData?.message);
            const responseData : ResponseLoginData = resApiData?.data;
            let loginUserInfo : UserInfo = {
              ...responseData,
              orgId: responseData?.organizationId,
              orgName: responseData?.organizationName,
              orgImage: responseData?.organizationImage,
            }
            handleLogin(loginUserInfo);
            updatePermissions(loginUserInfo.roleId)
            setAvailableLocations(loginUserInfo.id!)
            setTimeout(() => {
              navigate("/", { replace: true });
            }, 1000)
          } else {
            setSuccessText("");
            setErrorText(resApiData?.message);
          }
        }
      })
      .catch((error) => {
        console.log (error);
        setSuccessText("");
        setErrorText(error?.response?.data?.message || error?.message);
      });
  }

  const googleLogin = (credentialResponse: CredentialResponse) => {
    const user: any = jwtDecode(credentialResponse.credential || "")
    const loginValues = {
      username: user.name,
      email: user.email
    }
    googleLoginApi(loginValues)
    .then((response) => {

      const resData = response?.data;
      if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
        setErrorText("");
        setSuccessText(resData?.message);
        if (!resData.isPending) {
          const data = resData?.data
          let loginUserInfo : UserInfo = {
            ...data,
            orgId: data?.organizationId,
            orgName: data?.organizationName,
            orgImage: data?.organizationImage,
          }
          handleLogin(loginUserInfo);
          updatePermissions(loginUserInfo.roleId)
          setAvailableLocations(loginUserInfo.id!)
          setTimeout(() => {
            navigate("/", { replace: true });
          }, 1000)
        }
      } else {
        setSuccessText("");
        setErrorText(resData?.message);
      }
    })
    .catch((error) => {
        console.log (error);
        setSuccessText("");
        setErrorText(error?.response?.data?.message || error?.message);
    });
  }

  return (
    <>
      <Formik
        initialValues={{
          userName: "",
          password: ""
        }}
        onSubmit={(values) => loginHandler(values.userName, values.password)}
        validationSchema={loginSchema}
      >
        {props => (
          <form onSubmit={props.handleSubmit}>
          <Container>
            <Card>
              <Image src={LogoImg} alt="logo" />
              <BrandTitle>Food Waste Recognization System</BrandTitle>
              <LoginBox>
                <Title>Login</Title>
                <SuccessText>{successText}</SuccessText>
                <ErrorText>{errorText}</ErrorText>
                <TextTitle>Username</TextTitle>
                <InputContainer>
                  <Icon>
                    <TbUserCircle size={"25px"} />
                  </Icon>
                  <Input
                    placeholder="Username"
                    value={props.values.userName}
                    // autoFocus
                    name="userName"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </InputContainer>
                {props.touched.userName && props.errors.userName && (
                  <ErrorText>{props.errors.userName}</ErrorText>
                )}
                <Title></Title>
                <TextTitle>Password</TextTitle>
                <InputContainer>
                  <Icon>
                    <RiLockPasswordLine size={"25px"} />
                  </Icon>
                  <Input
                    type={"password"}
                    placeholder="Password"
                    name="password"
                    value={props.values.password}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </InputContainer>
                {props.touched.password && props.errors.password && (
                  <ErrorText>{props.errors.password}</ErrorText>
                )}
                <CustomButton type="submit" className='mb-3'><LoginIcon/> Login</CustomButton>
                <GoogleLogin
                  onSuccess={credentialResponse => {
                    googleLogin(credentialResponse)
                  }}
                  onError={() => {
                    console.log('Login Failed');
                  }}
                  width="300px"
                />
              </LoginBox>
            </Card>
          </Container>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Login;
