import React, { FC, useState, useEffect } from "react";
import Navbar from "./Navbar/Navbar";
import SideNav from "./SideNavbar/SideNavbar";
import Footer from "./Footer/Footer";
import { NodeProps } from "../../types/CommonType";
import { Container, ContainerItems } from "./LayoutElements";
const Layout: FC<NodeProps> = ({ children }) => {
  //ToggleSidebar
  const [sideNavOpened, setSideNavOpened] = useState(true);

  useEffect(() => {
    window.addEventListener("resize", resizeSidebar)
  }, [])

  const ToggleSidebar = () => {
    setSideNavOpened(!sideNavOpened);
    console.log("Pressed", sideNavOpened);
  }

  const resizeSidebar = () => {
    if (window.innerWidth < 760) {
      setSideNavOpened(false)
    }
    else {
      setSideNavOpened(true)
    }
  }

  return (
    <>
      {sideNavOpened ? <Navbar toggleSidebar={ToggleSidebar} toggleMenu={sideNavOpened} marginLeft="200px" />
                     : <Navbar toggleSidebar={ToggleSidebar} toggleMenu={sideNavOpened} marginLeft="0" /> }
      <Container>
        {sideNavOpened ? <SideNav width="200px" innerWidth="100%"/> : <SideNav width="0" innerWidth="0"/>}
        {sideNavOpened ? <ContainerItems marginValue="200px">{children}</ContainerItems> : <ContainerItems marginValue="0">{children}</ContainerItems>}
      </Container>
        
      {/* <Footer /> */}
    </>
  );
};

export default Layout;
