import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Badge, Modal } from "react-bootstrap";
import { TopFiveTenants } from '../CollapseTable/Row';

type Props = {
    show: boolean
    onClose: () => void
    topFiveTenants: TopFiveTenants[]
}

const colors = [
    "#144366", "#18507a", "#1c5d8e", "#206ba3", "#2478b7"
]

const TopFiveTenantsModal = (props: Props) => {
    const {show, onClose, topFiveTenants} = props
    return (  
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Today Top Five Tenants</Modal.Title>
                <CloseIcon onClick={onClose} style={{cursor: "pointer"}} />
            </Modal.Header>
            <Modal.Body>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Tenant</TableCell>
                        <TableCell >Total Weight (kg)</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        (topFiveTenants.length > 0) ?
                            topFiveTenants.map((tenant, index) => (
                                <TableRow
                                    key={tenant.tenantName}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>
                                        {tenant.tenantName}
                                    </TableCell>
                                    <TableCell style={{color: colors[index]}}>{tenant.totalWeight}</TableCell>
                                </TableRow>
                            )):
                        <TableRow
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>
                                <Badge bg="secondary" className='fs-6'>No Data</Badge>
                            </TableCell>
                        </TableRow>
                    }
                    </TableBody>
                </Table>
            </TableContainer>
            </Modal.Body>
        </Modal>
    );
}
 
export default TopFiveTenantsModal;