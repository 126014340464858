import http from "../apiServices";
import { CardInMachineADDRequestBody, CardInMachineData, CardInMachineDELETERequestBody, CardInMachineUpdateRequestBody } from "../../types/CardInMachineType";
import { STATUS_CODES } from "../../constants/GlobalConstants";

export const cardInMachineAddDataApi = (inputData : CardInMachineADDRequestBody) => {
    return http.post("/card-in-machine/add", inputData)
}

export const cardInMachineUpdateDataApi = (inputData : CardInMachineUpdateRequestBody) => {
    return http.post("/card-in-machine/update", inputData)
}

export const cardInMachineDeleteDataApi = (inputData: CardInMachineDELETERequestBody) => {
    return http.post("/card-in-machine/delete", inputData)
}

export const cardInMachineGetByMachineId = (inputData: number) => {
    return http.post("/card-in-machine/get/by/machineId", {"machineId": inputData})
}