import 'bootstrap/dist/css/bootstrap.min.css';
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import '../../styling/customStyle.css';
import Layout from "../Layout/Layout";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LOG_LEVEL, PAGE_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";
import {
    tenantAddDataApi,
    tenantDeleteDataApi,
    tenantGetExportListByOrgIdApi,
    tenantGetListByOrgIdApi,
    tenantUpdateDataApi
} from "../../services/RouteServices/TenantsApi";
import {
    TenantDELETERequestBody,
    TenantDefault,
    TenantListResponseBody,
    TenantResponseBody,
    TenantUPDATERequestBody,
    TenantsData,
} from "../../types/TenantsType";

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Button from "react-bootstrap/Button";
import XLSX from "xlsx";
import logoImage from '../../assets/logo/env-logo.png';
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { useFilterMachines } from "../../custom-hooks/useFilterMachines";
import { useFilterOrganizations } from "../../custom-hooks/useFilterOrganizations";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import { FilterValue } from "../../types/global/FilterValueType";
import { convertToLocaleDate, getCurrentLocaleDate } from '../../utils/DateTimeUtil';
import { getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import { addEventLog } from "../../utils/EventLogUtil";
import { addNormalLog } from "../../utils/LoggerUtil";
import { getCurrentLocalUser, isRootAndSuperUsers } from "../../utils/UserUtil";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title } from "../Shared/Common/Titles";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import ExportButton from "../Shared/Export/Export";
import TenantDeleteModal from "./TenantDeleteModal";
import TenantFilterContainer from "./TenantFilterContainer";
import TenantModal from './TenantsModal';

const loadTenantExportList = (dataList: TenantsData[]) => {
    if (!dataList) return []
    return dataList.map(data => {
        return {
            Id: data.id,
            "Organization": data.organizationName,
            "Name": data.tenantName,
            "Cards": data.assignedCards.map(card => `card ${card.cardId}[${card.machineName}]`).join(", "),
            "Remark": data.remark,
            "Created At": convertToLocaleDate(data.createdAt!),
        }
    })
}
 
const Tenants: React.FC = () => {
    const tableLimit = PAGE_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const [tablePage, setTablePage] = React.useState(1);
    const [tableOffset, setTableOffset] = React.useState(0);
    const [pageCount, setPageCount] = React.useState(0);
    const [tenantTotalCount, setTenantTotalCount] = React.useState(0);
    
    const [tenantsList, setTenantsList] = useState<TenantsData[]>([]); // * Original data object from db

    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [tenantData, setTenantData] = useState<TenantsData>(TenantDefault);
    const [modalSuccessMsg, setModalSuccessMsg] = useState<string>("");
    const [modalErrorMsg, setModalErrorMsg] = useState<string>("");
    const [filterValue, setFilterValue] = useState<FilterValue>(getDefaultFilterValue());
    const [viewUnassignedTenants, setViewUnassignedTenants] = useState<boolean>(false);

    const {organizations, error: orgError} = useFilterOrganizations()
    const {machines, error: machineError} = useFilterMachines(filterValue?.organizationId || -1, [filterValue.organizationId])

    const navigate = useNavigate()

    useEffect(() => {
        setTablePage(1)
        setTableOffset(0)
    }, [filterValue, viewUnassignedTenants])

    useEffect(() => {
        if (hasPermission("viewTenant")) {
            getManageTenantsApiHandler(filterValue.organizationId!, tableLimit, tableOffset);
        }
    }, [filterValue, viewUnassignedTenants, tableOffset]);

    useEffect(() => {
        if (tenantData.tenantName !== "" && tenantData.remark !== "" && 
        tenantData.organizationId !== 0 && tenantData.organizationId ) {
            setModalErrorMsg("");
        }
    }, [tenantData])

    useEffect(() => {
        setFilterValue({...filterValue, machineId: 0})
    }, [filterValue.organizationId, viewUnassignedTenants])

    const handleClickDetails = (tenant: TenantsData) => {
        navigate('/tenants/details', {
            state: {
                tenantId: tenant.id,
                tenantName: tenant.tenantName,
                orgId: tenant.organizationId,
            }
        });
    }

    const handleChangeFilterValue = (event: ChangeEvent<HTMLSelectElement>) => {
        setFilterValue(
            (prevValue) => {
                return {
                    ...prevValue,
                    [event.target.name]: event.target.value,
                }
            }
        )
    }

    const handleChangeViewUnassignedTenants = (event: ChangeEvent<HTMLInputElement>) => {
        setViewUnassignedTenants(event.target.checked)
    }

    // Input Elements
    const handleChangeTenantData = (event: ChangeEvent<HTMLInputElement>) => { 
        setTenantData(
            (prevTenantData) => {
                return  {
                    ...prevTenantData,
                    [event.target.name]: event.target.value,
                }
            }
        )
    }

    const showAlert = (resData: TenantResponseBody) => {
        if (resData?.status === STATUS_CODES.SUCCESS_CODE){
            setModalErrorMsg("")
            setModalSuccessMsg(resData?.message)
            setShowDeleteModal(false)
            setShowAddModal(false);
            setShowEditModal(false);
            setTenantData(TenantDefault);
            getManageTenantsApiHandler(filterValue.organizationId!, tableLimit, tableOffset);   
        }
        else{
            setModalErrorMsg(resData?.message)
        }
    }

    const handleClose = () => {
        setShowAddModal(false);
        setShowEditModal(false);
        setShowDeleteModal(false);
        setTenantData(TenantDefault);
    }

    const openAddTenantModal = () => {
        setModalErrorMsg("");
        setModalSuccessMsg("");
        setShowAddModal(true);
        setTenantData(
            (prevTenantData) => {
                return  {
                    ...prevTenantData,
                    organizationId: userInfo.orgId,
                }
            }
        )
    }
       
    const openEditTenantModal = (tenantObj: TenantsData) => {
        setModalErrorMsg("");
        setModalSuccessMsg("");
        setShowEditModal(true);
        const orgIdList = organizations.map(org => org.id)
        setTenantData({
            ...tenantObj,
            remark: tenantObj.remark || "",
            organizationId: (orgIdList.includes(tenantObj.organizationId))? tenantObj.organizationId: 0, 
        });
    }

    const openDeleteTenantModal = (tenantObj: TenantsData) => {
        setModalErrorMsg("");
        setModalSuccessMsg("");
        setShowDeleteModal(true);
        setTenantData(tenantObj);
    }

    const handleAddTenant = async () => {
        const inputObj = {
            organizationId: tenantData.organizationId,
            tenantName: tenantData.tenantName,
            remark: tenantData.remark,
            createdBy: userInfo.userName
        }
        await tenantAddDataApi(inputObj)
        .then(res => {
            showAlert(res?.data)
        })
        .catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }

    const handleEditTenant = async () => {
        const inputObj: TenantUPDATERequestBody = {
            id: tenantData.id || 0, 
            organizationId: tenantData.organizationId,
            tenantName: tenantData.tenantName,
            remark: tenantData.remark || "",
            updatedBy: userInfo.userName
        }
        await tenantUpdateDataApi(inputObj)
        .then(res => {
            showAlert(res?.data)
        })
        .catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }

    const handleDeleteTenant = async () => {
        const inputObj: TenantDELETERequestBody = {
            "id": tenantData.id || 0,
            "deletedBy":  userInfo.userName
        }
    
        await tenantDeleteDataApi(inputObj)
        .then(res => {
            showAlert(res?.data)
        })
        .catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }

    const exportToCSV = async () => { 
        let exportedData = await getExportedData()
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(loadTenantExportList(exportedData));
        XLSX.utils.book_append_sheet(workbook, worksheet, "Overall");
        XLSX.writeFile(workbook, `tenants-${getCurrentLocaleDate()}.xlsx`);
        const log: string = `${userInfo.userName} has downloaded tenants-${getCurrentLocaleDate()}.xlsx`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    };

    const exportToPdf = async () => {
        let exportedData = await getExportedData()
        const dataList = exportedData ? 
                         exportedData.map((data: TenantsData) => (
                            {
                                ...data, 
                                createdAt: convertToLocaleDate(data.createdAt!), 
                                assignedCards: data.assignedCards.map(card => `card ${card.cardId}[${card.machineName}]`)
                            }
                        )) : 
                         []
        generatePDF(dataList)
        const log: string = `${userInfo.userName} has downloaded tenants-${getCurrentLocaleDate()}.pdf`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    }

    const generatePDF = (dataList: any) => {
        const doc = new jsPDF('landscape', 'mm', 'a4');

        const headers = [
            "Id", "Organization", "Name",
            "Cards", "Remark", "Created At"
        ];
        const dataKeys = [
            "id", "organizationName", "tenantName",
            "assignedCards", "remark", "createdAt"
        ];

        const columnWidths = [20, 35, 45, 90, 50, 45];
        // Generate the columnStyles object dynamically
        const columnStyles: any = {};
        for (let i = 0; i < headers.length; i++) {
          columnStyles[dataKeys[i]] = { cellWidth: columnWidths[i] };
        }

        autoTable(doc, {
            head: [headers],
            body: dataList,
            columns: dataKeys.map((key) => ({ dataKey: key })),
            columnStyles: columnStyles,
            margin: { top: 20, left: 5},
            didParseCell: function (data) {
                if (data.section === "head") {
                  data.cell.styles.fontSize = 9;
                  data.cell.styles.textColor = [255, 255, 255];
                  data.cell.styles.fillColor = [0, 0, 0];
                }
              },
            didDrawPage: function (data) {
                doc.setFontSize(18);
                doc.text("Tenants", data.settings.margin.left, 10);
                // Add the logo image
                const logoWidth = 20;
                const logoHeight = 10;
                const logoX = data.settings.margin.left + 250;
                const logoY = 5;
                doc.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);
            }
        })
        doc.save(`tenants-${getCurrentLocaleDate()}.pdf`)
    }

    const getExportedData = async () => {
        let exportedData = []
        try {
            exportedData = await getTenantExportListByOrgId()
        } catch(e) {
            exportedData = []
        }
        return exportedData
    }

    const getTenantExportListByOrgId = async (): Promise<any> => {
        const dataLimitsObject = {
            orgId: filterValue.organizationId!,
            machineId: filterValue.machineId!,
            searchText: filterValue.search,
            viewUnassignedTenants: viewUnassignedTenants,
        }

        return tenantGetExportListByOrgIdApi(dataLimitsObject)
        .then(res => {
            return new Promise((resolve, reject) => {
                if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                    resolve(res?.data?.data?.tenantList)
                else 
                    reject([])
            })
        }).catch(error => {
            return Promise.reject([])
        })
    }  

    const getManageTenantsApiHandler = async (orgId: number, pageLimit : number, pageOffset : number) => {
        const dataLimitsObject = {
            orgId: orgId,
            machineId: filterValue.machineId!,
            searchText: filterValue.search,
            viewUnassignedTenants: viewUnassignedTenants,
            limit: pageLimit,
            offset: pageOffset
        }

        await tenantGetListByOrgIdApi(dataLimitsObject)
        .then(response => {
            const resData: TenantListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: TenantsData[] = resData.data?.tenantList
                const totalCount : number = resData?.data?.totalCount || 0;
                setTenantTotalCount(totalCount)
                setPageCount((Math.ceil(totalCount/tableLimit)) || 0);
                setTenantsList(dataList);
                // for not showing empty page when delete last item
                if (totalCount != 0 && dataList.length == 0) {
                    setTableOffset(prev => prev != 0 ? prev - 10 : prev)
                    setTablePage(prev => prev != 1 ? prev -1 : prev)
                }
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }   

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };
    // * Render Table Data
    const renderTable = () => {
        return (
            tenantsList?.length !== 0 ? tenantsList?.map((tenant: TenantsData) =>          
                    <tr key={tenant.id}>
                        <td>{tenant.id}</td>
                        {/* <td>{tenant.organizationId || "-"}</td> */}
                        <td>{tenant.organizationName || "-"}</td>
                        <td>{tenant.tenantName || "-"}</td>
                        <td>
                            {
                                tenant.assignedCards.map(card => (
                                    <p style={{margin: 0,}}>
                                        card {card.cardId} {`[${card.machineName}]`}
                                        {
                                            card.cardUuid &&
                                            <span className='text-primary'>{` [${card.cardUuid || ""}]`}</span>
                                        }
                                    </p>
                                ))
                            }
                        </td>
                        { isRootAndSuperUsers() &&
                            <>
                            <td>{tenant.createdBy || "-"}</td>
                            <td>{convertToLocaleDate(tenant.createdAt!) || "-"}</td>
                            <td>{tenant.updatedBy || "-"}</td>
                            <td>{convertToLocaleDate(tenant.updatedAt!) || "-"}</td>
                            </>
                        }
                        <td>{tenant.remark || "-"}</td>
                        <td>
                            <EditOutlinedIcon 
                                sx={{
                                    color: "blue",
                                }}
                                className="pointer"
                                onClick={() => openEditTenantModal(tenant)}
                            />
                            &nbsp;&nbsp;
                            {
                                isRootAndSuperUsers() &&
                                <DeleteOutlinedIcon 
                                    sx={{
                                        color: "red",
                                    }}
                                    className="pointer"
                                    onClick={() => openDeleteTenantModal(tenant)}
                                />
                            }
                        </td>
                        <td>
                            <button type="button" className="btn btn-outline-info text-dark"
                                onClick={() => handleClickDetails(tenant)}
                            >
                                Detail <ArrowForwardIcon />
                            </button>
                        </td>
                    </tr>
            ): 
            <tr>
                <td>No data found</td>
            </tr>
        )
    }


    if (!hasPermission("viewTenant")) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (
        <>
            <Layout>
                <Container>
                    <Box className="d-flex justify-content-between mb-2">
                        <Title>Tenants</Title>
                        <Box className="titleBox">
                            <Box sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 3
                            }}>
                                <ExportButton 
                                    exportToCSV={exportToCSV}
                                    exportToPdf={exportToPdf}
                                />
                                {
                                    hasPermission("addTenant") &&
                                    <Button className="pt-2 pb-2 ps-3 pe-3" id="btnAddUser" variant="success" onClick={openAddTenantModal}>Add</Button>
                                }
                            </Box>
                        </Box>
                    </Box>
                    {/* {successText && <Alert severity="success">{successText}<br/></Alert>} */}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}
                    <TenantFilterContainer
                        onChangeFilter={handleChangeFilterValue}
                        filterValue={filterValue}
                        viewUnassignedTenants={viewUnassignedTenants}
                        onChangeViewUnassignedTenants={handleChangeViewUnassignedTenants}
                        orgList={organizations}
                        machineList={machines}
                        userInfo={userInfo}
                    />

                    <TenantModal 
                        show={showAddModal}
                        title="Add tenant"
                        onChangeTenantData={handleChangeTenantData}
                        btnName="Add"
                        onTenantForm={handleAddTenant}
                        onClose={handleClose}
                        tenantData={tenantData}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        btnColor="success"
                        orgList={organizations}
                        userInfo={userInfo}
                    />

                    <TenantModal 
                        show={showEditModal}
                        title="Edit tenant"
                        onChangeTenantData={handleChangeTenantData}
                        btnName="Edit"
                        onTenantForm={handleEditTenant}
                        onClose={handleClose}
                        tenantData={tenantData}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        btnColor="primary"
                        orgList={organizations}
                        userInfo={userInfo}
                    />

                    <TenantDeleteModal 
                        show={showDeleteModal}
                        onClose={handleClose}
                        data={tenantData}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        handleDelete={handleDeleteTenant}
                    />  
                    
                    <SummaryText>{tenantTotalCount} Tenants found!</SummaryText>
                    <TableContainer>
                    <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    <th>Organization</th>
                                    <th>Name</th>
                                    <th>Cards</th>
                                    { isRootAndSuperUsers() &&
                                        <>
                                        <th>Created By</th>
                                        <th>Created At</th>
                                        <th>Updated By</th>
                                        <th>Updated At</th>
                                        </>
                                    }
                                    <th>Remark</th>
                                    <th>Action</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {
                                    renderTable()
                                    
                                }
                            </ tbody>
                        </table>
                    </InnterTableContainer>
                    </TableContainer>
                    <PaginationContainer>
                        <Pagination 
                            count={pageCount} 
                            onChange={handleChangePage} 
                            page={tablePage} 
                            variant="outlined" 
                            shape="rounded" size="large" 
                        />
                    </PaginationContainer>
                </Container>
            </Layout>
        </>
    );
};

export default Tenants;