export type HardwareData = {
    id: number,
    uniqueId: string,
    deviceName: string,
    ipAddress: string,
    heartBeatMacAddress: string,
    healthStatus: boolean,
    lastHeartBeatDate: string,
    userAssignedName: string,
    color: string,
    createdAt?: string,
    createdBy?: string,
}

export const HardwareDefaultData = {
  id: 0,
  uniqueId: "unknown",
  deviceName: "",
  ipAddress: "",
  heartBeatMacAddress: "",
  healthStatus: false,
  lastHeartBeatDate: "",
  userAssignedName: "",
  color: "",
  createdAt: "",
  createdBy: "",
}

export type HardwareGETRequestBody = {
    limit?: number,
    offset?: number,
}

export type HardwareUPDATERequestBody = {
  id: number,
  userAssignedName: string,
}

export type HardwareDELETERequestBody = {
  id: number,
  deletedBy: string,
}

export type HardwareListResponseBody = {
    data: {
      hardwareList: HardwareData[],
      totalCount: number,
    }
    status: number,
    message: string,
}

export type HardwareResponseBody = {
  data: HardwareData,
  status: number,
  message: string,
}

