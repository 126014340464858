import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { UserData } from "../../types/UsersType";
import logoImage from '../../assets/logo/env-logo.png';
import { getCurrentLocaleDate } from "../../utils/DateTimeUtil";

export const generatePDF = (dataList: UserData[]) => {
    const doc = new jsPDF('landscape', 'mm', 'a4');

    const headers = [
        "Id", "Organization", "Username", "Role", "Email", "PhoneNo", "Status"
    ];
    const dataKeys = [
        "id", "organizationName", "userName", "roleName", "email", "phoneNo", "userStatus"
    ];

    const columnWidths = [20, 45, 45, 45, 45, 45, 45];
    
    // Generate the columnStyles object dynamically
    const columnStyles: any = {};
    for (let i = 0; i < headers.length; i++) {
      columnStyles[dataKeys[i]] = { cellWidth: columnWidths[i] };
    }

    autoTable(doc, {
        head: [headers],
        body: dataList,
        columns: dataKeys.map((key) => ({ dataKey: key })),
        columnStyles: columnStyles,
        margin: { top: 25, left: 5},
        didParseCell: function (data) {
            if (data.section === "head") {
              data.cell.styles.fontSize = 9;
              data.cell.styles.textColor = [255, 255, 255];
              data.cell.styles.fillColor = [0, 0, 0];
            }
          },
        didDrawPage: function (data) {
            doc.setFontSize(18);
            doc.text("Users", data.settings.margin.left, 10);

            const logoWidth = 20;
            const logoHeight = 10;
            const logoX = data.settings.margin.left + 250;
            const logoY = 5;
            doc.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);
        }
    })
    doc.save(`users-${getCurrentLocaleDate()}.pdf`)
}