import styled from "styled-components";
import { NodeProps } from "../../../types/CommonType";
import {lightGray, darkGray} from "../../../constants/DesignContant";

export const SideNavContainer = styled.div`

`

export const SideNav = styled.div<NodeProps>`
    background-color: ${darkGray};
    box-shadow: 15px 0px 10px -15px #111;
    display: flex;
    justify-content: center;
    top: 0;
    position: fixed;
    z-index: 999;
    height: 100%;
    overflow-y: auto;
    width: ${(props) => props.width ? props.width : "20%"};
`;

export const SideNavItems = styled.div<NodeProps>`
    height: inherit;
    width: 100%;
    h5{  // * Main Text
        margin: 0;
        padding: 20px 0 10px 15px;
        color: #B0B0B0;
        font-weight: 500;
        font-size: 80%;
    }
    h4{ // * Button Text
        margin: 0;
        color: #fff;
        font-size: 120%;
        font-weight: normal;
    }
    .links{
        text-decoration: none;
    }
`;

export const ItemsContainer = styled.div<NodeProps>`
    margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 15px;
    width: 100%;
    resize: none;
    
    h4{
        margin-left: 10px;
        color: red;
    }
    &:hover {
        background-color: ${lightGray};
        cursor: pointer;
    };

    .icon{
        color: white;
    }
`;