import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const GoBackButton = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigates back one step in history
    };

    return (  
        <div className='mb-2'>
            <div style={{
                display: "inline",
                padding: "5px 10px",
            }} className='shadow bg-body-tertiary rounded border border-dark-subtle'>
                <ArrowBackIcon onClick={goBack} style={{cursor: "pointer"}} />
            </div>
        </div>
    );
}
 
export default GoBackButton;