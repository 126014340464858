import Box from "@mui/material/Box"
import MaintenanceSwitch from './MaintenanceSwitch';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { maintenanceConfigAddApi, maintenanceConfigGetFirstRowApi, toggleSwitchMaintenanceConfigApi } from '../../services/RouteServices/ConfigApi';
import { STATUS_CODES } from '../../constants/GlobalConstants';
import { getCurrentLocalUser } from '../../utils/UserUtil';
import { MaintenanceContext, MaintenanceContextType } from '../../contexts/MaintenanceContext';
import "./Config.css"
import { Button, Col, Form, Row } from "react-bootstrap";
import { MaintenanceConfigData, MaintenanceConfigDefault } from "../../types/MaintenanceConfigType";
import { convertToLocaleDate } from "../../utils/DateTimeUtil";

const MaintenanceConfig = ({
    handleErrorText,
    handleSuccessText
}: any) => {
    const userInfo = getCurrentLocalUser()
    const {setIsMaintenance, isMaintenance} = useContext(MaintenanceContext) as MaintenanceContextType;

    const [serverMaintenanceSwitch, setServerMaintenanceSwitch] = useState<boolean>(false)
    const [maintenanceConfig, setMaintenanceConfig] = useState<MaintenanceConfigData>(MaintenanceConfigDefault)

    useEffect(() => {
        maintenanceConfigGetFirstRowApi()
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setServerMaintenanceSwitch(res?.data?.data?.isMaintenance);
                setMaintenanceConfig(res?.data?.data)
            } else {
                console.log(res?.data?.message)
                setServerMaintenanceSwitch(false)
                setMaintenanceConfig(MaintenanceConfigDefault)
            }
        }).catch(error => {
            setServerMaintenanceSwitch(false)
            setMaintenanceConfig(MaintenanceConfigDefault)
            handleErrorText(error?.response?.data?.message || error?.message)   
        })
    }, [])

    // useEffect(() => {
    //     setIsMaintenance(serverMaintenanceSwitch)
    // }, [serverMaintenanceSwitch])

    const handleServerMaintenanceSwitch = (event: ChangeEvent<HTMLInputElement>) => {
        setServerMaintenanceSwitch(event.target.checked)
        
        const inputObject = {
            isMaintenance: event.target.checked,
            doneBy: userInfo.userName
        }
        toggleSwitchMaintenanceConfigApi(inputObject)
        .then(res => {
            if (res?.data?.status !== STATUS_CODES.SUCCESS_CODE) {
                setServerMaintenanceSwitch(!event.target.checked);
                setIsMaintenance((prevValue: boolean) => !prevValue)
            } else {
                setIsMaintenance((prevValue: boolean) => !prevValue)
            }
        }).catch(error => {
            handleErrorText(error?.response?.data?.message || error?.message);
            setServerMaintenanceSwitch(!event.target.checked)
            setIsMaintenance((prevValue: boolean) => !prevValue)
        })
        
    }

    const handleChangeMaintenanceConfig = (event: ChangeEvent<HTMLInputElement>) => {
        setMaintenanceConfig({
            ...maintenanceConfig,
            [event.target.name]: event.target.value
        })
    }

    const handleAddMaintenanceConfig = () => {
        handleErrorText("")
        const input = {
            details: maintenanceConfig.details || "",
            doneBy: userInfo.userName
        }
        maintenanceConfigAddApi(input)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setMaintenanceConfig(res?.data?.data)
                handleSuccessText(res?.data?.message)
                setTimeout(() => {
                    handleSuccessText("")
                }, 2000)
                setIsMaintenance((prevValue: boolean) => !prevValue)
            } else {
                handleErrorText(res?.data?.message)
            }
        }).catch(error => {
            handleErrorText(error?.response?.data?.message || error?.message)
        })
    }

    return (  
        <Box 
            sx={{minWidth: "400px", marginTop: 3}}
        >
            <Form>
                <Form.Label as="legend">
                    Maintenance Config
                </Form.Label>
                <div
                    className="border border-light border-3 rounded p-3 bg-light bg-gradient" 
                >
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            Details
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" name="details" value={maintenanceConfig.details || ""} onChange={handleChangeMaintenanceConfig} placeholder='System will be...' />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="">
                        <Form.Label column sm="3">
                             
                        </Form.Label>
                        <Col sm="9">
                            <span style={{fontSize:13, color:'#35a612'}}>Last Update - {convertToLocaleDate(maintenanceConfig?.updatedAt || "") || convertToLocaleDate(maintenanceConfig?.createdAt || "")}</span>
                            <br></br>
                            <span style={{fontSize:13, color:'#35a612'}}>Updated User - {maintenanceConfig.updatedBy || maintenanceConfig.createdBy}</span>
                        </Col>
                    </Form.Group>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "right"
                        }}
                    >
                        <Button variant="primary" onClick={handleAddMaintenanceConfig}>Update</Button>
                    </Box>
                    <MaintenanceSwitch 
                        maintenanceSwitch={serverMaintenanceSwitch}
                        handleMaintenanceSwitch={handleServerMaintenanceSwitch}
                    />
                </div>
            </Form>
        </Box>
    );
}
 
export default MaintenanceConfig;