import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, Pagination } from "@mui/material";
import { AxiosResponse } from "axios";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { CARD_LIMIT, LOG_LEVEL, STATUS_CODES } from "../../constants/GlobalConstants";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { manualDetectionAddApi, manualDetectionDeleteApi, manualDetectionGetExportListByOrgIdApi, manualDetectionGetExportListByUserIdApi, manualDetectionGetListByOrgIdApi, manualDetectionGetListByUserIdApi, manualDetectionUpdateApi } from "../../services/RouteServices/ManualDetectionApi";
import { CurrentManualDetection, ManualDetectionData, ManualDetectionFilterValue, ManualDetectionGETRequestBody, ManualDetectionListResponseBody } from "../../types/ManualDetectionType";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import { DateFilterValue } from "../../types/global/FilterValueType";
import { convertDateFormat, getCurrentLocaleDate, getDayDifference } from "../../utils/DateTimeUtil";
import { getDefaultDateFilterValue, getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import { addEventLog } from "../../utils/EventLogUtil";
import { addNormalLog } from "../../utils/LoggerUtil";
import { getCurrentLocalUser, isTenantUser } from "../../utils/UserUtil";
import Layout from "../Layout/Layout";
import { CardsContainer, Container, PaginationContainer } from "../Shared/Common/Containers";
import { SummaryText } from "../Shared/Common/Titles";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import LoadingModal from "../Shared/LoadingModal/LoadingModal";
import ErrorToastify from '../Shared/Toastify/ErrorToastify';
import SuccessToastify from '../Shared/Toastify/SuccessToastify';
import ManualDetectionFilterContainer from "./DetectionFilterContainer";
import ManualDetectionCard from "./MandualDetectionCard";
import AddModal from './components/AddModal';
import DeleteModal from './components/DeleteModal';
import EditModal from './components/EditModal';
import { generatePdfWithImage } from "./generatePdf";

const ManualDetection = () => {
    const tableLimit = CARD_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    const [currentDetection, setCurrentDetection] = useState<CurrentManualDetection>({} as CurrentManualDetection);
    const [detectionList, setDetectionList] = useState<ManualDetectionData[]>([]);
    const [detectionTotalCount, setDetectionTotalCount] = useState<number>(0);
    const [tableOffset, setTableOffset] = useState(0);
    const [tablePage, setTablePage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false)
    const [filterValue, setFilterValue] = useState<ManualDetectionFilterValue>({...getDefaultFilterValue(), type: "all"});
    const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>(getDefaultDateFilterValue())
    const [totalWeight, setTotalWeight] = useState<number | undefined>(0);
    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)

    const navigate = useNavigate();

    const dayRange = getDayDifference(dateFilterValue.startDate, dateFilterValue.endDate);

    useEffect(() => {
        setTablePage(1)
        setTableOffset(0)
    }, [filterValue, dateFilterValue])

    useEffect(() => {
        setErrorText("")
        setSuccessText("")
        if (hasPermission("viewManualDetection")) {
            setIsLoading(true)
            getDetectionList()
        }
    }, [filterValue, dateFilterValue, tableOffset]);

    const getDetectionList = () => {
        if (isTenantUser()) {
            getManualDetectionListByUserId();
        } else {
            getManualDetectionListByOrgId();
        }
    }

    const resetAlertMessage = () => {
        setErrorText("")
        setSuccessText("")
    }

    const handleChangeCurrentManualDetection = (event: ChangeEvent<any>) => {
        const {name, value} = event.target
        setCurrentDetection(prev => ({
            ...prev,
            [name]: value
        }))

        if (name === "organizationId")
            setCurrentDetection(prev => ({
                ...prev,
                "machineId": 0,
                "tenantId": 0,
            }))
        else if (name === "machineId")
            setCurrentDetection(prev => ({
                ...prev,
                "tenantId": 0,
            }))
    }

    const handleUploadImage = (base64Image: string) => {
        setCurrentDetection(prev => ({
            ...prev,
            imageUris: [base64Image]
        }))
    }

    const handleClickDeleteBtn = (manualDetection: ManualDetectionData) => {
        setCurrentDetection(prev => ({
            ...prev,
            id: manualDetection.id!
        }))
        setShowDeleteModal(!showDeleteModal)
    }

    const handleClickEditBtn = (manualDetection: ManualDetectionData) => {
        const current = {
            id: manualDetection.id!,
            organizationId: manualDetection.organizationId,
            machineId: manualDetection.machineId,
            tenantId: manualDetection.tenantId,
            imageType: manualDetection.isOrganic === "1" ? "organic": "nonOrganic",
            imageUris: [],
            weight: manualDetection.weight,
            remark: manualDetection.remark,
        }
        setCurrentDetection(current)
        setShowEditModal(!showEditModal)
    }

    const addManualDetection = () => {
        resetAlertMessage()
        const input = {
            organizationId: currentDetection?.organizationId,
            tenantId: currentDetection?.tenantId,
            machineId: currentDetection?.machineId,
            imageType: currentDetection?.imageType || "",
            weight: currentDetection?.weight || 0,
            remark: currentDetection?.remark,
            imageUris: currentDetection?.imageUris || [""],
            createdBy: userInfo?.userName || ""
        }
        manualDetectionAddApi(input)
        .then((res) => {
            const response = res?.data
            if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                setSuccessText(response?.message)
                setShowAddModal(false)
                getDetectionList()
            } else {
                setErrorText(response?.message)
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }

    const updatedManualDetection = () => {
        resetAlertMessage()
        const input = {
            id: currentDetection?.id,
            organizationId: currentDetection?.organizationId,
            tenantId: currentDetection?.tenantId,
            machineId: currentDetection?.machineId,
            imageType: currentDetection?.imageType || "",
            weight: currentDetection?.weight || 0,
            remark: currentDetection?.remark,
            imageUri: currentDetection?.imageUris?.[0] || "",
            updatedBy: userInfo?.userName || ""
        }
        manualDetectionUpdateApi(input)
        .then((res) => {
            const response = res?.data
            if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                setSuccessText(response?.message)
                setShowEditModal(false)
                getDetectionList()
            } else {
                setErrorText(response?.message)
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }

    const deleteManualDetection = () => {
        resetAlertMessage()
        const input = {
            id: currentDetection.id,
            deletedBy: userInfo?.userName || ""
        }
        manualDetectionDeleteApi(input)
        .then((res) => {
            const response = res?.data
            if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                setSuccessText(response?.message)
                setShowDeleteModal(false)
                getDetectionList()
            } else {
                setErrorText(response?.message)
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }

    const getManualDetectionListByOrgId = () => {
        const dataLimitsObject: ManualDetectionGETRequestBody = {
            type: filterValue.type,
            orgId: filterValue.organizationId!,
            machineId: filterValue.machineId!,
            tenantId: filterValue.tenantId!,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: tableLimit,
            offset: tableOffset,
        }
        manualDetectionGetListByOrgIdApi(dataLimitsObject)
        .then(response => {
            const resData: ManualDetectionListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: ManualDetectionData[] = resData.data?.detectionList
                const totalCount : number = resData?.data?.totalCount;
                setDetectionTotalCount(totalCount)
                setTotalWeight(resData?.data?.totalWeight)
                setPageCount((Math.ceil(totalCount/tableLimit)) || 0);
                setDetectionList(dataList);
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
            setIsLoading(false)
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            setIsLoading(false)
        })
    } 

    const getManualDetectionListByUserId = () => {
        const dataLimitsObject: ManualDetectionGETRequestBody = {
            type: filterValue.type,
            userId: userInfo.id,
            machineId: filterValue.machineId!,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: tableLimit,
            offset: tableOffset,
        }
        manualDetectionGetListByUserIdApi(dataLimitsObject)
        .then(response => {
            const resData: ManualDetectionListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: ManualDetectionData[] = resData.data?.detectionList
                const totalCount : number = resData?.data?.totalCount;
                setDetectionTotalCount(totalCount)
                setPageCount((Math.ceil(totalCount/tableLimit)) || 0);
                setTotalWeight(resData?.data?.totalWeight)
                setDetectionList(dataList);     
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
            setIsLoading(false)
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            setIsLoading(false)
        })
    } 
    
    const getExportedData = async() => {
        let exportedData = []
        try {
            if (isTenantUser()) {
                exportedData = await getManualDetectionExportListByUserId()
            } else {
                exportedData = await getManualDetectionExportListByOrgId()
            }
        } catch(e) {
            console.log(e)
            exportedData = []
        }
        return exportedData
    }

    const getManualDetectionExportListByOrgId = async (): Promise<any> => {
        const dataLimitsObject: ManualDetectionGETRequestBody = {
            type: filterValue.type,
            orgId: filterValue.organizationId!,
            machineId: filterValue.machineId!,
            tenantId: filterValue.tenantId!,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
        }
        try {
            const response: AxiosResponse<ManualDetectionListResponseBody> = await manualDetectionGetExportListByOrgIdApi(dataLimitsObject);
            const resData: ManualDetectionListResponseBody = response.data;
        
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
              const dataList: ManualDetectionData[] = resData.data?.detectionList;
              return dataList;
            } else {
              console.log(resData?.message);
              return [];
            }
        } catch (error: any) {
            console.log('Error fetching data:', error?.message);
            return [];
        }
    }

    const getManualDetectionExportListByUserId = async (): Promise<any> => {
        const dataLimitsObject: ManualDetectionGETRequestBody = {
            type: filterValue.type,
            userId: userInfo.id,
            machineId: filterValue.machineId!,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
        }
        return manualDetectionGetExportListByUserIdApi(dataLimitsObject)
        .then(response => {
            const resData: ManualDetectionListResponseBody = response?.data
            return new Promise((resolve, reject) => {
                if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                    const dataList: ManualDetectionData[] = resData.data?.detectionList
                    resolve(dataList)
                }
                else 
                    reject([])
            })
        })
        .catch(error => {
            console.log(error?.message)
            return Promise.reject([])
        })
    } 

    const exportToPdfWithImage = async () => {
        setIsLoading(true)
        let exportedData = await getExportedData()
        await generatePdfWithImage(exportedData)
        setIsLoading(false)
        const log: string = `${userInfo.userName} has downloaded manual-detections-with-images-${getCurrentLocaleDate()}.pdf`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    }

    const handleChangeFilterValue = (event: ChangeEvent<HTMLSelectElement>) => {
        setFilterValue(
            (prevValue) => {
                return {
                    ...prevValue,
                    [event.target.name]: event.target.value,
                }
            }
        )

        if (event.target.name === "organizationId") {
            setFilterValue(
                (prevFilterData) => {
                    return  {
                        ...prevFilterData,
                        machineId: 0,
                    }
                }
            )
        }

        if (event.target.name === "organizationId" || event.target.name === "machineId") {
            setFilterValue(
                (prevFilterData) => {
                    return  {
                        ...prevFilterData,
                        tenantId: 0,
                    }
                }
            )
        }
    }

    const handleChangeDateFilterValue = (key: "startDate" | "endDate", value: Date) => {
        const day = value.getDate().toString().padStart(2, '0');
        const month = (value.getMonth() + 1).toString().padStart(2, '0');
        const year = value.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setDateFilterValue({...dateFilterValue, [key]: formattedDate})
    }

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };

    if (!hasPermission("viewManualDetection")) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (  
        <Box>
            <Layout>
                <Container>
                    <div className="row">
                        <div className="col-lg-9 col-md-12">
                            <ManualDetectionFilterContainer
                                onChangeFilter={handleChangeFilterValue}
                                onChangeDateFilter={handleChangeDateFilterValue}
                                filterValue={filterValue}
                                dateFilterValue={dateFilterValue}
                            />
                        </div>
                        <div className="col-lg-3 col-md-12">
                            <Box className="titleBox">
                                {
                                    hasPermission("addManualDetection") &&
                                    <Button 
                                        className="pt-2 pb-2 ps-3 pe-3 me-3" 
                                        id="btnAddUser" variant="success" 
                                        onClick={() => {
                                            setErrorText("")
                                            setCurrentDetection({} as CurrentManualDetection);
                                            setShowAddModal(!showAddModal)
                                        }}>
                                            Add
                                    </Button>
                                }
                                <Button onClick={exportToPdfWithImage} disabled={dayRange > 31}><PictureAsPdfIcon /></Button>
                                <Button className="ms-3" onClick={() => navigate("/manual-detection/report")}>
                                    Report <ArrowForwardIcon/>
                                </Button>
                            </Box>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between flex-wrap">
                        <SummaryText>Total Weight: {totalWeight || 0}kg</SummaryText>
                        <SummaryText>{detectionTotalCount} Manual Detection(s) found!</SummaryText>
                    </div>
                        
                    <CardsContainer className="hide-scroll-bar">
                        {
                            detectionList?.length !== 0 && 
                            detectionList?.map((detection: ManualDetectionData) => (
                                <ManualDetectionCard 
                                    key={detection.id} 
                                    manualDetection={detection}
                                    onClickDelete={handleClickDeleteBtn}
                                    onClickEdit={handleClickEditBtn}
                                />
                            ))
                        }
                    </CardsContainer>
                    <LoadingModal 
                        show={isLoading}
                    />
                    <PaginationContainer>
                        <Pagination 
                            count={pageCount} 
                            onChange={handleChangePage} 
                            page={tablePage} 
                            variant="outlined" 
                            shape="rounded" size="large" 
                            disabled={isLoading}
                        />
                    </PaginationContainer>
                </Container>
                <AddModal 
                    show={showAddModal}
                    toggleShow={() => {
                        setShowAddModal(!showAddModal)
                    }}
                    manualDetection={currentDetection}
                    onChange={handleChangeCurrentManualDetection}
                    onUploadImage={handleUploadImage}
                    onAdd={addManualDetection}
                />
                <EditModal 
                    show={showEditModal}
                    toggleShow={() => {
                        setShowEditModal(!showEditModal)
                    }}
                    manualDetection={currentDetection}
                    onChange={handleChangeCurrentManualDetection}
                    onUploadImage={handleUploadImage}
                    onEdit={updatedManualDetection}
                />
                <DeleteModal 
                    manualDetection={currentDetection}
                    show={showDeleteModal}
                    toggleShow={() => setShowDeleteModal(!showDeleteModal)}
                    onDelete={deleteManualDetection}
                />
                <SuccessToastify 
                    text={successText}
                />
                <ErrorToastify 
                    text={errorText}
                />
            </Layout>
        </Box>
    )
}
 
export default ManualDetection;