import MenuOpenIcon from '@mui/icons-material/MenuOpenRounded';
import MenuIcon from '@mui/icons-material/MenuRounded';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Alert, Box, Button, Menu, MenuItem } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import React, { useContext, useEffect, useState } from "react";
import { FONT_SIZE_40 } from "../../../constants/DesignContant";
import { ROLES, STATUS_CODES } from "../../../constants/GlobalConstants";
import { MaintenanceContext, MaintenanceContextType } from "../../../contexts/MaintenanceContext";
import { UserAuthContext } from "../../../contexts/UserAuthContext";
import { maintenanceConfigGetFirstRowApi } from "../../../services/RouteServices/ConfigApi";
import { NodeProps } from "../../../types/CommonType";
import { RolesData } from "../../../types/RoleType";
import { DefaultUserInfo, UserAuthType, UserInfo } from "../../../types/UserAuth";
import { getUserPermissions, isAdminUser, isRootAndSuperUsers, isSuperSpecialUser, isTenantUser } from "../../../utils/UserUtil";
import LogoImg from './../../../assets/logo/env-logo.png';
import LogoutModal from "./LogoutModal";
import {
  Image,
  Logo,
  LogoContainer,
  LogoLink,
  Nav,
  NavItem,
  NavItemContainer,
  NavItemList,
  NavbarContainer,
  Title
} from "./NavbarElements";
import { IoLogOut } from "react-icons/io5";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#2C3333',
    color: "white",
    maxWidth: 220,
    // fontSize: theme.typography.pxToRem(30),
    border: '1px solid #dadde9',
    padding: "10px",
    borderRadius: "10px"
  },
}));

const Navbar = ({toggleSidebar, toggleMenu, marginLeft }: NodeProps) => {

  const userPermissions: RolesData = getUserPermissions();
  const {isMaintenance} = useContext(MaintenanceContext) as MaintenanceContextType;

  const [userData, setUserData] = useState<UserInfo>(DefaultUserInfo);
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const [modalSuccessMsg, setModalSuccessMsg] = useState<string>("");
  const [modalErrorMsg, setModalErrorMsg] = useState<string>("");
  const [isUnderMaintenance, setIsUnderMaintenance] = useState<boolean>(false);
  const [maintenanceMessage, setMaintenanceMessage] = useState<string>("");

  const { userInfo, handleLogout, isAuth } = useContext(
    UserAuthContext
  ) as UserAuthType;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpened = Boolean(anchorEl);

  useEffect(() => {
      maintenanceConfigGetFirstRowApi()
      .then(res => {
          if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
            setIsUnderMaintenance(res?.data?.data?.isMaintenance);
            setMaintenanceMessage(res?.data?.data?.details);
          } else {
              console.log(res?.data?.message)
          }
      }).catch(error => {
          setErrorText(error?.response?.data?.message || error?.message)   
      })
  }, [isMaintenance])

  const avatarColor = () => {
    const color = isRootAndSuperUsers() ? "darkorange"
                : isSuperSpecialUser() ? "hotpink"
                : isAdminUser() ? "dodgerblue"
                : isTenantUser() ? "darkviolet"
                : "limegreen";
    return color;
  }

  const handleAvatarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }
  const handleAvatarClose = () => {
    setAnchorEl(null);
  }

  const handleClose = () => {
    setShowLogoutModal(false);
    setUserData(DefaultUserInfo);
  }

  const openLogoutModal = (userInfo: UserInfo) => {
    handleAvatarClose();
    setShowLogoutModal(true);
    setUserData(userInfo);
}

  return (
      <Nav showAlert={isUnderMaintenance}>
        <NavbarContainer> {/* Navbar Box */}
          <LogoContainer marginLeft={marginLeft}>
              <Logo>
                  <LogoLink to={"/"}>
                    <Image src={LogoImg} alt="logo" />
                  </LogoLink>
              </Logo>
              {toggleMenu ? (<MenuOpenIcon id="MenuIcon" onClick={toggleSidebar} />) : (<MenuIcon id="MenuIcon" onClick={toggleSidebar}/>)}
          </LogoContainer>
          <Box>
            <Title>
                Waste Digitalisation System
            </Title> 
            {
              isUnderMaintenance &&
              <Alert severity="warning">
                 {maintenanceMessage || "System will be under maintainance soon!"}
              </Alert>
            }
          </Box>
          <Box
            className="d-flex align-items-center"
          >
            {
              (userPermissions?.isAdmin || userInfo.roleId === ROLES.ADMIN_ID) &&
              <VerifiedUserIcon 
                className="text-success fs-4"
              />
            }
            {
              (userPermissions?.isOperator && !userPermissions?.isAdmin) &&
              <PersonPinIcon 
                className="text-warning fs-3"
              />
            }
            <NavItemContainer>
              {isAuth() ? (
                <>
                  <NavItemList>
                    <Button onClick={handleAvatarClick} 
                      variant="text" color="warning"
                      style={{textTransform: 'none', fontSize: FONT_SIZE_40}}
                    >
                      {/* {userInfo.userName} haha */}
                      <HtmlTooltip 
                        title={
                          <>
                            <Typography 
                              color="inherit"
                            >
                                user : {userInfo.userName}
                            </Typography>
                            <Typography 
                              color="inherit"
                            >
                                role : {userInfo.roleName}
                            </Typography>
                          </>
                        } 
                        placement="left-end"
                        sx={{
                          fontSize: "1rem"
                        }}
                      >
                        <Avatar
                          sx={{ 
                            bgcolor: avatarColor(),
                            width: 40,
                            height: 40
                          }}
                          alt={userInfo.userName}
                          src={userInfo.orgImage}
                        >
                        </Avatar>
                      </HtmlTooltip>
                    </Button>
                  </NavItemList>
                  <Menu
                    id="logoMenu"
                    open={isOpened}
                    anchorEl={anchorEl}
                    onClose={handleAvatarClose}
                    MenuListProps={{
                      'aria-labelledby' : 'basic-button'
                    }}
                  >
                    <MenuItem>
                      <PersonPinIcon color="info"/>&nbsp;
                        {userInfo.userName}
                    </MenuItem>
                    <MenuItem>
                      <VerifiedUserIcon color="secondary"/>&nbsp;
                        {userInfo.roleName}
                    </MenuItem>
                    <hr/>
                    <MenuItem onClick={() => openLogoutModal(userInfo)} style={{color: 'red'}}>
                      Logout
                      <IoLogOut className='ms-2 fs-4' />
                    </MenuItem>
                  </Menu>
                </>              
              ) : (
                <NavItemList>
                  <NavItem to={"/login"}>Login</NavItem>
                </NavItemList>
              )}

              <LogoutModal 
                show={showLogoutModal}
                onClose={handleClose}
                data={userData}
                successText={modalSuccessMsg}
                errorText={modalErrorMsg}
                handleLogout={handleLogout}
              /> 
            </NavItemContainer>
          </Box>
        </NavbarContainer>
      </Nav>
  );
};

export default Navbar;
