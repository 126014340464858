import React, {useState, useEffect} from 'react';
import { SettingContainer,
    SettingText,
    SettingTextButton,
    SettingTextButtonContainer 
} from './SettingElement';
import "./Setting.css";
import { color_lightgreen, color_warn } from '../../constants/DesignContant';

const TenantSetting = ({
    tenantName
}: any) => {

    return ( 
        <SettingContainer>
            <SettingTextButton>
                <SettingTextButtonContainer>
                    <SettingText
                        style={{color: tenantName? color_lightgreen : color_warn}}
                    >
                        {tenantName? `Belong to [${tenantName}] tenant.` : "No tenant assign yet."}
                    </SettingText>
                </SettingTextButtonContainer>
            </SettingTextButton>
        </SettingContainer>
    );
}
 
export default TenantSetting;