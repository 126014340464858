import 'bootstrap/dist/css/bootstrap.min.css';
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import '../../styling/customStyle.css';
import Layout from "../Layout/Layout";

import { PAGE_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";
import { getAllOrgsList } from '../../services/RouteServices/CommonApi';
import { locationAddDataApi, locationDeleteDataApi, locationGetDataApi, locationRemoveImageDataApi, locationUpdateDataApi } from "../../services/RouteServices/LocationsApi";
import { LocationListResponseBody, LocationResponseBody, LocationsData, locationDefault } from "../../types/LocationsType";
import { OrganizationsData } from "../../types/OrganizationsType";

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Avatar } from '@mui/material';
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { Button } from "react-bootstrap";
import { IoLocationSharp } from "react-icons/io5";
import { ImageListType } from "react-images-uploading";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import { FilterValue } from "../../types/global/FilterValueType";
import { convertToLocaleDate } from '../../utils/DateTimeUtil';
import { getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import { getCurrentLocalUser, isRootAndSuperUsers } from "../../utils/UserUtil";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title } from "../Shared/Common/Titles";
import DeleteIconButton from "../Shared/DeleteIconButton/DeleteIconButton";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import LocationFilterContainer from "./LocationFilterContainer";
import './Locations.css';
import LocationAddModal from "./components/LocationAddModal";
import LocationUpdateModal from './components/LocationUpdateModal';
import { FcRemoveImage } from 'react-icons/fc';
import RemoveImageModal from './components/RemoveImageModal';
 
const Locations: React.FC = () => {
    const tableLimit = PAGE_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    const [errorText, setErrorText] = useState("");
    const [modalErrorText, setModalErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const [tablePage, setTablePage] = React.useState(1);
    const [tableOffset, setTableOffset] = React.useState(0);
    const [locationTotalCount, setlocationTotalCount] = React.useState(0);
    
    const [locationsList, setLocationsList] = useState<LocationsData[]>([]); // * Original data object from db
    const [orgList, setOrgList] = useState<OrganizationsData[]>([]);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showRemoveImageModal, setShowRemoveImageModal] = useState<boolean>(false);
    const [locationFormData, setLocationFormData] = useState<LocationsData>(locationDefault);
    const [filterValues, setFilterValues] = useState<FilterValue>(getDefaultFilterValue())
    const [images, setImages] = React.useState<ImageListType>([]);

    const pageCount = (Math.ceil(locationTotalCount/tableLimit)) || 0

    useEffect(() => {
        getAllOrgsList()
        .then(res => {
            setOrgList(res)
        })
        .catch(error => {
            setErrorText(error)
        })
    }, [])

    useEffect(() => {
        if (hasPermission("viewLocation")) {
            getManageLocationsApiHandler(tableLimit, tableOffset);
        }
    }, [tableOffset, filterValues]);

    const showAlertAndRefresh = (resData: LocationResponseBody, action: string) => {
        if (resData?.status === STATUS_CODES.SUCCESS_CODE){
            hideModals()
            setErrorText("")
            setModalErrorText("")
            setSuccessText(resData?.message)
            setTimeout(() => {
                setSuccessText("")
            }, 2000)
            getManageLocationsApiHandler(tableLimit, tableOffset);   
        }
        else{
            if (['add', 'update'].includes(action)) {
                setErrorText("")
                setModalErrorText(resData?.message)
            } else {
                setModalErrorText("")
                setErrorText(resData?.message)
            }
        }
    }

    const openAddModal = () => {
        setShowAddModal(true)
    }

    const openEditModal = (location: LocationsData) => {
        setShowUpdateModal(true)
    }

    const openRemoveImageModal = (location: LocationsData) => {
        setLocationFormData(location)
        setShowRemoveImageModal(true)
    }

    const hideModals = () => {
        setShowAddModal(false)
        setShowUpdateModal(false)
    }

    const handleChangeLocationImages = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
        // data for submit
        setImages(imageList);
    };

    const handleChangeFilterValues = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterValues({
            ...filterValues,
            [event.target.name]: event.target.value
        })
    }

    const handleChangeLocationFormData = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target
        setLocationFormData(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleAddLocation = () => {
        const inputObj = {
            organizationId: locationFormData.organizationId,
            locationName: locationFormData.locationName,
            locationImage: images[0]?.dataURL || "",
            remark: locationFormData.remark,
            createdBy: userInfo.userName
        }
        locationAddDataApi(inputObj)
        .then(res => {
            showAlertAndRefresh(res?.data, "add")
        })
        .catch(error => {
            setErrorText(error.message)
        })
    }

    const handleEditLocation = () => {
        // const originalLocations = locationsList
        // setLocationsList(locationsList.map(location => {
        //     return location.id === locationFormData.id ? locationFormData : location
        // }))
        const inputData = {
            id: locationFormData.id,
            organizationId: locationFormData.organizationId,
            locationName: locationFormData.locationName,
            locationImage: images[0]?.dataURL || "",
            remark: locationFormData.remark || "",
            updatedBy: userInfo.userName
        }
        locationUpdateDataApi(inputData)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                showAlertAndRefresh(res?.data, "update")
            } else {
                setModalErrorText(res?.data?.message)
                // setLocationsList(originalLocations)
            }
        })
        .catch(error => {
            setModalErrorText(error.message)
            // setLocationsList(originalLocations)
        })
    }

    const handleDeleteLocation = (id: number) => {
        const inputData = {
            id: id,
            deletedBy: userInfo.userName,
        }
        locationDeleteDataApi(inputData)
        .then(res => {
            showAlertAndRefresh(res?.data, "delete")
        })
        .catch(error => {
            setErrorText(error.message)
        })
    }

    const removeLocationImage = () => {
        const inputData = {
            id: locationFormData.id,
            organizationId: locationFormData.organizationId,
            updatedBy: userInfo.userName
        }
        locationRemoveImageDataApi(inputData)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                showAlertAndRefresh(res?.data, "update")
            } else {
                setModalErrorText(res?.data?.message)
                // setLocationsList(originalLocations)
            }
        })
        .catch(error => {
            setModalErrorText(error.message)
            // setLocationsList(originalLocations)
        })
    }

    const getManageLocationsApiHandler = (pageLimit : number, pageOffset : number) => {
        const dataLimitsObject = {
            searchText: filterValues.search || "",
            organizationId: filterValues.organizationId,
            limit: pageLimit,
            offset: pageOffset
        }
        
        locationGetDataApi(dataLimitsObject)
        .then(response => {
            const resData: LocationListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: LocationsData[] = resData.data?.locationList
                const totalCount : number = resData?.data?.totalCount;
                setLocationsList(dataList);
                setlocationTotalCount(totalCount)
                // for not showing empty page when delete last item
                if (totalCount != 0 && dataList.length == 0) {
                    setTableOffset(prev => prev != 0 ? prev - 10 : prev)
                    setTablePage(prev => prev != 1 ? prev -1 : prev)
                }
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }   

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };
    // * Render Table Data
    const renderTable = () => {
        return (
            locationsList?.length !== 0 ? locationsList?.map((location: LocationsData) =>          
                    <tr key={location.id}>
                        <td>{location?.id}</td>
                        <td>
                            <div style={{display: "flex", alignItems: "center", gap: 10}}>
                                <Avatar
                                    alt="Location Image"
                                    src={location.locationImage}
                                    sx={{ width: 45, height: 45, }}
                                    children={<IoLocationSharp style={{fontSize: "20px"}} />}
                                />
                                {location.locationName || "-"}
                            </div>
                        </td>
                        <td>{location.organizationName}</td>
                        <td>
                            {
                                (location.machineNames && location.machineNames.length > 0) ?   
                                    location.machineNames.map((machineName, index) => (
                                        <p className="m-0" key={index}>{machineName},</p>
                                    )) : "-"
                            }
                        </td>
                        <td>{location.remark || "-"}</td>
                        <td>{location.createdBy || "-"}</td>
                        <td>{location.updatedBy || "-"}</td>
                        <td>{convertToLocaleDate(location.createdAt!) || "-"}</td>
                        <td>{convertToLocaleDate(location.updatedAt!) || "-"}</td>
                        <td>
                            <EditOutlinedIcon 
                                sx={{
                                    color: "blue",
                                }}
                                className="pointer"
                                onClick={() => {
                                    openEditModal(location); setModalErrorText(""); 
                                    setLocationFormData(location);
                                    setImages([])
                                }}
                            />
                            &nbsp;&nbsp;
                            {
                                isRootAndSuperUsers() &&
                                <DeleteIconButton 
                                    deleteName={location.locationName}
                                    deleteObject="Location"
                                    onOpen={() => console.log("Click delete")}
                                    onDelete={() => handleDeleteLocation(location.id)}
                                />
                            }
                            <FcRemoveImage 
                                style={{
                                    fontSize: "23px"
                                }}
                                className="pointer"
                                onClick={() => openRemoveImageModal(location)}
                            />
                        </td>
                    </tr>
            ): 
            <tr>
                <td>No data found</td>
            </tr>
        )
    }

    if (!hasPermission("viewLocation")) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (
        <>
            <Layout>
                <Container>
                    <Box className="d-flex justify-content-between mb-2">
                        <Title>Locations</Title>
                        <Box className="titleBox">
                            <Button className="pt-2 pb-2 ps-3 pe-3" id="btnAddUser" 
                                variant="success" 
                                onClick={() => {
                                    openAddModal(); setModalErrorText(""); 
                                    setLocationFormData(locationDefault);
                                    setImages([])
                                }}
                            >
                                Add
                            </Button>
                        </Box>
                    </Box>
                    {successText && <Alert severity="success" sx={{marginBottom: 2}}>{successText}<br/></Alert>}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}

                    <LocationFilterContainer 
                        filterValues={filterValues}
                        onChangeFilter={handleChangeFilterValues}
                    />
                    
                    <SummaryText>{locationTotalCount} Locations found!</SummaryText>
                    <TableContainer>
                    <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Organization</th>
                                    <th>Machines</th>
                                    <th>Remark</th>
                                    <th>Created By</th>
                                    <th>Updated By</th>
                                    <th>Created At</th>
                                    <th>Updated At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {
                                    renderTable()
                                }
                            </ tbody>
                        </table>
                    </InnterTableContainer>
                    </TableContainer>
                    <PaginationContainer>
                        <Pagination count={pageCount} onChange={handleChangePage} page={tablePage} variant="outlined" shape="rounded" size="large" />
                    </PaginationContainer>

                    <LocationAddModal 
                        show={showAddModal}
                        error={modalErrorText}
                        onAdd={handleAddLocation} 
                        location={locationFormData}
                        locationImages={images}
                        orgList={orgList} 
                        onClose={hideModals}
                        onChange={handleChangeLocationFormData}
                        onChangeImage={handleChangeLocationImages}
                    />

                    <LocationUpdateModal 
                        show={showUpdateModal}
                        error={modalErrorText}
                        onUpdate={handleEditLocation} 
                        location={locationFormData}
                        locationImages={images}
                        orgList={orgList} 
                        onClose={hideModals}
                        onChange={handleChangeLocationFormData}
                        onChangeImage={handleChangeLocationImages}
                    />

                    <RemoveImageModal 
                        targetLocation={locationFormData}
                        show={showRemoveImageModal}
                        toggleShow={() => setShowRemoveImageModal(!showRemoveImageModal)}
                        onDelete={removeLocationImage}
                    />
                </Container>
            </Layout>
        </>
    );
};

export default Locations;