import { Alert } from "@mui/material";
import { Button, Modal } from "react-bootstrap";

const RejectModal = ({
    show,
    onReject,
    onClose,
    errorText
}: any) => {
    return (  
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Reject Confirmation</Modal.Title>
            </Modal.Header>
            {errorText && <Alert severity="error">{errorText}<br/></Alert>}
            <Modal.Body>
                <p>Are you sure you want to Reject?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Cancel</Button>
                <Button variant="danger" onClick={
                        () => {
                            onReject(); 
                }}>Reject</Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default RejectModal;