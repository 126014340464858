import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { getCurrentLocaleDate, getMonthName } from "../../../utils/DateTimeUtil";
import logoImage from '../../../assets/logo/env-logo.png';
import { FilterValue } from "./ReportDetails";

const customDateSort = (a: string, b: string): number => {
  // Skip the 'name' entry
  if (a === 'Name' || b === 'Name') {
    return a === 'Name' ? -1 : 1;
  }

  const dateA = new Date(a);
  const dateB = new Date(b);

  return dateA.getTime() - dateB.getTime();
};

export const generatePDF = (dataList: any[], filterNames: any) => {
    const doc = new jsPDF('landscape', 'mm', 'a4');

    const headers = [
        "Tenants", "Monthly Data"
    ];
    const dataKeys = [
        "tenant", "weightAmount"
    ];

    const columnWidths = [100, 50];
    // Generate the columnStyles object dynamically
    const columnStyles: any = {};
    for (let i = 0; i < headers.length; i++) {
      columnStyles[dataKeys[i]] = { cellWidth: columnWidths[i] };
    }

    autoTable(doc, {
        head: [headers],
        body: dataList,
        columns: dataKeys.map((key) => ({ dataKey: key })),
        columnStyles: columnStyles,
        margin: { top: 25, left: 5},
        didParseCell: function (data) {
            data.cell.styles.fontSize = 12
            if (data.section === "head") {
              data.cell.styles.textColor = [255, 255, 255];
              data.cell.styles.fillColor = [0, 0, 0];
            }
        },
        didDrawPage: function (data) {
            doc.setFontSize(18);
            doc.text("Monthly Tenant Weight", data.settings.margin.left, 10);

            const logoWidth = 20;
            const logoHeight = 10;
            const logoX = data.settings.margin.left + 250;
            const logoY = 5;
            doc.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight)
            doc.setFontSize(13);
            doc.text(`Organization: ${filterNames.organization} | Machine: ${filterNames.machine} | Year: ${filterNames.year} | Month: ${filterNames.month}`, data.settings.margin.left, 20);
        }
    })
    doc.save(`reports-details-${getCurrentLocaleDate()}.pdf`)
}

const addAnnotations = (
  doc: jsPDF, machine: string, organization: string, 
  year: number, month: number, x = 20, y=40
) => {
  doc.setFontSize(15);
  doc.text(`Machine: ${machine}`, x, y);
  x += doc.getTextWidth(`Machine: ${machine}`) + 10; // Add padding

  doc.text(`Organization: ${organization}`, x, y);
  x += doc.getTextWidth(`Organization: ${organization}`) + 10;

  doc.text(`Year: ${year}`, x, y);
  x += doc.getTextWidth(`Year: ${year}`) + 10;

  doc.text(`Month: ${getMonthName(month)}`, x, y);
}

const addTable = (
  doc: jsPDF, type: string, tableHeaders: string[], tableDataKeys: string[],
  dataList: any[],
  machine: string, organization: string, year: number, month: number,
  x: number, y: number
) => {

  autoTable(doc, {
    head: [tableHeaders],
    body: dataList,
    columns: tableDataKeys.map((key) => ({ dataKey: key })),
    margin: { top: x, left: y},
    didParseCell: function (data) {
        data.cell.styles.fontSize = 12.5
        if (data.section === "head") {
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fillColor = [0, 0, 0];
        }

        if (data.cell.raw === null || data.cell.raw === "" || data.cell.raw === undefined) {
          data.cell.text = ["0"];
        }
    },
    didDrawPage: function (data) {
        doc.setFontSize(18);
        doc.text(type, 10, 10);

        const logoWidth = 20;
        const logoHeight = 10;
        const logoX = 130 + 250;
        const logoY = 5;
        doc.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);

        addAnnotations(doc, machine, organization, year, month)
    }
  })

}

const addChart = (
  doc: jsPDF, totalValue: number, chartData: any[], type: "weight" | "open" | "scan"
) => {
  doc.setFontSize(18);
  if (type === "weight") {
    doc.text(`Total Weight: ${totalValue}kg`, 20, 30);
  } else if (type === "open") {
    doc.text(`Total Door Open: ${totalValue}`, 20, 30);
  } else if (type === "scan") {
    doc.text(`Total Door Scan: ${totalValue}`, 20, 30);
  }
 
  const chartX = 30;
  const chartY = 80;
  const chartWidth = 390;
  const chartHeight = 150;

  // Calculate the maximum value to scale the chart
  const maxScanDataValue = Math.max(...chartData.map(item => item.value));
  const scanScaleFactor = maxScanDataValue != 0 ? chartHeight / maxScanDataValue : 0;

  // Y-axis label
  doc.setFontSize(15);
  if (type === "weight") {
    doc.text('Weight (kg)', chartX - 23, chartY + chartHeight / 2, undefined, 90);
  } else {
    doc.text('Count', chartX - 23, chartY + chartHeight / 2, undefined, 90);
  }

  // X-axis label
  doc.text('Date', chartX + chartWidth / 2, chartY + chartHeight + 20);

  // Add Y-axis values and tick marks
  for (let i = 0; i <= maxScanDataValue; i += 10) {
    const y = chartY + chartHeight - i * scanScaleFactor;
    doc.text(i.toString(), chartX - 18, y + 3);
    doc.setDrawColor(100, 100, 100);
    doc.line(chartX - 8, y, chartX - 3, y);
  }

  // Draw the bars on the chart
  chartData.forEach((item, index) => {
    const barX = chartX + index * (chartWidth / chartData.length);
    const barY = chartY + chartHeight - item.value * scanScaleFactor;
    const barWidth = chartWidth / chartData.length - 2;
    const barHeight = item.value * scanScaleFactor;

    if (type === "weight") {
      doc.setFillColor(209, 77, 114); // Set the bar color
    } else if (type === "open") {
      doc.setFillColor(25, 167, 206); // Set the bar color
    } else if (type === "scan") {
      doc.setFillColor(255, 184, 76); // Set the bar color
    }
    doc.rect(barX, barY, barWidth, barHeight, 'F'); // Draw the bar
    doc.text(item.label, barX, chartY + chartHeight + 10);

    if (item.value > 0) {
      doc.text(
        item.value.toString(),
        barX + barWidth / 2, // Adjust the position as needed
        barY - 5, // Adjust the position as needed,
        undefined,
        90
      );
    }
  });
}

export const generateSpecialPDF = async (
  dailyDataList: any[], monthlyDataList: any[], weightChartData: any[],
  doorOpenChartData: any[], doorScanChartData: any[],
  filterValues: FilterValue, machine: string, organization: string
) => {
  const doc = new jsPDF('landscape', 'mm', 'a3');

  const table1Headers = [
      "TENANTS", "MONTHLY DATA"
  ];
  const table1DataKeys = [
      "tenant", "weightAmount"
  ];

  addTable(
    doc, "Monthly Data", table1Headers, table1DataKeys, monthlyDataList,
    machine, organization, filterValues.year, filterValues.month,
    65, 50
  )

  doc.addPage();

  let table2Columns = Object.keys(dailyDataList[0]).sort(customDateSort)
  const table2Headers = table2Columns.slice(0, table2Columns.length)
                        .map(column => column.split("-")[2])
  const table2DataKeys = table2Columns

  addTable(
    doc, "Daily Data", table2Headers, table2DataKeys, dailyDataList,
    machine, organization, filterValues.year, filterValues.month,
    55, 3.5
  )

  doc.addPage();

  addAnnotations(doc, machine, organization, filterValues.year, filterValues.month, 20, 15)
  addChart(doc, monthlyDataList[0]?.weightAmount, weightChartData, "weight")

  doc.addPage();

  addAnnotations(doc, machine, organization, filterValues.year, filterValues.month, 20, 15)
  addChart(doc, monthlyDataList[1]?.weightAmount, doorOpenChartData, "open")

  doc.addPage();

  addAnnotations(doc, machine, organization, filterValues.year, filterValues.month, 20, 15)
  addChart(doc, monthlyDataList[2]?.weightAmount, doorScanChartData, "scan")

  doc.save(`special-reports-details-${getCurrentLocaleDate()}.pdf`)
}