import { HardwareDELETERequestBody, HardwareGETRequestBody, HardwareUPDATERequestBody } from "../../types/HardwareType";
import http from "../apiServices";

export const hardwareGetDataApi = (inputData : HardwareGETRequestBody) => {
    return http.post("/hardware/get/list", inputData)
}

export const updateUserAssignedName = (inputData: HardwareUPDATERequestBody) => {
    return http.put("/hardware/update", inputData)
}

export const deleteHardware = (inputData: HardwareDELETERequestBody) => {
    return http.post("/hardware/delete", inputData)
}