import { DischargeWeightLogGETRequestBody } from "../../types/DischargeWeightLogType";
import http from "../apiServices";

export const dischargeWeightLogGetListByOrgIdApi = (inputData: DischargeWeightLogGETRequestBody) => {
    return http.post("discharge-weight-log/get/list/by/orgId", inputData)
}

export const dischargeWeightLogGetListByUserIdApi = (inputData: DischargeWeightLogGETRequestBody) => {
    return http.post("discharge-weight-log/get/list/by/userId", inputData)
}

export const dischargeWeightLogGetExportListByOrgId = (inputData: DischargeWeightLogGETRequestBody) => {
    return http.post("discharge-weight-log/get/export-list/by/orgId", inputData)
}

export const dischargeWeightLogGetExportListByUserId = (inputData: DischargeWeightLogGETRequestBody) => {
    return http.post("discharge-weight-log/get/export-list/by/userId", inputData)
}