import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Alert from "@mui/material/Alert"
import Button from "react-bootstrap/Button";

const AddModal = ({
    show,
    successMsg,
    errorMsg,
    handleClose,
    handleConfirmAdd
}: any) => {
    return ( 
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Add Confirmation</Modal.Title>
            </Modal.Header>
            {successMsg && <Alert severity="success">{successMsg}<br/></Alert>}
            {errorMsg && <Alert severity="error">{errorMsg}<br/></Alert>}
            <Modal.Body>
                <p>Are you sure you want to Add new Card?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="success" onClick={handleConfirmAdd}>Add</Button>
            </Modal.Footer>
        </Modal> 
    );
}

export default AddModal;