import React, { ChangeEvent, ReactEventHandler, useContext, useEffect, useState } from "react";
import moment from "moment";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import XLSX from "xlsx";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styling/customStyle.css';
import Layout from "../Layout/Layout";

import { NodeProps } from "../../types/CommonType";
import { Box, styled } from "@mui/material";
import Alert from '@mui/material/Alert';
import Pagination from "@mui/material/Pagination";

import { LOG_LEVEL, PAGE_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";
import { LFCDataTable, LfcCorrectionLog, ResponseExportLFCDataApiDataType, ResponseLFCData, ResponseLFCDataApiDataType, ResponseLFCDataApiType } from "../../types/LFCDataType";
import { convertDateFormat, convertToLocaleDate, getCurrentLocaleDate } from "../../utils/DateTimeUtil";

import { addLfcCorrectionApi, lfcDatagetDataApi, lfcGetExportDataApi } from "../../services/RouteServices/LFCDataApi";
import LFCDataFilterContainer from "./LFCDataFilterContainer";
import ExportButton from "../Shared/Export/Export";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title, TitleH5 } from "../Shared/Common/Titles";
import { DateFilterValue, FilterValue } from "../../types/global/FilterValueType";
import { getDefaultDateFilterValue, getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import { getCurrentLocalUser, isAdminUser, isRootAndSuperUsers, isSuperSpecialUser } from "../../utils/UserUtil";
import { Button } from "react-bootstrap";
import DateCorrectionModal from "./DateCorrectionModal";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import { LFC_CORRECTION_LOG } from "../../constants/LfcContants";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import { addEventLog } from "../../utils/EventLogUtil";
import { addNormalLog } from "../../utils/LoggerUtil";

const loadLfcDataExportList = (dataList: LFCDataTable[]) => {
    if (!dataList) return []
    return dataList.map(data => {
        return {
            Id: data.id,
            "Organization": data.organizationName,
            "Location": data.locationName,
            "Machine": data.machineName,
            "LFC Date": convertToLocaleDate(data.lfcTime),
            "Operational Logs": data.lfcOperationalLog,
            "Remark": data.remark || "-",
        }
    })
}

const LFCData: React.FC<NodeProps> = () => {
    const userInfo: UserInfo = getCurrentLocalUser();
    const defaultLfcCorrection = {
        correctionDate: "",
        orgId: userInfo.orgId,
        machineId: 0
    }
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    const [lfcDataList, setLFCDataList] = useState<LFCDataTable[]>(); // * Original data object from db
    const [totalDataCount, setTotalDataCount] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [exportLfcDataList, setExportLfcDataList] = useState<LFCDataTable[]>();

    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");
    const [modalErrorText, setModalErrorText] = useState("")

    const [showDateCorrectionModal, setShowDateCorrectionModal] = useState<boolean>(false)
    
    const [exportFileType, setExportFileType] = useState("");

    const [currentPage, setCurrentPage] = React.useState(1);
    const [tableOffset, setTableOffset] = React.useState(0);
    const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>(getDefaultDateFilterValue())
    const [filterValue, setFilterValue] = useState<FilterValue>(getDefaultFilterValue())
    const [lfcCorrectionLogData, setLfcCorrectionLogData] = useState<LfcCorrectionLog>(defaultLfcCorrection)

    const tableLimit = PAGE_LIMIT;

    useEffect(() => {
        setCurrentPage(1)
        setTableOffset(0)
    }, [filterValue, dateFilterValue])

    useEffect(() => {
        if (hasPermission("viewLfcData")) {
            getLFCDataApiHandler(tableLimit, tableOffset);
        }
    }, [tableOffset, dateFilterValue, filterValue]);

    useEffect(() => {
        if (exportFileType === "pdf" && exportLfcDataList?.length !== 0  && exportLfcDataList) {
            generatePDF(exportLfcDataList)
        }
    }, [exportLfcDataList])

    useEffect(() => {
        if (currentPage > pageTotal && pageTotal !== 0) {
            setCurrentPage(pageTotal)
            const offsetValue = (tableLimit * (pageTotal - 1));
            setTableOffset(offsetValue);
        }
    }, [pageTotal])

    useEffect(() => {
        setFilterValue({...filterValue, machineId: 0})
    }, [filterValue.organizationId])

    useEffect(() => {
        setLfcCorrectionLogData({...lfcCorrectionLogData, machineId: 0})
    }, [lfcCorrectionLogData.orgId])

    const generatePDF = (dataList: any) => {
        const doc = new jsPDF('landscape', 'mm', 'a4');
      
        // Define the table headers and data keys
        const headers = [
            "Id","Organization", "Machine",
            "Location","LFC Date","Operational Logs","Remark"
        ];
        const dataKeys = [
            "id", "organizationName", "machineName",
            "locationName","lfcTime","lfcOperationalLog","remark"
        ];
      
        // Set the width of each column in millimeters
        const columnWidths = [15, 35, 35, 35, 25, 65, 50];
      
        // Generate the columnStyles object dynamically
        const columnStyles: any = {};
        for (let i = 0; i < headers.length; i++) {
          columnStyles[dataKeys[i]] = { cellWidth: columnWidths[i] };
        }
      
        autoTable(doc, {
          head: [headers],
          body: dataList,
          columns: dataKeys.map((key) => ({ dataKey: key })),
          columnStyles: columnStyles,
          margin: { top: 20, left: 15 }, // Set the left margin to zero
          didParseCell: function (data) {
            if (data.section === "head") {
              data.cell.styles.fontSize = 9;
              data.cell.styles.textColor = [255, 255, 255];
              data.cell.styles.fillColor = [0, 0, 0];
            }
          },
          didDrawPage: function (data) {
            doc.setFontSize(18);
            doc.text("LFC Data", data.settings.margin.left, 10);
          }
        });
      
        doc.save(`lfcLogs-${getCurrentLocaleDate()}.pdf`);
        setExportFileType("");
        setExportLfcDataList([]);
    };

    const handleChangeDateFilterValue = (key: "startDate" | "endDate", value: Date) => {
        const day = value.getDate().toString().padStart(2, '0');
        const month = (value.getMonth() + 1).toString().padStart(2, '0');
        const year = value.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setDateFilterValue({...dateFilterValue, [key]: formattedDate})
    }

    const handleChangeFilterValue = (event: ChangeEvent<HTMLSelectElement>) => {
        setFilterValue({...filterValue, [event.target.name]: event.target.value})
    }

    const handleShowDateCorrectionModal = () => {
        setShowDateCorrectionModal(!showDateCorrectionModal)
        setLfcCorrectionLogData(defaultLfcCorrection)
        setModalErrorText("")
    }

    const handleChangeCorrectionDate = (value: Date) => {
        const day = value.getDate().toString().padStart(2, '0');
        const month = (value.getMonth() + 1).toString().padStart(2, '0');
        const year = value.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setLfcCorrectionLogData({...lfcCorrectionLogData, correctionDate: formattedDate})
    }

    const handleChangeLfcCorrectionLog = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setLfcCorrectionLogData({
            ...lfcCorrectionLogData,
            [event.target.name]: event.target.value
        })
    }

    const handleAddLfcCorrectionLog = () => {
        const input = {
            ...lfcCorrectionLogData,
            correctionDate: convertDateFormat(lfcCorrectionLogData.correctionDate),
            lfcLog: LFC_CORRECTION_LOG,
            createdBy: userInfo.userName,
        }
        addLfcCorrectionApi(input)
        .then(res => {
            const response = res?.data
            if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                getLFCDataApiHandler(tableLimit, tableOffset);
                setShowDateCorrectionModal(false)
                setSuccessText(response?.message)
                setTimeout(() => {
                    setSuccessText("")
                }, 2000)
            } else {
                setModalErrorText(response?.message)
            }
        }).catch(error => {
            setModalErrorText(error?.message)
        })
    }

    const exportToCSV = async () => { 
        let exportedData = [] 
        if (isSuperSpecialUser()) {
            exportedData = await getExportLfcData()
        }
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(loadLfcDataExportList(exportedData));
        XLSX.utils.book_append_sheet(workbook, worksheet, "Overall");
        XLSX.writeFile(workbook, `lfcLogs-${getCurrentLocaleDate()}.xlsx`);
        const log: string = `${userInfo.userName} has downloaded lfcLogs-${getCurrentLocaleDate()}.xlsx`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    };

    const exportToPdf = async () => {
        let exportedData = []
        if (isSuperSpecialUser()) {
            exportedData = await getExportLfcData()
        }
        const dataList = exportedData ? exportedData.map((data: LFCDataTable) => ({...data, lfcTime: convertToLocaleDate(data.lfcTime)})) : []
        generatePDF(dataList)
        const log: string = `${userInfo.userName} has downloaded lfcLogs-${getCurrentLocaleDate()}.pdf`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    }

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setCurrentPage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };
    
    const getLFCDataApiHandler = async (pageLimit : number, pageOffset : number) => {
        const dataLimitsObject = {
            searchText: filterValue.search || "",
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            orgId: +filterValue.organizationId!,
            machineId: +filterValue.machineId!,
            limit: pageLimit,
            offset: pageOffset
        }

        await lfcDatagetDataApi(dataLimitsObject)
        .then((res: ResponseLFCDataApiType) => {
            const resApiData: ResponseLFCDataApiDataType = res?.data;
            if (resApiData?.status === STATUS_CODES.SUCCESS_CODE) {
                const responseData : ResponseLFCData = resApiData?.data;

                const lfcDataList : LFCDataTable[] = responseData?.lfcList;
                setLFCDataList(lfcDataList);

                const totalCount : number = responseData.totalCount;
                setTotalDataCount(totalCount || 0);
                setPageTotal((Math.ceil(totalCount/tableLimit)) || 0);

                setErrorText(""); // Make sure error empty
            } 
            else {
                setErrorText(resApiData?.message)
            }
        })
        .catch((error) => {
            setErrorText(error?.response?.data?.message || error?.message); // set error text
        })
    }

    const getExportLfcData = (): Promise<any> => {
        const dataLimitsObject = {
            searchText: filterValue.search || "",
            orgId: filterValue.organizationId,
            machineId: filterValue.machineId || 0,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
        }

        return lfcGetExportDataApi(dataLimitsObject)
        .then(res => {
            const resApiData : ResponseExportLFCDataApiDataType = res?.data;
            return new Promise((resolve, reject) => {
                if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                    resolve(resApiData.data)
                else 
                    reject([])
            })   
        }).catch(error => {
            Promise.reject(error?.message)
        })
    }

    // * Render Table Data
    const renderTable = () => {
        return (
            (lfcDataList && lfcDataList?.length > 0) ? lfcDataList.map((lfcData : LFCDataTable) => {
                return (
                    <tr key={lfcData?.id}>
                        <td>{lfcData?.id}</td>
                        {/* <td>{lfcData?.organizationId || "-"}</td> */}
                        <td>{lfcData?.organizationName || "-"}</td>
                        {/* <td>{lfcData?.machineId || "-"}</td> */}
                        <td>{lfcData?.machineName || "-"}</td>
                        {/* <td>{lfcData?.locationId || "-"}</td> */}
                        <td>{lfcData?.locationName || "-"}</td>
                        <td>{convertToLocaleDate(lfcData?.lfcTime) || "-"}</td>
                        <td>{lfcData?.lfcOperationalLog || "-"}</td>
                        <td>{lfcData?.remark || "-"}</td>
                        <td>{lfcData?.createdBy || "-"}</td>
                        <td>{convertToLocaleDate(lfcData?.createdAt!) || "-"}</td>
                    </tr>
                )
            })
            :
            <tr>
                <td>No data found!</td>
            </tr>
        )
    }

    if (!hasPermission("viewLfcData")) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (
        <>
            <Layout>
                <Container>
                    <Title>LFC Data</Title>
                    <Box className="titleBox">
                        <Box sx={{
                            gap: 3,
                            display: "flex",
                            flexWrap: "wrap",
                        }}>
                            {
                                isRootAndSuperUsers() &&
                                <Button variant="info"
                                    onClick={handleShowDateCorrectionModal}
                                >
                                    Date Correction
                                </Button>
                            }
                            <ExportButton 
                                exportToCSV={exportToCSV}
                                exportToPdf={exportToPdf}
                            />
                        </Box>
                    </Box>
                    {successText && <Alert severity="success" sx={{marginBottom: 2}}>{successText}</Alert>}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}
                            
                    <LFCDataFilterContainer
                        onChangeDateFilter={handleChangeDateFilterValue}
                        onChangeFilter={handleChangeFilterValue}
                        dateFilterValue={dateFilterValue}
                        filterValue={filterValue}
                    />
                    <SummaryText>{totalDataCount} LFC Log found!</SummaryText>
                    <TableContainer>
                        <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    {/* <th>Organization Id</th> */}
                                    <th>Organization</th>
                                    {/* <th>Machine Id</th> */}
                                    <th>Machine</th>
                                    {/* <th>Location Id</th> */}
                                    <th>Location</th>
                                    <th>LFC Date</th>
                                    <th>Operational Logs</th>
                                    <th>Remarks</th>
                                    <th>Created By</th>
                                    <th>Created At</th>
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {renderTable()}
                            </tbody>
                        </table>
                        </InnterTableContainer>
                    </TableContainer>
                    <PaginationContainer>
                        <Pagination count={pageTotal} onChange={handleChangePage} page={currentPage} variant="outlined" shape="rounded" size="large" />
                    </PaginationContainer>
                </Container>
                <DateCorrectionModal 
                    show={showDateCorrectionModal}
                    onClose={handleShowDateCorrectionModal}
                    lfcCorrectionLogData={lfcCorrectionLogData}
                    onChangeCorrectionDate={handleChangeCorrectionDate}
                    onChangeLfcCorrectionLog={handleChangeLfcCorrectionLog}
                    onAddLfcCorrectionLog={handleAddLfcCorrectionLog}
                    errorText={modalErrorText}
                />
            </Layout>
        </>
    );
};

export default LFCData;