export type WeightLogData = {
    id?: number,
    organizationId: number,
    organizationName: string,
    machineId: number,
    machineName: string,
    locationId: number,
    locationName: string
    lfcWeight: number,
    lfcCard: string,
    cardId: number,
    syncAlready: number,
    tenantId: number,
    tenantName: string,
    lfcTime: string,
    lfcOperationalLog: string,
    remark: string,
    createdAt?: string,
    createdBy?: string,
}

export type WeightLogListResponseBody = {
    data: {
        weightLogList: WeightLogData[],
        totalCount: number,
        totalWeightAmount: number,
    }
    status: number,
    message: string,
}

export type WeightLogExportListResponseBody = {
    status: number;
    message: string;
    data: WeightLogData[]
}

export type WeightLogGETRequestBody = {
    orgId?: number,
    userId?: number,
    machineId?: number,
    tenantId?: number,
    startDate?: string,
    endDate?: string,
    limit?: number,
    offset?: number,
}

export const WeightLogDataDefault = {
    id: 0,
    organizationId: 0,
    organizationName: "",
    machineId: 0,
    machineName: "",
    locationId: 0,
    locationName: "",
    lfcWeight: 0,
    lfcCard: "",
    cardId: 0,
    syncAlready: 0,
    tenantId: 0,
    tenantName: "",
    lfcTime: "",
    lfcOperationalLog: "",
    remark: "",
}