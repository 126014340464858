import styled from "styled-components";
import { NodeProps } from "../../types/CommonType";

export const Container = styled.div<NodeProps>`
    background-color: #EFEFEF; // Change background color of body
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

export const ContainerItems = styled.div<NodeProps>`
    margin-left: ${(props) => props.marginValue ? props.marginValue : "20%"};
    transition: 0.5s;
    z-index: 999;
`;