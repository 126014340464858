import { Alert, Box, Pagination } from "@mui/material";
import Layout from "../Layout/Layout";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title } from "../Shared/Common/Titles";
import ReadMoreModal from "../Shared/ReadMoreModal/ReadMoreModal";
import LoadingModal from "../Shared/LoadingModal/LoadingModal";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import { getCurrentLocalUser, isSuperSpecialUser, isTenantUser } from "../../utils/UserUtil";
import { NO_IMAGE_URL, PAGE_WITH_IMG_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";
import ModalImage from "react-modal-image";
import { convertDateFormat, convertToLocaleDate, getCurrentLocaleDate, getDayDifference } from "../../utils/DateTimeUtil";
import { DateFilterValue, FilterValue } from "../../types/global/FilterValueType";
import { getDefaultDateFilterValue, getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import WasteUploadFilterContainer from "./FilterContainer";
import { mergeImageUrlWithBaseUrl } from "../../utils/ImageUtil";
import { wasteUploadGetListByOrgIdApi, wasteUploadGetListByUserIdApi } from "../../services/RouteServices/WasteUploadApi";
import { WasteUploadData, WasteUploadListResponseBody, WasteUploadFilterValue, WasteUploadGETRequestBody } from "../../types/WasteUploadType";

const WasteUpload = () => {
    const tableLimit = PAGE_WITH_IMG_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    const [wasteUplaodList, setWasteUploadList] = useState<WasteUploadData[]>([]);
    const [detectionTotalCount, setDetectionTotalCount] = useState<number>(0);
    const [tableOffset, setTableOffset] = useState(0);
    const [tablePage, setTablePage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false)
    const [showReadMoreModal, setShowReadMoreModal] = useState(false)
    const [remark, setRemark] = useState("");
    const [filterValue, setFilterValue] = useState<WasteUploadFilterValue>({...getDefaultFilterValue(), type: "all"});
    const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>(getDefaultDateFilterValue())
    const [totalWeight, setTotalWeight] = useState<number | undefined>(0);
    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    useEffect(() => {
        setTablePage(1)
        setTableOffset(0)
    }, [filterValue, dateFilterValue])

    useEffect(() => {
        setErrorText("")
        setSuccessText("")
        if (hasPermission("viewWasteUpload")) {
            setIsLoading(true)
            getDetectionList()
        }
    }, [filterValue, dateFilterValue, tableOffset]);

    const getDetectionList = () => {
        if (isTenantUser()) {
            getWasteTypeListByUserId();
        } else {
            getWasteUploadListByOrgId();
        }
    }

    const getWasteUploadListByOrgId = () => {
        const dataLimitsObject: WasteUploadGETRequestBody = {
            type: filterValue.type,
            orgId: filterValue.organizationId!,
            machineId: filterValue.machineId!,
            tenantId: filterValue.tenantId!,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: tableLimit,
            offset: tableOffset,
        }
        wasteUploadGetListByOrgIdApi(dataLimitsObject)
        .then(response => {
            const resData: WasteUploadListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: WasteUploadData[] = resData.data?.wasteUploadList
                const totalCount : number = resData?.data?.totalCount;
                setDetectionTotalCount(totalCount)
                setTotalWeight(resData?.data?.totalWeight)
                setPageCount((Math.ceil(totalCount/tableLimit)) || 0);
                setWasteUploadList(dataList);
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
            setIsLoading(false)
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            setIsLoading(false)
        })
    } 

    const getWasteTypeListByUserId = () => {
        const dataLimitsObject: WasteUploadGETRequestBody = {
            type: filterValue.type,
            userId: userInfo.id,
            machineId: filterValue.machineId!,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: tableLimit,
            offset: tableOffset,
        }
        wasteUploadGetListByUserIdApi(dataLimitsObject)
        .then(response => {
            const resData: WasteUploadListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: WasteUploadData[] = resData.data?.wasteUploadList
                const totalCount : number = resData?.data?.totalCount;
                setDetectionTotalCount(totalCount)
                setPageCount((Math.ceil(totalCount/tableLimit)) || 0);
                setTotalWeight(resData?.data?.totalWeight)
                setWasteUploadList(dataList);     
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
            setIsLoading(false)
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            setIsLoading(false)
        })
    } 

    const handleChangeFilterValue = (event: ChangeEvent<HTMLSelectElement>) => {
        setFilterValue(
            (prevValue) => {
                return {
                    ...prevValue,
                    [event.target.name]: event.target.value,
                }
            }
        )

        if (event.target.name === "organizationId") {
            setFilterValue(
                (prevFilterData) => {
                    return  {
                        ...prevFilterData,
                        machineId: 0,
                    }
                }
            )
        }

        if (event.target.name === "organizationId" || event.target.name === "machineId") {
            setFilterValue(
                (prevFilterData) => {
                    return  {
                        ...prevFilterData,
                        tenantId: 0,
                    }
                }
            )
        }
    }

    const handleChangeDateFilterValue = (key: "startDate" | "endDate", value: Date) => {
        const day = value.getDate().toString().padStart(2, '0');
        const month = (value.getMonth() + 1).toString().padStart(2, '0');
        const year = value.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setDateFilterValue({...dateFilterValue, [key]: formattedDate})
    }

    const handleClickReadmore = (remark: string) => {
        setShowReadMoreModal(true)
        setRemark(remark)
    }

    const handleClickClose = () => {
        setShowReadMoreModal(false)
        setRemark("")
    }

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };

    const renderTable = () => {
        return (
            wasteUplaodList?.length !== 0 ? wasteUplaodList?.map((detection: WasteUploadData) =>  { 
                return (
                <tr key={detection.id}>
                    <td>{detection.id}</td>
                    <td style={{minWidth: "350px"}}>
                        <ModalImage
                            small={mergeImageUrlWithBaseUrl(detection?.imagePath) || NO_IMAGE_URL}
                            large={mergeImageUrlWithBaseUrl(detection?.imagePath) || NO_IMAGE_URL}
                            alt="Image"
                            className="table-img"
                        />
                    </td>
                    <td>{detection?.wasteType || "-"}</td>
                    <td>{detection.weight || 0}</td>
                    <td>{convertToLocaleDate(detection.captureTime!) || "-"}</td>
                    <td>{detection.organizationName || "-"}</td>
                    <td>{detection.tenantName || "-"}</td>
                    <td>{detection.machineName || "-"}</td>
                    <td>
                        {
                            detection.remark ?
                            <div className='d-flex align-items-center'>
                                <p style={{overflow: "hidden", width: "250px"}} className="m-0">{detection.remark}</p>
                                {
                                    (detection?.remark?.split(" ").length > 5) &&
                                    <button onClick={() => handleClickReadmore(detection.remark!)} className='btn btn-outline-light text-primary'>more...</button>
                                }
                            </div>:
                            "-"
                        }
                    </td>
                    { isSuperSpecialUser() &&
                        <td>{convertToLocaleDate(detection.createdAt!) || "-"}</td>
                    }
                </tr>)
            }): 
            <tr>
                <td>No data found</td>
            </tr>
        
        )
    }

    if (!hasPermission("viewWasteUpload")) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (  
        <Box>
            <Layout>
                <Container>
                    <Title>Waste Upload</Title>

                    {successText && <Alert severity="success" sx={{marginBottom: 2}}>{successText}<br/></Alert>}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}
                    
                    <WasteUploadFilterContainer
                        onChangeFilter={handleChangeFilterValue}
                        onChangeDateFilter={handleChangeDateFilterValue}
                        filterValue={filterValue}
                        dateFilterValue={dateFilterValue}
                    />

                    <SummaryText>Total Weight: {totalWeight || 0}kg</SummaryText>
                    <SummaryText>{detectionTotalCount} Waste Upload(s) found!</SummaryText>
                    <TableContainer>
                    <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    <th>Image</th>
                                    <th>Waste Type</th>
                                    <th>Weight(kg)</th>
                                    <th>Capture Time</th>
                                    <th>Organization</th>
                                    <th>Tenant</th>
                                    <th>Machine</th>
                                    <th>Remark</th>
                                    { isSuperSpecialUser() &&
                                        <th>Created At</th>
                                    }
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {
                                    renderTable()
                                }
                            </ tbody>
                        </table>
                    </InnterTableContainer>
                    </TableContainer>
                    <ReadMoreModal 
                        show={showReadMoreModal}
                        handleClickClose={handleClickClose}
                        bodyText={remark}
                    />
                    <LoadingModal 
                        show={isLoading}
                    />
                    <PaginationContainer>
                        <Pagination 
                            count={pageCount} 
                            onChange={handleChangePage} 
                            page={tablePage} 
                            variant="outlined" 
                            shape="rounded" size="large" 
                            disabled={isLoading}
                        />
                    </PaginationContainer>
                </Container>
            </Layout>
        </Box>
    )
}
 
export default WasteUpload;