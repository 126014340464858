import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Users.css'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from "react-bootstrap/Button"
import Alert from "@mui/material/Alert"
import { OrganizationsData } from "../../types/OrganizationsType";
import { RolesData } from "../../types/RoleType";
import { isRootAndSuperUsers, isSuperSpecialUser } from "../../utils/UserUtil";
import { ModalContainer } from "../Shared/Common/Containers";

const UsersModal = ({
    show,
    title,
    onChangeUserData,
    btnName,
    onUserForm,
    onClose,
    userData,
    successText,
    errorText,
    btnColor,
    orgList,
    roleList,
}: any): JSX.Element => {

    return ( 
        <Modal show={show}>
        <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        {successText && <Alert severity="success">{successText}</Alert>}
        {errorText && <Alert severity="error">{errorText}</Alert>}
        <Modal.Body>
            <Form className="ModalForm">
                <ModalContainer>
                    <Form.Group className="mb-3">
                        <InputGroup className="mb-3">
                            <InputGroup.Text className="input-user"><span>User Role<span className="text-danger">*</span></span></InputGroup.Text>
                            <Form.Select onChange={onChangeUserData} 
                                name="roleId" aria-label="roleSelect" required
                                value={userData.roleId}
                            >
                                <option value={0}>Select Role</option>
                                {
                                    (roleList?.length !== 0) && roleList.map((role: RolesData) => 
                                        <option value={role.id} key={role.id}>{role.roleName}</option>
                                    )
                                }
                            </Form.Select>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <InputGroup className="mb-3">
                            <InputGroup.Text className="input-user"><span>Username <span className="text-danger">*</span></span></InputGroup.Text>
                            <Form.Control onChange={onChangeUserData} 
                                type="text" name="userName" 
                                placeholder="Enter Username" 
                                value={userData.userName} required
                            />
                        </InputGroup>
                        {
                            btnName !== "Edit" &&
                            <InputGroup className="mb-3">
                                <InputGroup.Text className="input-user"><span>Password <span className="text-danger">*</span></span></InputGroup.Text>
                                <Form.Control onChange={onChangeUserData} 
                                    type="password" name="password" 
                                    placeholder="Enter Password" 
                                    value={userData.password} required 
                                />
                            </InputGroup>
                        }
                            
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <InputGroup className="mb-3">
                            <InputGroup.Text className="input-user"><span>Email <span className="text-danger">*</span></span></InputGroup.Text>
                            <Form.Control onChange={onChangeUserData} 
                                type="email" name="email" 
                                placeholder="Enter Email Address" 
                                value={userData.email} required
                            />
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text className="input-user"><span>Contact <span className="text-danger">*</span></span></InputGroup.Text>
                            <Form.Control onChange={onChangeUserData} 
                                type="tel" name="phoneNo" 
                                placeholder="Enter Contact Number" 
                                value={userData.phoneNo} required
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <InputGroup className="mb-3">
                            <InputGroup.Text className="input-user"><span>Remark</span></InputGroup.Text>
                            <Form.Control onChange={onChangeUserData} 
                                type="text" name="remark" placeholder="Enter Remark" 
                                value={userData.remark} required
                            />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <InputGroup className="mb-3">
                            <InputGroup.Text className="input-user"><span>Organization <span className="text-danger">*</span></span></InputGroup.Text>
                            {
                                (btnName === "Add" && isRootAndSuperUsers()) ?
                                <Form.Select onChange={onChangeUserData} 
                                name="organizationId" aria-label="organizatioinId" 
                                value={userData.organizationId}
                                >
                                    <option value={0}>Choose Organization</option>
                                    {
                                    (orgList && orgList?.length !== 0 && Array.isArray(orgList)) && orgList.map((org:OrganizationsData) => 
                                        <option value={org.id} key={org.id}>{org.organizationName}</option>
                                    )
                                    }
                                </Form.Select> :
                                <Form.Select onChange={onChangeUserData} 
                                    name="organizationId" aria-label="organizatioinId" 
                                    value={userData.organizationId}
                                    disabled
                                >
                                    <option value={userData.organizationId}>
                                        {
                                            (orgList && orgList?.length !== 0 && Array.isArray(orgList)) &&
                                            orgList.filter((org:OrganizationsData) => org.id === +userData.organizationId)[0]?.organizationName
                                        }
                                    </option>
                                </Form.Select> 
                            }
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <InputGroup className="mb-3">
                            <InputGroup.Text className="input-user"><span>Status <span className="text-danger">*</span></span></InputGroup.Text>
                            <Form.Select onChange={onChangeUserData} 
                                name="userStatus" aria-label="userStatus" 
                                value={userData.userStatus}
                            >
                                <option value="Active">Active</option>
                                <option value="Disable">Disable</option>
                            </Form.Select>
                        </InputGroup>
                    </Form.Group>
                </ModalContainer>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>Cancel</Button>
            <Button variant={btnColor} onClick={onUserForm}>{btnName}</Button>
        </Modal.Footer>
    </Modal>
    );
}
 
export default UsersModal;