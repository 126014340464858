import http from "../apiServices";
import { 
    LocationsData, 
    LocationDELETERequestBody, 
    LocationUPDATERequestBody, 
    LocationGETRequestBody, 
    LocationADDRequestBody,
    LocationRemoveImageRequestBody
} from "../../types/LocationsType";
import { STATUS_CODES } from "../../constants/GlobalConstants";

// * Post all Given Data to database.table

export const locationGetDataApi = (inputData : LocationGETRequestBody) => {
    return http.post("/location/get/list", inputData)
}

export const locationGetByOrgIdApi = (orgId: number) => {
    return http.post("/location/get/by/orgId", {"orgId": orgId})
}

export const locationAddDataApi = (inputData : LocationADDRequestBody) => {
    return http.post("/location/add", inputData)
}

export const locationUpdateDataApi = (inputData : LocationUPDATERequestBody) => {
    return http.put(`/location/update`, inputData)
}

export const locationDeleteDataApi = (inputData : LocationDELETERequestBody) => {
    return http.post(`/location/delete`, inputData)
}

export const locationRemoveImageDataApi = (inputData : LocationRemoveImageRequestBody) => {
    return http.post(`/location/remove/image`, inputData)
}

