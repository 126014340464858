import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Machines.css'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { OrganizationsData } from "../../types/OrganizationsType";
import { LocationsData } from "../../types/LocationsType";
import InputGroup from 'react-bootstrap/InputGroup';
import Button from "react-bootstrap/Button";
import Alert from "@mui/material/Alert"
import { isRootAndSuperUsers, isSuperSpecialUser } from "../../utils/UserUtil";
import { ModalContainer } from "../Shared/Common/Containers";
import { MACHINE_TYPES } from "../../constants/GlobalConstants";

const MachineModal = ({
    show,
    title,
    onChangeMachineData,
    onChangeSyncExternalNfc,
    btnName,
    onMachineForm,
    onClose,
    machineData,
    successText,
    errorText,
    btnColor,
    orgList,
    locationList,
}: any): JSX.Element => {

    return ( 
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            {successText && <Alert severity="success">{successText}</Alert>}
            {errorText && <Alert severity="error">{errorText}</Alert>}
            <Modal.Body>
                <Form className="ModalForm">
                    <ModalContainer>
                        <Form.Group className="mb-3">
                            <InputGroup className="mb-3">
                                <InputGroup.Text className="input-machine"><span>Name <span className="text-danger">*</span></span></InputGroup.Text>
                                <Form.Control name="machineName" onChange={onChangeMachineData} type="name" placeholder="Enter Machine Name" value={machineData.machineName} required/>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text className="input-machine"><span>Remark</span></InputGroup.Text>
                                <Form.Control name="remark" onChange={onChangeMachineData} type="remark" placeholder="Enter Remark" value={machineData.remark} required/>
                            </InputGroup>
                            {
                                isRootAndSuperUsers() &&
                                <InputGroup className="mb-3">
                                    <InputGroup.Text className="input-machine"><span>Detect Weight <span className="text-danger">*</span></span></InputGroup.Text>
                                    <Form.Control name="detectWeight" onChange={onChangeMachineData} type="text" placeholder="0" value={machineData.detectWeight} required/>
                                </InputGroup>
                            }
                            <InputGroup className="mb-3">
                            {
                                (!isSuperSpecialUser() || btnName === "Edit") ?
                                <Form.Select onChange={onChangeMachineData} 
                                    name="organizationId" aria-label="organizatioinId" 
                                    value={machineData.organizationId}
                                    disabled
                                >
                                    <option value={machineData.organizationId}>
                                        {orgList.filter((org:OrganizationsData) => org.id === +machineData.organizationId)[0]?.organizationName}
                                    </option>
                                </Form.Select> :
                                <Form.Select onChange={onChangeMachineData} 
                                name="organizationId" aria-label="organizatioinId" 
                                value={machineData.organizationId}
                                >
                                    <option value={0}>Choose Organization</option>
                                    {
                                    (orgList && orgList?.length !== 0 && Array.isArray(orgList)) && orgList.map((org:OrganizationsData) => 
                                        <option value={org.id} key={org.id}>{org.organizationName}</option>
                                    )
                                    }
                                </Form.Select>
                            }
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <Form.Select  aria-label="Default select example" name="locationId"
                                    onChange={onChangeMachineData}
                                    disabled={!Boolean(machineData.organizationId && machineData.organizationId !== '0')}
                                    value={machineData.locationId}
                                >
                                    <option value={0}>Choose Location</option>
                                    {
                                        (locationList && locationList?.length !== 0 && Array.isArray(locationList))? locationList.map((location:LocationsData) => 
                                             <option value={location.id} key={location.id}>{location.locationName}</option>
                                        ) : []
                                    }
                                </Form.Select>
                            </InputGroup>
                            {
                                (isRootAndSuperUsers() || btnName === "Add") &&
                                <InputGroup className="mb-3">
                                    <InputGroup.Text className="input-machine"><span >Serail Number <span className="text-danger">*</span></span></InputGroup.Text>
                                    <Form.Control name="lfcSerialNumber" onChange={onChangeMachineData} type="name" placeholder="Enter Serial Number" value={machineData.lfcSerialNumber} required/>
                                </InputGroup>
                            }
                            <InputGroup className="mb-3">
                                <Form.Select aria-label="Default select example" name="machineType"
                                    onChange={onChangeMachineData}
                                    value={machineData.machineType}
                                >
                                    <option value="">Select Machine Type</option>
                                    <option value={MACHINE_TYPES.FOOD_WASTE}>Food Waste</option>
                                    <option value={MACHINE_TYPES.GENERAL_WASTE}>General Waste</option>
                                </Form.Select>
                            </InputGroup>
                            <InputGroup>
                                <Form.Check
                                    type="checkbox"
                                    label="Sync External NFC"
                                    id={`is_sync_external_nfc`}
                                    name="isSyncExternalNfc"
                                    value={1}
                                    defaultChecked={machineData.isSyncExternalNfc}
                                    onChange={onChangeSyncExternalNfc}
                                />
                            </InputGroup>
                        </Form.Group>
                    </ModalContainer>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Cancel</Button>
                <Button variant={btnColor} onClick={onMachineForm}>{btnName}</Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default MachineModal;