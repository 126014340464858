import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { OrganizationsData } from "../../types/OrganizationsType";
import { isAdminUser, isTenantUser } from '../../utils/UserUtil';
import { FilterContainer } from '../Shared/Common/Containers';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { RolesData } from '../../types/RoleType';

const UserFilterContainer = ({
    onChangeFilter, 
    filterValue, 
} : any) : JSX.Element => {
    return (
        <FilterContainer>
            <Form.Group>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <InputGroup>
                            <InputGroup.Text id="inputGroup-sizing-lg">Search</InputGroup.Text>
                            <Form.Control
                                aria-label="Large"
                                aria-describedby="inputGroup-sizing-sm"
                                value={filterValue.search}
                                onChange={onChangeFilter}
                                name="search"
                                placeholder='Username or Email'
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </Form.Group>
        </FilterContainer>
    )
}

export default UserFilterContainer;