import { Alert, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { STATUS_CODES } from "../../constants/GlobalConstants";
import { locationMapConfigAddOrUpdateApi, locationMapConfigGetFirstRowApi } from "../../services/RouteServices/ConfigApi";
import { LocationMapConfigType } from "../../types/LocationMapConfigType";
import { UserInfo } from "../../types/UserAuth";
import { convertToLocaleDate } from "../../utils/DateTimeUtil";
import { getCurrentLocalUser } from "../../utils/UserUtil";

const LocationMapApiKey = () => {
    const userInfo: UserInfo = getCurrentLocalUser();
    const [locationMapConfig, setLocationMapConfig] = useState<LocationMapConfigType>({} as LocationMapConfigType)
    const [successText, setSuccessText] = useState<String>("");
    const [errorText, setErrorText] = useState<String>("");

    useEffect(() => {
        locationMapConfigGetFirstRowApi()
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setLocationMapConfig(res?.data?.data)
            } else {
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message) 
        })
    }, [])

    const handleChangeLocationMapConfig = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target
        setLocationMapConfig(prevData => {
            return {
                ...prevData,
                [name]: value
            }
        })
    }


    const handleAddOrUpdateLocationMapConfig = () => {
        setErrorText("")
        const dataLimitObject = {
            googleMapApiKey: locationMapConfig?.googleMapApiKey?.trim() || "",
            remark: locationMapConfig?.remark?.trim() || "",
            doneBy: userInfo.userName
        }

        locationMapConfigAddOrUpdateApi(dataLimitObject)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setLocationMapConfig(res?.data?.data)
                setSuccessText(res?.data?.message)
                setTimeout(() => {
                    setSuccessText("")
                }, 2000)
            } else {
                setErrorText(res?.data?.message)
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }


    return (  
        <Box
            sx={{minWidth: "400px", marginTop: 3}}
        >
            <Form>
                <Form.Label as="legend">
                    Location Map Config
                </Form.Label>
                {successText && <Alert severity="success" className="mb-3">{successText}</Alert>}
                {errorText && <Alert severity="error" className="mb-3">{errorText}</Alert>}
                <div
                    className="border border-light border-3 rounded p-3 bg-light bg-gradient" 
                >
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            <p className='mb-0'>
                                <span>Google Map Api Key</span><span className='text-danger'>*</span>
                            </p>
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" name="googleMapApiKey" value={locationMapConfig.googleMapApiKey || ""} onChange={handleChangeLocationMapConfig} placeholder='6222126201:AAHoG7QI-T...' />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            <p className='mb-0'>
                                <span>Remark</span>
                            </p>
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" placeholder='' name="remark" value={locationMapConfig.remark || ""} onChange={handleChangeLocationMapConfig} />
                        </Col>
                    </Form.Group>
                    
                    <Form.Group as={Row} className="">
                        <Form.Label column sm="3">
                             
                        </Form.Label>
                        <Col sm="9">
                            <span style={{fontSize:13, color:'#35a612'}}>Last Update - {convertToLocaleDate(locationMapConfig?.updatedAt || "") || convertToLocaleDate(locationMapConfig?.createdAt || "")}</span>
                            <br></br>
                            <span style={{fontSize:13, color:'#35a612'}}>Updated User - {locationMapConfig.updatedBy || locationMapConfig.createdBy}</span>
                        </Col>
                    </Form.Group>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "right",
                            gap: 2,
                            marginTop: 3,
                        }}
                    >
                        <Button variant="primary" onClick={handleAddOrUpdateLocationMapConfig}>Update</Button>
                    </Box>
                </div>
            </Form>
        </Box>
    );
}
 
export default LocationMapApiKey;