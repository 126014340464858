import { Checkbox, ListItemButton, ListItemIcon } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { FunctionComponent, useState } from "react";
import { CgAddR } from "react-icons/cg";
import { UserData } from "../../../../types/UsersType";
import AssignLocationModal from "./AssignLocationModal";
import { getAvailableLocationsByUserIdsApi } from "../../../../services/RouteServices/UsersAvailableLocationsApi";
import { STATUS_CODES } from "../../../../constants/GlobalConstants";
import { UsersAvailableLocations } from "../../../../types/UsersAvailableLocationsType";

interface UsersListProps {
    users: UserData[],
    selectedUsers: number[]
    handleToggle: (value: number) => void
    handleAssignLocations: (userId: number, locationIds: number[]) => void
}
 
const UsersList: FunctionComponent<UsersListProps> = ({
    users,
    selectedUsers,
    handleToggle,
    handleAssignLocations
}) => {
    const [showAssignLocationModal, setShowAssignLocationModal] = useState<boolean>(false)
    const [selectedUser, setSelectedUser] = useState<UserData>({} as UserData)
    const [selectedLocations, setSelectedLocations] = useState<number[]>([])
    const [assignedLocations, setAssignedLocations] = useState([])

    const toggleShowAssignLocationModal = () => {
        setShowAssignLocationModal(!showAssignLocationModal)
        setSelectedLocations([])
    }

    const handleToggleLocaitions = (value: number) => {
        const currentIndex = selectedLocations.indexOf(value);
        const newChecked = [...selectedLocations];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedLocations(newChecked)
    }

    const handleClickAssignLocation = (user: UserData) => {
        getAssignedLocations(user.id!)
        setSelectedUser(user)
        toggleShowAssignLocationModal()
    }

    const handleAssignLocationsWrapper = () => {
        handleAssignLocations(selectedUser.id!, selectedLocations)
        toggleShowAssignLocationModal()
    }

    const getAssignedLocations = (userId: number) => {
        const input = {
            userIds: [userId]
        }
        getAvailableLocationsByUserIdsApi(input)
        .then(res => {
            const response = res?.data
            if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                setAssignedLocations(
                    response?.data?.map((data: UsersAvailableLocations) => data?.locationId) || []
                )
            }
        }).catch(error => {
            console.log(error)
        })
    }

    return (  
        <>
        <List sx={{ width: '100%', maxWidth: 400, maxHeight: 500, overflowY: "scroll", bgcolor: 'background.paper' }}>
        {users?.map((user) => {
            const labelId = `checkbox-list-label-${user}`;

            return (
                <ListItem
                    key={user.id}
                    secondaryAction={
                    <IconButton edge="end" aria-label="comments" onClick={() => handleClickAssignLocation(user)}>
                        <CgAddR className="text-success"/>
                    </IconButton>
                    }
                    disablePadding
                >
                    <ListItemButton role={undefined} onClick={() => handleToggle(user.id!)} dense>
                        <ListItemIcon>
                            <Checkbox
                            edge="start"
                            checked={selectedUsers.indexOf(user.id!) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={user.userName} />
                    </ListItemButton>
                </ListItem>
            );
        })}
        </List>
        <AssignLocationModal 
            show={showAssignLocationModal}
            toggleShow={toggleShowAssignLocationModal}
            handleAssignLocations={handleAssignLocationsWrapper}
            selectedUser={selectedUser}
            selectedLocations={selectedLocations}
            handleToggleLocaitions={handleToggleLocaitions}
            assignedLocations={assignedLocations}
        />
        </>
    );
}
 
export default UsersList;