import { Box, Typography } from "@mui/material";
import { FunctionComponent } from "react";
import LocationsList from "./components/LocationsList";
import { UsersAvailableLocations } from "../../../types/UsersAvailableLocationsType";

interface LocationsBoxProps {
    locations: UsersAvailableLocations[]
    handleRemoveLocation: (id: number) => void
}
 
const LocationsBox: FunctionComponent<LocationsBoxProps> = ({
    locations,
    handleRemoveLocation
}) => {
    return (  
        <Box sx={{
            // width: "400px",
            paddingX: "10px"
        }}>
            <Typography sx={{fontSize: "20px", fontWeight: 400}}>Assigned Locations</Typography>
            <LocationsList 
                locations={locations}
                handleRemoveLocation={handleRemoveLocation}
            />
        </Box>
    );
}
 
export default LocationsBox;