import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ModalImage from 'react-modal-image';
import { NO_IMAGE_URL } from '../../../constants/GlobalConstants';
import { convertToLocaleDate } from '../../../utils/DateTimeUtil';
import styles from '../CartonBox.module.css'
import { CartonBoxDetectionType } from '../../../types/CartonBoxDetectionType';

type Props = {
    cartonBox: CartonBoxDetectionType
}

const CartonBoxCard = (props: Props) => {
    const {cartonBox} = props;
    return (  
        <Card sx={{ marginX: 3, borderRadius: 3, overflow: "hidden" }} className='capture-card'>
            <CardMedia
                sx={{ display: "flex", gap: 2, flexWrap: "wrap", justifyContent: "center" }}
            >
                <ModalImage
                    small={cartonBox.detectionImagePath || NO_IMAGE_URL}
                    large={cartonBox.detectionImagePath || NO_IMAGE_URL}
                    alt="Image"
                    className={styles.cardImage}
                />
                <ModalImage
                    small={cartonBox.detectionImagePath || NO_IMAGE_URL}
                    large={cartonBox.detectionImagePath || NO_IMAGE_URL}
                    alt="Image"
                    className={styles.cardImage}
                />
            </CardMedia>
            <CardContent sx={{ maxHeight: 400, overflow: "scroll" }} className="hide-scroll-bar">
                <div className='d-flex justify-content-between align-items-center'>
                    <h4><span className="badge bg-info">Id: {cartonBox.id}</span></h4>
                </div>
                <table>
                    <tbody>
                        <tr>
                            <td className='pe-2 py-2'>Capture Time</td>
                            <td className='ps-3 border-start text-secondary'>{convertToLocaleDate(cartonBox.createdAt) || "-"}</td>
                        </tr>
                        <tr>
                            <td className='pe-3 py-2'>Organization</td>
                            <td className='ps-3 border-start text-secondary'>{cartonBox.organizationName || "-"}</td>
                        </tr>
                        <tr>
                            <td className='pe-3 py-2'>Location</td>
                            <td className='ps-3 border-start text-secondary'>{cartonBox.locationName || "-"}</td>
                        </tr>
                        <tr>
                            <td className='pe-3 py-2'>Machine</td>
                            <td className='ps-3 border-start text-secondary'>{cartonBox.machineName || "-"}</td>
                        </tr>
                    </tbody>
                </table>
            </CardContent>
        </Card>
    );
}
 
export default CartonBoxCard;