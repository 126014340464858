import Alert from "@mui/material/Alert";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { OrganizationsData } from "../../../types/OrganizationsType";
import { ModalContainer } from "../../Shared/Common/Containers";
import { isSuperSpecialUser } from "../../../utils/UserUtil";
import ImageUpload from "./ImageUpload";
import { Image } from "react-bootstrap";
import noImagePng from '../../../assets/images/no-image.png'

const LocationUpdateModal = ({
    show,
    location,
    locationImages,
    error,
    orgList,
    onUpdate,
    onChange,
    onChangeImage,
    onClose,
}: any) => {

    return ( 
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Update location</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <Alert severity="error" sx={{marginBottom: 2}}>{error}</Alert>}
                <ModalContainer>
                    <Image width={200} height={150} className="mx-auto mb-3" src={location.locationImage || noImagePng} rounded />
                    <Form.Group className="mb-3">
                        <div className='mb-3'>
                            <InputGroup>
                                <InputGroup.Text className="input-location"><span>Name <span className="text-danger">*</span></span></InputGroup.Text>
                                <Form.Control name="locationName" onChange={onChange} type="name" placeholder="Enter Location Name" value={location.locationName}/>
                            </InputGroup>
                        </div>
                        <div className='mb-3'>
                            <InputGroup>
                                <InputGroup.Text className="input-location"><span>Remark</span></InputGroup.Text>
                                <Form.Control name="remark" onChange={onChange} type="remark" placeholder="Enter Remark" value={location.remark}/>
                            </InputGroup>
                        </div>
                        <div className='mb-3'>
                        <InputGroup>
                            {
                                (!isSuperSpecialUser()) &&
                                <Form.Select onChange={onChange} 
                                    name="organizationId" aria-label="organizatioinId" 
                                    value={location.organizationId}
                                    disabled
                                >
                                    <option value={location.organizationId}>
                                        {orgList?.filter((org:OrganizationsData) => org.id === +location.organizationId)[0]?.organizationName}
                                    </option>
                                </Form.Select>
                            }
                        </InputGroup>
                        </div>
                        <ImageUpload 
                            images={locationImages}
                            onChange={onChangeImage}
                        />
                    </Form.Group>
                    <div className="d-flex justify-content-end gap-2">
                        <Button variant="secondary" onClick={onClose}>Cancel</Button>
                        <Button type="submit" variant="primary" onClick={onUpdate}>Update</Button>
                    </div>
                </ModalContainer>
            </Modal.Body>
        </Modal>
    );
}
 
export default LocationUpdateModal;