import React, { ChangeEvent, useState, useEffect } from "react";

import { Box, Pagination } from "@mui/material";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styling/customStyle.css';
import Layout from "../Layout/Layout";


import { NodeProps } from "../../types/CommonType";
import Button from "react-bootstrap/Button"
import Alert from '@mui/material/Alert';
import { DataSyncTable, DataSyncTableData, LFCLogData, LFCLogDataDefault, ResponseDataSyncApiDataType, ResponseDataSyncApiType } from "../../types/DataSyncType";
import { dataSyncImportToDBApi } from "../../services/RouteServices/DataSyncApi";
import { PAGE_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";
import DataSyncModal from "./DataSyncModal";
import { getAllOrgsList } from "../../services/RouteServices/CommonApi";
import { OrganizationsData } from "../../types/OrganizationsType";
import { MachinesData } from "../../types/MachinesType";
import { machineGetAllByOrgIdAndLocIdDataApi, machineGetAllByOrgIdDataApi } from "../../services/RouteServices/MachinesApi";
import { locationGetByOrgIdApi } from "../../services/RouteServices/LocationsApi";
import { LocationsData } from "../../types/LocationsType";
import { Container, InnterTableContainer, TableContainer } from "../Shared/Common/Containers";
import { Title, TitleH5 } from "../Shared/Common/Titles";
const DataSync: React.FC<NodeProps> = () => {
    const tableLimit = PAGE_LIMIT;
    const reader = new FileReader();

    const [showImportModal, setShowImportModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState<FileList | null>(null);
    const [dataSyncTableObject, setDataSyncTableObject] = useState({}); // * Original data object from csv file

    const dataSyncTableData : DataSyncTableData = dataSyncTableObject as DataSyncTableData; // * Formated data Table to map through
    const [loadDataStatus, setLoadDataStatus] = useState(false);
    const [lfcLogDataList, setLfcLogDataList] = useState<DataSyncTable[]>([]);
    const [lfcLogData, setLfcLogData] = useState<LFCLogData>(LFCLogDataDefault);
    
    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");
    const [warningText, setWarningText] = useState("");

    const [errorFormText, setErrorFormText] = useState("");
    const [successFormText, setSuccessFormText] = useState("");

    const [tablePage, setTablePage] = React.useState(1);
    const [tableOffset, setTableOffset] = React.useState(0);

    const [orgList, setOrgList] = useState<OrganizationsData[]>([]);
    const [machineList, setMachineList] = useState<MachinesData[]>([]);
    const [locationList, setLocationList] = useState<LocationsData[]>([]);

    useEffect(() => {
        getAllOrgsList()
        .then(res => {
            setOrgList(res)
        })
        .catch(error => {
            setErrorText(error)
        })
    }, [])

    useEffect(() => {
        if (lfcLogData.organizationId !== 0 && ''+lfcLogData.organizationId !== '0') {
            locationGetByOrgIdApi(lfcLogData.organizationId)
            .then((res) => {
                    if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                        setLocationList(res?.data?.data?.locationList)
                    else 
                        setErrorText(res?.data?.message)     
            })
            .catch(error => {
                setErrorText(error?.response?.data?.message || error?.message)
            })
        }
    }, [lfcLogData.organizationId])

    useEffect(() => {
        if (lfcLogData.locationId !== 0 && ''+lfcLogData.locationId !== '0' && 
            lfcLogData.organizationId !== 0 && ''+lfcLogData.organizationId !== '0') {
            machineGetAllByOrgIdAndLocIdDataApi(lfcLogData.organizationId, lfcLogData.locationId)
            .then(res => {
                if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                    setMachineList(res?.data?.data?.machineList)
                } else {
                    setErrorText(res?.data?.message) 
                }   
            }).catch(error => {
                setErrorText(error?.response?.data?.message || error?.message)
                console.log(error)
            })
        }
    }, [lfcLogData.locationId, lfcLogData.organizationId])

    useEffect(() => {
        if (selectedFile && 
            lfcLogData.organizationId !== 0 && ''+lfcLogData.organizationId !== '0' &&
            lfcLogData.machineId !== 0 && ''+lfcLogData.machineId !== '0' &&
            lfcLogData.locationId !== 0 && ''+lfcLogData.locationId !== '0'
        ) {
            setErrorFormText("")
        }
    }, [lfcLogData, selectedFile])

    const handleClose = () => {
        setShowImportModal(false);
    }

    const openLfcModalImport = () => {
        setShowImportModal(true);
        setLfcLogData(LFCLogDataDefault);
        setSelectedFile(null);
        setErrorFormText("");
        setSuccessFormText("");
    }

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => setSelectedFile(e.target.files);

    const handleLFCLogData = (event: ChangeEvent<HTMLInputElement>) => { 
        setLfcLogData(
            (prevData) => {
                return  {
                    ...prevData,
                    [event.target.name]: event.target.value,
                }
            }
        )
        if (event.target.name === "organizationId") {
            setLfcLogData(
                (prevMachineData) => {
                    return  {
                        ...prevMachineData,
                        machineId: 0,
                        locationId: 0,
                    }
                }
            )
        }

        if (event.target.name === "locationId") {
            setLfcLogData(
                (prevMachineData) => {
                    return  {
                        ...prevMachineData,
                        machineId: 0,
                    }
                }
            )
        }
        
    }

    const checkImportedData = (data: string) => {
        const rows = data.split('\n');
        const cells = rows.map(row => row.split('"'));
        const keys = cells[0];
        const values = cells.slice(1);
        values.pop(); // * remove undefined values
        // * Check if data has the following headers if not return.
        if (keys[0] !== '' && keys[3] !== 'LFC Time' && keys[5] !== 'LFC Operational Log')
            return "failed";
        else return values;
    }

    const handleImportedData = (data: string) => {
        const dataValidation = checkImportedData(data);
        if (dataValidation === "failed") {
            setErrorFormText('Invalid csv file! Please import the csv from LFC Cloud Only!'); // * Show error message in 
            setTimeout(() => {
                setErrorFormText('');
            }, 2500);
            return;
        }
        let lfcObjectData: DataSyncTable[] = dataValidation.map(value => {
            const dataSyncTableObject: DataSyncTable = { organizationId: 0, machineId: 0, locationId: 0, lfcTime: '', lfcOperationalLog: '' };
            dataSyncTableObject.organizationId = lfcLogData.organizationId;
            dataSyncTableObject.machineId = lfcLogData.machineId;
            dataSyncTableObject.locationId = lfcLogData.locationId;
            dataSyncTableObject.lfcTime = value[3];
            dataSyncTableObject.lfcOperationalLog = value[5];
            return dataSyncTableObject;
        });
        return lfcObjectData;
    }

    const dataSyncAPIHandler = async () => {
        if (lfcLogDataList.length > 0) {
            await dataSyncImportToDBApi(lfcLogDataList)
                .then((res: ResponseDataSyncApiType) => {
                    const resApiData: ResponseDataSyncApiDataType = res?.data;
                    if (resApiData) {
                        if (resApiData?.status === STATUS_CODES.SUCCESS_CODE) {
                            setErrorText("");
                            setSuccessText(resApiData?.message);
                            setLoadDataStatus(true);

                            setTimeout(() => {
                                setLoadDataStatus(false); // * Return as import button and remove text;
                                setWarningText("Import new data!");
                                setSuccessText("")
                            }, 3000);
                        } else {
                            setSuccessText("");
                            setErrorText(resApiData?.message);
                        }
                    }
                }).catch((error) => {
                    console.log(error);
                    setErrorText(error?.response?.data?.message || error?.message);
                    setLoadDataStatus(false);
                });
        }
    }

    // * Import data for preview
    const handleUploadButton = () => {
        // Check for file existence
        if (selectedFile && 
            lfcLogData.organizationId !== 0 && ''+lfcLogData.organizationId !== '0' &&
            lfcLogData.machineId !== 0 && ''+lfcLogData.machineId !== '0' &&
            lfcLogData.locationId !== 0 && ''+lfcLogData.locationId !== '0'
        ) {
            reader.onload = async function () {
                let data = reader.result;
                if (data) {
                    data = data.toString(); // * Change data to string if not already.
                    let lfcObjectData = handleImportedData(data);
                    // * Check if theres data.
                    if (!(lfcObjectData && lfcObjectData))
                        return;
                    setLoadDataStatus(true); // * Remove warning message.
                    setDataSyncTableObject(lfcObjectData); // * Set data to state for future use.
                    setSuccessText("Preview data loaded successfully!"); // * Show success message.
                    setTimeout(() => {
                        setSuccessText("")
                    }, 3000)
                    
                    setLfcLogDataList(lfcObjectData);
                }
                else {
                    setErrorText("Data not found or empty!"); // * Show Error message.
                    return;
                }
                handleClose();
            };
            reader.readAsText(selectedFile?.item(0) as Blob);
        }
        else {
            setErrorFormText("Fill all fileds!"); // * Show error message in form.
        }
    };

    const changeDataPaginationObjectHandler = () => {
        for (let i=tableOffset; i <= tableOffset+tableLimit; i++) {
            console.log(dataSyncTableData[i]);
        }
    }

    // * Pagination on preview data
    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue <= 1) {
            setTableOffset(0);
            console.log("offset: 0");
            console.log(pageValue);
        }
        else{
            const offsetValue = 1 + (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
            console.log("pageValue :", pageValue);
            console.log("offset: ", offsetValue);
        }
        changeDataPaginationObjectHandler();
    };

    return (
        <>
            <Layout>
                <Container>
                    <Title>Data Sync</Title>
                    <Box className="titleBox">
                        <TitleH5>0 Records found!</TitleH5>
                        {loadDataStatus ? <Button className="pt-2 pb-2 ps-3 pe-3" variant="success" onClick={dataSyncAPIHandler}>Submit</Button> : <Button className="pt-2 pb-2 ps-3 pe-3" variant="primary" onClick={openLfcModalImport}>Import</Button>}
                    </Box>
                    {!loadDataStatus && <Alert severity="warning">Import data needed!</Alert>}
                    {warningText && <Alert severity="warning">{warningText}</Alert>}
                    {successText && <Alert severity="success">{successText}</Alert>}
                    {errorText && <Alert severity="error">{errorText}</Alert>}

                    <DataSyncModal 
                        show={showImportModal}
                        successText={successFormText}
                        errorText={errorFormText}
                        handleClose={handleClose}
                        handleUploadButton={handleUploadButton}
                        onFileChange={onFileChange}
                        onChangeLFCLogData = {handleLFCLogData}
                        data={lfcLogData}
                        orgList={orgList}
                        machineList={machineList}
                        locationList={locationList}
                    />
                    {
                        loadDataStatus &&
                        <TableContainer>
                            <InnterTableContainer>
                            <table className="styled-table">
                                <thead className="table-header">
                                    <tr>
                                        <th>ID</th>
                                        <th>LFC Date</th>
                                        <th>Operational Logs</th>
                                    </tr>
                                </thead>
                                <tbody className="table-data">
                                    {dataSyncTableData && Object.keys(dataSyncTableData).map((key: string, index: number) => {
                                        let indexKey = Number(key);
                                        indexKey += 1;
                                        return (
                                            <tr key={index}>
                                                <td>{indexKey}</td>
                                                <td>{dataSyncTableData[key].lfcTime}</td>
                                                <td>{dataSyncTableData[key].lfcOperationalLog}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </ tbody>
                                <tfoot className="table-footer">
                                {/* <Pagination count={tableLimit} onChange={handleChangePage} page={tablePage} variant="outlined" shape="rounded" size="large" /> */}
                                </tfoot>
                            </table>
                            </InnterTableContainer>
                        </TableContainer>
                    }

                </Container>
            </Layout>
        </>
    );
};

export default DataSync;