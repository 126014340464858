import { AlertUserAddBodyType, AlertUserDeleteBodyType, AlertUserGETBodyType, AlertUserUpdateBodyType } from "../../types/AlertUserTyps";
import http from "../apiServices";

export const getAlertUsersApi = (body: AlertUserGETBodyType) => {
    return http.post("/alert-user/get", body)
}

export const addAlertUserApi = (body: AlertUserAddBodyType) => {
    return http.post("/alert-user/add", body)
}

export const updateAlertUserApi = (body: AlertUserUpdateBodyType) => {
    return http.post("/alert-user/update", body)
}

export const deleteAlertUserApi = (body: AlertUserDeleteBodyType) => {
    return http.post("/alert-user/delete", body)
}