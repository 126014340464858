import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../Tenants.css'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { MachinesData } from "../../../../types/MachinesType";
import InputGroup from 'react-bootstrap/InputGroup';
import Button from "react-bootstrap/Button";
import Alert from "@mui/material/Alert";
import { CardInMachineData } from "../../../../types/CardInMachineType";
import { ModalContainer } from "../../../Shared/Common/Containers";

const CardInTenantModal = ({
    show,
    title,
    onChangeData,
    onForm,
    onClose,
    data,
    successText,
    errorText,
    machineList,
    cardList,
    isProcessing
}: any): JSX.Element => {    
    return ( 
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            {successText && <Alert severity="success">{successText}</Alert>}
            {errorText && <Alert severity="error">{errorText}</Alert>}
            <Modal.Body>
                <Form className="ModalForm">
                    <ModalContainer>
                        <Form.Group className="mb-3">
                        <InputGroup className="mb-3">
                                <Form.Select  aria-label="Default select example" name="machineId"
                                    onChange={onChangeData}  
                                    value={data.machineId}
                                >
                                    <option value={0}>Choose Machine</option>
                                    {
                                        (machineList && machineList?.length !== 0 && Array.isArray(machineList)) && machineList.map((machine:MachinesData) => 
                                             <option value={machine.id} key={machine.id}>{machine.machineName}</option>
                                        )
                                    }
                                </Form.Select>
                        </InputGroup>
                        <InputGroup className="mb-3">
                                <Form.Select  aria-label="Default select example" name="cardId"
                                    onChange={onChangeData}  
                                    value={data.cardId}
                                    disabled={!Boolean(data.machineId && data.machineId !== '0')}
                                >
                                    <option value={0}>Choose Card</option>
                                    {
                                        (cardList && cardList?.length !== 0 && Array.isArray(cardList)) ? cardList.map((card:CardInMachineData) => 
                                            card.lfcCardId !== 1 && <option value={card.lfcCardId} data-cardinmachineid={card.id} key={card.id}>card {card.lfcCardId}</option>
                                        ) :
                                            <option></option>
                                    }
                                    
                                </Form.Select>
                        </InputGroup>
                        <InputGroup className="mb-3">
                                <InputGroup.Text className="input-tenant"><span>Remark</span></InputGroup.Text>
                                <Form.Control name="remark" onChange={onChangeData} type="remark" placeholder="Enter Remark" value={data.remark} required/>
                        </InputGroup>
                        </Form.Group>
                    </ModalContainer>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Cancel</Button>
                <button 
                    className="btn btn-outline-success" 
                    type="button" onClick={onForm}
                    disabled={isProcessing}
                > 
                    Assign
                </button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default CardInTenantModal;