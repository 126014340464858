export interface MaintenanceConfigReqeustBody {
    isMaintenance?: boolean;
    details?: string;
    remark?: string;
    doneBy: string;
}

export interface MaintenanceConfigData {
    isMaintenance: boolean;
    details: string;
    remark?: string;
    createdBy?: string;
    updatedBy?: string;
    deletedBy?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}

export const MaintenanceConfigDefault = {
    isMaintenance: false,
    details: "",
}