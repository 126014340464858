import { normalLogAddApi, systemLogAddApi } from "../services/RouteServices/LogApi"

export const addSystemLog = (
    logMessage: string,
    logLevel: string,
) => {
    const input = {
        logMessage: logMessage,
        logLevel: logLevel
    }
    systemLogAddApi(input)
    .then(res => {
        console.log(res?.data)
    })
    .catch(error => {
        console.log(error?.message)
    })
}

export const addNormalLog = (
    logMessage: string,
    logLevel: string,
) => {
    const input = {
        logMessage: logMessage,
        logLevel: logLevel
    }
    normalLogAddApi(input)
    .then(res => {
        console.log(res?.data)
    })
    .catch(error => {
        console.log(error?.message)
    })
}