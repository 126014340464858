import styled from "styled-components";

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3D90B0;
  padding: 5px;
  border-radius: 5px 5px 0 0;

  .generalMachineColor {
    color: #F4CE14;
  }
`

export const CardContainer = styled.section`
  display: grid;
  grid-template-columns repeat(3, 1fr);
  grid-gap: 20px;
  margin-bottom: 30px;
  width: 100%;
  h2 {
    color: #000;
  }
  h3 {
    font-size: 200%;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 80%;
  }
`;

export const MachineCardContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 30px;
  gap: 30px;
  width: 100%;
  h2 {
    color: #000;
  }
  h3 {
    font-size: 200%;
  }
  @media screen and (max-width: 650px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 80%;
  }
`;

export const MachineCard = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  width: 400px;
  margin-bottom: 30px;
  // overflow-x: scroll;
  @media screen and (max-width: 650px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 90%;
  }
`;

export const DigestorCard = styled.div`
  background-color: #dedede;
  color: #FFF;
  text-align: center;
  border-radius: 5px;
  width: 400px;
  // height: 100%;
  padding: 0px;
  h5 {
    border-radius: 5px 5px 0px 0px;
  }
`;

export const MachineTotalContainer = styled.div`
  display: block;
  padding: 5px;
  padding-bottom: 5px;

  h6 {
    color: black;
    text-align: left;
    padding: 5px;
  }
  .totalWeight {
    color : #c86bef
  }
  .totalDoorCount {
    color : #9f751f;
  }
  .totalNonOrganic {
    color : #ff4949;
  }
  .carbonReduction {
    color: #3876BF;
  }
`;

export const MachineStatusContainer = styled.div`
  // display: grid;
  // grid-template-columns: repeat(2, 1fr);
  // grid-gap: 10px;
  // justify-content: flex-start;
  // align-items: center;
  padding-bottom: 10px;
  h4 {
    font-size: 120%;
    color: #000;
    text-align: left;
    font-weight: normal;
    margin: 0;
    padding: 10px;
  }
  h4:nth-child(2) {
    color: #FFFFFF;
    text-align: left;
    font-weight: bold;
  }
`;

export const StatusCard = styled.div`
  background-color: #6dc573;
  color: #FFF;
  text-align: center;
  width: 100%;
  padding: 0;
`;