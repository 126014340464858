export type DoorLogData = {
    id?: number,
    organizationId: number,
    organizationName: string,
    machineId: number,
    machineName: string,
    locationId: number,
    locationName: string,
    lfcCard: string,
    cardId: number,
    syncAlready: number,
    tenantId: number,
    tenantName: string,
    lfcTime: string,
    lfcOperationalLog: string,
    doorOpenDuration: string,
    lfcType: string,
    remark: string,
    createdAt?: string,
    createdBy?: string,
}

export type DoorLogListResponseBody = {
    data: {
        doorLogList: DoorLogData[],
        totalCount: number,
        totalDoorOpenCount: number,
        totalDoorScanCount: number,
    }
    status: number,
    message: string,
}

export type DoorLogExportListResponseBody = {
    status: number;
    message: string;
    data: DoorLogData[]
}

export type DoorLogGETRequestBody = {
    orgId?: number,
    userId?: number,
    machineId: number,
    limit?: number,
    offset?: number,
    startDate: string,
    endDate: string,
}

export const DoorLogDefault = {
    organizationId: 0,
    organizationName: "",
    machineId: 0,
    machineName: "",
    locationId: 0,
    locationName: "",
    lfcCard: "",
    cardId: 0,
    syncAlready: 0,
    tenantId: 0,
    tenantName: "",
    lfcTime: "",
    lfcOperationalLog: "",
    doorOpenDuration: "",
    lfcType: "",
    remark: "",
}