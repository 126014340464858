import { 
    CARD1_WEIGHT, 
    INACTIVE_WEIGHT, 
    TOTAL_DOOR_OPEN_COUNT, 
    TOTAL_DOOR_SCAN_COUNT, 
    TOTAL_WEIGHT, 
    UNASSIGNED_WEIGHT 
} from "../../../constants/ReportDetailsContant";
import { MachinesData } from "../../../types/MachinesType";
import { OrganizationsData } from "../../../types/OrganizationsType";
import { DailyDataType, DailyReportsType } from "../../../types/ReportDetailsType";
import { 
    TenantDailyWeightAmount, 
    TenantMonthlyWeightAmount 
} from "../../../types/ReportType";

export const compareDates = (date1: string, date2: string) => {
    let dateA = new Date(date1);
    let dateB = new Date(date2);
    if (date1?.includes(".")) dateA = new Date(date1?.split('.').reverse().join('-'))
    if (date2?.includes(".")) dateB = new Date(date2?.split('.').reverse().join('-'))
    if (dateA < dateB) {
        return -1;
    } else if (dateA > dateB) {
        return 1;
    } else {
        return 0;
    }
};

export function calculateTotalValues(data: {[key: string]: number}[]) {
    const totals: {[key: string]: any} = {};

    data.forEach((item: {[key: string]: number}) => {
        for (const key in item) {
            if (key !== "DATE") {
                const sum: number = (totals[key] || 0) + (item[key] || 0)
                totals[key] = +sum.toFixed(2);
            } else {
                totals[key] = "MONTHLY TOTAL"
            }
        }
    });

    return totals;
}

export function sortData(data: {[key: string]: any}[], order: string) {
    if (order === "asc") {
        data.sort((a, b) => a[TOTAL_WEIGHT] - b[TOTAL_WEIGHT])
    } else if (order === "desc") {
        data.sort((a, b) => b[TOTAL_WEIGHT] - a[TOTAL_WEIGHT])
    } else if (order === "date") {
        data.sort((a, b) => compareDates(a["DATE"], b["DATE"]))
    }
    return data
}

export const loadDailyReports = (data: DailyReportsType, order: string) => {
    const dailyData = data?.dailyData
    const tenantWeights = data?.tenantWeights
    let result = []
    let keys = [
        "DATE", TOTAL_WEIGHT, TOTAL_DOOR_OPEN_COUNT, TOTAL_DOOR_SCAN_COUNT
    ]
    for (const data of dailyData) {
        const temp: {[key: string]: any} = {}
        temp['DATE'] = data["date"]
        temp[TOTAL_WEIGHT] = data["totalWeight"]
        temp[CARD1_WEIGHT] = data["card1Weight"]
        temp[UNASSIGNED_WEIGHT] = data["unassignedWeight"]
        temp[INACTIVE_WEIGHT] = data["inactiveWeight"]
        temp[TOTAL_DOOR_OPEN_COUNT] = data["openCount"]
        temp[TOTAL_DOOR_SCAN_COUNT] = data["scanCount"]
        const date = data.date
        for (const tenantWeight of tenantWeights) {
            if (tenantWeight.formattedDate === date) {
                temp[tenantWeight.tenantName] = tenantWeight.weight
                if (!keys.includes(tenantWeight.tenantName)) keys.push(tenantWeight.tenantName)
            }
        }
        result.push(temp)
    }
    keys = [...keys, CARD1_WEIGHT, UNASSIGNED_WEIGHT, INACTIVE_WEIGHT]

    result = sortData(result, order)

    const totalRow = calculateTotalValues(result)
    result.unshift(totalRow)
    return {keys, result}
}

export const loadDailyReportsForSpecialPdf = (data: DailyReportsType) => {
    const dailyData = data?.dailyData
    const tenantWeights = data?.tenantWeights
    const tenantNames: string[] = []
    for (const tenantWeight of tenantWeights) {
        if (!(tenantNames.includes(tenantWeight.tenantName))) {
            tenantNames.push(tenantWeight.tenantName)
        }
    }
    const rows = [
        TOTAL_WEIGHT, CARD1_WEIGHT, UNASSIGNED_WEIGHT, INACTIVE_WEIGHT, TOTAL_DOOR_OPEN_COUNT, TOTAL_DOOR_SCAN_COUNT,
    ]

    const result = []

    for (const row of rows) {
        const temp: {[key: string]: any} = {
            "Name": row
        }
        for (const daily of dailyData) {
            const date = daily?.date
            if (row === TOTAL_WEIGHT) temp[date] = daily.totalWeight
            else if (row === CARD1_WEIGHT) temp[date] = daily.card1Weight
            else if (row === UNASSIGNED_WEIGHT) temp[date] = daily.unassignedWeight
            else if (row === INACTIVE_WEIGHT) temp[date] = daily.inactiveWeight
            else if (row === TOTAL_DOOR_OPEN_COUNT) temp[date] = daily.openCount
            else if (row === TOTAL_DOOR_SCAN_COUNT) temp[date] = daily.scanCount
        }
        result.push(temp)
    }

    for (const tenantName of tenantNames) {
        const temp: {[key: string]: any} = {
            "Name": tenantName
        }
        for (const tenantWeight of tenantWeights) {
            if (tenantName === tenantWeight.tenantName) temp[tenantWeight.formattedDate] = tenantWeight?.weight || 0 
        }
        result.push(temp)
    }
    return result
}

export const loadPdfViewTenantWeightAmountList = (data: TenantMonthlyWeightAmount, order: string) => {
    const weightAmounts = data?.monthlyTenantWeightAmounts || [];

    const rows: {[key: string]: any}[] = []
    for (let i = 0; i < weightAmounts.length; i++) {
        const row = {
            "tenant": weightAmounts[i].tenantName + " (kg)",
            "weightAmount": weightAmounts[i].weightAmount,
        }
        rows.push(row)
    }
    if (order === "asc") {
        rows.sort((a, b) => a["weightAmount"] - b["weightAmount"])
    } else if (order === "desc") {
        rows.sort((a, b) => b["weightAmount"] - a["weightAmount"])
    }
    const totalOpenCountRow = {
        "tenant": TOTAL_DOOR_OPEN_COUNT,
        "weightAmount": data.totalOpenCount,
    }
    const totalScanCountRow = {
        "tenant": TOTAL_DOOR_SCAN_COUNT,
        "weightAmount": data.totalScanCount,
    }
    const totalWeightRow = {
        "tenant": TOTAL_WEIGHT,
        "weightAmount": data.totalWeightAmount.toFixed(1),
    }
    const card1WeightRow = {
        "tenant": CARD1_WEIGHT,
        "weightAmount": data.card1TotalWeightAmount.toFixed(1),
    }
    const unassignedWeightRow = {
        "tenant": UNASSIGNED_WEIGHT,
        "weightAmount": data.unassignedTotalWeightAmount.toFixed(1),
    }
    const inactiveWeightRow = {
        "tenant": INACTIVE_WEIGHT,
        "weightAmount": data.inactiveTotalWeightAmount.toFixed(1),
    }
    rows.push(unassignedWeightRow)
    rows.push(card1WeightRow)
    rows.push(inactiveWeightRow)
    rows.unshift(totalScanCountRow)
    rows.unshift(totalOpenCountRow)
    rows.unshift(totalWeightRow)
    return rows
}

export const loadDailyChartData = (dataList: DailyDataType[]) => {
    const labels = dataList.map((data: DailyDataType) => `${data?.date?.split('-')[2]} / ${data?.date?.split('-')[1]}`)
    const data = dataList.map((data: DailyDataType) => data?.totalWeight || 0)
    return {
        labels,
        datasets: [
            {
                label: "Weight Amount",
                data,
                backgroundColor: '#D14D72',
            }
        ]
    }
}

// export const loadWeightAmountChartData = (dataList: TenantDailyWeightAmount[]) => {
//     const labels = dataList.map((data: TenantDailyWeightAmount) => `${data?.date?.split('.')[0]} / ${data?.date?.split('.')[1]}`)
//     const data = dataList.map((data: TenantDailyWeightAmount) => data?.totalWeightAmount || 0)
//     return {
//         labels,
//         datasets: [
//             {
//                 label: "Weight Amount",
//                 data,
//                 backgroundColor: '#D14D72',
//             }
//         ]
//     }
// }

export const loadWeightAmountChartDataForPdf = (dataList: DailyDataType[]) => {
    return dataList.map(data => {
        return {
            label: data?.date?.split('-')[2],
            value: data?.totalWeight || 0
        }
    })
}

export const loadDoorOpenChartDataForPdf = (dataList: DailyDataType[]) => {
    return dataList.map(data => {
        return {
            label: data?.date?.split('-')[2],
            value: data?.openCount || 0
        }
    })
}

export const loadDoorScanChartDataForPdf = (dataList: DailyDataType[]) => {
    return dataList.map(data => {
        return {
            label: data?.date?.split('-')[2],
            value: data?.scanCount || 0
        }
    })
}

export const getSelectedOrganizationName = (id: number, organizations: OrganizationsData[]) => {
    for (const organization of organizations) {
        if (+organization.id! === +id) {
            return organization.organizationName
        }
    }
    return ""
}

export const getSelectedMachineName = (id: number, machines: MachinesData[]) => {
    for (const machine of machines) {
        if (+machine.id! === +id) {
            return machine.machineName
        }
    }
    return ""
}