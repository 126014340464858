import Alert from "@mui/material/Alert";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { convertToLocaleDate } from '../../utils/DateTimeUtil';
import ImageUpload from '../Locations/components/ImageUpload';
import CalendarModal from '../Shared/CalendarModal/CalendarModal';
import { ModalContainer } from "../Shared/Common/Containers";
import './Organizations.css';
import noImagePng from '../../assets/images/no-image.png'

const OrgModal = ({
    show,
    title,
    orgData,
    orgImages,
    successText,
    errorText,
    btnColor,
    btnName,
    onChangeImage,
    onChangeOrgData,
    onChangeSubData,
    onOrgForm,
    onClose,
}: any): JSX.Element => {
    const [expiredDatePicker, setExpiredDatePicker] = useState(false)

    return ( 
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            {successText && <Alert severity="success">{successText}</Alert>}
            {errorText && <Alert severity="error">{errorText}</Alert>}
            <Modal.Body>
                <Form className="ModalForm">
                    <ModalContainer>
                        <Image width={200} height={150} className="mx-auto mb-3" src={orgData.organizationImage || noImagePng} rounded />
                        <Form.Group className="mb-3">
                            <InputGroup className="mb-3">
                                <InputGroup.Text className="input-org">Name <span className="text-danger">*</span></InputGroup.Text>
                                <Form.Control name="organizationName" onChange={onChangeOrgData} type="name" placeholder="Enter Organization Name" value={orgData.organizationName} required/>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text className="input-org">Remark</InputGroup.Text>
                                <Form.Control name="remark" onChange={onChangeOrgData} type="remark" placeholder="Enter Remark" value={orgData.remark} required/>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Carbon Reduction Factor <span className="text-danger">*</span></InputGroup.Text>
                                <Form.Control name="carbonReductionFactor" onChange={onChangeOrgData} type="carbonReductionFactor" placeholder="Enter Carbon Reduction Factor" value={orgData.carbonReductionFactor} required/>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <InputGroup.Text>Subscrption Expired Date <span className="text-danger">*</span></InputGroup.Text>
                                <Button variant="outline-secondary" id="button-addon1" onClick={() => setExpiredDatePicker(true)}>
                                    {
                                         convertToLocaleDate(orgData?.subscriptionExpiredDate) || "Expired Date"
                                    }
                                </Button>
                                <CalendarModal 
                                    show={expiredDatePicker}
                                    onCloseModal={() => {setExpiredDatePicker(false)}}
                                    onChange={(value: Date) => {
                                        onChangeSubData(value)
                                        setExpiredDatePicker(false)
                                    }}
                                    defaultValue={
                                        convertToLocaleDate(orgData?.subscriptionExpiredDate) ?
                                        new Date(convertToLocaleDate(orgData?.subscriptionExpiredDate)) :
                                        ""
                                    }
                                />
                            </InputGroup>
                            <ImageUpload 
                                images={orgImages}
                                onChange={onChangeImage}
                            />
                        </Form.Group>
                    </ModalContainer>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Cancel</Button>
                <Button variant={btnColor} onClick={onOrgForm}>{btnName}</Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default OrgModal;