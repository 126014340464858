import { RoleDefault, RolesData } from "./RoleType";

export interface UserInfo {
  id?: number;
  userName: string;
  name: string;
  email: string;
  accessToken: string;
  roleId: number;
  orgId: number;
  orgName?: string;
  orgImage?: string;
  roleName?: string;
  roleData: RolesData;
}

export type UserAuthType = {
  userInfo: UserInfo;
  getUserInfo: () => UserInfo;
  updateUserInfo: (userInfo: UserInfo) => void;
  handleLogin: (username: any) => void;
  handleLogout: () => void;
  isAuth: () => boolean;
  hasPermission: (permission: string) => boolean;
};

export const DefaultUserInfo: UserInfo = {
  id: -1,
  userName: "",
  name: "",
  email: "",
  accessToken: "",
  roleId: 0,
  orgId: 0,
  orgName: "",
  orgImage: "",
  roleName: "",
  roleData: RoleDefault,
};
