import { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

type Props = {
    text: string
}

const SuccessToastify = (props: Props) => {
    const {text} = props
    useEffect(() => {
        if (text)
            toast.success(text, {
                position: "top-right"
            })
    }, [text])
    return (  
        <>
            <ToastContainer />
        </>
    );
}
 
export default SuccessToastify;