export type UserInTenantData = {
    id?: number,
    organizationId: number,
    tenantId: number,
    tenantName: string,
    userId: number,
    userName: string,
    remark?: string,
    createdBy?: string;
    updatedBy?: string;
    deletedBy?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}

export type UserInTenantResponseBody = {
    data: UserInTenantData,
    status: number,
    message: string,
}
  
export type UserInTenantGETRequestBody = {
    tenantId: number,
    limit?: number,
    offset?: number
}

export type UserInTenantADDRequestBody = {
    tenantId: number,
    userId: number,
    remark?: string,
    createdBy: string,
}

export type UserInTenantDELETERequestBody = {
    id: number,
    deletedBy: string
}

export type DeleteUser = {
    id: number,
    name?: string | number,
}
  
export const UserDefault = {
    organizationId: 0,
    userId: 0,
    userName: "",
    remark: "",
}
