import Modal from 'react-bootstrap/Modal';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from "swiper";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { convertToLocaleDate } from '../../utils/DateTimeUtil';
import { NOImage } from '../../constants/NoImageContant';
import "./ImageSwiper.css"
import "swiper/css";
import "swiper/css/pagination";
import { NO_IMAGE_URL } from '../../constants/GlobalConstants';
import { mergeImageUrlWithBaseUrl } from '../../utils/ImageUtil';

const ImageModal = ({
    show, 
    title,
    handleClose,
    images,
    count
}: any) => {
    return ( 
        <Modal show={show} size="lg">
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <CloseOutlinedIcon 
                    onClick={handleClose}
                />
            </Modal.Header>
            <Modal.Body>
                <p>Total Image Count: {count}</p>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="door-log-swiper"
                >
                    {
                        (images && images.length > 0) ?
                        images.map((image: {url: string, captureTime: string}, index: number) => 
                            <SwiperSlide key={index} className='door-log-swiper-slide'>
                                <img src={mergeImageUrlWithBaseUrl(image?.url) || NO_IMAGE_URL} alt="Capture Image" />
                                <p>{convertToLocaleDate(image?.captureTime) || "No Date Available"}</p>
                            </SwiperSlide>
                        ) :
                        <SwiperSlide className='door-log-swiper-slide'>
                            <img src={NO_IMAGE_URL} alt="Not Found" />
                        </SwiperSlide>
                    }
                </Swiper>
            </Modal.Body>
        </Modal>
    );
}
 
export default ImageModal;