import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { Box, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { ReactNode, useState } from "react";
import { Form } from "react-bootstrap";
import { IoIosWarning } from "react-icons/io";
import { IoFastFood } from "react-icons/io5";
import SearchableSelect from 'react-select';
import { DropdownData } from "../../types/global/DropDownType";
import { getMonthChoices, getYearChoices } from "../../utils/DateTimeUtil";
import { isTenantUser } from "../../utils/UserUtil";
import CalendarModal from "../Shared/CalendarModal/CalendarModal";
import "./Report.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            overflow    : "scroll"
        },
    },
};

export const getMachineLabel = (label: string): ReactNode => {
    if (label?.startsWith("[GW]")) return <><RestoreFromTrashIcon className="me-2 text-success fs-3"/><span>{label.split("] ")[1]}</span></>
    else if (label?.startsWith("[FW]")) return <><IoFastFood className="me-2 text-primary fs-4"/><span>{label.split("] ")[1]}</span></>
    else if (label?.startsWith("[NO!]")) return <><IoIosWarning className="me-2 text-danger fs-4"/><span>{label.split("] ")[1]}</span></>
}

const FilterSection = ({
    commonFilters,
    hourFilters,
    dayFilters,
    monthFilters,
    orgList,
    locationDropdownList,
    machineDropdownList,
    tenantDropDownList,
    selectedYear,
    tempLocationList,
    tempMachineList,
    minMaxEndDate,
    handleCloseMachineListDropDown,
    handleCloseLocationListDropDown,
    handleChangeSelectedYear,
    handleTempChangeMachineList,
    handleTempChangeLocationList,
    onChangeCommonFilter,
    onChangeDayFilter,
    onChangeMonthFilter,
    onChangeHourFilter,
}: any) => {

    const [showStartDateModal, setShowStartDateModal] = useState(false)
    const [showEndDateModal, setShowEndDateModal] = useState(false)
    const [showDayModal, setDayModal] = useState(false)

    const toggleDayModal = () => {
        setDayModal(prev => !prev)
    }

    const toggleStartDateModal = () => {
        setShowStartDateModal(prev => !prev)
    }

    const toggleEndDateModal = () => {
        setShowEndDateModal(prev => !prev)
    }

    return (  
        <Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        gap: 2,
                        marginY: 3,
                        flexWrap: "wrap",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Form.Group>
                        <Form.Label className="fw-bold">Organization</Form.Label>
                        <Form.Select 
                            style={{width: "250px"}}
                            name="org" 
                            value={commonFilters.org}
                            onChange={(event) => onChangeCommonFilter(event.target.name, event.target.value)}
                        >
                            {
                                (orgList && orgList.length > 0) ?
                                orgList.map((org: DropdownData) => <option value={org.value} key={org.value}>{org.label}</option>) :
                                <option value={-1}>No Organizations</option>
                            }
                        </Form.Select>
                    </Form.Group>
                    {
                        !isTenantUser() &&
                        <FormControl sx={{ m: 1, width: 250 }}>
                            <InputLabel id="demo-multiple-checkbox-label">Location</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                multiple
                                name="locations"
                                value={tempLocationList}
                                onChange={handleTempChangeLocationList}
                                onClose={handleCloseLocationListDropDown}
                                input={<OutlinedInput label="Location" />}
                                MenuProps={MenuProps}
                                renderValue={(selected) => {
                                    return `Locations: ${selected.length}`
                                }}
                            >
                            {
                                (locationDropdownList && locationDropdownList.length > 0) &&
                                locationDropdownList.map((location: DropdownData) => (
                                    <MenuItem key={location.value} value={+location.value!} >
                                        <Checkbox checked={tempLocationList.indexOf(+location.value!) > -1} />
                                        <ListItemText primary={location.label}/>
                                    </MenuItem>
                                ))
                            }
                            </Select>
                        </FormControl>
                    }
                    <FormControl sx={{ m: 1, width: 250 }}>
                        <InputLabel id="demo-multiple-checkbox-label">Machine</InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            name="machines"
                            value={tempMachineList}
                            onChange={handleTempChangeMachineList}
                            onClose={handleCloseMachineListDropDown}
                            input={<OutlinedInput label="Machine" />}
                            MenuProps={MenuProps}
                            renderValue={(selected) => {
                                return `Machines: ${selected.length}`
                            }}
                        >
                        {
                            (machineDropdownList && machineDropdownList.length > 0) &&
                            machineDropdownList.map((machine: DropdownData) => (
                                <MenuItem key={machine.value} value={+machine.value!} >
                                    <Checkbox checked={tempMachineList.indexOf(+machine.value!) > -1} />
                                    <ListItemText primary={getMachineLabel(machine.label || "")}/>
                                </MenuItem>
                            ))
                        }
                        </Select>
                    </FormControl>
                    {
                        (!isTenantUser()) &&
                        <Form.Group>
                            <Form.Label className="fw-bold">Tenant ({(tenantDropDownList?.length - 2) || 0})</Form.Label>
                            <SearchableSelect
                                name="tenant"
                                value={commonFilters.tenant}
                                onChange={(item) => onChangeCommonFilter("tenant", item)}
                                options={tenantDropDownList}
                                isSearchable
                                className="tenant-select"
                            />
                        </Form.Group>
                    }
                </Box>
            </Box>
            <Box 
                sx={{
                    display: "flex",
                    gap: 2,
                    flexWrap: "wrap",
                    alignItems: "flex-end",
                }}
            >
                <Form.Select 
                    name="view"
                    onChange={(event) => onChangeCommonFilter(event.target.name, event.target.value)}
                    value={commonFilters.view}
                    style={{width: "100px"}} 
                >
                    <option value="1">Month</option>
                    <option value="2">Year</option>
                    <option value="3">Day</option>
                    <option value="4">Hour</option>
                </Form.Select>
                {
                    commonFilters.view === '1' &&
                    <Box className="d-flex gap-2">
                        <Form.Select
                            name="year"
                            onChange={(event) => onChangeMonthFilter(event.target.name, event.target.value)}
                            value={monthFilters.year}
                            style={{width: "150px"}} 
                        >
                            {
                                getYearChoices().map((year) => <option value={year.value} key={year.value}>{year.label}</option>)
                            }
                        </Form.Select>
                        <Form.Select
                            name="month"
                            onChange={(event) => onChangeMonthFilter(event.target.name, event.target.value)}
                            value={monthFilters.month}
                            style={{width: "150px"}} 
                        >
                            {
                                getMonthChoices().map((month) => <option value={month.value} key={month.value}>{month.label}</option>)
                            }
                        </Form.Select>
                    </Box>
                }
                {
                    commonFilters.view === '2' &&
                    <Form.Select
                        onChange={handleChangeSelectedYear}
                        value={selectedYear}
                        style={{width: "150px"}} 
                    >
                        {
                            getYearChoices().map((year) => <option value={year.value} key={year.value}>{year.label}</option>)
                        }
                    </Form.Select>
                }
                {
                    commonFilters.view === '3' &&
                    <Box
                        sx={{
                            display: "flex",
                            gap: 5,
                            width: "500px",
                            flexWrap: "wrap",
                            alignItems: "flex-end",
                        }}
                    >
                        <Box>
                            <Typography>Start Date</Typography>
                            <button type="button" className="btn btn-outline-dark" onClick={toggleStartDateModal} >{dayFilters.startDate || "Start Date"}</button>
                        </Box>
                        <Box>
                            <Typography>End Date</Typography>
                            <button type="button" className="btn btn-outline-dark" onClick={toggleEndDateModal}>{dayFilters.endDate || "End Date"}</button>
                        </Box>
                        <CalendarModal 
                            show={showStartDateModal}
                            onCloseModal={toggleStartDateModal}
                            onChange={(value: Date) => {
                                onChangeDayFilter("startDate", value)
                                toggleStartDateModal()
                            }}
                        />
                        <CalendarModal 
                            show={showEndDateModal}
                            onCloseModal={toggleEndDateModal}
                            onChange={(value: Date) => {
                                onChangeDayFilter("endDate", value)
                                toggleEndDateModal()
                            }}
                            maxDate={minMaxEndDate.max ? new Date(minMaxEndDate.max) : new Date()}
                            minDate={minMaxEndDate.min ? new Date(minMaxEndDate.min) : new Date(2000, 1, 1)}
                        />
                    </Box>
                }
                {
                    commonFilters.view === '4' &&
                    <Box>
                        <Box>
                            <button type="button" className="btn btn-outline-dark" onClick={toggleDayModal} >{`${hourFilters.day}` || "Select Day"}</button>
                        </Box>
                        <CalendarModal 
                            show={showDayModal}
                            onCloseModal={toggleDayModal}
                            onChange={(value: Date) => {
                                onChangeHourFilter("day", value)
                                toggleDayModal()
                            }}
                        />
                    </Box>
                }
            </Box>
        </Box>
    );
}
 
export default FilterSection;