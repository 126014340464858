import { FilterContainer } from "../Shared/Common/Containers";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { ChangeEvent, FunctionComponent } from "react";
import { FilterValue } from "../../types/global/FilterValueType";

interface Props {
    filterValues: FilterValue,
    onChangeFilter: (event: ChangeEvent<HTMLInputElement>) => void
}

const AlertUserFilters: FunctionComponent<Props> = ({
    filterValues,
    onChangeFilter
}) => {
    return (  
        <FilterContainer>
            <Form.Group>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <InputGroup>
                            <InputGroup.Text id="inputGroup-sizing-lg">Search</InputGroup.Text>
                            <Form.Control
                                aria-label="Large"
                                aria-describedby="inputGroup-sizing-sm"
                                value={filterValues.search}
                                onChange={onChangeFilter}
                                name="search"
                                placeholder="User"
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </Form.Group>
        </FilterContainer>
    );
}
 
export default AlertUserFilters;