import { MACHINE_TYPES } from "../constants/GlobalConstants";
import { LocationsData } from "../types/LocationsType";
import { MachinesData } from "../types/MachinesType";
import { OrganizationsData } from "../types/OrganizationsType";
import { TenantsData } from "../types/TenantsType";

export const loadMachineList = (machineList: MachinesData[]) => {
    if (!machineList) {return [];}
    let machineDopDownList = machineList.map((machine) => {
      let label = ""
      switch(machine?.machineType) {
        case MACHINE_TYPES.FOOD_WASTE:
          label = `[FW] ${machine?.machineName}`
          break;
        case MACHINE_TYPES.GENERAL_WASTE:
          label = `[GW] ${machine?.machineName}`
          break;
        case MACHINE_TYPES.NO_TYPE:
          label = `[NO!] ${machine?.machineName}`
          break;
        default:
          label = `[NO!] ${machine?.machineName}`
      }
      return {
        label: label,
        value: machine?.id?.toString() || "0",
      };
    });
    return machineDopDownList
};

export const loadOrganizationList = (orgList: OrganizationsData[]) => {
    if (!orgList) return [];
    let orgDropDownList = orgList.map((org) => {
      return {
        label: org?.organizationName,
        value: org?.id?.toString(),
      }
    })
    return orgDropDownList;
}

export const loadLocationList = (locations: LocationsData[]) => {
  if (!locations) return [];
  let locationDropDownList = locations.map((location) => {
    return {
      label: location?.locationName,
      value: location?.id?.toString(),
    }
  })
  return locationDropDownList;
}

export const loadTenantList = (tenantList: TenantsData[]) => {
  if (!tenantList) return [];
  let tenantDropDownList = tenantList.map((tenant) => {
    return {
      label: tenant?.tenantName,
      value: tenant?.id?.toString(),
    }
  })
  return tenantDropDownList;
}