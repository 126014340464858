import { ROLES, STATUS_CODES } from "../constants/GlobalConstants";
import { roleGetDataByIdApi } from "../services/RouteServices/RoleApi";
import { getAvailableLocationsByUserIdsApi } from "../services/RouteServices/UsersAvailableLocationsApi";
import { RoleResponseBody, RolesData } from "../types/RoleType";
import { DefaultUserInfo, UserInfo } from "../types/UserAuth";

export const getCurrentLocalUser = () => {
    let cUser : UserInfo = DefaultUserInfo;
    const localUser = localStorage.getItem('fwUserAuth');
    if (localUser) {
        cUser = JSON.parse(localUser) || null;
    }
    return cUser;
}

export const getUserPermissions = () => {
    const permissions = JSON.parse(localStorage.getItem("permissions") || "{}");
    return permissions;
}

export const getCurrentUsernameOnly = () => {
    let cUserOnly : string = getCurrentLocalUser()?.userName || "unknown";
    return cUserOnly;
}

export const getCurrentUserRoleName = () => {
    const roleData: RolesData = JSON.parse(localStorage.getItem("permissions") || "{}");
    return roleData?.roleName || "unknown role"
}

export const isSuperSpecialUser = () : boolean => {
    let cUserRoleId : number = getCurrentLocalUser()?.roleId || 0;
    const res = (cUserRoleId <= 4 && cUserRoleId > 0) ? true : false;
    return res;
}

export const isSpecialUser = () : boolean => {
    let currentUser : UserInfo = getCurrentLocalUser();
    const permission = getUserPermissions();
    if (permission?.isAdmin) return false
    return (currentUser?.roleId > 6 && permission?.isOperator && !permission?.isAdmin)
}

export const isRootAndSuperUsers = () : boolean => {
    let cUserRoleId : number = getCurrentLocalUser()?.roleId || 0;
    const res = (cUserRoleId <= 2 && cUserRoleId > 0) ? true : false;
    return res;
}

export const isAdminUser = (): boolean => {
    let currentUser : UserInfo = getCurrentLocalUser();
    const permission = getUserPermissions();
    if (JSON.stringify(permission) === "{}") return false;
    return (currentUser?.roleId === ROLES.ADMIN_ID) || (currentUser?.roleId > 6 && permission?.isAdmin);
}

export const isTenantUser = () : boolean => {
    let cUserRoleId : number = getCurrentLocalUser()?.roleId || 0;
    const permission = getUserPermissions();
    if (JSON.stringify(permission) === "{}") return false;
    if (cUserRoleId > 6 && !permission?.isAdmin && !permission?.isOperator) return true
    return (cUserRoleId === ROLES.TENANT_ID);
}

export const checkSuperSpecialRole = (roleId: number): boolean => {
    const roles = [1, 2, 3, 4]
    if (roleId === 0) {
        return false
    }
    return roles.includes(roleId)
}

export const checkSpecialRole = (roleId: number): boolean => {
    if (!roleId || roleId <= 0) return false;
    return roleId > 6;
}

export const checkAdminRole = (roleId: number): boolean => {
    if (roleId <= 0) {
        return false
    }
    return roleId === ROLES.ADMIN_ID
}

export const checkTenantRole = (roleId: number): boolean => {
    if (roleId === ROLES.TENANT_ID) {
        return true;
    } else {
        return false;
    }
}

export const updatePermissions = (roleId: number) => {
    roleGetDataByIdApi(roleId)
    .then(res => {
        const resData: RoleResponseBody = res?.data
        if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
            localStorage.setItem("permissions", JSON.stringify(resData.data));
        } else {
            console.log("Bad Request")
        }
    }).catch(error => {
        console.log(error)
    })
}

export const setAvailableLocations = (userId: number) => {
    const input = {
        userIds: [userId]
    }
    getAvailableLocationsByUserIdsApi(input)
    .then((res) => {
        const response = res?.data
        if (response?.status === STATUS_CODES.SUCCESS_CODE) {
            localStorage.setItem("available-locations", JSON.stringify(response.data?.map((dict: any) => dict.locationId)));
        } else {
            console.log("Bad Request")
        }
    }).catch(error => {
        console.log(error)
    })
}

export const getAvailableLocations = () => {
    const locations = JSON.parse(localStorage.getItem("available-locations") || "[]");
    return locations;
}

export const checkLocation = () => {
    return (!isSuperSpecialUser() && !isSpecialUser())
}

export const clearCurrentUserFromLocal = () => {
    localStorage.removeItem('fwUserAuth');
}