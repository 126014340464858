import React, { useEffect, useState } from 'react';
import AccountSetting from './AccountSetting';
import Layout from '../Layout/Layout';
import "./Setting.css"
import TenantSetting from './TenantSetting';
import { getCurrentLocalUser, isAdminUser, isSuperSpecialUser } from '../../utils/UserUtil';
import { UserInfo } from '../../types/UserAuth';
import { getTenantByUserIdApi } from '../../services/RouteServices/TenantsApi';
import { STATUS_CODES } from '../../constants/GlobalConstants';
import { Container } from '../Shared/Common/Containers';
import { Title } from '../Shared/Common/Titles';
import { Box } from '@mui/material';
import UpdatePasswordSetting from './UpdatePasswordSetting';

const Setting: React.FC = () => {

  const [tenantName, setTenantName] = useState<string>();

  const userInfo: UserInfo = getCurrentLocalUser();

  useEffect(() => {
    getTenantByUserIdApi(userInfo?.id || 0)
    .then(res => {
      if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
        setTenantName(res?.data?.data?.tenantName)
      } else {
        console.log(res?.data?.message)
      }
    }).catch(error => {
      console.log(error)
    })
  }, [userInfo])

  return ( 
      <>
          <Layout>
              <Container>
                  <Box sx={(theme) => ({
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    flexWrap: "wrap",
                    
                  })}>
                    <Box sx={(theme) => ({
                      marginBottom: 3,
                    })}>
                      <Title>Account</Title>
                      <AccountSetting/>
                    </Box>
                    <Box>
                      <UpdatePasswordSetting />
                    </Box>
                  </Box>

                  <br/>

                  {(!isSuperSpecialUser() && !isAdminUser()) &&
                    <>
                      <Title>Tenant</Title>
                      <TenantSetting tenantName={tenantName}/>
                    </>
                  }
                  
              </Container>
          </Layout>
      </>
  );

}
 
export default Setting;