import React, { ChangeEvent, useEffect, useState } from "react";
import '../../styling/customStyle.css';
import Layout from "../Layout/Layout";

import { PAGE_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";
import { organizationAddDataApi, organizationDeleteDataApi, organizationGetDataApi, organizationRemoveImageApi, organizationUpdateDataApi } from "../../services/RouteServices/OrganizationsApi";
import { OrgDefault, OrgListResponseBody, OrgResponseBody, OrgUPDATERequestBody, OrganizationsData } from "../../types/OrganizationsType";

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Avatar } from "@mui/material";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Button from "react-bootstrap/Button";
import { FaBuilding } from "react-icons/fa";
import { FcRemoveImage } from "react-icons/fc";
import { ImageListType } from "react-images-uploading";
import { toast } from 'react-toastify';
import { UserInfo } from "../../types/UserAuth";
import { FilterValue } from "../../types/global/FilterValueType";
import { convertDateToString, convertToLocaleDate, getCurrentLocaleDate, getDayDifference } from '../../utils/DateTimeUtil';
import { getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import { getCurrentLocalUser, isRootAndSuperUsers } from "../../utils/UserUtil";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title } from "../Shared/Common/Titles";
import DeleteIconButton from "../Shared/DeleteIconButton/DeleteIconButton";
import OrgModal from './OrgModal';
import OrganizationFilterContainer from "./OrganizationFilterContainer";
import RemoveImageModal from "./RemoveImageModal";

export interface OrgFilterValueType extends FilterValue {
    expire: number,
}

const Organizations: React.FC = () => {
    const tableLimit = PAGE_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();
    const DefaultFilterValues = {
        ...getDefaultFilterValue(),
        expire: 0
    }

    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const [tablePage, setTablePage] = useState(1);
    const [tableOffset, setTableOffset] = useState(0);
    const [orgTotalCount, setOrgTotalCount] = useState(0);
    
    const [organizationsList, setOrganizationsList] = useState<OrganizationsData[]>([]); // * Original data object from db

    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showRemoveImageModal, setShowRemoveImageModal] = useState<boolean>(false);
    const [orgData, setOrgData] = useState<OrganizationsData>(OrgDefault);
    const [modalSuccessMsg, setModalSuccessMsg] = useState<string>("");
    const [modalErrorMsg, setModalErrorMsg] = useState<string>("");
    const [filterValues, setFilterValues] = useState<OrgFilterValueType>(DefaultFilterValues)
    const [images, setImages] = useState<ImageListType>([]);

    const pageCount = (Math.ceil(orgTotalCount/tableLimit)) || 0

    useEffect(() => {
        getManageOrganizationsApiHandler(tableLimit, tableOffset);
    }, [tableOffset, filterValues]);

    useEffect(() => {
        if (orgData.organizationName !== "" && orgData.remark !== "") 
            setModalErrorMsg("");
    }, [orgData])

    useEffect(() => {
        if(successText) {
            displaySuccessAlert()
        }
    }, [successText])

    const handleChangeOrgImages = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
        // data for submit
        setImages(imageList);
    };

    // Input Elements
    const handleChangeOrgData = (event: ChangeEvent<HTMLInputElement>) => {
        setOrgData((prevOrgData:OrganizationsData) => {
            return {
                ...prevOrgData,
                [event.target.name]: event.target.value
            }
        })
    }

    const handleChangeSubExpiredDate = (value: Date) => {
        setOrgData((prevOrgData:OrganizationsData) => {
            return {
                ...prevOrgData,
                "subscriptionExpiredDate": convertDateToString(value)
            }
        })
    }

    const handleClickRemoveData = (event: ChangeEvent<HTMLInputElement>) => {
        removeOrganizationImage()
    }

    const showAlertAndRefresh = (resData: OrgResponseBody, action: string) => {
        if (resData?.status === STATUS_CODES.SUCCESS_CODE){
            setModalErrorMsg("")
            setErrorText("")
            setSuccessText(resData?.message)
            setTimeout(() => {
                setSuccessText("")
            }, 2000)
            setShowAddModal(false);
            setShowEditModal(false);
            setOrgData(OrgDefault);
            getManageOrganizationsApiHandler(tableLimit, tableOffset);   
        }
        else{
            if (['add', 'update'].includes(action)) {
                setErrorText("")
                setModalErrorMsg(resData?.message)
            } else {
                setModalErrorMsg("")
                setErrorText(resData?.message)
            }
        }
    }

    const displaySuccessAlert = () => {
        toast.success(successText, {
            position: toast.POSITION.TOP_CENTER,
            closeButton: false,
            style: {
                width: "500px"
            }
        });
    }

    const handleClose = () => {
        setShowAddModal(false);
        setShowEditModal(false);
        setOrgData(OrgDefault);
    }

    const handleChangeFilterValues = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterValues({
            ...filterValues,
            [event.target.name]: event.target.value
        })
    }


    const openAddOrgModal = () => {
        setModalErrorMsg("");
        setModalSuccessMsg("");
        setImages([])
        setShowAddModal(true);
    }
       
    const openEditOrgModal = (orgObj: OrganizationsData) => {
        setModalErrorMsg("");
        setModalSuccessMsg("");
        setShowEditModal(true);
        orgObj = {
            ...orgObj,
            remark: orgObj.remark || ""
        }
        setOrgData(orgObj);
        setImages([])
    }

    const openRemoveImageModal = (orgObj: OrganizationsData) => {
        setOrgData(orgObj);
        setShowRemoveImageModal(true)
    }

    const handleAddOrg = async () => {
        const inputObj = {
            organizationName: orgData.organizationName,
            organizationImage: images[0]?.dataURL || "",
            carbonReductionFactor: orgData.carbonReductionFactor,
            subExpiredDate: orgData.subscriptionExpiredDate,
            remark: orgData.remark,
            createdBy: userInfo.userName,
        }
        await organizationAddDataApi(inputObj)
        .then(res => {
            showAlertAndRefresh(res?.data, "add")
        })
        .catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }

    const handleEditOrg = async () => {
        const inputObj: OrgUPDATERequestBody = {
            id: orgData.id || 0, 
            organizationName: orgData.organizationName,
            organizationImage: images[0]?.dataURL || "",
            carbonReductionFactor: orgData.carbonReductionFactor,
            subExpiredDate: convertToLocaleDate(orgData.subscriptionExpiredDate),
            remark: orgData.remark || "", 
            updatedBy: userInfo.userName,
        }
        await organizationUpdateDataApi(inputObj)
        .then(res => {
            showAlertAndRefresh(res?.data, "update")
        })
        .catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        }) 
    }

    const handleDeleteOrg = (id: number) => {
        const inputData = {
            "id": id,
            "deletedBy": userInfo.userName
        }
        organizationDeleteDataApi(inputData)
        .then(res => {
            showAlertAndRefresh(res?.data, "delete")
        })
        .catch(error => {
            setErrorText(error.message)
        })
    }

    const removeOrganizationImage = () => {
        const inputData = {
            "id": orgData.id || 0,
            "updatedBy": userInfo.userName
        }
        organizationRemoveImageApi(inputData)
        .then(res => {
            showAlertAndRefresh(res?.data, "update")
        })
        .catch(error => {
            setErrorText(error.message)
        })
    }

    const getManageOrganizationsApiHandler = async (pageLimit : number, pageOffset : number) => {
        const dataLimitsObject = {
            searchText: filterValues.search || "",
            expire: filterValues.expire,
            limit: pageLimit,
            offset: pageOffset
        }
        
        await organizationGetDataApi(dataLimitsObject)
        .then(response => {
            const resData: OrgListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: OrganizationsData[] = resData.data?.orgList
                const totalCount : number = resData?.data?.totalCount;
                setOrgTotalCount(totalCount)
                setOrganizationsList(dataList);
                // for not showing empty page when delete last item
                if (totalCount != 0 && dataList.length == 0) {
                    setTableOffset(prev => prev != 0 ? prev - 10 : prev)
                    setTablePage(prev => prev != 1 ? prev -1 : prev)
                }
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }   

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };
    // * Render Table Data
    const renderTable = () => {
        return (
            organizationsList?.length !== 0 ? organizationsList?.map((orgData: OrganizationsData) => {          
                const subscriptionExpiredDate = convertToLocaleDate(orgData.subscriptionExpiredDate!)
                const currentDate = getCurrentLocaleDate()
                const daysDiff = getDayDifference(currentDate, subscriptionExpiredDate)
                let subDateColor = ""
                if (daysDiff < 0) {
                    subDateColor = "danger"
                } else if (daysDiff <= 10) {
                    subDateColor = "warning"
                } else if (daysDiff <= 30) {
                    subDateColor = "primary"
                }
                return (
                    <tr key={orgData.id}>
                        <td>{orgData.id}</td>
                        <td>
                            <div style={{display: "flex", alignItems: "center", gap: 10}}>
                                <Avatar
                                    alt="Organization Image"
                                    src={orgData.organizationImage}
                                    sx={{ width: 45, height: 45, }}
                                    children={<FaBuilding style={{fontSize: "20px"}} />}
                                />
                                <div className="d-flex align-items-center gap-2">
                                    {orgData.organizationName || "-"}
                                    {
                                        daysDiff < 0 &&
                                        <WarningAmberIcon 
                                            className="text-danger"
                                            style={{
                                                fontSize: "20px"
                                            }}
                                        />
                                    }
                                </div>
                            </div>
                            
                        </td>
                        <td>{orgData.carbonReductionFactor || 0}</td>
                        <td className={`text-${subDateColor}`}>{convertToLocaleDate(orgData.subscriptionExpiredDate!) || "-"}</td>
                        <td>{orgData.remark || "-"}</td>
                        <td>{orgData.createdBy || "-"}</td>
                        <td>{orgData.updatedBy || "-"}</td>
                        <td>{convertToLocaleDate(orgData.createdAt!) || "-"}</td>
                        <td>{convertToLocaleDate(orgData.updatedAt!) || "-"}</td>
                        <td>
                            <EditOutlinedIcon 
                                sx={{
                                    color: "blue",
                                }}
                                className="pointer"
                                onClick={() => openEditOrgModal(orgData)}
                            />
                            &nbsp;&nbsp;
                            {
                                (isRootAndSuperUsers()) && orgData.id !== 1 &&
                                <DeleteIconButton 
                                    deleteName={orgData.organizationName}
                                    deleteObject="Organization"
                                    onDelete={() => handleDeleteOrg(orgData.id!)}
                                    onOpen={() => setModalErrorMsg("")}
                                />
                            }
                            <FcRemoveImage 
                                style={{
                                    fontSize: "23px"
                                }}
                                className="pointer"
                                onClick={() => openRemoveImageModal(orgData)}
                            />
                        </td>
                    </tr>
                )
            }):
            <tr>
                <td>No data found</td>
            </tr>
        )
    }

    return (
        <>
            <Layout>
                <Container>
                    <Title>Organizations</Title>
                    <Box className="titleBox">
                        <Button className="pt-2 pb-2 ps-3 pe-3" id="btnAddUser" variant="success" onClick={openAddOrgModal}>Add</Button>
                    </Box>
                    {/* {successText && <ToastContainer autoClose={3000} />} */}
                    {successText && <Alert severity="success" sx={{marginBottom: 2}}>{successText}<br/></Alert>}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}

                    <OrganizationFilterContainer 
                        filterValues={filterValues}
                        onChangeFilter={handleChangeFilterValues}
                    />

                    <OrgModal 
                        show={showAddModal}
                        title="Add Organization"
                        onChangeOrgData={handleChangeOrgData}
                        onChangeSubData={handleChangeSubExpiredDate}
                        onChangeImage={handleChangeOrgImages}
                        btnName="Add"
                        onOrgForm={handleAddOrg}
                        onClose={handleClose}
                        orgData={orgData}
                        orgImages={images}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        btnColor="success"
                    />

                    <OrgModal 
                        show={showEditModal}
                        title="Edit Organization"
                        onChangeOrgData={handleChangeOrgData}
                        onChangeSubData={handleChangeSubExpiredDate}
                        onChangeImage={handleChangeOrgImages}
                        btnName="Edit"
                        onOrgForm={handleEditOrg}
                        onClose={handleClose}
                        orgData={orgData}
                        orgImages={images}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        btnColor="primary"
                    />

                    <RemoveImageModal 
                        targetOrg={orgData}
                        show={showRemoveImageModal}
                        toggleShow={() => setShowRemoveImageModal(!showRemoveImageModal)}
                        onDelete={removeOrganizationImage}
                    />
                    
                    <SummaryText>{orgTotalCount} Organizations found!</SummaryText>
                    <TableContainer>
                    <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Carbon Reduction Factor</th>
                                    <th>Subscription Expired Date</th>
                                    <th>Remark</th>
                                    <th>Created By</th>
                                    <th>Updated By</th>
                                    <th>Created At</th>
                                    <th>Updated At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {renderTable()}
                            </ tbody>
                        </table>
                    </InnterTableContainer>
                    </TableContainer>
                    <PaginationContainer>
                        <Pagination count={pageCount} onChange={handleChangePage} page={tablePage} variant="outlined" shape="rounded" size="large" />
                    </PaginationContainer>
                </Container>
            </Layout>
        </>
    );
};

export default Organizations;