import styled from "styled-components";
import { NodeProps } from "../../../types/CommonType";

export const Container = styled.div<NodeProps>`
  background-color: ${(props) => props.backgroundColor ? props.backgroundColor : "#3D90B0"};
  color: #FFF;
  text-align: center;
  border-radius: 11px;
  width: ${(props) => props.width ? props.width : "100%"};
  padding: 10px;
  margin: 0;
`;
