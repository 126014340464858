import React, { ChangeEvent, useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styling/customStyle.css';
import Layout from "../Layout/Layout";

import { STATUS_CODES, PAGE_LIMIT, ROLES } from "../../constants/GlobalConstants";

import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Pagination from "@mui/material/Pagination"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Button from "react-bootstrap/Button";
import { convertToLocaleDate } from '../../utils/DateTimeUtil';
import { RoleDELETERequestBody, RoleDefault, RoleListResponseBody, RoleResponseBody, RolesData } from "../../types/RoleType";
import { roleAddDataApi, roleDeleteDataApi, roleGetListDataApi, roleUpdateDataApi } from "../../services/RouteServices/RoleApi";
import RoleModal from "./RoleModal";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title } from "../Shared/Common/Titles";
import { UserInfo } from "../../types/UserAuth";
import { checkSuperSpecialRole, getCurrentLocalUser, isAdminUser, isRootAndSuperUsers } from "../../utils/UserUtil";
import DeleteIconButton from "../Shared/DeleteIconButton/DeleteIconButton";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PersonPinIcon from '@mui/icons-material/PersonPin';

const Roles: React.FC = () => {
    const tableLimit = PAGE_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();

    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const [tablePage, setTablePage] = React.useState(1);
    const [tableOffset, setTableOffset] = React.useState(0);
    const [pageCount, setPageCount] = React.useState(0);
    const [roleTotalCount, setRoleTotalCount] = React.useState(0);
    const [roleList, setRoleList] = React.useState<RolesData[]>([]);
    const [roleData, setRoleData] = React.useState<RolesData>(RoleDefault);
    
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [modalSuccessMsg, setModalSuccessMsg] = useState<string>("");
    const [modalErrorMsg, setModalErrorMsg] = useState<string>("");  

    useEffect(() => {
        if (isRootAndSuperUsers()) {
            getManageRoleApiHandler(tableLimit, tableOffset)
        }
    }, [tableOffset])

    useEffect(() => {
        if (roleData.roleName !== "" && roleData.remark !== "") {
            setModalErrorMsg("");
        }
    }, [roleData])

    const openAddRoleModal = () => {
        setModalErrorMsg("");
        setModalSuccessMsg("");
        setShowAddModal(true);
    }

    const openEditRoleModal = (roleObj: RolesData) => {
        setModalErrorMsg("");
        setModalSuccessMsg("");
        setShowEditModal(true);
        setRoleData({
            ...roleObj,
            remark: roleObj.remark || "",
        });
    }

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };

    const handleClose = () => {
        setShowAddModal(false);
        setShowEditModal(false);
        setRoleData(RoleDefault);
    }

    const handleChangeRoleData = (event: ChangeEvent<HTMLInputElement>) => {
        setRoleData((prevRoleData) => {
            return {
                ...prevRoleData,
                [event.target.name]: event.target.value
            }
        })
    }

    const handleChangeRolePermission = (event: ChangeEvent<HTMLInputElement>) => {
        setRoleData(
            (prevRolePermission) => {
                return {
                    ...prevRolePermission,
                    [event.target.name]: event.target.checked
                }
            }
        )
    }

    const handleAddRole = async () => {
        const inputObj = {
            ...roleData,
            createdBy: userInfo.userName,
        }
        delete inputObj.updatedBy;
        delete inputObj.deletedBy;
        delete inputObj.deletedAt;
        delete inputObj.createdAt;
        delete inputObj.updatedAt;
        delete inputObj.isAdmin;
        delete inputObj.isOperator;
        await roleAddDataApi(inputObj)
        .then(res => {
            showAlertAndRefresh(res?.data, "add")
        })
        .catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }

    const handleEditRole = () => {
        let inputObj = {
            id: roleData.id || 0, 
            ...roleData,
            updatedBy: userInfo.userName,
        }
        if (!roleData.isAdmin && 
            !(checkSuperSpecialRole(roleData.id!) || roleData.id === ROLES.ADMIN_ID)) {
            inputObj = {
                ...inputObj,
                viewTenant: false,
                viewLfcMachine: false,
                viewUser: false,
                viewPendingUser: false,
                viewMachineLog: false,
                addUser: false,
                editUser: false,
            }
        }
        delete inputObj.createdBy;
        delete inputObj.deletedBy;
        delete inputObj.deletedAt;
        delete inputObj.createdAt;
        delete inputObj.updatedAt;
        roleUpdateDataApi(inputObj)
        .then(res => {
            showAlertAndRefresh(res?.data, "update")
        })
        .catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }

    const handleDeleteRole = (id: number) => {
        const inputObj: RoleDELETERequestBody = {
            "id": id,
            "deletedBy":  userInfo.userName
        }
        roleDeleteDataApi(inputObj)
        .then(res => {
            showAlertAndRefresh(res?.data, "delete")
        })
        .catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }

    const showAlertAndRefresh = (resData: RoleResponseBody, action: string) => {
        if (resData?.status === STATUS_CODES.SUCCESS_CODE){
            setModalErrorMsg("")
            setErrorText("")
            setSuccessText(resData?.message)
            setTimeout(() => {
                setSuccessText("")
            }, 2000)
            setShowAddModal(false);
            setShowEditModal(false);
            setRoleData(RoleDefault);
            getManageRoleApiHandler(tableLimit, tableOffset);   
        }
        else{
            if (['add', 'update'].includes(action)) {
                setErrorText("")
                setModalErrorMsg(resData?.message)
            } else {
                setModalErrorMsg("")
                setErrorText(resData?.message)
            }
        }
    }

    const getManageRoleApiHandler = async (pageLimit : number, pageOffset : number) => {
        const dataLimitsObject = {
            limit: pageLimit,
            offset: pageOffset
        }
        
        await roleGetListDataApi(dataLimitsObject)
        .then(response => {
            const resData: RoleListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: RolesData[] = resData.data?.roleList
                const totalCount : number = resData?.data?.totalCount || 0;
                setRoleTotalCount(totalCount)
                setPageCount((Math.ceil(totalCount/tableLimit)) || 0);
                setRoleList(dataList);
                // for not showing empty page when delete last item
                if (totalCount != 0 && dataList.length == 0) {
                    setTableOffset(prev => prev != 0 ? prev - 10 : prev)
                    setTablePage(prev => prev != 1 ? prev -1 : prev)
                }
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }   

    // * Render Table Data
    const renderTable = () => {
        return (
            roleList?.length !== 0 ? roleList?.map((role: RolesData) => {          
                return (
                    <tr key={role.id}>
                        <td>{role.id}</td>
                        <td>
                            {role.roleName || "-"}
                            {
                                (role.isAdmin || role.id === ROLES.ADMIN_ID) &&
                                <VerifiedUserIcon 
                                    className="text-success fs-6 ms-1"
                                />
                            }
                            {
                                (!role.isAdmin && role.isOperator) &&
                                <PersonPinIcon 
                                    className="text-warning fs-5 ms-1"
                                />
                            }
                        </td>
                        <td>{role.remark || "-"}</td>
                        <td>{role.createdBy || "-"}</td>
                        <td>{role.updatedBy || "-"}</td>
                        <td>{convertToLocaleDate(role.createdAt!) || "-"}</td>
                        <td>{convertToLocaleDate(role.updatedAt!) || "-"}</td>
                        <td>
                            <EditOutlinedIcon 
                                sx={{
                                    color: "blue",
                                }}
                                className="pointer"
                                onClick={() => openEditRoleModal(role)}
                            />
                            &nbsp;&nbsp;
                            {
                                isRootAndSuperUsers() &&
                                <DeleteIconButton 
                                    deleteName={role.roleName}
                                    deleteObject="Role"
                                    onDelete={() => handleDeleteRole(role.id!)}
                                    onOpen={() => setModalErrorMsg("")}
                                />
                            }
                        </td>
                    </tr>
                )
            }):
            <tr>
                <td>No data found</td>
            </tr>
        )
    }

    if (!isRootAndSuperUsers()) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (
        <>
            <Layout>
                <Container>
                    <Box className="d-flex justify-content-between mb-2">
                        <Title>Roles</Title>
                        <Box className="titleBox">
                            <Button className="pt-2 pb-2 ps-3 pe-3" id="btnAddUser" variant="success" onClick={openAddRoleModal}>Add</Button>
                        </Box>
                    </Box>
                    {successText && <Alert severity="success" sx={{marginBottom: 2}}>{successText}<br/></Alert>}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}
                    <RoleModal 
                        show={showAddModal}
                        title="Add Role"
                        onChangeRoleData={handleChangeRoleData}
                        onChangeRolePermission={handleChangeRolePermission}
                        btnName="Add"
                        onRoleForm={handleAddRole}
                        onClose={handleClose}
                        selectedRole={roleData}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        btnColor="success"
                    />

                    <RoleModal 
                        show={showEditModal}
                        title="Edit Role"
                        onChangeRoleData={handleChangeRoleData}
                        onChangeRolePermission={handleChangeRolePermission}
                        btnName="Edit"
                        onRoleForm={handleEditRole}
                        onClose={handleClose}
                        selectedRole={roleData}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        btnColor="primary"
                    />
                    {
                        (userInfo?.roleData?.isOperator && !userInfo?.roleData?.isAdmin) ?
                        <Box className="d-flex justify-content-between">
                            <Box className="d-flex gap-5">
                                <Box className="d-flex gap-1 align-items-center">
                                    <VerifiedUserIcon 
                                        className="text-success fs-5 ms-1"
                                    />
                                    <span>Admin</span>
                                </Box>
                                <Box className="d-flex gap-1 align-items-center">
                                    <PersonPinIcon 
                                        className="text-warning fs-4 ms-1"
                                    />
                                    <span>Operator</span>
                                </Box>
                            </Box>      
                            <SummaryText>{roleTotalCount} Roles found!</SummaryText>
                        </Box> :
                        <SummaryText>{roleTotalCount} Roles found!</SummaryText>
                    }
                    <TableContainer>
                    <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>Remark</th>
                                    <th>Created By</th>
                                    <th>Updated By</th>
                                    <th>Created At</th>
                                    <th>Updated At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {renderTable()}
                            </ tbody>
                        </table>
                    </InnterTableContainer>
                    </TableContainer>
                    <PaginationContainer>
                        <Pagination count={pageCount} onChange={handleChangePage} page={tablePage} variant="outlined" shape="rounded" size="large" />
                    </PaginationContainer>
                </Container>
            </Layout>
        </>
    );
};

export default Roles;