import { WeightLogGETRequestBody } from "../../types/WeightLogType";
import http from "../apiServices";

export const weightLogGetListByMachineIdApi = (inputData: WeightLogGETRequestBody) => {
    return http.post("weight-log/get/by/machineId", inputData)
}

export const weightLogGetListByOrgIdApi = (inputData: WeightLogGETRequestBody) => {
    return http.post("weight-log/get/list/by/orgId", inputData)
}

export const weightLogGetListByUserIdApi = (inputData: WeightLogGETRequestBody) => {
    return http.post("weight-log/get/list/by/userId", inputData)
}

export const weightLogGetExportListByOrgId = (inputData: WeightLogGETRequestBody) => {
    return http.post("weight-log/get/export-list/by/orgId", inputData)
}

export const weightLogGetExportListByUserId = (inputData: WeightLogGETRequestBody) => {
    return http.post("weight-log/get/export-list/by/userId", inputData)
}