import { DetectionCounts, DischargeWeightsData, DoorCounts, HourFilter, NewOverallData, WeightsData } from "../../types/ReportType"
import { convertToLocaleDate, convertUTCHourToLocal } from "../../utils/DateTimeUtil"

const generateLabelArray = (array: any[], view: string) => {
    if (!array) return []
    let labels: any[] = []
    if (view === "1" || view === "3") {
        labels = array?.map((data) => `${convertToLocaleDate(data.date)?.split(" ")[0]}`)
    } else if (view === "2") {
        labels = array?.map((data) => data?.monthName)
    } else if (view === "4") {
        labels = array?.map((data) => data?.hour)?.sort(function(a, b){return a-b})
    }
    return labels
}

export const loadFoodAndGeneralWeightAmountChartData = (overallData: NewOverallData, view: string) => {
    let weightLabels: string[] = generateLabelArray(overallData?.weights, view)
    let dischargeWeightLabels: string[] = generateLabelArray(overallData?.dischargeWeights, view)
    const labels: string[] = Array.from(new Set([...weightLabels, ...dischargeWeightLabels]))

    const foodWeight: number[] = Array.from({ length: labels.length }, (_, index) => {
        const data: WeightsData | undefined = overallData?.weights.find(weight => (
            convertToLocaleDate(weight.date)?.split(" ")[0] === labels[index] || weight.hour === labels[index] || 
            weight.monthName === labels[index]
        ))
        return data?.foodWeight || 0
    });
    const generalWeight: number[] = Array.from({ length: labels.length }, (_, index) =>
        overallData?.weights.find(weight => (
            convertToLocaleDate(weight.date)?.split(" ")[0] === labels[index] || weight.hour === labels[index] || 
            weight.monthName === labels[index]
        ))?.generalWeight || 0
    );
    const dischargeWeight: number[] = Array.from({ length: labels.length }, (_, index) =>
        overallData?.dischargeWeights.find(weight => (
            convertToLocaleDate(weight.date)?.split(" ")[0] === labels[index] || weight.hour === labels[index] || 
            weight.monthName === labels[index]
        ))?.totalDischargeWeight || 0
    );
    return {
        labels,
        datasets: [
            {
                label: "Food Waste",
                data: foodWeight,
                backgroundColor: '#58A399',
            },
            {
                label: "General Waste",
                data: generalWeight,
                backgroundColor: '#41C9E2',
            },
            {
                label: "Discharge Weight",
                data: dischargeWeight || [],
                backgroundColor: '#E9C874',
            }
        ]
    }
}

export const loadCarbonReductionChartData = (overallData: NewOverallData, view: string) => {
    let labels: string[] = generateLabelArray(overallData?.weights, view)
    const data = overallData?.weights?.map((weight: WeightsData) => weight?.carbonReduction || 0)
    return {
        labels,
        datasets: [
            {
                label: "Carbon Reduction",
                data,
                backgroundColor: '#C69774',
            }
        ]
    }
}

export const loadDoorOpenCountChartData = (overallData: NewOverallData, view: string) => {
    let labels: string[] = generateLabelArray(overallData?.doorCounts, view)
    const openCountData = overallData?.doorCounts?.map((doorCount: DoorCounts) => doorCount?.openCount || 0)
    const scanCountData = overallData?.doorCounts?.map((doorCount: DoorCounts) => doorCount?.scanCount || 0)
    return {
        labels,
        datasets: [
            {
                label: "Door Open Count",
                data: openCountData,
                backgroundColor: '#19A7CE'
            },
            {
                label: "Door Scan Count",
                data: scanCountData,
                backgroundColor: '#FFB84C'
            }
        ]
    }
}

export const loadDetectionCountChartData = (overallData: NewOverallData, view: string) => {
    let labels: string[] = generateLabelArray(overallData?.detectionCounts, view)
    const data = overallData?.detectionCounts?.map((detectionCount: DetectionCounts) => detectionCount?.nonOrganicCount || 0)
    return {
        labels,
        datasets: [
            {
                label: "Non-organic Count",
                data,
                backgroundColor: '#4A55A2',
            }
        ]
    }
}


export const transformWeightsDataForExport = (weights: WeightsData[], hourFilters: HourFilter) => {
    return weights.map(weight => {
        let result: any = {}
        if ("date" in weight) result["Date"] = convertToLocaleDate(weight.date)?.split(" ")[0]
        if ("hour" in weight) result["Day"] = hourFilters.day
        if ("hour" in weight) result["Hour"] = weight.hour
        if ("year" in weight) result["Year"] = weight.year
        if ("monthName" in weight) result["Month"] = weight.monthName
        if ("machineName" in weight) result["Machine"] = weight.machineName
        if ("foodWeight" in weight) result["Total Food Waste (kg)"] = weight.foodWeight
        if ("generalWeight" in weight) result["Total General Waste (kg)"] = weight.generalWeight
        if ("totalWeight" in weight) result["Total Weight (kg)"] = weight.totalWeight
        if ("carbonReduction" in weight) result["Carbon Reduction (kg)"] = weight.carbonReduction
        return result
    })
}


export const transformDischargeWeightsDataForExport = (weights: DischargeWeightsData[], hourFilters: HourFilter) => {
    return weights.map(weight => {
        let result: any = {}
        if ("date" in weight) result["Date"] = convertToLocaleDate(weight.date)?.split(" ")[0]
        if ("hour" in weight) result["Day"] = hourFilters.day
        if ("hour" in weight) result["Hour"] = weight.hour
        if ("year" in weight) result["Year"] = weight.year
        if ("monthName" in weight) result["Month"] = weight.monthName
        if ("machineName" in weight) result["Machine"] = weight.machineName
        if ("totalDischargeWeight" in weight) result["Discharge Weight (kg)"] = weight.totalDischargeWeight
        return result
    })
}


export const transformDoorCountsDataForExport = (doorCounts: DoorCounts[], hourFilters: HourFilter) => {
    return doorCounts.map(doorCount => {
        let result: any = {}
        if ("date" in doorCount) result["Date"] = convertToLocaleDate(doorCount.date)?.split(" ")[0]
        if ("hour" in doorCount) result["Day"] = hourFilters.day
        if ("hour" in doorCount) result["Hour"] = doorCount.hour
        if ("year" in doorCount) result["Year"] = doorCount.year
        if ("monthName" in doorCount) result["Month"] = doorCount.monthName
        if ("machineName" in doorCount) result["Machine"] = doorCount.machineName
        if ("scanCount" in doorCount) result["Scan Count"] = doorCount.scanCount
        if ("openCount" in doorCount) result["Open Count"] = doorCount.openCount
        return result
    })
}


export const transformDetectionCountsDataForExport = (detectionCounts: DetectionCounts[], hourFilters: HourFilter) => {
    return detectionCounts.map(detectionCount => {
        let result: any = {}
        if ("date" in detectionCount) result["Date"] = convertToLocaleDate(detectionCount.date)?.split(" ")[0]
        if ("hour" in detectionCount) result["Day"] = hourFilters.day
        if ("hour" in detectionCount) result["Hour"] = detectionCount.hour
        if ("year" in detectionCount) result["Year"] = detectionCount.year
        if ("monthName" in detectionCount) result["Month"] = detectionCount.monthName
        if ("machineName" in detectionCount) result["Machine"] = detectionCount.machineName
        if ("organicCount" in detectionCount) result["Organic Count"] = detectionCount.organicCount
        if ("nonOrganicCount" in detectionCount) result["NonOrganic Count"] = detectionCount.nonOrganicCount
        return result
    })
}


export const generateModifiedChartOption = (orgOptions: {}, barPercentage: number, categoryPercentage: number) => {
    return {
        ...orgOptions,
        datasets: {
            bar: {
              barPercentage: barPercentage,
              categoryPercentage: categoryPercentage,
            },
        },
    }
}


export const generatePdfAnnotations = (
    totalWeight: number,
    totalFoodWeight: number,
    totaGeneralWeight: number,
    totalCarbonReduction: number,
    totalDischargeWeight: number,
    totalDoorOpenCount: number,
    totalDoorScanCount: number,
    totalNonOrganicCount: number
) => {
    return [
        {
            anotations: [
                {
                    text: `Total Weight: ${totalWeight.toFixed(2)}kg`,
                    color: '#D14D72',
                },
                {
                    text: `Food Waste: ${totalFoodWeight.toFixed(2)}kg`,
                    color: '#58A399',
                },
                {
                    text: `Genral Waste: ${totaGeneralWeight.toFixed(2)}kg`,
                    color: '#41C9E2',
                },
                {
                    text: `Discharge Weight: ${totalDischargeWeight.toFixed(2)}kg`,
                    color: '#E9C874',
                },
            ]
        },
        {
            anotations: [
                {
                    text: `Total Carbon Reduction: ${totalCarbonReduction.toFixed(2)}kg`,
                    color: '#C69774',
                },
            ]
        },
        {
            anotations: [
                {
                    text: `Total Door Open Count: ${totalDoorOpenCount || 0}`,
                    color: '#19A7CE'
                },
                {
                    text: `Total Door Scan Count: ${totalDoorScanCount || 0}`,
                    color: '#FFB84C'
                }
            ]
        },
        {
            anotations: [
                {
                    text: `Total Non-organic Count: ${totalNonOrganicCount || 0}`,
                    color: '#4A55A2',
                },
            ]
        },
    ]
}


export const calculateSummary = (data: number[]) => {
    if (!data || data.length < 1) return 0;
    return data.reduce((total: number, current: number) => total + current)
}