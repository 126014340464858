import { Box } from "@mui/material";

const AnnotationContainer = ({children}: any) => {
    return (  
        <Box 
            sx={{
                display: "flex",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
                alignItems: "start"
            }}
        >
            {children}
        </Box>
    );
}
 
export default AnnotationContainer;