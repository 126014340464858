import { FunctionComponent } from "react";
import PreviewTableContainer from "../components/PreviewTableContainer";
import { CARD1_WEIGHT, INACTIVE_WEIGHT, TOTAL_WEIGHT, UNASSIGNED_WEIGHT } from "../../../constants/ReportDetailsContant";

interface MonthlyTableProps {
    data: { [key: string]: any }[]
}
 
const MonthlyTable: FunctionComponent<MonthlyTableProps> = ({
    data
}) => {
    return (  
        <PreviewTableContainer 
            style={{
                minWidth: "550px",
                maxWidth: "650px",
                maxHeight: "500px",
            }}
        >
            <table className="table">
                <thead>
                    <tr className="border-bottom border-dark">
                        <th className="text-uppercase">Tenants</th>
                        <th className="text-uppercase">Monthly Data</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((row, index) => (
                            <tr key={index}
                                className={row.tenant.includes("TOTAL") ? "font-monospace fst-italic fw-bold" : ""}
                            >
                                <td className={
                                    row.tenant === CARD1_WEIGHT ? "text-danger" : 
                                    row.tenant === UNASSIGNED_WEIGHT ? "text-warning" :
                                    row.tenant === INACTIVE_WEIGHT ? "text-primary":
                                    row.tenant === TOTAL_WEIGHT ? "text-success" : ""
                                }
                                >
                                    {row.tenant}
                                </td>
                                <td>{row.weightAmount}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </PreviewTableContainer>
    );
}
 
export default MonthlyTable;