import Box from "@mui/material/Box";
import { ChangeEvent, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from "react-bootstrap";
import { STATUS_CODES } from '../../constants/GlobalConstants';
import { operatorLogConfigAddOrUpdateApi, operatorLogConfigGetFirstRowApi } from '../../services/RouteServices/ConfigApi';
import { OperatorLogConfigType } from "../../types/OperatorLogConfigType";
import { convertToLocaleDate } from "../../utils/DateTimeUtil";
import { getCurrentLocalUser } from '../../utils/UserUtil';
import "./Config.css";
import SuccessToastify from "../Shared/Toastify/SuccessToastify";
import ErrorToastify from "../Shared/Toastify/ErrorToastify";

const OperatorLogConfig = () => {
    const userInfo = getCurrentLocalUser()

    const [operatorLogConfig, setOperatorLogConfig] = useState<OperatorLogConfigType>({} as OperatorLogConfigType)
    const [successText, setSuccessText] = useState<string>("")
    const [errorText, setErrorText] = useState<string>("")

    useEffect(() => {
        operatorLogConfigGetFirstRowApi()
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setOperatorLogConfig(res?.data?.data)
            } else {
                console.log(res?.data?.message)
                setOperatorLogConfig({} as OperatorLogConfigType)
            }
        }).catch(error => {
            setOperatorLogConfig({} as OperatorLogConfigType)
            // handleErrorText(error?.response?.data?.message || error?.message)   
        })
    }, [])

    const resetAlertText = () => {
        setErrorText("")
        setSuccessText("")
    }

    const handleChangeOperatorLogConfig = (event: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target
        setOperatorLogConfig(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleAddOperatorLogConfig = () => {
        resetAlertText()
        const input = {
            dayCount: operatorLogConfig?.dayCount,
            doneBy: userInfo.userName
        }

        operatorLogConfigAddOrUpdateApi(input)
        .then((res: any) => {
            console.log(res)
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setOperatorLogConfig(res?.data?.data)
                setSuccessText(res?.data?.message)
            } else {
                setErrorText(res?.data?.message)
            }
        }).catch((error) => {
            console.log(error)
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }

    return (  
        <Box 
            sx={{minWidth: "400px", marginTop: 3}}
        >
            <Form>
                <Form.Label as="legend">
                    Operator Log Config
                </Form.Label>
                <div
                    className="border border-light border-3 rounded p-3 bg-light bg-gradient" 
                >
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            Day Count
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" name="dayCount" value={operatorLogConfig?.dayCount || ""} onChange={handleChangeOperatorLogConfig} placeholder='0' />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="">
                        <Form.Label column sm="3">
                            
                        </Form.Label>
                        <Col sm="9">
                            <span style={{fontSize:13, color:'#35a612'}}>Last Update - {convertToLocaleDate(operatorLogConfig?.updatedAt || "") || convertToLocaleDate(operatorLogConfig?.createdAt || "")}</span>
                            <br></br>
                            <span style={{fontSize:13, color:'#35a612'}}>Updated User - {operatorLogConfig?.updatedBy || operatorLogConfig?.createdBy}</span>
                        </Col>
                    </Form.Group>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "right"
                        }}
                    >
                        <Button variant="primary" onClick={handleAddOperatorLogConfig}>Update</Button>
                    </Box>
                </div>
            </Form>

            <SuccessToastify
                text={successText}
            />
            <ErrorToastify 
                text={errorText}
            />
        </Box>
    );
}
 
export default OperatorLogConfig;