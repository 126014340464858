import { STATUS_CODES } from "../../constants/GlobalConstants";
import { MachineListResponseBody, MachinesData } from '../../types/MachinesType';
import { TenantListResponseBody, TenantsData } from '../../types/TenantsType';
import { isAdminUser, isTenantUser } from '../../utils/UserUtil';
import { locationGetByOrgIdApi } from './LocationsApi';
import { machineGetAllByLocationIdListDataApi, machineGetAllByOrgIdDataApi, machineGetAllDataApi, machineGetAllOfATenantApi } from './MachinesApi';
import { organizationGetAllDataApi, organizationGetByIdApi } from './OrganizationsApi';
import { tenantGetAllByMachineIdApi, tenantGetAllByMachineIdListApi, tenantGetAllByOrgIdApi } from './TenantsApi';

export const getAllOrgsList = (): Promise<any> => {
    return organizationGetAllDataApi()
    .then((res) => {
        return new Promise((resolve, reject) => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                resolve(res?.data?.data?.orgList)
            else 
                reject(res?.data?.message)
        })        
    })
    .catch(error => {
        return Promise.reject(error?.response?.data?.message || error?.message)
    })
}

export const getAllMachineList = (): Promise<any> => {
    return machineGetAllDataApi()
    .then((res) => {
        return new Promise((resolve, reject) => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                resolve(res?.data?.data?.machineList)
            else 
                reject(res?.data?.message)
        })        
    })
    .catch(error => {
        return Promise.reject(error?.response?.data?.message || error?.message)
    })
}

export const getLocationByOrgId = (orgId: number): Promise<any> => {
    return locationGetByOrgIdApi(orgId)
    .then(res => {
        return new Promise((resolve, reject) => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                resolve(res?.data?.data?.locationList)
            }
            else 
                reject(res?.data?.message)
        })   
    }).catch(error => {
        return Promise.reject(error?.response?.data?.message || error?.message)
    })
}

export const machineGetAllByLocationIdListData = (locationIdList: number[]): Promise<any> => {
    return machineGetAllByLocationIdListDataApi(locationIdList)
    .then(res => {
        return new Promise((resolve, reject) => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                resolve(res?.data?.data?.machineList)
            }
            else 
                reject(res?.data?.message)
        })   
    }).catch(error => {
        return Promise.reject(error?.response?.data?.message || error?.message)
    })
}

export const getMachineListByOrgId = (orgId: number): Promise<any> => {
    return machineGetAllByOrgIdDataApi(orgId)
    .then(res => {
        return new Promise((resolve, reject) => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                resolve(res?.data?.data?.machineList)
            }
            else 
                reject(res?.data?.message)
        })   
    }).catch(error => {
        return Promise.reject(error?.response?.data?.message || error?.message)
    })
}

export const getMachineListOfATenant = (userId: number): Promise<any> => {
    return machineGetAllOfATenantApi(userId)
    .then(response => {
        const resData: MachineListResponseBody = response?.data
        return new Promise((resolve, reject) => {
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: MachinesData[] = resData.data?.machineList
                resolve(dataList)
            }
            else 
                reject(resData?.message)
        })   
    }).catch(error => {
        return Promise.reject(error?.response?.data?.message || error?.message)
    })
}

export const getAllTenantByMachineId = (machineId: number): Promise<any> => {
    return tenantGetAllByMachineIdApi({machineId})
    .then(response => {
        const resData: TenantListResponseBody = response?.data
        return new Promise((resolve, reject) => {
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: TenantsData[] = resData.data?.tenantList
                resolve(dataList)
            }
            else 
                reject(resData?.message)
        })   
    }).catch(error => {
        return Promise.reject(error?.response?.data?.message || error?.message)
    })
}

export const getAllTenantByMachineIdList = async (machineIdList: number[]): Promise<any> => {
    return tenantGetAllByMachineIdListApi({machineIdList})
    .then(response => {
        const resData: TenantListResponseBody = response?.data
        return new Promise((resolve, reject) => {
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: TenantsData[] = resData.data?.tenantList
                resolve(dataList)
            }
            else 
                reject(resData?.message)
        })   
    }).catch(error => {
        return Promise.reject(error?.response?.data?.message || error?.message)
    })
}

export const getAllTenantByOrgId = (orgId: number): Promise<any> => {
    return tenantGetAllByOrgIdApi({orgId})
    .then(response => {
        const resData: TenantListResponseBody = response?.data
        return new Promise((resolve, reject) => {
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: TenantsData[] = resData.data?.tenantList
                resolve(dataList)
            }
            else 
                reject(resData?.message)
        })   
    }).catch(error => {
        return Promise.reject(error?.response?.data?.message || error?.message)
    })
}

export const validateOrgExist = async (selectedOrgId: number): Promise<any> => {
    if ((selectedOrgId === 0 || ''+selectedOrgId === '0') && !(isTenantUser() && isAdminUser())) {
        return true
    } else {
        return organizationGetByIdApi(selectedOrgId)
        .then(res => {
            return new Promise((resolve, reject) => {
                if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                    resolve(true)
                else 
                    reject(false)
            })    
        }).catch(error => {
            console.log(error)
            return Promise.reject(false)
        })
    }
}