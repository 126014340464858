export const weightAmountOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Weight Amount Chart',
      },
      
    },
    scales: {
        y: {
          title: {
            display: true,
            text: 'Weight Amount'
          },
          ticks: {
            callback: function(value: any, index: any, ticks: any) {
              return value + ' kg';
            }
          }
        },
        x: {
            title: {
                display: true,
                text: "Date/Time"
            }
        }
    },
    datasets: {
      bar: {
        barPercentage: 0.3,
        categoryPercentage: 0.5,
      },
    }, 
};

export const carbonReductionOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Carbon Reduction Chart',
    },
    
  },
  scales: {
      y: {
        title: {
          display: true,
          text: 'Carbon Reduction'
        },
        ticks: {
          callback: function(value: any, index: any, ticks: any) {
            return value + ' kg';
          }
        }
      },
      x: {
          title: {
              display: true,
              text: "Date/Time"
          }
      }
  },
  datasets: {
    bar: {
      barPercentage: 0.3,
      categoryPercentage: 0.5,
    },
  }, 
};

export const doorOpenCountOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Door Open/Scan Count Chart',
      },
    },
    scales: {
        y: {
          title: {
            display: true,
            text: 'Count'
          },
          ticks: {
            precision: 0
          }
        },
        x: {
            title: {
                display: true,
                text: "Date/Time"
            }
        }
    },
    datasets: {
      bar: {
        barPercentage: 0.3,
        categoryPercentage: 0.5,
      },
    },
};

export const detectionCountOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Detection Count',
    },
  },
  scales: {
      y: {
        title: {
          display: true,
          text: 'Count'
        },
        ticks: {
          precision: 0
        }
      },
      x: {
          title: {
              display: true,
              text: "Date/Time"
          }
      }
  },
  datasets: {
    bar: {
      barPercentage: 0.3,
      categoryPercentage: 0.5,
    },
  },
};

const labels = ['Default', 'Default', 'Default', 'Default', 'Default', 'Default', 'Default'];
export const chartDefaultData = {
    labels,
    datasets: [
      {
        label: 'Sample Data',
        data: labels.map(() => 0),
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
};