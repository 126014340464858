import { EventLogAddRequestBody, EventLogGETListRequestBody } from "../../types/EventLogType";
import http from "../apiServices";

export const eventLogGetListByOrgIdApi = (inputData: EventLogGETListRequestBody) => {
    return http.post("event-log/get/list/by/orgId", inputData)
}

export const eventLogGetExportListByOrgIdApi = (inputData: EventLogGETListRequestBody) => {
    return http.post("event-log/get/export-list/by/orgId", inputData)
}

export const eventLogAddApi = (inputData: EventLogAddRequestBody) => {
    return http.post("event-log/add", inputData)
}