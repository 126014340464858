import { useEffect, useState } from "react";
import { addMinuteToDate, addSecondToDate, convertToLocaleDate } from "../utils/DateTimeUtil";
import { detectionGetAllByMachineIdAndCaptureTime } from "../services/RouteServices/DetectionApi";
import { DetectionData, DetectionListResponseBody } from "../types/DetectionType";
import { STATUS_CODES } from "../constants/GlobalConstants";
import { WeightLogData } from "../types/WeightLogType";

const useDetectionImages = (
    dataLimitObj: {machineId: number, startDate: string, endDate: string},
    deps: any[]
) => {
    const [organicImages, setOrganicImages] = useState<{url: string, captureTime: string}[]>([])
    const [nonOrganicImages, setNonOrganicImages] = useState<{url: string, captureTime: string}[]>([])
    const [error, setError] = useState<string>("")

    useEffect(() => {
        if (dataLimitObj.machineId !== 0) {
            detectionGetAllByMachineIdAndCaptureTime(dataLimitObj)
            .then(res => {
                const resData: DetectionListResponseBody = res?.data
                if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                    const dataList: DetectionData[] = resData.data.detectionList
                    setOrganicImages(dataList.map(data => {return {url: data.organicImageUrl, captureTime: data.captureTime}}))
                    setNonOrganicImages(dataList.map(data => {return {url: data.nonOrganicImageUrl, captureTime: data.captureTime}}))
                }
                else {
                    setError(resData?.message)
                }
            }).catch(error => {
                setError(error?.response?.data?.message || error?.message)
            })
        }
    }, deps ? deps : [])

    return {organicImages, nonOrganicImages, error}
}
 
export default useDetectionImages;