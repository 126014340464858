import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Users.css'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from "react-bootstrap/Button"
import Alert from "@mui/material/Alert"
import { ModalContainer } from "../Shared/Common/Containers";

const UpdatePasswordModal = ({
    show,
    title,
    onChangeUserData,
    btnName,
    onUserForm,
    onClose,
    userData,
    successText,
    errorText,
    btnColor,
}: any): JSX.Element => {
    return ( 
        <Modal show={show}>
        <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        {successText && <Alert severity="success">{successText}</Alert>}
        {errorText && <Alert severity="error">{errorText}</Alert>}
        <Modal.Body>
            <Form className="ModalForm">
                <ModalContainer>
                    <Form.Group className="mb-3">
                        <InputGroup className="mb-3">
                            <InputGroup.Text className="input-user"><span>Password <span className="text-danger">*</span></span></InputGroup.Text>
                            <Form.Control onChange={onChangeUserData} 
                                type="password" name="password" 
                                placeholder="Enter Password" 
                                value={userData.password} required 
                            />
                        </InputGroup>      
                    </Form.Group>
                </ModalContainer>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>Cancel</Button>
            <Button variant={btnColor} onClick={onUserForm}>{btnName}</Button>
        </Modal.Footer>
    </Modal>
    );
}
 
export default UpdatePasswordModal;