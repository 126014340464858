import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Alert from "@mui/material/Alert"
import Button from "react-bootstrap/Button";

const DeleteModal = ({
    show,
    successText,
    errorText,
    currentData,
    handleClose,
    handleConfirmDelete
}: any) => {
    return ( 
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Delete Confirmation</Modal.Title>
            </Modal.Header>
            {successText && <Alert severity="success">{successText}<br/></Alert>}
            {errorText && <Alert severity="error">{errorText}<br/></Alert>}
            <Modal.Body>
                <p>Are you sure you want to delete ID: <strong>[{currentData?.id}]</strong>?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="danger" onClick={handleConfirmDelete}>Delete</Button>
            </Modal.Footer>
        </Modal> 
    );
}

export default DeleteModal;