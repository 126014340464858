import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styling/customStyle.css';
import Layout from "../Layout/Layout";
import { STATUS_CODES, PAGE_LIMIT, USER_STATUS, ROLES } from "../../constants/GlobalConstants";
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Pagination from "@mui/material/Pagination"
import { getCurrentLocalUser, getUserPermissions, isRootAndSuperUsers, isSuperSpecialUser } from "../../utils/UserUtil";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import { RolesData } from "../../types/RoleType";
import UserFilerContainer from "./UserFilterContainer";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title } from "../Shared/Common/Titles";
import { FilterValue } from "../../types/global/FilterValueType";
import { getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { approvePendingUserApi, getPendingUsersListApi, rejectPendingUserApi } from "../../services/RouteServices/PendingUsersApi";
import { ApproveFormDataType, ApproveFormDefaultData, DefaultPendingUser, PendingUserType } from "../../types/PendingUserTypes";
import ApproveModal from "./ApproveModal";
import RejectModal from "./RejectModal";
import { convertToLocaleDate } from "../../utils/DateTimeUtil";
 
const PendingUsers: React.FC = () => {
    const tableLimit = PAGE_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();
    const userPermissions: RolesData = getUserPermissions();
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const [tablePage, setTablePage] = React.useState(1);
    const [tableOffset, setTableOffset] = React.useState(0);
    const [userTotalCount, setUserTotalCount] = React.useState(0);
    
    const [usersList, setUsersList] = useState<PendingUserType[]>([]); // * Original data object from db
    const [currentUser, setCurrentUser] = useState<PendingUserType>(DefaultPendingUser);
    const [approveFormData, setApproveFormData] = useState<ApproveFormDataType>(ApproveFormDefaultData)
    const [modalSuccessMsg, setModalSuccessMsg] = useState<string>("");
    const [modalErrorMsg, setModalErrorMsg] = useState<string>("");
    const [filterValue, setFilterValue] = useState<FilterValue>(getDefaultFilterValue());
    const [showApproveModal, setShowApproveModal] = useState<boolean>(false);
    const [showRejectModal, setShowRejectModal] = useState<boolean>(false)

    const pageCount = (Math.ceil(userTotalCount/tableLimit)) || 0

    useEffect(() => {
        setTablePage(1)
        setTableOffset(0)
    }, [filterValue])

    useEffect(() => {
        setErrorText("")
        if (hasPermission("viewPendingUser")) {
            getPendingUsersList();
        }
    }, [filterValue, tableOffset])

    const showAlertAndRefresh = (resData: {status: number, message: string}) => {
        if (resData?.status === STATUS_CODES.SUCCESS_CODE){
            setModalErrorMsg("")
            setErrorText("")
            setSuccessText(resData?.message)
            setTimeout(() => {
                setSuccessText("")
            }, 2000)
            setCurrentUser(DefaultPendingUser);
            setShowApproveModal(false)
            setShowRejectModal(false)
            getPendingUsersList(); 
        } else {
            setModalErrorMsg(resData?.message)
        }
    }

    const handleRejectPendingUser = (id: number) => {
        const inputData = {
            id: currentUser?.id,
            rejectedBy: userInfo.userName
        }
        rejectPendingUserApi(inputData)
        .then(res => {
            showAlertAndRefresh(res?.data)
        }).catch(error => {
            setErrorText(error.message)
        })
    }

    const handleApprovePendingUser = () => {
        const inputData = {
            "id": currentUser?.id,
            orgId: approveFormData?.orgId,
            remark: approveFormData?.remark || "",
            approvedBy: userInfo.userName
        }
        approvePendingUserApi(inputData)
        .then(res => {
            showAlertAndRefresh(res?.data)
        }).catch(error => {
            setModalErrorMsg(error?.message)
        })
    }

    const handleChangeApproveForm = (event: ChangeEvent<HTMLInputElement>) => {
        setApproveFormData({
            ...approveFormData,
            [event.target.name]: event.target.value
        })
    }

    const handleOpenApproveModal = (user: PendingUserType) => {
        setApproveFormData(ApproveFormDefaultData)
        setCurrentUser(user)
        setModalErrorMsg("")
        setShowApproveModal(true)
    }

    const handleCloseApproveModal = () => {
        setCurrentUser(DefaultPendingUser);
        setShowApproveModal(false)
    }

    const handleOpenRejectModal = (user: PendingUserType) => {
        setCurrentUser(user)
        setModalErrorMsg("")
        setShowRejectModal(true)
    }

    const handleCloseRejectModal = () => {
        setCurrentUser(DefaultPendingUser)
        setShowRejectModal(false)
    }

    const getPendingUsersList = () => {
        const dataLimitsObject = {
            searchText: filterValue.search || "",
            limit: tableLimit,
            offset: tableOffset
        }
        getPendingUsersListApi(dataLimitsObject)
        .then(response => {
            const resData = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: PendingUserType[] = resData.data?.pendingUserList
                const totalCount : number = resData?.data?.totalCount;
                setUserTotalCount(totalCount)
                setUsersList(dataList);
                // for not showing empty page when delete last item
                if (totalCount != 0 && dataList?.length == 0) {
                    setTableOffset(prev => prev != 0 ? prev - 10 : prev)
                    setTablePage(prev => prev != 1 ? prev -1 : prev)
                }
            }
            else {
                setErrorText(resData?.message)
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }   

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };

    // Filter Change
    const handleChangeFilterValue = (event: ChangeEvent<HTMLSelectElement>) => {
        setFilterValue(
            (prevValue) => {
                return {
                    ...prevValue,
                    [event.target.name]: event.target.value,
                }
            }
        )
    }

    // * Render Table Data
    const renderTable = () => {
        return (
        usersList?.length !== 0 ? usersList?.map((user: PendingUserType) => {          
            return (
                <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>
                        {user.username || "-"}
                    </td>
                    <td>{user.email || "-"}</td>
                    <td>{user.remark || "-"}</td>
                    <td>{convertToLocaleDate(user.createdAt!)|| "-"}</td>
                    <td>
                         {
                            <CheckIcon 
                                sx={{
                                    color: "green",
                                }}
                                className="pointer"
                                onClick={() => handleOpenApproveModal(user)}
                            />
                         }
                        &nbsp;&nbsp;
                        {
                            <ClearIcon 
                                sx={{
                                    color: "red",
                                }}
                                className="pointer"
                                onClick={() => handleOpenRejectModal(user)}
                            />
                        }
                    </td>
                </tr>
            )
        }):
            <tr>
                <td>No data found</td>
            </tr>
        )
    }

    if (!hasPermission("viewPendingUser")) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (
        <>
            <Layout>
                <Container>
                    <Title>Pending Users</Title>
                    {successText && <Alert severity="success" sx={{marginBottom: 2}}>{successText}<br/></Alert>}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}

                    <ApproveModal
                        show={showApproveModal}
                        onChange={handleChangeApproveForm}
                        formData={approveFormData}
                        errorText={modalErrorMsg}
                        onClose={handleCloseApproveModal}
                        onApprove={handleApprovePendingUser}
                    />

                    <RejectModal 
                        show={showRejectModal}
                        onReject={handleRejectPendingUser}
                        onClose={handleCloseRejectModal}
                        errorText={modalErrorMsg}
                    />

                    <UserFilerContainer
                        onChangeFilter={handleChangeFilterValue}
                        filterValue={filterValue}
                        userInfo={userInfo}
                    />
                    
                    <SummaryText>{userTotalCount} pending users found!</SummaryText>
                    <TableContainer>
                        <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    <th>Username</th>
                                    <th>Email</th>
                                    <th>Remark</th>
                                    <th>Created At</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {renderTable()}
                            </ tbody>
                        </table>
                        </InnterTableContainer>
                    </TableContainer>
                    <PaginationContainer>
                        <Pagination count={pageCount} onChange={handleChangePage} page={tablePage} variant="outlined" shape="rounded" size="large" />
                    </PaginationContainer>
                </Container>
            </Layout>
        </>
    );
};

export default PendingUsers;