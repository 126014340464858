import { useEffect, useState } from "react"
import { STATUS_CODES } from "../constants/GlobalConstants"
import { machineGetAllByOrgIdDataApi, machineGetAllOfATenantApi } from "../services/RouteServices/MachinesApi"
import { MachinesData } from "../types/MachinesType"
import { UserInfo } from "../types/UserAuth"
import { getCurrentLocalUser, isTenantUser } from "../utils/UserUtil"

export const useFilterMachines = (
    orgId: number,
    deps: any[]
) => {
    const userInfo: UserInfo = getCurrentLocalUser();

    const [machines, setMachines] = useState<MachinesData[]>([])
    const [error, setError] = useState("")

    useEffect(() => {
        if (orgId > 0) {
            if (!isTenantUser()) {
                machineGetAllByOrgIdDataApi(orgId)
                .then(res => {
                    if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                        setMachines(res?.data?.data?.machineList)
                    } else {
                        setError(res?.data?.message)
                    }
                }).catch(error => {
                    console.log(error.message)
                    setError(error.message)
                })
            } else {
                machineGetAllOfATenantApi(userInfo.id || 0)
                .then(res => {
                    if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                        setMachines(res?.data?.data?.machineList)
                    } else {
                        setError(res?.data?.message)
                    }
                }).catch(error => {
                    setError(error.message)
                })
            }
        } else {
            setMachines([])
        }

    }, deps ? deps : [])

    return {machines, error}
}