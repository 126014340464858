import {
    Box,
    Pagination,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import Paper from '@mui/material/Paper';
import { ChangeEvent, FunctionComponent } from "react";
import { Table } from "react-bootstrap";
import { PaginationContainer } from "../../Shared/Common/Containers";
import { TotalLocationsData } from "../Dashboard";
import { Row } from "./Row";
import { PAGE_LIMIT } from "../../../constants/GlobalConstants";

interface CollapseTableProps {
    rows: any[],
    totalLocationsData: TotalLocationsData
    machineType: string
    onPageChange: (event : ChangeEvent<unknown>, pageValue : number) => void
    page: number
}
 
const CollapseTable: FunctionComponent<CollapseTableProps> = ({
    rows,
    totalLocationsData,
    machineType,
    onPageChange,
    page,
}) => {

    return (  
        <Box>
            <TableContainer className="hide-scroll-bar" sx={{minWidth: "1200px", marginBottom: 2, overflow: "scroll"}} component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                <TableRow>
                    <TableCell />
                    <TableCell>
                        Location Name <span style={{color: "#40679E"}}>[{totalLocationsData.totalLocationCount}]</span>
                    </TableCell>
                    <TableCell align="right">
                        Total Weight (kg) <span style={{color: "#40679E"}}>[{totalLocationsData.totalWeight} kg]</span>
                    </TableCell>
                    <TableCell align="right">
                        Total Carbon Reduction <span style={{color: "#40679E"}}>[{(totalLocationsData.totalCarbonReduction / 1000).toFixed(2)} tonne]</span>
                    </TableCell>
                    <TableCell align="right">
                        Machine <span style={{color: "#40679E"}}>[{totalLocationsData.totalMachineCount}]</span>
                    </TableCell>
                    <TableCell align="right">
                        Status
                    </TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row) => (
                    <Row key={row.id} row={row} machineType={machineType} />
                ))}
                </TableBody>
            </Table>
            </TableContainer>
            <PaginationContainer>
                <Pagination 
                    count={(Math.ceil(totalLocationsData.totalLocationCount/PAGE_LIMIT)) || 0} 
                    onChange={onPageChange} 
                    page={page} 
                    variant="outlined" 
                    shape="rounded" size="large" 
                />
            </PaginationContainer>
        </Box>
    );
}
 
export default CollapseTable;