import { createContext, useState } from "react";

export interface MaintenanceContextType {
    isMaintenance: boolean
    setIsMaintenance: (value: any) => void
}

export const MaintenanceContext = createContext<MaintenanceContextType | null>(null);

const MaintenanceContextProvider = ({
    children
}: any) => {
    const [isMaintenance, setIsMaintenance] = useState<boolean>(false);
    return (  
        <MaintenanceContext.Provider
            value={{
                isMaintenance,
                setIsMaintenance,
            }}
        >
            {children}
        </MaintenanceContext.Provider>
    );
}
 
export default MaintenanceContextProvider;