export const AI_IMAGES_FOLDER = "/smu"
export const MANUAL_IMAGES_FOLDER = "/manual_detections"

export const AI_IMAGES_ZIP_FOLDER = "/archive_folders"
export const MANUAL_IMAGES_ZIP_FOLDER = "/archive_folders"

export const NORMAL_FOLDER = "normal"
export const ZIP_FOLDER = "zip"

// export const AI_IMAGES_FOLDER = "/home/guruhein/smu/ai_images"
// export const MANUAL_IMAGES_FOLDER = "/home/guruhein/smu/manual_detections"

// export const AI_IMAGES_ZIP_FOLDER = "/home/guruhein/smu/archive_folders/ai"
// export const MANUAL_IMAGES_ZIP_FOLDER = "/home/guruhein/smu/archive_folders/manual"

// export const NORMAL_FOLDER = "normal"
// export const ZIP_FOLDER = "zip"