import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FunctionComponent, useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import { UsersAvailableLocations } from "../../../../types/UsersAvailableLocationsType";
import ConfirmDeleteModal from './ConfirmDeleteModal';


interface LocationsListProps {
    locations: UsersAvailableLocations[]
    handleRemoveLocation: (id: number) => void
}
 
const LocationsList: FunctionComponent<LocationsListProps> = ({
    locations,
    handleRemoveLocation
}) => {
    const [showRemoveLocationModal, setShowRemoveLocationModal] = useState<boolean>(false)
    const [selectedLocation, setSelectedLocation] = useState<UsersAvailableLocations>({} as UsersAvailableLocations)

    const toggleShowRemoveLocationModal = () => {
        setShowRemoveLocationModal(!showRemoveLocationModal)
    }

    const handleClickRemoveLocation = (location: UsersAvailableLocations) => {
        setSelectedLocation(location)
        toggleShowRemoveLocationModal()
    }

    const handleRemoveLocationWrapper = () => {
        handleRemoveLocation(selectedLocation.id)
        toggleShowRemoveLocationModal()
    }

    return (  
        <>
            <TableContainer component={Paper}>
            <Table sx={{ width: "500px", maxHeight: 600 }} aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Location</TableCell>
                    <TableCell>User</TableCell>
                    <TableCell></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {locations.map((location) => (
                    <TableRow
                    key={location.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell>{location.locationName}</TableCell>
                        <TableCell>{location.username}</TableCell>
                        <TableCell>
                            <RiDeleteBin5Line style={{fontSize: "23px", cursor: "pointer"}}
                                className='text-danger'
                                onClick={() => handleClickRemoveLocation(location)}
                            />
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
            <ConfirmDeleteModal 
                show={showRemoveLocationModal}
                toggleShow={toggleShowRemoveLocationModal}
                handleRemoveLocation={handleRemoveLocationWrapper}
            />  
        </>
    );
}
 
export default LocationsList;