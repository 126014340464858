import { getCurrentLocalUser } from "../utils/UserUtil";
import { UserInfo } from "./UserAuth";
import { DropdownData } from "./global/DropDownType";

export interface CommonFilter {
    org: string,
    locations: number[],
    tenant: DropdownData,
    machines: number[],
    view: string
}

export interface HourFilter {
    day: string,
}

export interface DayFilter {
    startDate: string,
    endDate: string,
    range: number
}

export interface MonthFilter {
    month: string,
    year: string,
}

export interface YearFilter {
    year: string
}


export const getDefaultCommonFilters = () => {
    const userInfo: UserInfo = getCurrentLocalUser();
    return {
        org: ''+userInfo.orgId || "-1",
        tenant: {value: "0", label: "All"},
        locations: [],
        machines: [],
        view: "1"
    }
}

export const DefaultHourFilters = {
    day: new Date().toISOString().split('T')[0]
}

export const getDefaultDayFilters = () => {
    const today = new Date();
    const sevenDaysAgo = new Date(today.getTime() - 6 * 24 * 60 * 60 * 1000);
    const startDateDefault = sevenDaysAgo.toISOString().split('T')[0];
    const result: DayFilter = {
        range: 32,
        startDate: startDateDefault,
        endDate: today.toISOString().split('T')[0]
    }

    return result
}

export const DefaultMonthFilters = {
    month: '' + (new Date().getMonth() + 1),
    year: new Date().getFullYear().toString()
}

export interface WeightsData {
    hour: string,
    date: string,
    year: number,
    month: number,
    monthName: string,
    totalWeight: number,
    foodWeight: number,
    generalWeight: number,
    carbonReduction: number,
} 

export interface DischargeWeightsData {
    hour: string,
    date: string,
    year: number,
    month: number,
    monthName: string,
    totalDischargeWeight: number,
} 

export interface DoorCounts {
    hour: string,
    date: string,
    year: number,
    month: number,
    monthName: string,
    scanCount: number,
    openCount: number
}

export interface DetectionCounts {
    hour: string,
    date: string,
    year: number,
    month: number,
    monthName: string,
    nonOrganicCount: number,
    organicCount: number
}

export interface NewOverallData {
    weights: WeightsData[],
    dischargeWeights: DischargeWeightsData[],
    doorCounts: DoorCounts[],
    detectionCounts: DetectionCounts[],
    totalWeight: number,
    totalDischargeWeight: number,
    totalFoodWeight: number,
    totalGeneralWeight: number,
    totalCarbonReduction: number,
    totalDoorOpenCount: number,
    totalDoorScanCount: number,
    totalNonOrganicCount: number,
}

export interface OverallData {
    date?: string,
    year?: number,
    monthName?: string,
    monthId?: string,
    totalWeight?: number,
    totalGeneralWeight?: number,
    totalFoodWeight?: number,
    totalCarbonReduction?: number,
    totalOpenCount?: number,
    totalScanCount?: number,
    totalNonOrganicCount?: number,
}

export interface ExportOverallDataNew {
    weights: WeightsData[],
    dischargeWeights: DischargeWeightsData[],
    doorCounts: DoorCounts[],
    detectionCounts: DetectionCounts[],
}

export const DefaultExportOverallData = {
    weights: [],
    carbonReductions: [],
    doorCounts: [],
    detectionCounts:[],
    dischargeWeights: []
}

export interface ExportOverallData {
    Date?: string,
    Year?: number,
    Month?: string,
    "Weight Amount"?: number,
    "Door Open Count"?: number,
}

export interface ChartData {
    labels: string[];
    datasets: {
        label: string;
        data: number[];
        backgroundColor: string,
    }[];
}

export interface OverallRequestBody {
    orgId?: number,
    userId?: number,
    tenantId?: number,
    machineIdList: number[],
    dateList: Array<string>,
}

export interface YearOverallRequestBody {
    orgId?: number,
    userId?: number,
    machineIdList: number[],
    year: number,
}

export interface DailyTenantWeightAmountRequestBody {
    organizationId: number,
    machineId: number,
    tenantIdList: number[],
    dateList: string[]
}

export interface MonthlyTenantWeightAmountRequestBody {
    organizationId: number,
    machineId: number,
    tenantIdList: number[],
    startDate: string,
    endDate: string
}

export interface TenantWeightAmount {
    tenantName: string,
    tenantId: number,
    weightAmount: number,
}

export interface TenantDailyWeightAmount {
    date: string,
    tenantWeights: {[key: string]: number},
    totalOpenCount: number,
    totalScanCount: number,
    totalNonOrganicCount: number,
    totalWeightAmount: number,
    card1TotalWeightAmount: number,
    unassignedTotalWeightAmount: number,
    inactiveTotalWeightAmount: number,
}

export interface TenantMonthlyWeightAmount {
    totalOpenCount: number,
    totalScanCount: number,
    totalNonOrganicCount: number,
    totalWeightAmount: number,
    card1TotalWeightAmount: number,
    unassignedTotalWeightAmount: number,
    inactiveTotalWeightAmount: number,
    monthlyTenantWeightAmounts: TenantWeightAmount[]
}

export interface TenantMonthlyWeightAmountResponseBody {
    data: TenantMonthlyWeightAmount
}

export interface TenantWeightAmountResponseBody {
    data: TenantDailyWeightAmount[]
}

export type DataLimitObject = {
    orgId?: number,
    machineId?: number,
    dateList?: string[],
}