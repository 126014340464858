import { Box, Typography } from "@mui/material";
import { ChangeEvent, FunctionComponent } from "react";
import { FilterContainer } from "../Shared/Common/Containers";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { getCurrentLocalUser, isSuperSpecialUser } from "../../utils/UserUtil";
import { DateFilterValue, FilterValue } from "../../types/global/FilterValueType";
import { useFilterOrganizations } from "../../custom-hooks/useFilterOrganizations";
import { OrganizationsData } from "../../types/OrganizationsType";
import { UserInfo } from "../../types/UserAuth";
import CalendarModal from "../Shared/CalendarModal/CalendarModal";
import { useState } from 'react';

interface LoginHistoryFilterContainerProps {
    filterValue: FilterValue,
    dateFilterValue: DateFilterValue
    onChangeFilter: (event: ChangeEvent<any>) => void
    onChangeDateFilter: (key: "startDate" | "endDate", value: Date) => void
}
 
const LoginHistoryFilterContainer: FunctionComponent<LoginHistoryFilterContainerProps> = ({
    filterValue,
    dateFilterValue,
    onChangeFilter,
    onChangeDateFilter
}) => {
    const userInfo: UserInfo = getCurrentLocalUser();
    const {organizations, error: orgError} = useFilterOrganizations()

    const [showStartDateModal, setShowStartDateModal] = useState(false)
    const [showEndDateModal, setShowEndDateModal] = useState(false)

    const handleClickStartDate = () => {
        setShowStartDateModal(!showStartDateModal)
    }

    const handleClickEndDate = () => {
        setShowEndDateModal(!showEndDateModal)
    }

    const handleCloseDateModal = () => {
        setShowStartDateModal(false)
        setShowEndDateModal(false)
    }

    return (  
        <FilterContainer>
            <Form.Group>
            <Row className="mb-3">
                    <Col xs={6} md={4} lg={2}>
                        <Box 
                            sx={(theme) => ({
                                display: "flex", 
                                gap: 1, 
                                alignItems: "center",
                                [theme.breakpoints.down('sm')]: {
                                    flexDirection: "column",
                                    justifyContent: "center",
                                },
                            })}
                        >
                            <Typography>Start Date</Typography>
                            <button type="button" className="btn btn-outline-dark" onClick={handleClickStartDate}>{dateFilterValue?.startDate || "Start Date"}</button>
                            <CalendarModal 
                                show={showStartDateModal}
                                onCloseModal={handleClickStartDate}
                                onChange={(value: Date) => {
                                    onChangeDateFilter("startDate", value)
                                    handleCloseDateModal()
                                }}
                                maxDate={new Date(dateFilterValue.endDate)}
                                defaultValue={new Date(dateFilterValue.startDate)}
                            />
                        </Box>
                    </Col>
                    <Col xs={6} md={4} lg={2}>
                        <Box 
                            sx={(theme) => ({
                                display: "flex", 
                                gap: 1, 
                                alignItems: "center",
                                [theme.breakpoints.down('sm')]: {
                                    flexDirection: "column",
                                    justifyContent: "center",
                                },
                            })}
                        > 
                            <Typography>End Date</Typography>
                            <button type="button" className="btn btn-outline-dark" onClick={handleClickEndDate}>{dateFilterValue?.endDate || "End Date"}</button>
                            <CalendarModal 
                                show={showEndDateModal}
                                onCloseModal={handleClickEndDate}
                                onChange={(value: Date) => {
                                    onChangeDateFilter("endDate", value)
                                    handleCloseDateModal()
                                }}
                                maxDate={new Date()}
                                minDate={new Date(dateFilterValue.startDate)}
                                defaultValue={new Date(dateFilterValue.endDate)}
                            />
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <InputGroup className="mb-3">
                        <InputGroup.Text className="input-user"><span>Organization</span></InputGroup.Text>
                            {
                                !isSuperSpecialUser() ?
                                <Form.Select onChange={onChangeFilter} 
                                    name="organizationId" aria-label="organizatioinId" 
                                    value={filterValue.organizationId}
                                    disabled
                                >
                                    <option value={userInfo.orgId}>
                                            {organizations.filter((org:OrganizationsData) => org.id === +userInfo.orgId)[0]?.organizationName}
                                    </option>
                                </Form.Select> :
                                <Form.Select onChange={onChangeFilter} 
                                name="organizationId" aria-label="organizatioinId" 
                                value={filterValue.organizationId}
                                >
                                    <option value={0}>All</option>
                                    {
                                    (organizations && organizations?.length !== 0) && organizations.map((org:OrganizationsData) => 
                                        <option value={org.id} key={org.id}>{org.organizationName}</option>
                                    )
                                    }
                                </Form.Select>
                            }
                        </InputGroup>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <InputGroup>
                            <InputGroup.Text id="inputGroup-sizing-lg">Search</InputGroup.Text>
                            <Form.Control
                                aria-label="Large"
                                aria-describedby="inputGroup-sizing-sm"
                                value={filterValue.search}
                                onChange={onChangeFilter}
                                name="search"
                                placeholder="User"
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </Form.Group>
        </FilterContainer>
    );
}
 
export default LoginHistoryFilterContainer;