import http from "../apiServices";

export const getAvailableLocationsByUserIdsApi = (inputData: any) => {
    return http.post("/users-available-locations/get/by/user-ids", inputData)
}

export const assignLocationsToUserApi = (inputData: any) => {
    return http.post("/users-available-locations/assign/locations", inputData)
}

export const removeLocationsToUserApi = (inputData: any) => {
    return http.post("/users-available-locations/remove/location", inputData)
}