import { useState, useEffect } from 'react';
import { Box } from "@mui/material";
import Layout from "../../Layout/Layout";
import { Container } from "../../Shared/Common/Containers";
import { Title } from "../../Shared/Common/Titles";
import { Bar } from 'react-chartjs-2';
import { countReportOptions, weightReportOptions } from "./ChartConstants";
import { ManualDetectionWeightReportResponse, ManualDetectionReportDateFilterValue, ManualDetectionReportFilterValue, WeightReportType, SummaryData, ExportReportData } from '../../../types/ManualDetectionType';
import { ChartData } from '../../../types/ReportType';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate } from 'react-router-dom';
import { UserInfo } from '../../../types/UserAuth';
import { getCurrentLocalUser, isTenantUser } from '../../../utils/UserUtil';
import { DropdownData } from '../../../types/global/DropDownType';
import FilterSection from './FilterSection';
import { getDefaultReportDateFilterValue, getDefaultReportFilterValue } from '../../../utils/DefaultFilterValueUtil';
import { convertToLocaleDate, getCurrentLocaleDate, getDatesBetweenStartDateAndEndDate, getDayDifference, getDaysListOfMonth, getStartDateAndEndDateOfAMonth } from '../../../utils/DateTimeUtil';
import { manualDetectionGetDailyReportByOrgIdApi, manualDetectionGetDailyReportByUserIdApi, manualDetectionGetYearReportByUserIdApi, manualDetectionGetYearlyReportByOrgIdApi } from '../../../services/RouteServices/ManualDetectionApi';
import { LOG_LEVEL, STATUS_CODES } from '../../../constants/GlobalConstants';
import { chartDefaultData } from '../../Report/ChartConstant';
import { calculateBarWidth, calculateCategoryWidth } from '../../../utils/ChartUtil';
import XLSX from "xlsx";
import jsPDF from 'jspdf';
import { addEventLog } from '../../../utils/EventLogUtil';
import { addNormalLog } from '../../../utils/LoggerUtil';
import { creatPdf } from './createReprotPdf';

const loadWeightReportChartData = (dataList: ManualDetectionWeightReportResponse[]) => {
    const labels = dataList.map((data) => data?.date ? `${convertToLocaleDate(data.date)?.split(" ")[0]}` : data?.monthName!)
    const nonOrganicWeight = dataList.map((data) => data?.nonOrganicWeight || 0)
    const organicWeight = dataList.map((data) => data?.organicWeight || 0)
    return {
        labels,
        datasets: [
            {
                label: "Non-norganic Weight",
                data: nonOrganicWeight,
                backgroundColor: '#9A3B3B'
            },
            {
                label: "Organic Weight",
                data: organicWeight,
                backgroundColor: '#5B9A8B'
            }
        ]
    }
}

const loadCountReportChartData = (dataList: ManualDetectionWeightReportResponse[]) => {
    const labels = dataList.map((data) => data?.date ? `${convertToLocaleDate(data.date)?.split(" ")[0]}` : data?.monthName!)
    const nonOrganicCount = dataList.map((data) => data?.nonOrganicCount || 0)
    const organicCount = dataList.map((data) => data?.organicCount || 0)
    return {
        labels,
        datasets: [
            {
                label: "Non-norganic Count",
                data: nonOrganicCount,
                backgroundColor: '#9A3B3B'
            },
            {
                label: "Organic Count",
                data: organicCount,
                backgroundColor: '#5B9A8B'
            }
        ]
    }
}

const loadDailyExportReportData = (dataList: ManualDetectionWeightReportResponse[]) => {
    return dataList.map((data) => {
        return {
            Date: convertToLocaleDate(data.date)?.split(" ")[0],
            "Organic Weight": data?.organicWeight || 0,
            "Non-organic Weight": data?.nonOrganicWeight || 0,
            "Organic Count": data?.organicCount || 0,
            "Non-organic Count": data?.nonOrganicCount || 0,
        }
    })
}

const loadYearlyExportReportData = (dataList: ManualDetectionWeightReportResponse[]) => {
    return dataList.map((data) => {
        return {
            Year: data.year,
            Month: data.monthName,
            "Organic Weight": data?.organicWeight || 0,
            "Non-organic Weight": data?.nonOrganicWeight || 0,
            "Organic Count": data?.organicCount || 0,
            "Non-organic Count": data?.nonOrganicCount || 0,
        }
    })
}

const ManualDetectionReport = () => {
    const navigate = useNavigate();
    const userInfo: UserInfo = getCurrentLocalUser();

    const [weightReport, setWeightReport] = useState<ChartData>(chartDefaultData)
    const [countReport, setCountReport] = useState<ChartData>(chartDefaultData)
    const [exportData, setExportData] = useState<ExportReportData[]>([])
    const [summaryData, setSummaryData] = useState<SummaryData>({} as SummaryData)
    const [filterValue, setFilterValue] = useState<ManualDetectionReportFilterValue>(getDefaultReportFilterValue());
    const [dateFilterValue, setDateFilterValue] = useState<ManualDetectionReportDateFilterValue>(getDefaultReportDateFilterValue())
    const [selectedView, setSelectedView] = useState("1")
    const [maxEndDate, setMaxEndDate] = useState("")

    const weightReportBarPercentage = calculateBarWidth(weightReport.datasets[0].data.length);
    const weightReportCategoryPercentage = calculateCategoryWidth(weightReport.datasets[0].data.length);

    const modifiedWeightReportOptions = {
        ...weightReportOptions,
        datasets: {
            bar: {
              barPercentage: weightReportBarPercentage,
              categoryPercentage: weightReportCategoryPercentage,
            },
        },
    }

    const modifiedCountReportOptions = {
        ...countReportOptions,
        datasets: {
            bar: {
                barPercentage: weightReportBarPercentage,
                categoryPercentage: weightReportCategoryPercentage,
            }
        }
    }

    const pdfAnotations = [
        {
            anotations: [
                {
                    text: `Total Non-organic Count: ${summaryData?.totalNonOrganicCount || 0}`,
                    color: '#9A3B3B'
                },
                {
                    text: `Total Organic Count: ${summaryData?.totalOrganicCount || 0}`,
                    color: '#5B9A8B'
                },
            ]
        },
        {
            anotations: [
                {
                    text: `Total Non-organic Weight: ${summaryData?.totalNonOrganicWeight || 0}kg`,
                    color: '#9A3B3B'
                },
                {
                    text: `Total Organic Weight: ${summaryData?.totalOrganicWeight || 0}kg`,
                    color: '#5B9A8B'
                },
            ]
        },
    ]

    useEffect(() => {
        const daysDiff = getDayDifference(dateFilterValue.date.startDate, dateFilterValue.date.endDate)
        if (+filterValue.orgId > 0
            && selectedView === "3" &&
            daysDiff <= dateFilterValue.date.range
        ) {
            getDailyWeightReport("daily")
        }
    }, [dateFilterValue.date, filterValue.machineIdList, filterValue.tenant, selectedView])

    useEffect(() => {
        if ( +filterValue.orgId > 0 && filterValue.tenant.value
            && selectedView === "1") {
            getDailyWeightReport("monthly")
        }
    }, [dateFilterValue.month, filterValue.machineIdList, filterValue.tenant, selectedView])

    useEffect(() => {
        if ( +filterValue.orgId > 0 && selectedView === "2") { 
            getYearlyWeightReport()
        }
    }, [dateFilterValue.year, filterValue.machineIdList, filterValue.tenant, selectedView])

    useEffect(() => {
        setFilterValue({
            ...filterValue,
            machineIdList: [],
            tenant: {value: "0", label: "All"}
        })
    }, [filterValue.orgId])

    useEffect(() => {
        const {startDate, endDate, range} = dateFilterValue.date
        if (startDate) {
            const startDateObj = new Date(startDate)
            const endDateObj = new Date(endDate)
            const newStartDateObj = new Date(startDateObj.getTime());
            newStartDateObj.setDate(newStartDateObj.getDate() + +range);
            const daysDiff = getDayDifference(startDate, endDate)

            setMaxEndDate(newStartDateObj.toISOString().split('T')[0])
    
            if (endDateObj > newStartDateObj || startDateObj > endDateObj  || daysDiff > +range) {
                setDateFilterValue({
                    ...dateFilterValue,
                    date: {
                        ...dateFilterValue.date,
                        endDate: newStartDateObj.toISOString().split('T')[0]
                    }
                })
            }
        }
    }, [dateFilterValue.date.startDate, dateFilterValue.date.range])

    useEffect(() => {
        const {startDate, endDate, range} = dateFilterValue.date
        if (endDate) {
            const startDateObj = new Date(startDate)
            const endDateObj = new Date(endDate)
            const newEndDateObj = new Date(endDateObj.getTime())
            newEndDateObj.setDate(newEndDateObj.getDate() - +range)

            if (startDateObj < newEndDateObj || startDateObj > endDateObj) {
                setDateFilterValue({
                    ...dateFilterValue,
                    date: {
                        ...dateFilterValue.date,
                        startDate: newEndDateObj.toISOString().split('T')[0]
                    }
                })
            }
        }
    }, [dateFilterValue.date.endDate])

    const handleChangeFilterValue = (name: string, value: string | number[] | DropdownData) => {
        setFilterValue({
            ...filterValue,
            [name]: value
        })
    }

    const handleChangeDateFilterValue = (name: string, value: string | Date | number) => {
        if ((name === "startDate" || name === "endDate" || name === "range")) {
            if (value instanceof Date) {
                const day = value.getDate().toString().padStart(2, '0');
                const month = (value.getMonth() + 1).toString().padStart(2, '0');
                const year = value.getFullYear();
                value = `${year}-${month}-${day}`;
            } 
            setDateFilterValue({
                ...dateFilterValue,
                date: {
                    ...dateFilterValue.date,
                    [name]: value
                }
            })
        } else if (name === "month" || name === "m_year") {
            setDateFilterValue({
                ...dateFilterValue,
                month: {
                    ...dateFilterValue.month,
                    [name]: value
                }
            })
        } else {
            setDateFilterValue({
                ...dateFilterValue,
                [name]: value
            })
        }
    }

    const handleChangeSelectedView = (value: string) => {
        setSelectedView(value)
    }

    const exportToExcel = async () => {  
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Overall");
        XLSX.writeFile(workbook, `reports-${getCurrentLocaleDate()}.xlsx`);
        const log: string = `${userInfo.userName} has downloaded manual-detection-report-${getCurrentLocaleDate()}.xlsx`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    };

    async function exportChartsToPdf() {
        const doc = new jsPDF('landscape', 'mm', 'a4');
        const elements = document.getElementsByClassName("my-chart");
        await creatPdf({ doc, elements, selectedView, dateFilterValue, pdfAnotations }); 
        doc.save(`report-charts-${getCurrentLocaleDate()}.pdf`); 
        const log: string = `${userInfo.userName} has downloaded manual-detection-report-charts-${getCurrentLocaleDate()}.pdf`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    }

    const getDailyWeightReport = (type: string) => {
        let startDate = dateFilterValue?.date?.startDate
        let endDate = dateFilterValue?.date?.endDate
        if (type === "monthly") {
            const date = getStartDateAndEndDateOfAMonth(+dateFilterValue.month.month - 1, +dateFilterValue.month.m_year);
            startDate = date.startDate
            endDate = date.endDate
        }
        if (!isTenantUser()) {
            getDailyWeightReportByOrgId(startDate, endDate)
        } else {
            getDailyWeightReportByUserId(startDate, endDate)
        }
    }

    const getDailyWeightReportByOrgId = (startDate: string, endDate: string) => {
        const dataLimitObject = {
            orgId: filterValue.orgId,
            tenantId: +filterValue.tenant.value!,
            machineIdList: filterValue.machineIdList,
            startDate: startDate,
            endDate: endDate
        }
        manualDetectionGetDailyReportByOrgIdApi(dataLimitObject)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                const weightData = loadWeightReportChartData(res?.data?.data?.dailyReport)
                const coutData = loadCountReportChartData(res?.data?.data?.dailyReport)
                const totalNonOrganicWeight = res?.data?.data?.totalNonOrganicWeight
                const totalOrganicWeight = res?.data?.data?.totalOrganicWeight
                const totalNonOrganicCount = res?.data?.data?.totalNonOrganicCount
                const totalOrganicCount = res?.data?.data?.totalOrganicCount
                setSummaryData({
                    totalNonOrganicWeight,
                    totalOrganicWeight,
                    totalNonOrganicCount,
                    totalOrganicCount
                })
                setWeightReport(weightData)
                setCountReport(coutData)
                setExportData(loadDailyExportReportData(res?.data?.data?.dailyReport))
            }
        }).catch(error => {
            console.log(error?.message)
        })
    }

    const getDailyWeightReportByUserId = (startDate: string, endDate: string) => {
        const dataLimitObject = {
            userId: +userInfo.id!,
            machineIdList: filterValue.machineIdList,
            startDate: startDate,
            endDate: endDate
        }
        manualDetectionGetDailyReportByUserIdApi(dataLimitObject)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                const weightData = loadWeightReportChartData(res?.data?.data?.dailyReport)
                const coutData = loadCountReportChartData(res?.data?.data?.dailyReport)
                const totalNonOrganicWeight = res?.data?.data?.totalNonOrganicWeight
                const totalOrganicWeight = res?.data?.data?.totalOrganicWeight
                const totalNonOrganicCount = res?.data?.data?.totalNonOrganicCount
                const totalOrganicCount = res?.data?.data?.totalOrganicCount
                setSummaryData({
                    totalNonOrganicWeight,
                    totalOrganicWeight,
                    totalNonOrganicCount,
                    totalOrganicCount
                })
                setWeightReport(weightData)
                setCountReport(coutData)
                setExportData(loadDailyExportReportData(res?.data?.data?.dailyReport))
            }
        }).catch(error => {
            console.log(error?.message)
        })
    }

    const getYearlyWeightReport = () => {
        if (!isTenantUser()) {
            getYearlyWeightReportByOrgId()
        } else {
            getYearlyWeightReportByUserId()
        }
    }

    const getYearlyWeightReportByOrgId = () => {
        const dataLimitObject = {
            orgId: filterValue.orgId,
            tenantId: +filterValue.tenant.value!,
            machineIdList: filterValue.machineIdList,
            year: +dateFilterValue.year
        }
        manualDetectionGetYearlyReportByOrgIdApi(dataLimitObject)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                const weightData = loadWeightReportChartData(res?.data?.data?.monthlyReport)
                const countData = loadCountReportChartData(res?.data?.data?.monthlyReport)
                const totalNonOrganicWeight = res?.data?.data?.totalNonOrganicWeight
                const totalOrganicWeight = res?.data?.data?.totalOrganicWeight
                const totalNonOrganicCount = res?.data?.data?.totalNonOrganicCount
                const totalOrganicCount = res?.data?.data?.totalOrganicCount
                setSummaryData({
                    totalNonOrganicWeight,
                    totalOrganicWeight,
                    totalNonOrganicCount,
                    totalOrganicCount
                })
                setWeightReport(weightData)
                setCountReport(countData)
                setExportData(loadYearlyExportReportData(res?.data?.data?.monthlyReport))
            }
        }).catch(error => {
            console.log(error?.message)
        })
    }

    const getYearlyWeightReportByUserId = () => {
        const dataLimitObject = {
            userId: +userInfo.id!,
            machineIdList: filterValue.machineIdList,
            year: +dateFilterValue.year
        }
        manualDetectionGetYearReportByUserIdApi(dataLimitObject)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                const weightData = loadWeightReportChartData(res?.data?.data?.monthlyReport)
                const countData = loadCountReportChartData(res?.data?.data?.monthlyReport)
                const totalNonOrganicWeight = res?.data?.data?.totalNonOrganicWeight
                const totalOrganicWeight = res?.data?.data?.totalOrganicWeight
                const totalNonOrganicCount = res?.data?.data?.totalNonOrganicCount
                const totalOrganicCount = res?.data?.data?.totalOrganicCount
                setSummaryData({
                    totalNonOrganicWeight,
                    totalOrganicWeight,
                    totalNonOrganicCount,
                    totalOrganicCount
                })
                setWeightReport(weightData)
                setCountReport(countData)
                setExportData(loadYearlyExportReportData(res?.data?.data?.monthlyReport))
            }
        }).catch(error => {
            console.log(error?.message)
        })
    }

    return (  
        <Box>
            <Layout>
                <Container>
                    <Box className='d-flex align-items-center gap-3 mb-3'>
                        <ArrowBackIcon 
                            onClick={() => navigate(-1)}
                            className="pointer"
                        />
                        <Title>Report</Title>
                    </Box>
                    <FilterSection 
                        filterValue={filterValue}
                        dateFilterValue={dateFilterValue}
                        selectedView={selectedView}
                        maxEndDate = {maxEndDate}
                        handleChangeFilterValue={handleChangeFilterValue}
                        handleChangeDateFilterValue={handleChangeDateFilterValue}
                        handleChangeSelectedView={handleChangeSelectedView}
                        exportToExcel={exportToExcel}
                        exportChartsToPdf={exportChartsToPdf}
                    />
                    <Box
                        className='mt-5'
                    >
                        <Box sx={(theme) => ({
                            marginY: 5,
                            maxHeight: 600,
                            width: "75%",
                            margin: "0 auto",
                            [theme.breakpoints.down('sm')]: {
                                width: "100%",
                                maxHeight: "300px"
                            },
                        })}>
                            <Box className='d-flex justify-content-between'>
                                <p className="fw-bold fs-5" style={{"color": "#9A3B3B"}}>Total Non-organic Count: {summaryData?.totalNonOrganicCount || 0}</p>
                                <p className="fw-bold fs-5" style={{"color": "#5B9A8B"}}>Total Organic Count: {summaryData?.totalOrganicCount || 0}</p>
                            </Box>
                            <Bar options={modifiedCountReportOptions} data={countReport} className='my-chart' />
                        </Box>
                    </Box>
                    <Box
                        className='mt-5'
                    >
                        <Box sx={(theme) => ({
                            maxHeight: 600,
                            width: "75%",
                            margin: "0 auto",
                            [theme.breakpoints.down('sm')]: {
                                width: "100%",
                                maxHeight: "300px"
                            },
                        })}>
                            <Box className='d-flex justify-content-between'>
                                <p className="fw-bold fs-5" style={{"color": "#9A3B3B"}}>Total Non-organic Weight: {summaryData?.totalNonOrganicWeight || 0}kg</p>
                                <p className="fw-bold fs-5" style={{"color": "#5B9A8B"}}>Total Organic Weight: {summaryData?.totalOrganicWeight || 0}kg</p>
                            </Box>
                            <Bar options={modifiedWeightReportOptions} data={weightReport} className='my-chart' />
                        </Box>
                    </Box>
                </Container>
            </Layout>
        </Box>
    );
}
 
export default ManualDetectionReport;