import { UserInfo } from "../types/UserAuth";
import { getCurrentLocalUser, isAdminUser, isSuperSpecialUser, isTenantUser } from "./UserUtil";

export const getDefaultDateFilterValue = () => {
    // change this value for default day range
    const DEFAULT_DAY_RANGE = 6
    const today = new Date();
    const sevenDaysAgo = new Date(today.getTime() - DEFAULT_DAY_RANGE * 24 * 60 * 60 * 1000);
    const startDateDefault = sevenDaysAgo.toISOString().split('T')[0];
    return {
        startDate: startDateDefault,
        endDate: today.toISOString().split('T')[0]
    }
}

export const getDefaultFilterValue = () => {
    const userInfo: UserInfo = getCurrentLocalUser();
    return {
        organizationId: isTenantUser() || isAdminUser() ? userInfo.orgId : 0, // allow "0" for only special users so that they can see all rows
        locationId: 0,
        machineId: 0,
        tenantId: 0,
        roleId: 0,
        search: "",
    }
}

export const getDefaultReportFilterValue = () => {
    const userInfo: UserInfo = getCurrentLocalUser();
    return {
        orgId: userInfo.orgId,
        machineIdList: [],
        tenant: {value: "0", label: "All"}
    }
}

export const getDefaultReportDateFilterValue = () => {
    const today = new Date();
    const sevenDaysAgo = new Date(today.getTime() - 6 * 24 * 60 * 60 * 1000);
    const startDateDefault = sevenDaysAgo.toISOString().split('T')[0];

    return {
        month: {
            month: '' + (new Date().getMonth() + 1),
            m_year: new Date().getFullYear().toString(),
        },
        year: new Date().getFullYear().toString(),
        date: {
            startDate: startDateDefault,
            endDate: today.toISOString().split('T')[0],
            range: 32
        }
    }
}