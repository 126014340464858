import { useEffect, useState } from "react";
import { LocationsData } from "../types/LocationsType";
import { locationGetByOrgIdApi } from "../services/RouteServices/LocationsApi";
import { STATUS_CODES } from "../constants/GlobalConstants";
import { getAvailableLocations, isSpecialUser, isSuperSpecialUser } from "../utils/UserUtil";

const useFilterLocations = (
    orgId: number,
    deps: any[]
) => {
    const [locations, setLocations] = useState<LocationsData[]>([])
    const [error, setError] = useState("")

    useEffect(() => {
        locationGetByOrgIdApi(orgId)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                let locations = res?.data?.data?.locationList || []
                if (!isSuperSpecialUser() && !isSpecialUser()) {
                    const avaiLocations = getAvailableLocations()
                    locations = locations.filter((location: LocationsData) => avaiLocations.includes(location.id))
                }
                setLocations(locations)
            }
            else 
                setError(res?.data?.message)
        }).catch(error => {
            setError(error.message)
        })
    }, deps? deps: [])

    return {locations, error};
}
 
export default useFilterLocations;