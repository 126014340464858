import { Alert, Box, Typography } from "@mui/material";
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Title } from "../Shared/Common/Titles";
import { userUpdatePwdApi } from "../../services/RouteServices/UsersApi";
import { UserInfo } from "../../types/UserAuth";
import { getCurrentLocalUser } from "../../utils/UserUtil";
import { STATUS_CODES } from "../../constants/GlobalConstants";

const UpdatePasswordSetting = () => {
    const userInfo: UserInfo = getCurrentLocalUser();
    const [newPassword, setNewPassword] = useState("")
    const [successText, setSuccessText] = useState("")

    const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(event.target.value)
    }

    const handleClickUpdate = () => {
        const requestBody = {
            id: userInfo.id!,
            password: newPassword,
            updatedBy: userInfo.userName,
        }
        userUpdatePwdApi(requestBody)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setSuccessText("Password updated successfully")
                setTimeout(() => {
                    setSuccessText("")
                    window.location.reload()
                }, 2000)
            } else {
                console.log(res?.data?.message)
            }
        }).catch(error => {
            console.log(error.message)
        })
    }

    return (  
        <Box>
            {successText && <Alert severity="success">{successText}<br/></Alert>}
            <Title>Update Password</Title>
            <InputGroup className="my-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                    New Password
                </InputGroup.Text>
                <Form.Control
                    onChange={handleChangePassword}
                    value={newPassword}
                    type="password"
                />
            </InputGroup>
            <Box sx={{
                textAlign: "right"
            }}>
                <Button variant="primary" onClick={handleClickUpdate}>Update</Button>
            </Box>
        </Box>
    );
}
 
export default UpdatePasswordSetting;