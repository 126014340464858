import { Box } from "@mui/material";
import { Col, Form, Row } from "react-bootstrap";
import { RolesData } from "../../../types/RoleType";
import { ChangeEvent } from "react";

type Props = {
    selectedRole: RolesData
    onChangeRolePermission: (event: ChangeEvent<HTMLInputElement>) => void
}

const TenantPermissions = (props: Props) => {
    const {selectedRole, onChangeRolePermission} = props
    return (  
        <Box>
            <Row>
                <Col>
                    <Form.Check
                        inline
                        label="View Detection"
                        name="viewDetection"
                        value={1}
                        defaultChecked={selectedRole.viewDetection}
                        type={"checkbox"}
                        id={`view_detection`}
                        onChange={onChangeRolePermission}
                        className="text-primary"
                    />
                </Col>
                <Col>
                    <Form.Check
                        inline
                        label="View Capture"
                        name="viewCapture"
                        value={1}
                        defaultChecked={selectedRole.viewCapture}
                        type={"checkbox"}
                        id={`view_capture`}
                        onChange={onChangeRolePermission}
                        className="text-primary"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        inline
                        label="View Door Log"
                        name="viewDoorLog"
                        value={1}
                        defaultChecked={selectedRole.viewDoorLog}
                        type={"checkbox"}
                        id={`view_door_log`}
                        onChange={onChangeRolePermission}
                        className="text-primary"
                    />
                </Col>
                <Col>
                    <Form.Check
                        inline
                        label="View Weight Log"
                        name="viewWeightLog"
                        value={1}
                        defaultChecked={selectedRole.viewWeightLog}
                        type={"checkbox"}
                        id={`view_weight_log`}
                        onChange={onChangeRolePermission}
                        className="text-primary"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        inline
                        label="View Report"
                        name="viewReport"
                        value={1}
                        defaultChecked={selectedRole.viewReport}
                        type={"checkbox"}
                        id={`view_report`}
                        onChange={onChangeRolePermission}
                        className="text-primary"
                    />
                </Col>
                <Col>
                    <Form.Check
                        inline
                        label="View Upload Detection"
                        name="viewUploadDetection"
                        value={1}
                        defaultChecked={selectedRole.viewUploadDetection}
                        type={"checkbox"}
                        id={`view_upload_detection`}
                        onChange={onChangeRolePermission}
                        className="text-primary"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        inline
                        label="View Dashboard"
                        name="viewDashboard"
                        value={1}
                        defaultChecked={selectedRole.viewDashboard}
                        type={"checkbox"}
                        id={`view_dashboard`}
                        onChange={onChangeRolePermission}
                        className="text-primary"
                    />
                </Col>
                <Col>
                    <Form.Check
                        inline
                        label="View Manual Detection"
                        name="viewManualDetection"
                        value={1}
                        defaultChecked={selectedRole.viewManualDetection}
                        type={"checkbox"}
                        id={`view_manual_detection`}
                        onChange={onChangeRolePermission}
                        className="text-primary"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        inline
                        label="View Waste Upload"
                        name="viewWasteUpload"
                        value={1}
                        defaultChecked={selectedRole.viewWasteUpload}
                        type={"checkbox"}
                        id={`view_waste_upload`}
                        onChange={onChangeRolePermission}
                        className="text-primary"
                    />
                </Col>
                <Col>
                    <Form.Check
                        inline
                        label="View Discharge Weight Log"
                        name="viewDischargeWeightLog"
                        value={1}
                        defaultChecked={selectedRole.viewDischargeWeightLog}
                        type={"checkbox"}
                        id={`view_discharge_weight_log`}
                        onChange={onChangeRolePermission}
                        className="text-primary"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        inline
                        label="View Carton Box Detection"
                        name="viewCartonBox"
                        value={1}
                        defaultChecked={selectedRole.viewCartonBox}
                        type={"checkbox"}
                        id={`view_carton_box`}
                        onChange={onChangeRolePermission}
                        className="text-primary"
                    />
                </Col>
                <Col>
                    <Form.Check
                        inline
                        label="Add Manual Detection"
                        name="addManualDetection"
                        value={1}
                        defaultChecked={selectedRole.addManualDetection}
                        type={"checkbox"}
                        id={`add_manual_detection`}
                        onChange={onChangeRolePermission}
                        className="text-primary"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check
                        inline
                        label="Edit manual Detection"
                        name="editManualDetection"
                        value={1}
                        defaultChecked={selectedRole.editManualDetection}
                        type={"checkbox"}
                        id={`edit_manual_detection`}
                        onChange={onChangeRolePermission}
                        className="text-primary"
                    />
                </Col>
                <Col>
                    <Form.Check
                        inline
                        label="Delete Manual Detection"
                        name="deleteManualDetection"
                        value={1}
                        defaultChecked={selectedRole.deleteManualDetection}
                        type={"checkbox"}
                        id={`delete_manual_detection`}
                        onChange={onChangeRolePermission}
                        className="text-primary"
                    />
                </Col>
            </Row>
        </Box>
    );
}
 
export default TenantPermissions;