export type LocationsData = {
    id: number;
    organizationId: number;
    organizationName: string;
    machineNames: string[];
    locationName: string;
    locationImage: string;
    remark: string;
    createdBy?: string;
    updatedBy?: string;
    deletedBy?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}
  
export type LocationListResponseBody = {
    data: {
      locationList: LocationsData[],
      totalCount: number,
    }
    status: number,
    message: string,
}
  
export type LocationResponseBody = {
    data: LocationsData,
    status: number,
    message: string,
}
  
export type LocationGETRequestBody = {
    searchText: string,
    limit: number,
    offset: number
}

export type LocationADDRequestBody = {
    organizationId: number;
    locationName: string,
    locationImage: string,
    remark: string,
    createdBy: string
}
  
export type LocationDELETERequestBody = {
    id: number,
    deletedBy: string,
}

export type LocationRemoveImageRequestBody = {
    id: number,
    organizationId: number;
    updatedBy: string,
}
  
export type LocationUPDATERequestBody = {
    id: number,
    organizationId: number;
    locationName: string,
    locationImage: string,
    remark: string,
    updatedBy: string,
}

export const locationDefault: LocationsData = {
  id: 0,
  organizationId: 0,
  organizationName: "",
  machineNames: [],
  locationName: "",
  locationImage: "",
  remark: ""
}
    