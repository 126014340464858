import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button"
import Alert from '@mui/material/Alert';
import { InputGroup } from 'react-bootstrap';
import { MachinesData } from '../../types/MachinesType';
import { OrganizationsData } from '../../types/OrganizationsType';
import { LocationsData } from '../../types/LocationsType';
import { ModalContainer } from '../Shared/Common/Containers';

const DataSyncModal = ({
    show,
    successText,
    errorText,
    handleClose,
    handleUploadButton,
    onFileChange,
    orgList,
    machineList,
    locationList,
    onChangeLFCLogData,
    data
}:any) => {
    return ( 
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Upload LFC Data</Modal.Title>
            </Modal.Header>
            {successText && <Alert severity="success">{successText}</Alert>}
            {errorText && <Alert severity="error">{errorText}</Alert>}
            <Modal.Body>
                <Form className="ModalForm">
                    <ModalContainer>
                        <Form.Group className="mb-3">
                            <InputGroup className="mb-3">
                                <Form.Select 
                                onChange={onChangeLFCLogData} 
                                name="organizationId" aria-label="organizatioinId" 
                                value={data.organizationId}
                                >
                                    <option value={0}>Choose Organization</option>
                                    {
                                    (orgList && orgList?.length !== 0) && orgList.map((org:OrganizationsData) => 
                                        <option value={org.id} key={org.id}>{org.organizationName}</option>
                                    )
                                    }
                                </Form.Select>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <Form.Select  aria-label="Default select example" name="locationId"
                                    onChange={onChangeLFCLogData}
                                    disabled={!Boolean(data.organizationId && data.organizationId !== '0')}
                                    value={data.locationId}
                                >
                                    <option value={0}>Choose Location</option>
                                    {
                                        (locationList?.length !== 0)? locationList.map((location:LocationsData) => 
                                             <option value={location.id} key={location.id}>{location.locationName}</option>
                                        ) : []
                                    }
                                </Form.Select>
                            </InputGroup>
                            <InputGroup className="mb-3">
                                <Form.Select  aria-label="Default select example" name="machineId"
                                    onChange={onChangeLFCLogData}  
                                    value={data.machineId}
                                    disabled={!Boolean(data.locationId && data.locationId !== '0')}
                                >
                                    <option value={0}>Choose Machine</option>
                                    {
                                        (machineList && machineList.length !== 0) && machineList.map((machine:MachinesData) => 
                                                <option value={machine.id} key={machine.id}>{machine.machineName}</option>
                                        )
                                    }
                                </Form.Select>
                            </InputGroup>

                            <Form.Label>Add lfc log file</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control id="formFileInput" name="file" type="file" accept=".csv" onChange={onFileChange} />
                            </InputGroup>
                        </Form.Group>
                    </ModalContainer>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button id="btnUploadData" variant="warning" onClick={handleUploadButton}>Upload</Button>
            </Modal.Footer>
        </Modal>
     );
}
 
export default DataSyncModal;