import http from "../apiServices";
import { CardInTenantADDRequestBody, CardInTenantDELETERequestBody } from "../../types/CardInTenantType";
import { STATUS_CODES } from "../../constants/GlobalConstants";

export const cardInTenantAddDataApi = (inputData : CardInTenantADDRequestBody) => {
    return http.post("/card-in-tenant/add", inputData)
}

export const cardInTenantDeleteDataApi = (inputData: CardInTenantDELETERequestBody) => {
    return http.post("/card-in-tenant/delete", inputData)
}

export const cardInTenantGetByMId = (inputData: number) => {
    return http.post("/card-in-tenant/get/by/machineId", {"machineId": inputData})
}

export const cardInTenantUpdateDataApi = (inputData: any) => {
    return http.put("/card-in-tenant/update", inputData)
}