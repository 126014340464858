import http from "../apiServices";
import { MachinesData, MachineDELETERequestBody, MachineUPDATERequestBody, MachineGETRequestBody, MachineGETByOrgIdRequestBody, MachineADDRequestBody } from "../../types/MachinesType";
import { CardInMachineGETRequestBody } from "../../types/CardInMachineType";
import { STATUS_CODES } from "../../constants/GlobalConstants";

// * Post all Given Data to database.table

export const machineGetDataApi = (inputData : MachineGETRequestBody) => {
    return http.post("/machine/get/list", inputData)
}

export const machineGetByOrgIdDataApi = (inputData : MachineGETByOrgIdRequestBody) => {
    return http.post("/machine/get/list/by/orgId", inputData)
}

export const machineGetByOrgIdExportDataApi = (inputData : MachineGETByOrgIdRequestBody) => {
    return http.post("/machine/get/export-list/by/orgId", inputData)
}

export const machineGetAllDataApi = () => {
    return http.post("/machine/get/all", {})
}

export const machineGetAllByLocationIdListDataApi = (locationIdList: number[]) => {
    return http.post("/machine/get/all/by/locationIdList", {locationIdList})
}

export const machineGetAllByOrgIdDataApi = (orgId: number) => {
    return http.post("/machine/get/all/by/orgId", {orgId})
}

export const machineGetAllOfATenantApi = (userId: number) => {
    return http.post("/machine/get/all/of/tenant", {userId})
}

export const machineGetAllByOrgIdAndLocIdDataApi = (orgId: number, locationId: number) => {
    return http.post("/machine/get/all/by/orgId-locId", {orgId, locationId})
}

export const machineDetailsGetDataApi = (inputData : CardInMachineGETRequestBody) => {
    return http.post("/machine/get/details", inputData)
}

export const machineGetByIdApi = (id: number) => {
    return http.post("/machine/get/by/id", {"id": id})
}

export const machineAddDataApi = (inputData : MachineADDRequestBody) => {
    return http.post("/machine/add", inputData)
}

export const machineUpdateDataApi = (inputData : MachineUPDATERequestBody) => {
    return http.put(`/machine/update`, inputData)
}

export const machineUpdateUUIDDataApi = (id: number) => {
    return http.put(`/machine/update/uuid`, {id})
}

export const machineDeleteDataApi = (inputData : MachineDELETERequestBody) => {
    return http.post(`/machine/delete`, inputData)
}

