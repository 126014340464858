import http from "../apiServices";
import { OrganizationsData, OrgDELETERequestBody, OrgUPDATERequestBody, OrgGETRequestBody, OrgADDRequestBody, OrgRemoveImageRequestBody } from "../../types/OrganizationsType";

// * Post all Given Data to database.table

export const organizationGetDataApi = (inputData : OrgGETRequestBody) => {
    return http.post("/organization/get/list", inputData)
}

export const organizationGetAllDataApi = () => {
    return http.post("/organization/get/all", {})
}

export const organizationGetByIdApi = (id: number) => {
    return http.post("/organization/get/by/id", {id: id})
}

export const organizationAddDataApi = (inputData : OrgADDRequestBody) => {
    return http.post("/organization/add", inputData)
}

export const organizationUpdateDataApi = (inputData : OrgUPDATERequestBody) => {
    return http.put(`/organization/update`, inputData)
}

export const organizationDeleteDataApi = (inputData : OrgDELETERequestBody) => {
    return http.post(`/organization/delete`, inputData)
}

export const organizationRemoveImageApi = (inputData : OrgRemoveImageRequestBody) => {
    return http.post(`/organization/remove/image`, inputData)
}
