import * as React from 'react';
import { useState, useEffect } from 'react';
import { Alert, Box, Pagination } from "@mui/material";
import Layout from "../Layout/Layout";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title } from "../Shared/Common/Titles";
import { Button } from "react-bootstrap";
import { addAlertUserApi, deleteAlertUserApi, getAlertUsersApi, updateAlertUserApi } from "../../services/RouteServices/AlertUsersApi";
import { PAGE_LIMIT, STATUS_CODES } from '../../constants/GlobalConstants';
import { AlertUserDefault, AlertUserType } from '../../types/AlertUserTyps';
import { convertToLocaleDate } from '../../utils/DateTimeUtil';
import AlertUserFormModal from './AlertUserFormModal';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteIconButton from "../Shared/DeleteIconButton/DeleteIconButton";
import { getCurrentLocalUser } from '../../utils/UserUtil';
import { UserInfo } from '../../types/UserAuth';
import AlertUserFilters from './AlertUserFilters';
import { FilterValue } from '../../types/global/FilterValueType';
import { getDefaultFilterValue } from '../../utils/DefaultFilterValueUtil';

const AlertUsers = () => {
    const tableLimit = PAGE_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();
    const [alertUsers, setAlertUsers] = useState<AlertUserType[]>([])
    const [alertUsersCount, setAlertUsersCount] = useState<number>(0);
    const [showAssignModal, setShowAssignModal] = useState<boolean>(false)
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [alertUser, setAlertUser] = useState<AlertUserType>(AlertUserDefault);
    const [tablePage, setTablePage] = React.useState(1);
    const [tableOffset, setTableOffset] = React.useState(0);
    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");
    const [modalErrorText, setModalErrorText] = useState("")
    const [filterValues, setFilterValues] = useState<FilterValue>(getDefaultFilterValue())


    const pageCount = (Math.ceil(alertUsersCount/tableLimit)) || 0

    useEffect(() => {
        setTablePage(1)
        setTableOffset(0)
    }, [filterValues])

    useEffect(() => {
        getAlertUsers()
    }, [tableOffset, filterValues])

    const getAlertUsers = () => {
        setErrorText("")
        const body = {
            searchText: filterValues.search,
            limit: tableLimit,
            offset: tableOffset,
        }
        getAlertUsersApi(body)
        .then(res => {
            const response = res?.data
            if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                const totalCount: number = response?.data?.totalCount;
                const alertUsers = response?.data?.alertUsers
                setAlertUsers(alertUsers)
                setAlertUsersCount(totalCount)
                // for not showing empty page when delete last item
                if (totalCount != 0 && alertUsers.length == 0) {
                    setTableOffset(prev => prev != 0 ? prev - 10 : prev)
                    setTablePage(prev => prev != 1 ? prev -1 : prev)
                }
            } else {
                setErrorText(response?.message)
            }
        }).catch(error => {
            setErrorText(error?.message)
        })
    }


    const addAlertUser = () => {
        const body = {
            userId: alertUser.userId,
            remark: alertUser.remark,
            dailyReportSms: alertUser.dailyReportSms,
            dailyReportEmail: alertUser.dailyReportEmail,
            machineHealthSms: alertUser.machineHealthSms,
            machineHealthEmail: alertUser.machineHealthEmail,
            createdBy: userInfo.userName
        }
        addAlertUserApi(body)
        .then(res => {
            const response = res?.data
            if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                getAlertUsers()
                handleShowAssignModal()
                setSuccessText(response?.message)
                setTimeout(() => {
                    setSuccessText("")
                }, 2000)
            } else {
                setModalErrorText(response?.message)
            }
        }).catch(error => {
            setModalErrorText(error?.message)
        })
    }

    const updateAlertUser = () => {
        const body = {
            id: alertUser.id,
            remark: alertUser.remark || "",
            dailyReportSms: alertUser.dailyReportSms,
            dailyReportEmail: alertUser.dailyReportEmail,
            machineHealthSms: alertUser.machineHealthSms,
            machineHealthEmail: alertUser.machineHealthEmail,
            updatedBy: userInfo.userName
        }
        updateAlertUserApi(body)
        .then(res => {
            const response = res?.data
            if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                getAlertUsers()
                handleCloseEditModal()
                setSuccessText(response?.message)
                setTimeout(() => {
                    setSuccessText("")
                }, 2000)
            } else {
                setModalErrorText(response?.message)
            }
        }).catch(error => {
            setModalErrorText(error?.message)
        })
    }

    const deleteAlertUser = (alertUser: AlertUserType) => {
        const body = {
            id: alertUser.id,
            deletedBy: userInfo.userName
        }
        deleteAlertUserApi(body)
        .then(res => {
            const response = res?.data
            if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                getAlertUsers()
                handleCloseEditModal()
                setSuccessText(response?.message)
                setTimeout(() => {
                    setSuccessText("")
                }, 2000)
            } else {
                setModalErrorText(response?.message)
            }
        }).catch(error => {
            setModalErrorText(error?.message)
        })
    }

    const handleChangeAlertUser = (name: string, value: string | number) => {
        setAlertUser({...alertUser, [name]: value})
    }

    const handleShowAssignModal = () => {
        setAlertUser(AlertUserDefault)
        setModalErrorText("")
        setShowAssignModal(!showAssignModal)
    }

    const handleCloseEditModal = () => {
        setShowEditModal(false)
        setAlertUser(AlertUserDefault)
    }

    const handleShowEditModal = (alertUser: AlertUserType) => {
        setModalErrorText("")
        setAlertUser(alertUser)
        setShowEditModal(!showEditModal)
    }

    const handleChangeFilterValues = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterValues({
            ...filterValues,
            [event.target.name]: event.target.value
        })
    }

    const handleChangePage = (event : React.ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };

    const renderTable = () => {
        if (alertUsers && alertUsers.length === 0) {
            return (
                <tr>
                    <td>No data found</td>
                </tr>
            )
        }
        return alertUsers.map(alertUser => (
            <tr key={alertUser.id}>
                <td>{alertUser.id}</td>
                <td>{alertUser.organizationName}</td>
                <td>{alertUser.userName}</td>
                <td>
                    {alertUser.dailyReportSms && <p style={{margin: 0,}}>Daily Report</p>}
                    {alertUser.machineHealthSms && <p style={{margin: 0,}}>Machine Health</p>}
                </td>
                <td>
                    {alertUser.dailyReportEmail && <p style={{margin: 0,}}>Daily Report</p>}
                    {alertUser.machineHealthEmail && <p style={{margin: 0,}}>Machine Health</p>}
                </td>
                <td>{alertUser.remark}</td>
                <td>{convertToLocaleDate(alertUser.createdAt || "")}</td>
                <td>{alertUser.createdBy}</td>
                <td>{convertToLocaleDate(alertUser.updatedAt || "")}</td>
                <td>{alertUser.updatedBy}</td>
                <td>
                    <EditOutlinedIcon 
                        sx={{
                            color: "blue",
                            cursor: "pointer",
                        }}
                        onClick={() => handleShowEditModal(alertUser)}
                    />
                    &nbsp;
                    <DeleteIconButton 
                        sx={{
                            cursor: "pointer",
                        }}
                        deleteName={alertUser.userName}
                        deleteObject="Alert User"
                        onOpen={() => console.log("click delete")}
                        onDelete={() => deleteAlertUser(alertUser)}
                    />
                </td>
            </tr>
        ))
    }

    return (  
        <>
             <Layout>
                <Container>
                    <Box className="d-flex justify-content-between mb-2">
                        <Title>Alert Users</Title>
                        <Box className="titleBox">
                            <Button className="pt-2 pb-2 ps-3 pe-3" 
                            id="btnAddUser" variant="success" 
                            onClick={handleShowAssignModal}>
                                Assign
                            </Button>
                        </Box>
                    </Box>
                    {successText && <Alert severity="success" sx={{marginBottom: 2}}>{successText}</Alert>}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}
                    <AlertUserFilters 
                        filterValues={filterValues}
                        onChangeFilter={handleChangeFilterValues}
                    />

                    <SummaryText>{alertUsersCount} users</SummaryText>
                    <TableContainer>
                        <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    <th>Oragnization</th>
                                    <th>User</th>
                                    <th>SMS</th>
                                    <th>Email</th>
                                    <th>Remark</th>
                                    <th>Created At</th>
                                    <th>Created By</th>
                                    <th>Updated At</th>
                                    <th>Updated By</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {renderTable()}
                            </ tbody>
                        </table>
                        </InnterTableContainer>
                    </TableContainer>
                    <PaginationContainer>
                        <Pagination count={pageCount} onChange={handleChangePage} page={tablePage} variant="outlined" shape="rounded" size="large" />
                    </PaginationContainer>
                    <AlertUserFormModal 
                        title="Assign"
                        show={showAssignModal}
                        handleShowAssignModal={handleShowAssignModal}
                        alertUser={alertUser}
                        onChange={handleChangeAlertUser}
                        onClick={addAlertUser}
                        errorText={modalErrorText}
                    />
                    <AlertUserFormModal 
                        title="Edit"
                        show={showEditModal}
                        handleShowAssignModal={handleCloseEditModal}
                        alertUser={alertUser}
                        onChange={handleChangeAlertUser}
                        onClick={updateAlertUser}
                        errorText={modalErrorText}
                    />
                </Container>
            </Layout>
        </>
    );
}
 
export default AlertUsers;