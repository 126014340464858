import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";

import UserAuthProvider from "./contexts/UserAuthContext";
import AppContainer from "./container/AppContainer";
import MaintenanceContextProvider from "./contexts/MaintenanceContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { googleLoginAuthGetFirstRowApi } from "./services/RouteServices/ConfigApi";


async function fetchClientId() {
  try {
    const response = await googleLoginAuthGetFirstRowApi()
    return response?.data?.data?.token;
  } catch (error) {
    console.error('Error fetching clientId:', error);
    return null;
  }
}

function App() {

  const [googleClientId, setGoogleClientId] = useState("")

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientId = await fetchClientId();
          setGoogleClientId(clientId);
      } catch (error) {
        console.error('Error fetching clientId:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <GoogleOAuthProvider clientId={googleClientId}>
        <UserAuthProvider>
          <MaintenanceContextProvider>
            <AppContainer />
          </MaintenanceContextProvider>
        </UserAuthProvider>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
