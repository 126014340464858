import axios from "axios";
import { BASE_URL } from "../constants/ApiConstants";
import { STATUS_CODES } from "../constants/GlobalConstants";
import { clearCurrentUserFromLocal, getCurrentLocalUser } from "../utils/UserUtil";
import { DefaultUserInfo, UserInfo } from "../types/UserAuth";

axios.defaults.baseURL = BASE_URL;
// axios.defaults.baseURL = "http://13.214.34.80:8080/api";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["SecretToken"] = "Bear " + process.env.REACT_APP_SECRET_TOKEN || "";

const getAccessTokenForApiOnly = () : string => {
    let cUser : UserInfo = DefaultUserInfo;
    const localUser = localStorage.getItem('fwUserAuth');
    if (localUser) {
        cUser = JSON.parse(localUser) || null;
    }
    return cUser?.accessToken || "";
}

axios.interceptors.request.use((config) => {
    config.headers.AccessToken = "Bear " + getAccessTokenForApiOnly();
    return config;
});

axios.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        if (err?.response?.status === STATUS_CODES.UNAUTHORIZE_CODE) {
            clearCurrentUserFromLocal();
            window.location.reload();
        }
        return Promise.reject(err);
    }
);

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
}