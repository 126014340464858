import http from "../apiServices";
import { TenantsData, TenantDELETERequestBody, TenantUPDATERequestBody, TenantGETRequestBody, TenantGETByOrgIdRequestBody, TenantGETAllByMachineIdRequestBody, TenantGETAllByMachineIdListRequestBody, TenantADDRequestBody } from "../../types/TenantsType";
import { CardInTenantGETRequestBody } from "../../types/CardInTenantType";

// * Post all Given Data to database.table

export const tenantGetDataApi = (inputData : TenantGETRequestBody) => {
    return http.post("/tenant/get/list", inputData)
}

export const tenantGetByIdApi = (id: number) => {
    return http.post("/tenant/get/by/id", {"id": id})
}

export const tenantGetListByOrgIdApi = (inputData: TenantGETByOrgIdRequestBody) => {
    return http.post("/tenant/get/list/by/orgId", inputData)
}

export const tenantGetExportListByOrgIdApi = (inputData: TenantGETByOrgIdRequestBody) => {
    return http.post("/tenant/get/export-list/by/orgId", inputData)
}

export const tenantGetAllByOrgIdApi = (inputData: TenantGETByOrgIdRequestBody) => {
    return http.post("/tenant/get/all/by/orgId", inputData)
}

export const tenantGetAllByMachineIdApi = (inputData: TenantGETAllByMachineIdRequestBody) => {
    return http.post("/tenant/get/all/by/machineId", inputData)
}

export const tenantGetAllByMachineIdListApi = (inputData: TenantGETAllByMachineIdListRequestBody) => {
    return http.post("/tenant/get/all/by/machineIdList", inputData)
}

export const tenantDetailsGetDataApi = (inputData : CardInTenantGETRequestBody) => {
    return http.post("/tenant/get/details", inputData)
}

export const tenantAddDataApi = (inputData : TenantADDRequestBody) => {
    return http.post("/tenant/add", inputData)
}

export const tenantUpdateDataApi = (inputData : TenantUPDATERequestBody) => {
    return http.put(`/tenant/update`, inputData)
}

export const tenantDeleteDataApi = (inputData : TenantDELETERequestBody) => {
    return http.post(`/tenant/delete`, inputData)
}

export const getTenantByUserIdApi = (userId: number) => {
    return http.post('/tenant/get/by/userId', {userId})
}