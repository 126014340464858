import { DataSyncTable } from "../../types/DataSyncType";
import http from "../apiServices";

// * Post all Given Data to database.table
export const dataSyncImportToDBApi = (inputData : DataSyncTable[]) => {
    return http.post("/dataSync/import", inputData)
}

// * Get all DataSync Data from database.table
export const dataSyncExportFromDBApi = (inputData : string) => {
    return http.post("/dataSync", inputData)
}