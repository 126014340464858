import { FunctionComponent, useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ModalImage from 'react-modal-image';
import { convertToLocaleDate } from '../../utils/DateTimeUtil';
import { isSuperSpecialUser } from '../../utils/UserUtil';
import { mergeImageUrlWithBaseUrl } from '../../utils/ImageUtil';
import { NO_IMAGE_URL } from '../../constants/GlobalConstants';
import { ManualDetectionData } from '../../types/ManualDetectionType';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';
import { UserAuthContext } from '../../contexts/UserAuthContext';
import { UserAuthType } from '../../types/UserAuth';

interface ManualDetectionCardProps {
    manualDetection: ManualDetectionData
    onClickDelete: (manualDetection: ManualDetectionData) => void
    onClickEdit: (manualDetection: ManualDetectionData) => void
}
 
const ManualDetectionCard: FunctionComponent<ManualDetectionCardProps> = ({
    manualDetection,
    onClickDelete,
    onClickEdit
}) => {
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    let imageUrl = "";
    if (manualDetection.isOrganic === "1") {
        imageUrl = mergeImageUrlWithBaseUrl(manualDetection?.organicImageUrl) || NO_IMAGE_URL
    } else {
        imageUrl = mergeImageUrlWithBaseUrl(manualDetection?.nonOrganicImageUrl) || NO_IMAGE_URL
    }

    console.log("or", manualDetection?.organicImageUrl)
    console.log("non", manualDetection?.nonOrganicImageUrl)

    return (  
        <Card sx={{ width: 350, marginX: 3, borderRadius: 3, overflow: "hidden" }} className='capture-card'>
            <CardMedia
                sx={{ height: 250, overflow: "hidden" }}
            >
                <ModalImage
                    small={imageUrl}
                    large={imageUrl}
                    alt="Image"
                    className="card-img"
                />
            </CardMedia>
            <CardContent sx={{ maxHeight: 400, overflow: "scroll" }} className="hide-scroll-bar">
                <div className='d-flex justify-content-end align-items-center gap-2 mb-3'>
                    {
                        hasPermission("editManualDetection") &&
                        <EditOutlinedIcon 
                            sx={{
                                color: "blue",
                                fontSize: "30px"
                            }}
                            className="pointer"
                            onClick={() => onClickEdit(manualDetection)}
                        />
                    }
                    {
                        hasPermission("deleteManualDetection") &&
                        <DeleteOutlinedIcon 
                            sx={{
                                color: "red",
                                fontSize: "30px"
                            }}
                            className="pointer"
                            onClick={() => onClickDelete(manualDetection)}
                        />
                    }
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                    <h4><span className="badge bg-info">Id: {manualDetection.id}</span></h4>
                    {
                        manualDetection.isNonOrganic && manualDetection.isNonOrganic == "1" ?
                        <span className="badge" style={{backgroundColor: "#FFA447"}}>NonOrganic</span> :
                        <span className="badge" style={{backgroundColor: "#9BCF53"}}>Organic</span>
                    }
                </div>
                <table>
                    <tbody>
                        <tr>
                            <td className='pe-2 py-2'>Weight(kg)</td>
                            <td className='ps-3 border-start text-secondary'>{manualDetection.weight || 0}</td>
                        </tr>
                        <tr>
                            <td className='pe-2 py-2'>Capture Time</td>
                            <td className='ps-3 border-start text-secondary'>{convertToLocaleDate(manualDetection.captureTime) || "-"}</td>
                        </tr>
                        <tr>
                            <td className='pe-3 py-2'>Organization</td>
                            <td className='ps-3 border-start text-secondary'>{manualDetection.organizationName || "-"}</td>
                        </tr>
                        <tr>
                            <td className='pe-3 py-2'>Tenant</td>
                            <td className='ps-3 border-start text-secondary'>{manualDetection.tenantName || "-"}</td>
                        </tr>
                        <tr>
                            <td className='pe-3 py-2'>Machine</td>
                            <td className='ps-3 border-start text-secondary'>{manualDetection.machineName || "-"}</td>
                        </tr>
                        <tr>
                            <td className='pe-3 py-2'>Remark</td>
                            <td className='ps-3 border-start text-secondary remark-container hide-scroll-bar'>{manualDetection.remark || "-"}</td>
                        </tr>
                        {
                            isSuperSpecialUser() &&
                            <tr>
                                <td className='pe-3'>Created At</td>
                                <td className='ps-3 border-start text-secondary'>{convertToLocaleDate(manualDetection.createdAt!) || "-"}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </CardContent>
        </Card>
    );
}
 
export default ManualDetectionCard;