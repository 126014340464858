import { SelectChangeEvent } from '@mui/material';
import Box from '@mui/material/Box';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';
import { ChangeEvent, useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { Bar } from 'react-chartjs-2';
import XLSX from "xlsx";
import logoImage from '../../assets/logo/env-logo.png';
import { LOG_LEVEL, STATUS_CODES } from '../../constants/GlobalConstants';
import { getAllOrgsList, getAllTenantByMachineIdList, getLocationByOrgId, getMachineListOfATenant, machineGetAllByLocationIdListData } from '../../services/RouteServices/CommonApi';
import { getReportDailyOverallByOrgIdApi, getReportDailyOverallByUserIdApi, getReportHourlyOverallByOrgIdApi, getReportHourlyOverallByUserIdApi, getReportMonthlyOverallByOrgIdApi, getReportMonthlyOverallByUserIdApi } from '../../services/RouteServices/ReportApi';
import { ChartData, CommonFilter, DayFilter, DefaultExportOverallData, DefaultHourFilters, DefaultMonthFilters, ExportOverallDataNew, HourFilter, MonthFilter, NewOverallData, YearFilter, getDefaultCommonFilters, getDefaultDayFilters } from '../../types/ReportType';
import { UserInfo } from '../../types/UserAuth';
import { DropdownData } from '../../types/global/DropDownType';
import { getCurrentLocaleDate, getMonthName, getStartDateAndEndDateOfAMonth } from '../../utils/DateTimeUtil';
import { addEventLog } from '../../utils/EventLogUtil';
import { loadLocationList, loadMachineList, loadOrganizationList, loadTenantList } from '../../utils/LoadDataUtil';
import { addNormalLog } from '../../utils/LoggerUtil';
import { getAvailableLocations, getCurrentLocalUser, isAdminUser, isSpecialUser, isSuperSpecialUser, isTenantUser } from '../../utils/UserUtil';
import Layout from '../Layout/Layout';
import { Container } from '../Shared/Common/Containers';
import { Title as RTitle } from "../Shared/Common/Titles";
import LoadingModal from '../Shared/LoadingModal/LoadingModal';
import { carbonReductionOptions, chartDefaultData, detectionCountOptions, doorOpenCountOptions, weightAmountOptions } from './ChartConstant';
import FilterSection from './FilterSection';
import ReportButtons from './ReportButtons';
import AnnotationContainer from './components/AnnotationContainer';
import BadgeTooltip from './components/BadgeToolTip';
import ChartContainer from './components/ChartContainer';
import {
    generatePdfAnnotations,
    loadCarbonReductionChartData,
    loadDetectionCountChartData,
    loadDoorOpenCountChartData,
    loadFoodAndGeneralWeightAmountChartData,
    transformDetectionCountsDataForExport,
    transformDischargeWeightsDataForExport,
    transformDoorCountsDataForExport,
    transformWeightsDataForExport
} from './generalFunctions';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

const Report = () => {

    const userInfo: UserInfo = getCurrentLocalUser();

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString())
    const [isLoading, setIsLoading] = useState(false)
    const [minMaxEndDate, setMinMaxEndDate] = useState({min: "", max: ""})

    const [orgList, setOrgList] = useState<DropdownData[]>([]);
    const [locationDropdownList, setLocationDropdownList] = useState<DropdownData[]>([]);
    const [tempLocationList, setTempLocationList] = useState<number[]>([]);
    const [machineDropdownList, setMachineDropdownList] = useState<DropdownData[]>([]);
    const [tenantDropDownList, setTenantDropDownList] = useState<DropdownData[]>([]);
    const [tempMachineList, setTempMachineList] = useState<number[]>([])

    const [commonFilters, setCommonFilters] = useState<CommonFilter>(getDefaultCommonFilters())
    const [hourFilters, setHourFilters] = useState<HourFilter>(DefaultHourFilters)
    const [dayFilters, setDayFilters] = useState<DayFilter>(getDefaultDayFilters())
    const [monthFilters, setMonthFilters] = useState<MonthFilter>(DefaultMonthFilters)
    const [yearFilters, setYearFilters] = useState<YearFilter>({} as YearFilter)

    const [weightAmountChartData, setWeightAmountChartData] = useState<ChartData>(chartDefaultData)
    const [carbonReductionChartData, setCarbonReductionChartData] = useState<ChartData>(chartDefaultData)
    const [doorOpenScanCountChartData, setDoorOpenScanCountChartData] = useState<ChartData>(chartDefaultData)
    const [detectionCountChartData, setDetectionCountChartData] = useState<ChartData>(chartDefaultData)

    const [totalWeight, setTotalWeight] = useState(0)
    const [totalDischargeWeight, setTotalDischargeWeight] = useState(0)
    const [totalFoodWeight, setTotalFoodWeight] = useState(0)
    const [totalGeneralWeight, setTotalGeneralWeight] = useState(0)
    const [totalCarbonReduction, setTotalCarbonReduction] = useState(0)
    const [totalDoorOpenCount, setTotalDoorOpenCount] = useState(0)
    const [totalDoorScanCount, setTotalDoorScanCount] = useState(0)
    const [totalNonOrganicCount, setTotalNonOrganicCount] = useState(0)

    const [overallExportData, setOverallExportData] = useState<ExportOverallDataNew>(DefaultExportOverallData)

    const pdfAnotations = generatePdfAnnotations(
        totalWeight, totalFoodWeight, totalGeneralWeight, 
        totalCarbonReduction, totalDischargeWeight, 
        totalDoorOpenCount, totalDoorScanCount, totalNonOrganicCount
    )

    const setMachineData = (res: []) => {
        const result = loadMachineList(res)
        setMachineDropdownList(result)
        setTempMachineList(result?.map(item => +item.value) || [])
        setCommonFilters(prev => ({
            ...prev,
            machines: result?.map(item => +item.value) || []
        }))
    }

    useEffect(() => {
        if (!(isAdminUser() || isTenantUser())) {
            getAllOrgsList()
            .then(res => {
                setOrgList(loadOrganizationList(res))
            })
            .catch(error => {
                // setErrorMessage(error)
                console.log(error.message)
            })
        } else {
            setOrgList([{label: userInfo.orgName, value: ''+userInfo.orgId}])
            getMachineListOfATenant(userInfo.id || 0)
            .then(res => {
                setMachineData(res)
            }).catch(error => {
                console.log(error)
            })
        }
    }, [])

    useEffect(() => {
        if (+commonFilters.org > 0 && commonFilters.org !== '') {
            getLocationByOrgId(+commonFilters.org)
            .then((res: any) => {
                let result = loadLocationList(res)
                if (!isSuperSpecialUser() && !isSpecialUser()) {
                    const avaiLocations = getAvailableLocations()
                    result = result.filter(res => avaiLocations.includes(+res.value))
                }
                setLocationDropdownList(result)
                setTempLocationList(result?.map(item => +item.value) || [])
                setCommonFilters(prev => ({
                    ...prev,
                    locations: result?.map(item => +item.value) || []
                }))
            }).catch(error => {
                console.log(error)
            })
        }
    }, [commonFilters.org])

    useEffect(() => {
        if (!isTenantUser()) {
            machineGetAllByLocationIdListData(commonFilters.locations)
            .then((res: any) => {
                setMachineData(res)
            }).catch(error => {
                console.log(error)
            })
        }
    }, [commonFilters.locations]);

    useEffect(() => {
        getAllTenantByMachineIdList(commonFilters.machines)
        .then(res => {
            const result = loadTenantList(res)
            setTenantDropDownList([{value: "0", label: `All`}, {value: "-1", label: "Inactive"}, ...result])
        }).catch(error => {
            console.log(error)
        })
    }, [commonFilters.machines])

    useEffect(() => {
        if ( +commonFilters.org > 0 && commonFilters.org !== '' && commonFilters.tenant.value
            && commonFilters.view === "1") {
            setIsLoading(true)
            const {startDate, endDate} = getStartDateAndEndDateOfAMonth(+monthFilters.month - 1, +monthFilters.year);
            if (isTenantUser()) {
                getDailyOverallByUserId(startDate, endDate)
            } else {
                getDailyOverallByOrgId(startDate, endDate)
            }
        }
    }, [monthFilters, commonFilters.tenant, commonFilters.view, commonFilters.machines])

    useEffect(() => {
        if ( +commonFilters.org > 0 && commonFilters.org !== ''
            && commonFilters.view === "2") { 
                setIsLoading(true)
                if (isTenantUser()) {
                    getMonthlyOverallByUserId()
                } else {
                    getMonthlyOverallByOrgId()
                }
        }
    }, [selectedYear, commonFilters.tenant, commonFilters.view, commonFilters.machines])

    useEffect(() => {
        if ( +commonFilters.org > 0 && commonFilters.org !== ''
            && commonFilters.view === "4") { 
                setIsLoading(true)
                if (isTenantUser()) {
                    getHourlyOverallByUserId()
                } else {
                    getHourlyOverallByOrgId()
                }
        }
    }, [hourFilters, commonFilters.tenant, commonFilters.view, commonFilters.machines])

    useEffect(() => {
        const startDate = new Date(dayFilters.startDate)
        const endDate = new Date(dayFilters.endDate)
        const minEndDate = new Date(minMaxEndDate.min)
        const maxEndDate = new Date(minMaxEndDate.max)
        const daysDiff = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)

        if (+commonFilters.org > 0 && commonFilters.org !== ''
            && commonFilters.view === "3" &&
            startDate <= endDate &&
            minEndDate <= endDate && maxEndDate >= endDate &&
            daysDiff <= +dayFilters.range
        ) {
            setIsLoading(true)
            if (isTenantUser()) {
                getDailyOverallByUserId(dayFilters.startDate, dayFilters.endDate)
            } else {
                getDailyOverallByOrgId(dayFilters.startDate, dayFilters.endDate)
            }
            
        }
    }, [dayFilters, commonFilters.tenant, commonFilters.view, commonFilters.machines])

    useEffect(() => {
        if (dayFilters.startDate) {
            const startDateObj = new Date(dayFilters.startDate)
            const endDateObj = new Date(dayFilters.endDate)
            const newStartDateObj = new Date(startDateObj.getTime());
            newStartDateObj.setDate(newStartDateObj.getDate() + +dayFilters.range);

            setMinMaxEndDate({
                min: dayFilters.startDate,
                max: newStartDateObj.toISOString().split('T')[0],
            })
    
            if (endDateObj > newStartDateObj || startDateObj > endDateObj) {
                setDayFilters(prev => ({...prev, endDate: newStartDateObj.toISOString().split('T')[0]}))
            }
        }
    }, [dayFilters.startDate])

    useEffect(() => {
        if (dayFilters.endDate) {
            const startDateObj = new Date(dayFilters.startDate)
            const endDateObj = new Date(dayFilters.endDate)
            const newEndDateObj = new Date(endDateObj.getTime())
            newEndDateObj.setDate(newEndDateObj.getDate() - +dayFilters.range)

            if (startDateObj < newEndDateObj || startDateObj > endDateObj) {
                setDayFilters(prev => ({...prev, startDate: newEndDateObj.toISOString().split('T')[0]}))
            }
        }
    }, [dayFilters.endDate])

    useEffect(() => {
        const startDateObj = new Date(dayFilters.startDate)
        const endDateObj = new Date(dayFilters.endDate)
        const daysDiff = (endDateObj.getTime() - startDateObj.getTime()) / (1000 * 60 * 60 * 24)
        const newStartDateObj = new Date(startDateObj.getTime());
        newStartDateObj.setDate(newStartDateObj.getDate() + +dayFilters.range);
        if (daysDiff > +dayFilters.range) {
            setDayFilters(prev => ({...prev, endDate: newStartDateObj.toISOString().split('T')[0]}))
        }
        setMinMaxEndDate({
            min: dayFilters.startDate,
            max: newStartDateObj.toISOString().split('T')[0],
        })
    }, [dayFilters.range])

    const handleChangeCommonFilter = (name: string, value: any) => {
        let copyCommonFilters = {...commonFilters}

        if (name === "org") {
            copyCommonFilters = {
                ...copyCommonFilters,
                tenant: {value: "0", label: "All"},
                [name]: value
            }
        } else {
            copyCommonFilters = {
                ...copyCommonFilters,
                [name]: value
            } 
        }
        setCommonFilters(copyCommonFilters)
    }

    const handleChangeHourFilter = (name: string, value: any) => {
        const day = value.getDate().toString().padStart(2, '0');
        const month = (value.getMonth() + 1).toString().padStart(2, '0');
        const year = value.getFullYear();
        value = `${year}-${month}-${day}`;
        setHourFilters(prev => ({...prev, [name]: value}))
    }

    const handleChangeDayFilter = (name: string, value: any) => {
        let copyDayFilters = {... dayFilters}

        if (name === "startDate" || name === "endDate") {
            const day = value.getDate().toString().padStart(2, '0');
            const month = (value.getMonth() + 1).toString().padStart(2, '0');
            const year = value.getFullYear();
            value = `${year}-${month}-${day}`;
        }
        copyDayFilters = {
            ...copyDayFilters,
            [name]: value
        }
        setDayFilters(copyDayFilters)
    }

    const handleChangeMonthFilter = (name: string, value: any) => {
        setMonthFilters(prev => ({...prev, [name]: value}))
    }

    const handleChangeSelectedYear = (event: ChangeEvent<HTMLSelectElement>) => {
        setSelectedYear(event.target.value)
    }

    const handleTempChangeMachineList = (event: SelectChangeEvent<typeof tempMachineList>) => {
        const {
        target: { value },
        } = event;
        setTempMachineList(
            typeof value === 'string' ? value.split(',').map(value => +value) : value,
        );
    };

    const handleTempChangeLocationList = (event: SelectChangeEvent<typeof tempLocationList>) => {
        const {
        target: { value },
        } = event;
        setTempLocationList(
            typeof value === 'string' ? value.split(',').map(value => +value) : value,
        );
    };

    const handleCloseMachineListDropDown = () => {
        setCommonFilters(prev => {
            return {
                ...prev,
                tenant: {value: "0", label: `All`},
                machines: [...tempMachineList]
            }
        })
    }

    const handleCloseLocationListDropDown = () => {
        setCommonFilters(prev => {
            return {
                ...prev,
                tenant: {value: "0", label: `All`},
                locations: [...tempLocationList]
            }
        })
    }

    const loadDataToStates = (data: NewOverallData) => {
        const doorCount = loadDoorOpenCountChartData(data, commonFilters.view)
        const foodAndGeneralWeightAmount = loadFoodAndGeneralWeightAmountChartData(data, commonFilters.view)
        const detectionCount = loadDetectionCountChartData(data, commonFilters.view)
        const carbonReduction = loadCarbonReductionChartData(data, commonFilters.view)
        setWeightAmountChartData(foodAndGeneralWeightAmount)
        setCarbonReductionChartData(carbonReduction)
        setDoorOpenScanCountChartData(doorCount)
        setDetectionCountChartData(detectionCount)
        setTotalWeight(data?.totalWeight)
        setTotalDischargeWeight(data?.totalDischargeWeight || 0)
        setTotalFoodWeight(data?.totalFoodWeight)
        setTotalGeneralWeight(data?.totalGeneralWeight)
        setTotalCarbonReduction(data?.totalCarbonReduction)
        setTotalDoorOpenCount(data?.totalDoorOpenCount)
        setTotalDoorScanCount(data?.totalDoorScanCount)
        setTotalNonOrganicCount(data?.totalNonOrganicCount)
    }

    const getHourlyOverallByOrgId = () => {
        const input = {
            orgId: +commonFilters.org,
            tenantId: +commonFilters.tenant.value!,
            machineIdList: commonFilters.machines,
            day: hourFilters.day,
        }
        getReportHourlyOverallByOrgIdApi(input)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                const data: NewOverallData = res?.data?.data
                loadDataToStates(data)
                setOverallExportData({
                    weights: data.weights,
                    doorCounts: data.doorCounts,
                    detectionCounts: data.detectionCounts,
                    dischargeWeights: data.dischargeWeights
                })
            } else {
                console.log(res?.data?.message)
            }
            setIsLoading(false)
        }).catch(error => {
            console.log(error?.message)
            setIsLoading(false)
        })
    }

    const getDailyOverallByOrgId = (startDate: string, endDate: string) => {
        const input = {
            orgId: +commonFilters.org,
            tenantId: +commonFilters.tenant.value!,
            machineIdList: commonFilters.machines,
            startDate: startDate,
            endDate: endDate
        }
        getReportDailyOverallByOrgIdApi(input)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                const data: NewOverallData = res?.data?.data
                loadDataToStates(data)
                setOverallExportData({
                    weights: data.weights,
                    doorCounts: data.doorCounts,
                    detectionCounts: data.detectionCounts,
                    dischargeWeights: data.dischargeWeights
                })
            } else {
                console.log(res?.data?.message)
            }
            setIsLoading(false)
        }).catch(error => {
            console.log(error?.message)
            setIsLoading(false)
        })
    }

    const getMonthlyOverallByOrgId = () => {
        const input = {
            orgId: +commonFilters.org,
            tenantId: +commonFilters.tenant.value!,
            machineIdList: commonFilters.machines,
            year: selectedYear
        }
        getReportMonthlyOverallByOrgIdApi(input)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                const data: NewOverallData = res?.data?.data
                loadDataToStates(data)
                // Empty object = empty row in exel
                setOverallExportData({
                    weights: data.weights,
                    doorCounts: data.doorCounts,
                    detectionCounts: data.detectionCounts,
                    dischargeWeights: data.dischargeWeights
                })
            } else {
                console.log(res?.data?.message)
            }
            setIsLoading(false)
        }).catch(error => {
            console.log(error?.message)
            setIsLoading(false)
        })
    }

    const getHourlyOverallByUserId = () => {
        const input = {
            userId: +userInfo.id!,
            machineIdList: commonFilters.machines,
            day: hourFilters.day,
        }
        getReportHourlyOverallByUserIdApi(input)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                const data: NewOverallData = res?.data?.data
                loadDataToStates(data)
                setOverallExportData({
                    weights: data.weights,
                    doorCounts: data.doorCounts,
                    detectionCounts: data.detectionCounts,
                    dischargeWeights: data.dischargeWeights
                })
            } else {
                console.log(res?.data?.message)
            }
            setIsLoading(false)
        }).catch(error => {
            console.log(error?.message)
            setIsLoading(false)
        })
    }

    const getDailyOverallByUserId = (startDate: string, endDate: string) => {
        const input = {
            userId: +userInfo.id!,
            machineIdList: commonFilters.machines,
            startDate: startDate,
            endDate: endDate
        }
        getReportDailyOverallByUserIdApi(input)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                const data: NewOverallData = res?.data?.data
                loadDataToStates(data)
                setOverallExportData({
                    weights: data.weights,
                    doorCounts: data.doorCounts,
                    detectionCounts: data.detectionCounts,
                    dischargeWeights: data.dischargeWeights
                })
            } else {
                console.log(res?.data?.message)
            }
            setIsLoading(false)
        }).catch(error => {
            console.log(error?.message)
            setIsLoading(false)
        })
    }

    const getMonthlyOverallByUserId = () => {
        const input = {
            userId: +userInfo.id!,
            machineIdList: commonFilters.machines,
            year: selectedYear
        }
        getReportMonthlyOverallByUserIdApi(input)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                const data: NewOverallData = res?.data?.data
                loadDataToStates(data)
                setOverallExportData({
                    weights: data.weights,
                    doorCounts: data.doorCounts,
                    detectionCounts: data.detectionCounts,
                    dischargeWeights: data.dischargeWeights
                })
            } else {
                console.log(res?.data?.message)
            }
            setIsLoading(false)
        }).catch(error => {
            console.log(error?.message)
            setIsLoading(false)
        })
    }

    const exportToExcel = async () => {  
        let total: {[key: string]: any} = {}
        if (commonFilters.view === "1") {
            total["View"] = "Month"
            total["Year"] = monthFilters.year
            total["Month"] = monthFilters.month
        } else if (commonFilters.view === "2") {
            total["View"] = "Year"
            total["Year"] = selectedYear
        } else if (commonFilters.view == "3") {
            total["View"] = "Day"
            total["StartDate"] = dayFilters.startDate
            total["EndDate"] = dayFilters.endDate
        } else if (commonFilters.view == "4") {
            total["View"] = "Hour"
            total["Day"] = hourFilters.day
        } 
        total = {
            ...total,
            // "Organization": commonFilters.org,
            // "Machines": commonFilters.machines?.join(", "),
            "Machines": machineDropdownList.filter(machine => tempMachineList.includes(+machine.value!))
                                            .map(machine => machine.label).join(",\n"),
            "Tenant": commonFilters.tenant.label,
            "Total Weight (kg)": totalWeight,
            "Total Carbon Reduction": totalCarbonReduction,
            "Total Door Open Count": totalDoorOpenCount,
            "Total Door Scan Count": totalDoorScanCount,
            "Total Non-organic Count": totalNonOrganicCount,
        }

        const workbook = XLSX.utils.book_new();
        const weights = XLSX.utils.json_to_sheet(transformWeightsDataForExport(overallExportData.weights, hourFilters));
        const dischrageWeights = XLSX.utils.json_to_sheet(transformDischargeWeightsDataForExport(overallExportData.dischargeWeights, hourFilters));
        const doorCounts = XLSX.utils.json_to_sheet(transformDoorCountsDataForExport(overallExportData?.doorCounts, hourFilters));
        const detectionCounts = XLSX.utils.json_to_sheet(transformDetectionCountsDataForExport(overallExportData?.detectionCounts, hourFilters));
        const summary = XLSX.utils.json_to_sheet([total])
        XLSX.utils.book_append_sheet(workbook, summary, "Summary");
        XLSX.utils.book_append_sheet(workbook, weights, "Weight");
        XLSX.utils.book_append_sheet(workbook, dischrageWeights, "Discharge Weight");
        XLSX.utils.book_append_sheet(workbook, doorCounts, "Door Count");
        XLSX.utils.book_append_sheet(workbook, detectionCounts, "Detection Count")

        XLSX.writeFile(workbook, `reports-${getCurrentLocaleDate()}.xlsx`);

        const log: string = `${userInfo.userName} has downloaded reports-${getCurrentLocaleDate()}.xlsx`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    };

    async function exportChartsToPdf() {
        const doc = new jsPDF('landscape', 'mm', 'a4');
        const elements = document.getElementsByClassName("my-chart");
        await creatPdf({ doc, elements }); 
        doc.save(`report-charts-${getCurrentLocaleDate()}.pdf`); 
        const log: string = `${userInfo.userName} has downloaded report-charts-${getCurrentLocaleDate()}.pdf`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    }

    async function creatPdf({
        doc,
        elements,
        }: {
        doc: jsPDF;
        elements: HTMLCollectionOf<Element>;
    }) {
        let top = 25;
        const padding = 10;

        doc.setFontSize(12)
        doc.setTextColor("#1A5D1A")
        if (commonFilters.view === "1") {
            doc.text(`Year: ${selectedYear}, Month: ${getMonthName(+monthFilters.month)}`, 20, 10)
        } else if (commonFilters.view === "2") {
            doc.text(`Year: ${selectedYear}`, 20, 10)
        } else if (commonFilters.view === "3") {
            doc.text(`Start Date: ${dayFilters.startDate}, End Date: ${dayFilters.endDate}`, 20, 10)
        }

        const logoWidth = 20;
        const logoHeight = 10;
        const logoX = 250;
        const logoY = 5;
        doc.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);
      
        for (let i = 0; i < elements.length; i++) {
            const el = elements.item(i) as HTMLElement;
            const imgData = await toPng(el);
        
            let elHeight = el.offsetHeight;
            let elWidth = el.offsetWidth;
        
            const pageWidth = doc.internal.pageSize.getWidth() - 10;
            if (elWidth > pageWidth) {
                const ratio = pageWidth / elWidth;
                elHeight = elHeight * ratio - padding;
                elWidth = elWidth * ratio - padding;
            }
        
            const pageHeight = doc.internal.pageSize.getHeight();
            if (top + elHeight > pageHeight) {
                doc.addPage();
                top = 25;
            }

            const anotations = pdfAnotations[i].anotations
            let x = 20
            let y = 22
            doc.setFontSize(13);
            for (const anotation of anotations) {
                doc.setTextColor(anotation.color)
                doc.text(anotation.text, x, y)
                x += 60
            }
            doc.addImage(imgData, "PNG", padding, top, elWidth, elHeight, `image${i}`);
            top += elHeight + 10;
        }
    }

  return ( 
      <>
          <Layout>
              <Container>
                <Box className="d-flex justify-content-between mb-2">
                    <RTitle>Report</RTitle>
                    <ReportButtons 
                        exportToExcel={exportToExcel}
                        exportChartsToPdf={exportChartsToPdf}
                    />
                </Box>
                    <FilterSection 
                        commonFilters={commonFilters}
                        hourFilters={hourFilters}
                        dayFilters={dayFilters}
                        monthFilters={monthFilters}
                        orgList={orgList}
                        locationDropdownList={locationDropdownList}
                        machineDropdownList={machineDropdownList}
                        tenantDropDownList={tenantDropDownList}
                        selectedYear={selectedYear}
                        tempLocationList={tempLocationList}
                        tempMachineList={tempMachineList}
                        minMaxEndDate={minMaxEndDate}
                        handleCloseMachineListDropDown={handleCloseMachineListDropDown}
                        handleCloseLocationListDropDown={handleCloseLocationListDropDown}
                        handleChangeSelectedYear={handleChangeSelectedYear}
                        handleTempChangeMachineList={handleTempChangeMachineList}
                        handleTempChangeLocationList={handleTempChangeLocationList}
                        onChangeCommonFilter={handleChangeCommonFilter}
                        onChangeDayFilter={handleChangeDayFilter}
                        onChangeMonthFilter={handleChangeMonthFilter}
                        onChangeHourFilter={handleChangeHourFilter}
                    />
                    {
                        isLoading &&
                        <LoadingModal 
                            show={isLoading}
                        />
                    }
                    {
                        tempMachineList?.length > 0 &&
                        <fieldset className='border border-3 rounded mt-3 p-3 '>
                            <legend className='fs-6 fw-bold'>Selected Machines:</legend>
                            <p className='fw-light'>
                                {
                                    machineDropdownList.filter(machine => tempMachineList.includes(+machine.value!))
                                                    .map(machine => machine.label)
                                                    .join(", ")
                                }
                            </p>
                        </fieldset>
                    }
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <ChartContainer>
                            <AnnotationContainer>
                                <BadgeTooltip 
                                    badge_text={`Total Weight: ${totalWeight.toFixed(2)} kg`}
                                    tooltip_text='Total Weight'
                                    color='#D14D72'
                                />
                                <BadgeTooltip 
                                    badge_text={`Food Waste: ${totalFoodWeight.toFixed(2)} kg`}
                                    tooltip_text='Total Food Waste'
                                    color='#58A399'
                                />
                                <BadgeTooltip 
                                    badge_text={`General Waste: ${totalGeneralWeight.toFixed(2)} kg`}
                                    tooltip_text='Total General Waste'
                                    color='#41C9E2'
                                />
                                {
                                    (totalDischargeWeight > 0) &&
                                    <BadgeTooltip 
                                        badge_text={`Discharge Weight: ${totalDischargeWeight.toFixed(2)} kg`}
                                        tooltip_text='Total Discharge Weight'
                                        color='#E9C874'
                                    />
                                }
                            </AnnotationContainer>
                            <Bar options={weightAmountOptions} data={weightAmountChartData!} className='my-chart' />
                        </ChartContainer>
                        <ChartContainer>
                            <AnnotationContainer>
                                <BadgeTooltip 
                                    badge_text={`Carbon Reduction: ${totalCarbonReduction.toFixed(2)} kg`}
                                    tooltip_text='Total Carbon Reduction'
                                    color='#C69774'
                                />
                            </AnnotationContainer>
                            <Bar options={carbonReductionOptions} data={carbonReductionChartData!} className='my-chart' />
                        </ChartContainer>
                        <ChartContainer>
                            <AnnotationContainer>
                                <BadgeTooltip 
                                    badge_text={`Door Open Count: ${totalDoorOpenCount || 0}`}
                                    tooltip_text='Total Door Open Count'
                                    color='#19A7CE'
                                />
                                <BadgeTooltip 
                                    badge_text={`Door Scan Count: ${totalDoorScanCount || 0}`}
                                    tooltip_text='Total Door Scan Count'
                                    color='#FFB84C'
                                />
                            </AnnotationContainer>
                            <Bar options={doorOpenCountOptions} data={doorOpenScanCountChartData!} className='my-chart' />
                        </ChartContainer>
                        <ChartContainer>
                            <AnnotationContainer>
                                <BadgeTooltip 
                                    badge_text={`Non-organic Count: ${totalNonOrganicCount || 0}`}
                                    tooltip_text='Total Non-organic Count'
                                    color='#4A55A2'
                                />
                            </AnnotationContainer>
                            <Bar options={detectionCountOptions} data={detectionCountChartData!} className='my-chart' />
                        </ChartContainer>
                    </Box>
              </Container>
          </Layout>
      </>
  );

}
 
export default Report;