import { ApproveRequest, PendingUsersGETListRequest, RejectRequest } from "../../types/PendingUserTypes";
import http from "../apiServices";

export const getPendingUsersListApi = (inputData : PendingUsersGETListRequest) => {
    return http.post("/pending-users/get/list", inputData)
}

export const approvePendingUserApi = (inputData: ApproveRequest) => {
    return http.post("/pending-users/approve", inputData)
}

export const rejectPendingUserApi = (inputData: RejectRequest) => {
    return http.post("/pending-users/reject", inputData)
}