import { Box } from '@mui/material';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { convertToLocaleDate } from '../../utils/DateTimeUtil';

const SmsConfig = ({
    smsConfig,
    onChange,
    onClick
}: any) => {
    return (  
        <Box
            sx={{
                minWidth: "400px",
                marginTop: 3,
            }}
        >   
            <Form>
                <Form.Label as="legend">
                    SMS Config
                </Form.Label>
                <div
                    className="border border-light border-3 rounded p-3 bg-light bg-gradient" 
                >
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            Hostname
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" placeholder='http://datumbluedoor.sendquickasp.com/' name="hostname" value={smsConfig?.hostname} onChange={onChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            Token
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" placeholder='GmwFgPfLWL3Uhu7Y' name="token" value={smsConfig?.token} onChange={onChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            CountryCode
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" placeholder='65' name="countryCode" value={smsConfig?.countryCode} onChange={onChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            Remark
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" placeholder='Remark' name="remark" value={smsConfig?.remark} onChange={onChange} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="">
                        <Form.Label column sm="3">
                             
                        </Form.Label>
                        <Col sm="9">
                            <span style={{fontSize:13, color:'#35a612'}}>Last Update - {convertToLocaleDate(smsConfig.updatedAt) || convertToLocaleDate(smsConfig.createdAt)}</span>
                            <br></br>
                            <span style={{fontSize:13, color:'#35a612'}}>Updated User - {smsConfig.updatedBy || smsConfig.createdBy}</span>
                        </Col>
                    </Form.Group>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "right"
                        }}
                    >
                        <Button variant="primary" onClick={onClick}>Update</Button>
                    </Box>
                </div>
            </Form>
        </Box>
    );
}
 
export default SmsConfig;