import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { getLocationByOrgId } from "../../../../services/RouteServices/CommonApi";
import { LocationsData } from "../../../../types/LocationsType";
import { UserData } from "../../../../types/UsersType";

interface AssignLocationModalProps {
    show: boolean,
    toggleShow: () => void,
    handleAssignLocations: () => void
    selectedUser: UserData,
    handleToggleLocaitions: (id: number) => void
    selectedLocations: number[]
    assignedLocations: number[]
}

const AssignLocationModal: FunctionComponent<AssignLocationModalProps> = ({
    show,
    toggleShow,
    handleAssignLocations,
    selectedUser,
    selectedLocations,
    assignedLocations,
    handleToggleLocaitions,
}) => {
    const [locations, setLocations] = useState<LocationsData[]>([])

    useEffect(() => {
        setLocations([])
        if (selectedUser && selectedUser.organizationId > 0) {
            getLocationByOrgId(selectedUser.organizationId)
                .then((res: LocationsData[]) => {
                    setLocations(res)
                })
        }
    }, [selectedUser])

    return (
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Assign Locations</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Typography sx={{fontSize: "20px", fontWeight: "bold"}}>Locations</Typography>
                <TableContainer sx={{ marginTop: 3, maxHeight: 500, overflowY: "scroll" }} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Location</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {locations.map((location) => (
                                <TableRow
                                    key={location.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell>
                                        <Checkbox 
                                            checked={
                                                (selectedLocations.indexOf(location.id!) !== -1) ||
                                                (assignedLocations.indexOf(location.id!) !== -1)
                                            }
                                            onChange={() => handleToggleLocaitions(location.id)}
                                            disabled={assignedLocations.indexOf(location.id!) !== -1}
                                        />
                                    </TableCell>
                                    <TableCell>{location.locationName}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={toggleShow}>Cancel</Button>
                <Button variant="success" onClick={handleAssignLocations}>Assign</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AssignLocationModal;