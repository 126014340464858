export type OrganizationsData = {
  id?: number;
  organizationName: string;
  organizationImage: string;
  carbonReductionFactor: number;
  subscriptionExpiredDate: string;
  remark: string;
  createdBy?: string;
  updatedBy?: string;
  deletedBy?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

export type OrgListResponseBody = {
  data: {
    orgList: OrganizationsData[],
    totalCount: number,
  }
  status: number,
  message: string,
}

export type OrgResponseBody = {
  data: OrganizationsData,
  status: number,
  message: string,
}

export type OrgGETRequestBody = {
  searchText: string,
  limit: number,
  offset: number
}

export type OrgADDRequestBody = {
  organizationName: string,
  organizationImage: string,
  remark: string,
  createdBy: string
}

export type OrgDELETERequestBody = {
  id: number,
  deletedBy: string,
}

export type OrgRemoveImageRequestBody = {
  id: number,
  updatedBy: string,
}

export type OrgUPDATERequestBody = {
  id: number,
  organizationName: string,
  organizationImage: string,
  carbonReductionFactor: number,
  subExpiredDate: string,
  remark: string,
  updatedBy: string,
}

export const OrgDefault: OrganizationsData = {
  id: 0,
  organizationName: "",
  organizationImage: "",
  carbonReductionFactor: 0,
  subscriptionExpiredDate: "",
  remark: ""
}
  