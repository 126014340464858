import React, { ChangeEvent, useEffect, useState } from "react";
// import { NodeProps } from "../../types/CommonType";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styling/customStyle.css';
import Layout from "../Layout/Layout";
import { useNavigate } from "react-router-dom";
import { Container } from "../Shared/Common/Containers";
import { Title, TitleH5 } from "../Shared/Common/Titles";
const InOrganicDetection: React.FC = () => {
    const navigate = useNavigate();
    const redirectToHome = () => {
        setTimeout(()=> {
            navigate("/dashboard", {replace: true});
        }, 1500);
    }
    useEffect(() => {
        redirectToHome();
    }, [])
    return (
        <>
            <Layout>
                <Container>
                    <Title>Page Not Found</Title>
                    <TitleH5>Error 404! Redirecting to homepage...</TitleH5>
                </Container>
            </Layout>
        </>
    );
};

export default InOrganicDetection;