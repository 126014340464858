import React, { useContext } from 'react'
import {
    Card,
} from "./../TenantDetailsElements";
import Box from "@mui/material/Box"
import TenantDetailsTable from './../TenantDetailsTable'
import AddIcon from '@mui/icons-material/Add';
import { TitleH5 } from '../../../Shared/Common/Titles';
import { UserAuthContext } from '../../../../contexts/UserAuthContext';
import { UserAuthType } from '../../../../types/UserAuth';

const CardWithTable = ({
    title,
    type,
    totalCount,
    dataList,
    columnList,
    openAssignModal,
    openDeleteModal,
    openUpdateModal,
}:any) => {   
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType
    return ( 
        <Card backgroundColor="#f4f4f3">
            <Box className="card-top">
                <Box
                    className="detail-button-box"
                >
                    <TitleH5>{title}: </TitleH5>
                    {
                        (hasPermission("assignCard") || type === "User") &&
                        <button 
                            className="btn btn-outline-success" 
                            type="button" 
                            onClick={() => openAssignModal(type)}
                        >
                                <AddIcon/> Assign
                        </button>
                    }
                </Box>
                    <TitleH5>{totalCount || 0} Rows</TitleH5>
            </Box>
            <TenantDetailsTable 
                dataList={dataList || []}
                columnList={columnList}
                openDeleteModal={openDeleteModal}
                openUpdateModal={openUpdateModal}
            />
        </Card>
    );
}
 
export default CardWithTable;