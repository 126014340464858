import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from "react-bootstrap/Button";
import Alert from "@mui/material/Alert"
import { checkSuperSpecialRole, checkAdminRole, isSuperSpecialUser, checkSpecialRole } from "../../utils/UserUtil";
import Box from "@mui/material/Box"
import { ModalContainer } from "../Shared/Common/Containers";
import './Roles.css'
import TenantPermissions from './components/TenantPermissions';
import AdminPermissions from './components/AdminPermissions';
import SystemAdminPermissions from './components/SystemAdminPermissions';

const RoleModal = ({
    show,
    title,
    onChangeRoleData,
    onChangeRolePermission,
    btnName,
    onRoleForm,
    onClose,
    selectedRole,
    successText,
    errorText,
    btnColor,
}: any): JSX.Element => {

    return ( 
        <Modal show={show} onHide={onClose}
            dialogClassName="modal-90w"
            size='xl'
            fullscreen={true}
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            {successText && <Alert severity="success">{successText}</Alert>}
            {errorText && <Alert severity="error">{errorText}</Alert>}
            <Modal.Body>
                <Form className="ModalForm">
                    <ModalContainer>
                        <Form.Group className="mb-3">
                            <div className='d-flex gap-2 flex-wrap'>
                                <InputGroup className="mb-3" style={{width: "500px"}}>
                                    <InputGroup.Text className="input-org">Name <span className="text-danger"> *</span></InputGroup.Text>
                                    <Form.Control name="roleName" onChange={onChangeRoleData} type="name" placeholder="Enter Role Name" value={selectedRole.roleName} required/>
                                </InputGroup>
                                <InputGroup className="mb-3" style={{width: "500px"}}>
                                    <InputGroup.Text className="input-org">Remark</InputGroup.Text>
                                    <Form.Control name="remark" onChange={onChangeRoleData} type="remark" placeholder="Enter Remark" value={selectedRole.remark} required/>
                                </InputGroup>
                            </div>
                            {
                                (checkSpecialRole(selectedRole.id) || checkAdminRole(selectedRole.id)) &&
                                <Form.Check
                                    inline
                                    label="Is Admin"
                                    name="isAdmin"
                                    value={1}
                                    defaultChecked={checkAdminRole(selectedRole.id) || selectedRole.isAdmin}
                                    type={"checkbox"}
                                    id={`is_admin`}
                                    onChange={onChangeRolePermission}
                                    className=""
                                    disabled={checkAdminRole(selectedRole.id) || selectedRole.isOperator}
                                /> 
                            }
                            {
                                checkSpecialRole(selectedRole.id) &&
                                <Form.Check
                                    inline
                                    label="Is Operator"
                                    name="isOperator"
                                    value={1}
                                    defaultChecked={selectedRole.isOperator}
                                    type={"checkbox"}
                                    id={`is_operator`}
                                    onChange={onChangeRolePermission}
                                    className=""
                                    disabled={selectedRole.isAdmin}
                                />
                            }

                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label as="legend">
                                Permissions
                            </Form.Label>
                            <div className='d-flex gap-5 justify-content-center'>
                                <div>
                                    <Box sx={{ width: '20px', height: '20px', backgroundColor : "#3B71CA"}}/>
                                    <span style={{fontSize:'15px'}}>Tenant</span>
                                </div>
                                
                                <div>
                                    <Box sx={{ width: '20px', height: '20px', backgroundColor : "#54B4D3"}}/>
                                    <span style={{fontSize:'15px'}}>Admin</span>
                                </div>
                                
                                <div>
                                    <Box sx={{ width: '20px', height: '20px', backgroundColor : "#DC4C64"}}/>
                                    <span style={{fontSize:'15px'}}>System Admin</span>
                                </div>
                            </div>
                            <div
                                className="border border-light border-3 rounded p-3 bg-light bg-gradient d-flex justify-content-around" 
                            >   
                                <TenantPermissions 
                                    selectedRole={selectedRole}
                                    onChangeRolePermission={onChangeRolePermission}
                                />
                                {
                                    (checkSuperSpecialRole(selectedRole.id) || checkAdminRole(selectedRole.id) || selectedRole.isAdmin) &&
                                    <AdminPermissions 
                                        selectedRole={selectedRole}
                                        onChangeRolePermission={onChangeRolePermission}
                                    />
                                }
                                {
                                    (checkSuperSpecialRole(selectedRole.id)) &&
                                    <SystemAdminPermissions 
                                        selectedRole={selectedRole}
                                        onChangeRolePermission={onChangeRolePermission}
                                    />
                                }
                            </div>
                        </Form.Group>
                    </ModalContainer>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Cancel</Button>
                <Button variant={btnColor} onClick={onRoleForm}>{btnName}</Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default RoleModal;