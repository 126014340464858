import { Box, Pagination } from "@mui/material";
import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title } from "../Shared/Common/Titles";
import LoginHistoryFilterContainer from "./LoginHistoryFilterContainer";
import { DateFilterValue, FilterValue } from "../../types/global/FilterValueType";
import { getDefaultDateFilterValue, getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import { PAGE_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";
import { LoginHistoryType } from "../../types/LoginHistoryType";
import { getLoginHistoriesByOrgIdApi } from "../../services/RouteServices/LoginHistoriesApi";
import { convertDateFormat, convertToLocaleDate } from "../../utils/DateTimeUtil";
import { isRootAndSuperUsers } from "../../utils/UserUtil";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";

interface LoginHistoryProps {
    
}
 
const LoginHistory: FunctionComponent<LoginHistoryProps> = () => {
    const tableLimit = PAGE_LIMIT;

    const [filterValue, setFilterValue] = useState<FilterValue>(getDefaultFilterValue());
    const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>(getDefaultDateFilterValue());
    const [tablePage, setTablePage] = useState(1);
    const [tableOffset, setTableOffset] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [loginHistories, setLoginHistories] = useState<LoginHistoryType[]>([])

    const pageCount = (Math.ceil(totalCount/tableLimit)) || 0

    useEffect(() => {
        if (isRootAndSuperUsers()) { 
            getLoginHistoriesByOrgId()
        }
    }, [filterValue, dateFilterValue, tableOffset])

    const handleChangeFilterValue = (event: ChangeEvent<any>) => {
        setFilterValue(
            (prevValue) => {
                return {
                    ...prevValue,
                    [event.target.name]: event.target.value,
                }
            }
        )
    }

    const handleChangeDateFilterValue = (key: "startDate" | "endDate", value: Date) => {
        const day = value.getDate().toString().padStart(2, '0');
        const month = (value.getMonth() + 1).toString().padStart(2, '0');
        const year = value.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setDateFilterValue({...dateFilterValue, [key]: formattedDate})
    }

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };

    const getLoginHistoriesByOrgId = () => {
        const body = {
            orgId: filterValue.organizationId!,
            searchText: filterValue.search || "",
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: tableLimit,
            offset: tableOffset
        }
        getLoginHistoriesByOrgIdApi(body)
        .then(res => {
            const response = res?.data
            if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                setLoginHistories(response?.data?.loginHistories)
                setTotalCount(response?.data?.totalCount)
            }
        }).catch(error => {

        })
    }

    const renderTable = () => {
        if (loginHistories && loginHistories.length > 0) {
            return loginHistories.map(loginHistory => {
                return (
                    <tr key={loginHistory.id}>
                        <td>{loginHistory.id}</td>
                        <td>{loginHistory.organizationName || "-"}</td>
                        <td>{loginHistory.username || "-"}</td>
                        <td>{loginHistory.roleName || "-"}</td>
                        <td>{loginHistory.remark || "-"}</td>
                        <td>{convertToLocaleDate(loginHistory.loginDate)}</td>
                    </tr>
                )
            })
        } else {
            return (
                <tr>
                    <td>No data found</td>
                </tr>
            )
        }
    }

    if (!isRootAndSuperUsers()) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (  
        <Box>
            <Layout>
                <Container>
                    <Title>Login History</Title>
                    <LoginHistoryFilterContainer 
                        filterValue={filterValue}
                        dateFilterValue={dateFilterValue}
                        onChangeFilter={handleChangeFilterValue}
                        onChangeDateFilter={handleChangeDateFilterValue}
                    />

                    <SummaryText>{totalCount} LoginHistories found!</SummaryText>
                    <TableContainer>
                        <InnterTableContainer>
                            <table className="styled-table">
                                <thead className="table-header">
                                    <tr>
                                        <th>Id</th>
                                        <th>Organization</th>
                                        <th>User</th>
                                        <th>Role</th>
                                        <th>Remark</th>
                                        <th>Login Date</th>
                                    </tr>
                                </thead>
                                <tbody className="table-data">
                                    {renderTable()}
                                </tbody>
                            </table>
                        </InnterTableContainer>
                    </TableContainer>
                    <PaginationContainer>
                        <Pagination count={pageCount} onChange={handleChangePage} page={tablePage} variant="outlined" shape="rounded" size="large" />
                    </PaginationContainer>
                </Container>
            </Layout>
        </Box>
    );
}
 
export default LoginHistory;