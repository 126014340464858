import { FunctionComponent } from "react";
import PreviewTableContainer from "../components/PreviewTableContainer";
import { CARD1_WEIGHT, INACTIVE_WEIGHT, TOTAL_WEIGHT, UNASSIGNED_WEIGHT } from "../../../constants/ReportDetailsContant";

interface DailyTableProps {
    headers: string[]
    data: { [key: string]: any }[]
}
 
const DailyTable: FunctionComponent<DailyTableProps> = ({
    headers, data
}) => {
    return (  
        <PreviewTableContainer 
            style={{
                maxWidth: "700px",
                maxHeight: "500px",
            }}
        >
            <table className="table">
                <thead>
                    <tr>
                        {
                            headers.map(header => (
                                <th key={header} scope="col" 
                                    className={
                                        header === CARD1_WEIGHT ? "text-danger" : 
                                        header === UNASSIGNED_WEIGHT ? "text-warning" :
                                        header === INACTIVE_WEIGHT ? "text-primary" :
                                        header === TOTAL_WEIGHT ? "text-success" : "" }
                                >
                                    {header}
                                </th>
                            ))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((row, index) => (
                            <tr key={index}
                                className={index === 0 ? "font-monospace fst-italic text-secondary border-bottom border-dark" : ""}
                            >
                                {
                                    headers.map((header, index) => (
                                        <td key={index} 
                                        >
                                            {row[header] || 0}
                                        </td>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table> 
        </PreviewTableContainer>
    );
}
 
export default DailyTable;