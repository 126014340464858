import { RoleADDRequestBody, RoleDELETERequestBody, RoleGETRequestBody, RoleUPDATERequestBody, RolesData } from "../../types/RoleType";
import http from "../apiServices";

export const roleGetAllDataApi = () => {
    return http.post("/role/get/all", {})
}

export const roleGetListDataApi = (inputData: RoleGETRequestBody) => {
    return http.post("role/get/list", inputData)
}

export const roleGetDataByIdApi = (id: number) => {
    return http.post("role/get/by/id", {id: id})
}

export const roleAddDataApi = (inputData: RoleADDRequestBody) => {
    return http.post("role/add", inputData)
}

export const roleUpdateDataApi = (inputData: RoleUPDATERequestBody) => {
    return http.put("role/update",  inputData)
}

export const roleDeleteDataApi = (inputData: RoleDELETERequestBody) => {
    return http.post("role/delete", inputData)
}
