import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

const LoadingModal = ({
    show,
}: any) => {
    return (  
        <Modal show={show} size="sm">
            <Button variant="primary" className='d-flex justify-content-center gap-3 align-items-center' disabled>
                <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                />
                <span>
                    Loading...
                </span>
            </Button>
        </Modal>
    );
}
 
export default LoadingModal;