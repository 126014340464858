import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from "react-bootstrap/Button";
import Box from "@mui/material/Box"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Alert from "@mui/material/Alert"
import { ModalContainer } from "../Shared/Common/Containers";
import { Formik } from 'formik';
import * as Yup from "yup";

const hardwareSchema = Yup.object({
    userAssignedName: Yup.string().required("User assigned name is required!")
})

const HardwareUpdateModal = ({
    show,
    title,
    hardware,
    onOpen,
    onClose,
    onUpdate,
    error,
}: any) => {

    return (  
        <>
            <Box sx={{
                backgroundColor: "white",
                display: "inline",
                padding: 1,
                borderRadius: 2,
            }}>
                <EditOutlinedIcon 
                    sx={{
                        color: "blue",
                    }}
                    className="pointer"
                    onClick={onOpen}
                />
            </Box>
        
            <Modal show={show}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error && <Alert severity="error" sx={{marginBottom: 2}}>{error}</Alert>}
                    <Formik
                        initialValues={hardware}
                        onSubmit={(values) => {onUpdate(values);}}
                        // validationSchema={hardwareSchema}
                    >
                        {
                            ({values, touched, errors, handleChange, handleSubmit, handleBlur}) => (
                            <Form className="ModalForm" onSubmit={handleSubmit}>
                                <ModalContainer>
                                    <Form.Group className="mb-3">
                                        <div className="mb-3">
                                            <InputGroup>
                                                <InputGroup.Text className="d-flex">User Assigned Name <span className="text-danger">*</span></InputGroup.Text>
                                                <Form.Control name="userAssignedName" onChange={handleChange} onBlur={handleBlur} type="name" placeholder="Enter User Assigned Name" value={values.userAssignedName || ""}/>
                                            </InputGroup>
                                            {(touched.userAssignedName && errors.userAssignedName) && <p className='text-danger'>{""+errors.userAssignedName}</p>}
                                        </div>
                                    </Form.Group>
                                    <div className="d-flex justify-content-end gap-4">
                                        <Button variant="secondary" onClick={onClose}>Cancel</Button>
                                        <Button type="submit" variant={"primary"} >Update</Button>
                                    </div>
                                </ModalContainer>
                            </Form>
                            )
                        }
                        
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
}
 
export default HardwareUpdateModal;