import React from 'react'
import {
    Card,
} from "../MachineDetailsElements";
import Box from "@mui/material/Box"
import TextField  from "@mui/material/TextField"
import MachineDetailsTable from './../MachineDetailsTable'
import AddIcon from '@mui/icons-material/Add';
import { isRootAndSuperUsers } from '../../../../utils/UserUtil';
import { TitleH5 } from '../../../Shared/Common/Titles';
import { Form } from 'react-bootstrap';

const CardWithTable = ({
    title,
    type,
    totalCount,
    dataList,
    columnList,
    onOpenAddModal,
    onOpenDeleteModal,
    onOpenUpdateModal,
    onOpenUpdateUUID,
    disable,
    machineUUID,
    handleShowOnlyUnassignedCheck
}:any) => {
    return ( 
        <Card backgroundColor="#f4f4f3">
            <Box className="card-top">
                <Box
                    className="detail-button-box"
                >
                    {/* {
                        isRootAndSuperUsers() &&
                        <>
                            <TitleH5>{title}: </TitleH5>
                            <button 
                                className="btn btn-outline-success" 
                                type="button" 
                                onClick={() => onOpenAddModal(type)}
                                disabled={disable}
                            >
                                    <AddIcon/> Add
                            </button>
                        </>
                    } */}
                </Box>
                <Form.Check
                    type="checkbox"
                    label="Show only unassigned cards"
                    id={`show-only-unassigned`}
                    name="showOnlyUnassigned"
                    value={1}
                    onChange={handleShowOnlyUnassignedCheck}
                />
                <TitleH5>{totalCount || 0} Rows</TitleH5>
            </Box>
            <MachineDetailsTable 
                dataList={dataList || []}
                columnList={columnList}
                onOpenDeleteModal={onOpenDeleteModal}
                onOpenUpdateModal={onOpenUpdateModal}
            />
            {
                isRootAndSuperUsers() &&
                <Box
                    sx={{
                        textAlign: "center",
                    }}
                >
                    {/* <button 
                    className="btn btn-outline-warning text-black mb-2" 
                    type="button" 
                    onClick={onOpenUpdateUUID}
                    >
                        Generate New UUID
                    </button> */}
                    <p
                        className="text-info"
                    >
                        Current UUID - [{machineUUID}]
                    </p>
                </Box>
            }
        </Card>
    );
}
 
export default CardWithTable;