import { Alert, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { STATUS_CODES } from "../../constants/GlobalConstants";
import { googleLoginAuthAddOrUpdateApi, googleLoginAuthGetFirstRowApi } from "../../services/RouteServices/ConfigApi";
import { GoogleLoginAuthType } from "../../types/GoogleLoginAuthType";
import { UserInfo } from "../../types/UserAuth";
import { convertToLocaleDate } from "../../utils/DateTimeUtil";
import { getCurrentLocalUser } from "../../utils/UserUtil";

const GoogleLogAuth = () => {
    const userInfo: UserInfo = getCurrentLocalUser();
    const [googleLoginAuth, setGoogleLoginAuth] = useState<GoogleLoginAuthType>({} as GoogleLoginAuthType)
    const [successText, setSuccessText] = useState<String>("");
    const [errorText, setErrorText] = useState<String>("");

    useEffect(() => {
        googleLoginAuthGetFirstRowApi()
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setGoogleLoginAuth(res?.data?.data)
            } else {
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message) 
        })
    }, [])

    const handleChangeGoogleLoginAuth = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target
        setGoogleLoginAuth(prevData => {
            return {
                ...prevData,
                [name]: value
            }
        })
    }

    const handleChangeEnableLoginSwitch = (value: boolean) => {
        setGoogleLoginAuth(prevData => {
            return {
                ...prevData,
                enableLogin: value
            }
        })
    }

    const handleAddOrUpdateGoogleLoginAuthConfig = () => {
        setErrorText("")
        const dataLimitObject = {
            token: googleLoginAuth?.token?.trim() || "",
            enableLogin: googleLoginAuth.enableLogin,
            remark: googleLoginAuth?.remark?.trim() || "",
            doneBy: userInfo.userName
        }

        googleLoginAuthAddOrUpdateApi(dataLimitObject)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setGoogleLoginAuth(res?.data?.data)
                setSuccessText(res?.data?.message)
                setTimeout(() => {
                    setSuccessText("")
                }, 2000)
            } else {
                setErrorText(res?.data?.message)
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }

    return (  
        <Box
            sx={{minWidth: "400px", marginTop: 3}}
        >
            <Form>
                <Form.Label as="legend">
                    Google Login Auth
                </Form.Label>
                {successText && <Alert severity="success" className="mb-3">{successText}</Alert>}
                {errorText && <Alert severity="error" className="mb-3">{errorText}</Alert>}
                <div
                    className="border border-light border-3 rounded p-3 bg-light bg-gradient" 
                >
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            <p className='mb-0'>
                                <span>Token</span><span className='text-danger'>*</span>
                            </p>
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" name="token" value={googleLoginAuth.token || ""} onChange={handleChangeGoogleLoginAuth} placeholder='6222126201:AAHoG7QI-T...' />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="3">
                            <p className='mb-0'>
                                <span>Remark</span>
                            </p>
                        </Form.Label>
                        <Col sm="9">
                            <Form.Control type="text" placeholder='' name="remark" value={googleLoginAuth.remark || ""} onChange={handleChangeGoogleLoginAuth} />
                        </Col>
                    </Form.Group>

                    <Form.Check 
                        inline
                        type="switch"
                        id="enable-login"
                        label="Enable Login"
                        className="mb-3"
                        defaultChecked={googleLoginAuth.enableLogin}
                        onChange={(event) => handleChangeEnableLoginSwitch(event.target.checked)}
                    />
                    
                    <Form.Group as={Row} className="">
                        <Form.Label column sm="3">
                             
                        </Form.Label>
                        <Col sm="9">
                            <span style={{fontSize:13, color:'#35a612'}}>Last Update - {convertToLocaleDate(googleLoginAuth?.updatedAt || "") || convertToLocaleDate(googleLoginAuth?.createdAt || "")}</span>
                            <br></br>
                            <span style={{fontSize:13, color:'#35a612'}}>Updated User - {googleLoginAuth.updatedBy || googleLoginAuth.createdBy}</span>
                        </Col>
                    </Form.Group>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "right",
                            gap: 2,
                            marginTop: 3,
                        }}
                    >
                        <Button variant="primary" onClick={handleAddOrUpdateGoogleLoginAuthConfig}>Update</Button>
                    </Box>
                </div>
            </Form>
        </Box>
    );
}
 
export default GoogleLogAuth;