import { Button, Modal } from "react-bootstrap";
import Alert from "@mui/material/Alert"

const TenantDeleteModal = ({
    show,
    successMsg,
    errorMsg,
    data,
    onClose,
    handleDelete
}: any) => {
    return ( 
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Delete Confirmation</Modal.Title>
            </Modal.Header>
            {successMsg && <Alert severity="success">{successMsg}<br/></Alert>}
            {errorMsg && <Alert severity="error">{errorMsg}<br/></Alert>}
            <Modal.Body>
                <p>Are you sure you want to delete <strong>[{data.tenantName}]</strong> Tenant?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Cancel</Button>
                <Button variant="danger" onClick={handleDelete}>Delete</Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default TenantDeleteModal;