import { Navigate, Outlet } from "react-router-dom";
import { isSuperSpecialUser } from "../utils/UserUtil";

const SpecialRoute = () => {
  return isSuperSpecialUser() ? (
    <Outlet />
  ) : (
    <Navigate to={"/not-found"} replace />
  );
};

export default SpecialRoute;
