import jsPDF from 'jspdf';
import { ManualDetectionReportDateFilterValue } from '../../../types/ManualDetectionType';
import { getMonthName } from '../../../utils/DateTimeUtil';
import { toPng } from 'html-to-image';
import logoImage from '../../../assets/logo/env-logo.png';



export async function creatPdf({
    doc,
    elements,
    selectedView,
    dateFilterValue,
    pdfAnotations
    }: {
    doc: jsPDF;
    elements: HTMLCollectionOf<Element>;
    selectedView: string,
    dateFilterValue: ManualDetectionReportDateFilterValue,
    pdfAnotations: any[]
}) {
    let top = 40;
    const padding = 10;

    doc.setFontSize(12)
    doc.setTextColor("#1A5D1A")
    if (selectedView === "1") {
        doc.text(`Year: ${dateFilterValue.year}, Month: ${getMonthName(+dateFilterValue.month)}`, 20, 10)
    } else if (selectedView === "2") {
        doc.text(`Year: ${dateFilterValue.year}`, 20, 10)
    } else if (selectedView === "3") {
        doc.text(`Start Date: ${dateFilterValue.date.startDate}, End Date: ${dateFilterValue.date.endDate}`, 20, 10)
    }

    const logoWidth = 20;
    const logoHeight = 10;
    const logoX = 250;
    const logoY = 5;
    doc.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);
  
    for (let i = 0; i < elements.length; i++) {
        const el = elements.item(i) as HTMLElement;
        const imgData = await toPng(el);
    
        let elHeight = el.offsetHeight;
        let elWidth = el.offsetWidth;
    
        const pageWidth = doc.internal.pageSize.getWidth() - 10;
        if (elWidth > pageWidth) {
            const ratio = pageWidth / elWidth;
            elHeight = elHeight * ratio - padding;
            elWidth = elWidth * ratio - padding;
        }
    
        const pageHeight = doc.internal.pageSize.getHeight();
        if (top + elHeight > pageHeight) {
            doc.addPage();
            top = 25;
        }

        const anotations = pdfAnotations[i].anotations
        let x = 20
        let y = 20
        doc.setFontSize(13);
        for (const anotation of anotations) {
            doc.setTextColor(anotation.color)
            doc.text(anotation.text, x, y)
            x += 80
        }
        doc.addImage(imgData, "PNG", padding, top, elWidth, elHeight, `image${i}`);
        top += elHeight + 10;
    }
}