import React from "react";
import { Button } from "react-bootstrap";
import ImageUploading, { ImageListType } from "react-images-uploading";

type Props = {
    images: ImageListType
    onChange: (imageList: ImageListType, addUpdateIndex: number[] | undefined) => void
}

const ImageUpload = (props: Props) => {
    const {images, onChange} = props

    return (  
        <ImageUploading
            value={images}
            onChange={onChange}
        >
        {({
          imageList,
          onImageUpload,
          onImageRemove,
          dragProps
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <Button variant="outline-secondary"
              onClick={onImageUpload}
              {...dragProps}
            >
              Upload Image
            </Button>
            &nbsp;
            {imageList.map((image, index) => (
              <div key={index} className="image-item">
                <img src={image.dataURL} alt="" width="200" className="my-2" />
                <div className="image-item__btn-wrapper">
                  <Button variant="outline-danger"
                    onClick={() => onImageRemove(index)}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
        </ImageUploading>
    );
}
 
export default ImageUpload;