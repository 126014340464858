import { Box, Button, Tooltip } from "@mui/material";

type Props = {
    badge_text: string
    tooltip_text: string
    color: string
}

const BadgeTooltip = (props: Props) => {
    const {badge_text, tooltip_text, color} = props
    return (  
        <Tooltip title={tooltip_text} placement="top-end">
            <Box sx={{
                padding: 1,
                border: 1,
                borderRadius: 1,
                borderColor: color,
                color: color,
                fontWeight: "bold"
            }}>
                {badge_text}
            </Box>
        </Tooltip>
    );
}
 
export default BadgeTooltip;