import { Box, Typography } from "@mui/material";
import { ChangeEvent, FunctionComponent } from "react";
import { Form } from "react-bootstrap";
import { LocationsData } from "../../../types/LocationsType";
import { MachinesData } from "../../../types/MachinesType";
import { OrganizationsData } from "../../../types/OrganizationsType";
import { TenantsData } from "../../../types/TenantsType";
import { DropdownData } from "../../../types/global/DropDownType";
import { getMonthChoices, getYearChoices } from "../../../utils/DateTimeUtil";
import { loadLocationList, loadMachineList, loadOrganizationList } from "../../../utils/LoadDataUtil";
import { isAdminUser, isTenantUser } from "../../../utils/UserUtil";
import { FilterValue } from "./ReportDetails";

interface ReportDetailsFiltersProps {
    filterValues: FilterValue,
    currentView: string
    organizations: OrganizationsData[],
    locations: LocationsData[],
    tenants: TenantsData[],
    machines: MachinesData[],
    handleChangeFilterValue: (event: ChangeEvent<any>) => void
}
 
const ReportDetailsFilters: FunctionComponent<ReportDetailsFiltersProps> = ({
    filterValues,
    currentView,
    organizations,
    locations,
    machines,
    tenants,
    handleChangeFilterValue
}) => {
    const orgList = loadOrganizationList(organizations)
    const machineList = loadMachineList(machines)
    const locationList = loadLocationList(locations)

    return (  
        <Box>
            <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Organization</Form.Label>
                <Form.Select 
                    value={filterValues.organizationId}
                    onChange={handleChangeFilterValue}
                    name="organizationId"
                    disabled={isAdminUser() || isTenantUser()}
                >
                    {
                        (orgList && orgList.length > 0) ?
                        orgList.map((org: DropdownData) => <option value={org.value} key={org.value}>{org.label}</option>) :
                        <option value={-1}>No Organizations</option>
                    }
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Location</Form.Label>
                <Form.Select 
                    value={filterValues.locationId}
                    onChange={handleChangeFilterValue}
                    name="locationId"
                >
                    <option value={0} key={0}>Select Location</option>
                    {
                        (locationList && locationList.length > 0) &&
                        locationList.map((location: DropdownData) => <option value={location.value} key={location.value}>{location.label}</option>)
                    }
                </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Machine</Form.Label>
                <Form.Select 
                    value={filterValues.machineId}
                    onChange={handleChangeFilterValue}
                    name="machineId"
                >
                    <option value={0} key={0}>Select Machine</option> 
                    {
                        (machineList && machineList.length > 0) &&
                        machineList.map((machine: DropdownData) => {
                            return (
                                <option value={machine.value} key={machine.value}>
                                    {machine.label}
                                </option>
                            )
                        })
                    }
                </Form.Select>
            </Form.Group>
            <Box sx={{
                display: "flex",
                gap: 3,
                marginBottom: 2
            }}>
                <Form.Group>
                    <Form.Label className="fw-bold">Year</Form.Label>
                    <Form.Select
                        onChange={handleChangeFilterValue}
                        value={filterValues.year}
                        name="year"
                    >
                        {
                            [
                                <option value={0} key={0}>Select Year</option>, 
                                ...getYearChoices().map((year) => <option value={year.value} key={year.value}>{year.label}</option>)
                            ]
                        }
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="fw-bold">Month</Form.Label>
                    <Form.Select
                        onChange={handleChangeFilterValue}
                        value={filterValues.month}
                        name="month"
                    >
                        {
                            [<option value={0} key={0}>Select Month</option>, ...getMonthChoices().map((month) => <option value={month.value} key={month.value}>{month.label}</option>)]
                        }
                    </Form.Select>
                </Form.Group>
                <Form.Group>
                    <Form.Label className="fw-bold">Order</Form.Label>
                    <Form.Select
                        onChange={handleChangeFilterValue}
                        value={filterValues.order}
                        name="order"
                        disabled={currentView !== "daily"}
                    >
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                        <option value="date">Order By Date</option>
                    </Form.Select>
                </Form.Group>
            </Box>
            <Box sx={{marginBottom: 2}}>
                <Typography sx={{fontWeight: "bold", marginBottom: 1}}>Tenants Preview</Typography>
                <Box sx={{
                    backgroundColor: "white",
                    height: "200px",
                    overflowY: "scroll",
                    borderRadius: 2,
                    padding: 3,
                }}>
                    <ol>
                        {
                            (tenants && tenants.length > 0) &&
                            tenants.map(tenant => <li key={tenant.id}>{tenant.tenantName}</li>)
                        }
                    </ol>
                </Box>
            </Box>
        </Box>
    );
}
 
export default ReportDetailsFilters;