import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FilterContainer } from "../Shared/Common/Containers";
import { Box, Typography } from "@mui/material";
import CalendarModal from "../Shared/CalendarModal/CalendarModal";
import { useState } from "react";

const OperatorLogFilterContainer = ({
    dateFilterValue,
    onChangeDateFilter, 
    filterValue,
    onChangeFilter
}: any) => {
    const [showStartDateModal, setShowStartDateModal] = useState(false)
    const [showEndDateModal, setShowEndDateModal] = useState(false)

    const handleClickStartDate = () => {
        setShowStartDateModal(!showStartDateModal)
    }

    const handleClickEndDate = () => {
        setShowEndDateModal(!showEndDateModal)
    }

    const handleCloseDateModal = () => {
        setShowStartDateModal(false)
        setShowEndDateModal(false)
    }
    return (  
        <FilterContainer>
            <Form.Group>
            <Row className="mb-3">
                    <Col xs={6} md={4} lg={3}>
                        <Box 
                            sx={(theme) => ({
                                display: "flex", 
                                gap: 1, 
                                alignItems: "center",
                                [theme.breakpoints.down('sm')]: {
                                    flexDirection: "column",
                                    justifyContent: "center",
                                },
                            })}
                        >
                            <Typography>Start Date</Typography>
                            <button type="button" className="btn btn-outline-dark" onClick={handleClickStartDate}>{dateFilterValue?.startDate || "Start Date"}</button>
                            <CalendarModal 
                                show={showStartDateModal}
                                onCloseModal={handleClickStartDate}
                                onChange={(value: Date) => {
                                    onChangeDateFilter("startDate", value)
                                    handleCloseDateModal()
                                }}
                                maxDate={new Date(dateFilterValue.endDate)}
                                defaultValue={new Date(dateFilterValue.startDate)}
                            />
                        </Box>
                    </Col>
                    <Col xs={6} md={4} lg={3}>
                        <Box 
                            sx={(theme) => ({
                                display: "flex", 
                                gap: 1, 
                                alignItems: "center",
                                [theme.breakpoints.down('sm')]: {
                                    flexDirection: "column",
                                    justifyContent: "center",
                                },
                            })}
                        > 
                            <Typography>End Date</Typography>
                            <button type="button" className="btn btn-outline-dark" onClick={handleClickEndDate}>{dateFilterValue?.endDate || "End Date"}</button>
                            <CalendarModal 
                                show={showEndDateModal}
                                onCloseModal={handleClickEndDate}
                                onChange={(value: Date) => {
                                    onChangeDateFilter("endDate", value)
                                    handleCloseDateModal()
                                }}
                                maxDate={new Date()}
                                minDate={new Date(dateFilterValue.startDate)}
                                defaultValue={new Date(dateFilterValue.endDate)}
                            />
                        </Box>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <InputGroup>
                            <InputGroup.Text id="inputGroup-sizing-lg">Search</InputGroup.Text>
                            <Form.Control
                                aria-label="Large"
                                aria-describedby="inputGroup-sizing-sm"
                                value={filterValue.search}
                                onChange={onChangeFilter}
                                name="search"
                                placeholder="Operator Log"
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </Form.Group>
        </FilterContainer>
    );
}
 
export default OperatorLogFilterContainer;