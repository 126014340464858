export interface InternalTelegramConfigData {
    botToken: string;
    channelId: string;
    createdBy?: string;
    updatedBy?: string;
    deletedBy?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}

export interface InternalTelegramConfigReqeustBody {
    botToken?: string;
    channelId?: string;
    doneBy?: string;
}

export const DefaultInternalTelegramConfig = {
    botToken: "",
    channelId: ""
}