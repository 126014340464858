import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../styling/customStyle.css';
import Layout from "../../Layout/Layout";
import { STATUS_CODES } from "../../../constants/GlobalConstants";
import CardInTenantModal from './TenantDetailsModals/CardInTenantModal'
import UserInTenantModal from './TenantDetailsModals/UserInTenantModal'
import DeleteModal from './TenantDetailsModals/DeleteModal'
import { tenantDetailsGetDataApi, tenantGetByIdApi } from "../../../services/RouteServices/TenantsApi";
import { 
    cardInTenantAddDataApi,
    cardInTenantDeleteDataApi,
    cardInTenantUpdateDataApi,  
} from "../../../services/RouteServices/CardInTenantApi";
import {
    userInTenantAddDataApi,
    userInTenantDeleteDataApi,
    userInTenantUpdateDataApi,
} from "../../../services/RouteServices/UserInTenantApi";
import { 
    CardInTenantData, 
    CardDefault, 
    CardInTenantResponseBody,
    CardInTenantDELETERequestBody,
 } from '../../../types/CardInTenantType'
import {
    UserInTenantData,
    UserDefault, 
    UserInTenantDELETERequestBody,
    UserInTenantResponseBody,
} from '../../../types/UserInTenantType'
import { 
    TenantDetailsListResponseBody,
    TenantDetailsGetRequestBody,
} from '../../../types/TenantDetailsType'
import { MachinesData, } from "../../../types/MachinesType";
import { UserData } from '../../../types/UsersType'
import { OrgDefault, OrganizationsData } from "../../../types/OrganizationsType";
import CardWithTable from './TenantDetailsCards/CardWithTable'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { CardInMachineData } from "../../../types/CardInMachineType";
import { cardInMachineGetByMachineId } from "../../../services/RouteServices/CardInMachineApi";
import { userGetAllByOrgIdAndTenantIdApi } from "../../../services/RouteServices/UsersApi";
import UpdateModal from "./TenantDetailsModals/UpdateModal";
import Alert from "@mui/material/Alert";
import { organizationGetByIdApi } from "../../../services/RouteServices/OrganizationsApi";
import { machineGetAllByOrgIdDataApi } from "../../../services/RouteServices/MachinesApi";
import { CardContainer, Container } from "../../Shared/Common/Containers";
import { DetailTitle } from "../../Shared/Common/Titles";
import { UserInfo } from "../../../types/UserAuth";
import { getCurrentLocalUser } from "../../../utils/UserUtil";

const TenantDetails: React.FC = () => {
    const userInfo: UserInfo = getCurrentLocalUser();

    const navigate = useNavigate();
    const location = useLocation();
    const tenantId = location.state?.tenantId || 0;
    const tenantName = location.state?.tenantName || "";
    const tenantOrgId = location.state?.orgId || 0;
    const CardDefaultWithTId = {
        tenantId: tenantId,
        tenantName: tenantName,
        ...CardDefault
    }
    const UserDefaultWithTId = {
        tenantId: tenantId,
        tenantName: tenantName,
        ...UserDefault
    }

    const [showCardAssignModal, setShowCardAssignModal] = useState<boolean>(false)
    const [showUserAssignModal, setShowUserAssignModal] = useState<boolean>(false)
    const [cardList, setCardList] = useState<CardInTenantData[]>([]); // cards in tenant
    const [cardData, setCardData] = useState<CardInTenantData>(CardDefaultWithTId);
    const [userList, setUserList] = useState<UserInTenantData[]>([]);
    const [userData, setUserData] = useState<UserInTenantData>(UserDefaultWithTId);
    const [orgData, setOrgData] = useState<OrganizationsData>(OrgDefault);
    const [remark, setRemark] = useState<string>("");
    const [modalSuccessMsg, setModalSuccessMsg] = useState<string>("");
    const [modalErrorMsg, setModalErrorMsg] = useState<string>("");
    const [errorText, setErrorText] = useState<string>("");
    const [cardTotalCount, setCardTotalCount] = useState<number>(0);
    const [userTotalCount, setUserTotalCount] = useState<number>(0);
    const [showCardDeleteModal, setShowCardDeleteModal] = useState<boolean>(false);
    const [showUserDeleteModal, setShowUserDeleteModal] = useState<boolean>(false);
    const [showCardUpdateModal, setShowCardUpdateModal] = useState<boolean>(false);
    const [showUserUpdateModal, setShowUserUpdateModal] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [machineList, setMachineList] = useState<MachinesData[]>([]);
    const [userListByOrgId, setUserListByOrgId] = useState<UserData[]>([]);
    const [validCardList, setValidCardList] = useState<CardInMachineData[]>([]); // cards in machine
    // const [disableAction, setDiableAction] = useState<boolean>(false);

    const CardInTenantColumns = ["ID", "Tenant", "Machine", "Card", "Remark", "Action"]
    const UserInTenantColumns = ["ID", "Tenant", "User Id", "User", "Remark", "Action"]

    useEffect(() => {
        organizationGetByIdApi(tenantOrgId)
        .then((res) => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setOrgData(res?.data?.data)
                // getUserList()
                getMachineList()
            }
            else      
                setErrorText(res?.data?.message)
        })
        getTenantDetailsApiHandler()
    }, [])

    useEffect(() => {
        if(userData.userId && userData.userId !== 0)
            setModalErrorMsg("")
    }, [userData])

    useEffect(() => {
        if (cardData.machineId && cardData.machineId !== 0
            && cardData.cardId && cardData.cardId !== 0
        )
            setModalErrorMsg("")
    }, [cardData])

    useEffect(() => {
        setValidCardList([])
        if (cardData.machineId !== 0 && ''+cardData.machineId !== '0') {
            cardInMachineGetByMachineId(cardData.machineId)
            .then(res => {
                if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                    setValidCardList(res?.data?.data?.cardList)
                else {
                    console.log("error here")
                    setErrorText(res?.data?.message)
                }
            })
            .catch(error => {
                setErrorText(error?.response?.data?.message || error?.message)
            })
        } else {
            console.log("didn't call api")
        }
        
    }, [cardData.machineId])

    const openAssignModal = (modalName: string) => {
        setModalErrorMsg("")
        setModalSuccessMsg("")
        if (modalName === "Card") {
            setShowCardAssignModal(true)
        }
        else if (modalName === "User") {
            getUserList()
            setUserData(
                (prevUserData) => {
                    return {
                        ...prevUserData,
                        organizationId: tenantOrgId,
                    }
                }
            )
            setShowUserAssignModal(true)
        }
    }

    const openDeleteCardModal = (cardObj: CardInTenantData) => {
        setShowCardDeleteModal(true);
        setCardData(cardObj);
        setModalErrorMsg("");
        setModalSuccessMsg("");
    }

    const openDeleteUserModal = (userObj: UserInTenantData) => {
        setShowUserDeleteModal(true);
        setUserData(userObj);
        setModalErrorMsg("");
        setModalSuccessMsg("");
    }

    const openUpdateCardModal = (cardObj: CardInTenantData) => {
        setModalErrorMsg("");
        setModalSuccessMsg("");
        setShowCardUpdateModal(true)
        setCardData(cardObj)
        setRemark(cardObj.remark || "")
    }

    const openUpdateUserModal = (userObj: UserInTenantData) => {
        setModalErrorMsg("");
        setModalSuccessMsg("");
        setUserData(userObj)
        setRemark(userObj.remark || "")
        setShowUserUpdateModal(true)
    }

    const handleCloseModal = () => {
        setModalErrorMsg("")
        setModalSuccessMsg("")
        setShowCardAssignModal(false)
        setShowCardDeleteModal(false)
        setShowUserDeleteModal(false)
        setShowUserAssignModal(false)
        setShowUserUpdateModal(false)
        setShowCardUpdateModal(false)
        setUserData(UserDefaultWithTId)
        setCardData(CardDefaultWithTId)
    }

    const handleChangeCardData = (event: ChangeEvent<any>) => { 
        if (event.target.name === "cardId") {
            const selectedOption = event.target.options[event.target.selectedIndex];
            const cardInMachineId = selectedOption.getAttribute("data-cardinmachineid");
            setCardData(
                (prevTenantData) => {
                    return  {
                        ...prevTenantData,
                        cardId: +event.target.value,
                        cardInMachineId: cardInMachineId,
                    }
                }
            )
        } else {
            setCardData(
                (prevTenantData) => {
                    return  {
                        ...prevTenantData,
                        [event.target.name]: event.target.value,
                    }
                }
            )
        }
        if (event.target.name === "machineId") {
            setCardData(
                (prevCardData) => {
                    return  {
                        ...prevCardData,
                        cardId: 0,
                    }
                }
            )
        }
    }

    const handleChangeRemark = (event: ChangeEvent<HTMLInputElement>) => {
        setRemark(event.target.value)
    }

    const handleChangeUserData = (event: ChangeEvent<HTMLInputElement>) => { 
        setUserData(
            (prevTenantData) => {
                return  {
                    ...prevTenantData,
                    [event.target.name]: event.target.value,
                }
            }
        )
    }

    const handleAddCardData = async () => {
        setIsProcessing(true)
        const inputObj = {
            tenantId: cardData.tenantId,
            machineId: cardData.machineId,
            cardId: cardData.cardId,
            cardInMachineId: cardData.cardInMachineId,
            remark: cardData.remark || "",
            createdBy: userInfo.userName
        }
        await cardInTenantAddDataApi(inputObj)
        .then((res) => {
            showAlert(res?.data)
        }).catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            setIsProcessing(false)
            console.log(error)
        })
    }

    const handleAddUserData = async () => {
        setIsProcessing(true)
        const inputObj = {
            tenantId: userData.tenantId,
            userId: userData.userId,
            remark: userData.remark || "",
            createdBy: userInfo.userName
        }
        await userInTenantAddDataApi(inputObj)
        .then((res) => {
            showAlert(res?.data)
        }).catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            setIsProcessing(false)
            console.log(error)
        })
    }

    const handleUpdateCard = async () => {
        setIsProcessing(true)
        const inputObj = {
            id: cardData.id,
            remark: remark || "",
            updatedBy: userInfo.userName
        }
        await cardInTenantUpdateDataApi(inputObj)
        .then((res) => {
            showAlert(res?.data)
        })
        .catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            setIsProcessing(false)
            console.log(error)
        })
    }

    const handleUpdateUser = async () => {
        setIsProcessing(true)
        const inputObj = {
            id: userData.id,
            remark: remark || "",
            updatedBy: userInfo.userName
        }
        await userInTenantUpdateDataApi(inputObj)
        .then((res) => {
            showAlert(res?.data)
        })
        .catch(error => {
            setIsProcessing(false)
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }

    const handleDeleteCard = async () => {
        const inputObj: CardInTenantDELETERequestBody = {
            "id": cardData.id || 0,
            "deletedBy": userInfo.userName
        } 
        await cardInTenantDeleteDataApi(inputObj)
        .then(res => {
            const resData: CardInTenantResponseBody = res?.data
            showAlert(resData)
        })
        .catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }

    const handleDeleteUser = async () => {
        const inputObj: UserInTenantDELETERequestBody = {
            "id": userData.id || 0,
            "deletedBy": userInfo.userName
        } 
        await userInTenantDeleteDataApi(inputObj)
        .then(res => {
            const resData: UserInTenantResponseBody = res?.data
            showAlert(resData)
        })
        .catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }

    const getUserList = (): void => {
        userGetAllByOrgIdAndTenantIdApi(tenantOrgId, tenantId)
        .then((res) => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setUserListByOrgId(res?.data?.data?.userList)
            } else {
                setErrorText(res?.data?.message)
            }   
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }

    const getMachineList = (): void => {
        machineGetAllByOrgIdDataApi(tenantOrgId)
        .then((res) => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setMachineList(res?.data?.data?.machineList)
            } else {
                setErrorText(res?.data?.message) 
            }
        }).catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }

    const getTenantDetailsApiHandler = async () => {
        const dataLimitsObject: TenantDetailsGetRequestBody = {
            tenantId: tenantId,
        }

        await tenantDetailsGetDataApi(dataLimitsObject)
        .then(response => {
            const resData: TenantDetailsListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const cardList: CardInTenantData[] = resData.data?.cardList;
                const cardCount : number = resData?.data?.cardCount || 0; 
                const userList: UserInTenantData[] = resData.data?.userList;
                const userCount: number = resData?.data?.userCount || 0;  
                setCardList(cardList)
                setCardTotalCount(cardCount)
                setUserList(userList)
                setUserTotalCount(userCount)
            }
            else {
                console.log(errorText)
            }
        })
        .catch(error => {
            setErrorText(error?.response?.data?.message || error?.message)
        })
    }   

    const showAlert = (resData: CardInTenantResponseBody | UserInTenantResponseBody) => {
        setIsProcessing(false)
        if (resData?.status === STATUS_CODES.SUCCESS_CODE){
            setModalErrorMsg("")
            setModalSuccessMsg(resData?.message)
            setModalSuccessMsg(resData?.message);
            setCardData(CardDefaultWithTId);
            setUserData(UserDefaultWithTId)
            setShowCardAssignModal(false);
            setShowUserAssignModal(false)
            setShowCardDeleteModal(false)
            setShowUserDeleteModal(false)
            setShowCardUpdateModal(false)
            setShowUserUpdateModal(false)
            getTenantDetailsApiHandler();   
        }
        else{
            setModalErrorMsg(resData?.message)
        }
    }
    
    return ( 
        <Layout> 
                <Container>
                <ArrowBackIcon 
                        onClick={() => navigate(-1)}
                        className="pointer"
                />  
                {
                    errorText && <Alert severity="error">{errorText}</Alert>
                }              
                    <DetailTitle>Tenant ID: {tenantId} | Tenant Name: {tenantName}</DetailTitle>
                    <CardInTenantModal 
                        show={showCardAssignModal}
                        title="Card In Tenant"
                        onChangeData={handleChangeCardData}
                        btnName="Assign"
                        onForm={handleAddCardData}
                        onClose={handleCloseModal}
                        data={cardData}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        btnColor="black"
                        machineList={machineList}
                        cardList={validCardList || []}  
                        isProcessing={isProcessing}               
                    />
                    <UserInTenantModal 
                        show={showUserAssignModal}
                        title="User In Tenant"
                        onChangeData={handleChangeUserData}
                        btnName="Assign"
                        onForm={handleAddUserData}
                        onClose={handleCloseModal}
                        data={userData}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        btnColor="black"
                        org={orgData}
                        userList={userListByOrgId}
                        isProcessing={isProcessing} 
                    />
                    <DeleteModal 
                        show={showCardDeleteModal}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        currentData={cardData}
                        handleClose={handleCloseModal}
                        handleConfirmDelete={handleDeleteCard}
                    />
                    <DeleteModal 
                        show={showUserDeleteModal}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        currentData={userData}
                        handleClose={handleCloseModal}
                        handleConfirmDelete={handleDeleteUser}
                    />
                    <UpdateModal 
                        onChange={handleChangeRemark}
                        show={showCardUpdateModal}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        remark={remark}
                        handleClose={handleCloseModal}
                        handleUpdate={handleUpdateCard}
                        isProcessing={isProcessing}
                    />
                    <UpdateModal 
                        onChange={handleChangeRemark}
                        show={showUserUpdateModal}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        remark={remark}
                        handleClose={handleCloseModal}
                        handleUpdate={handleUpdateUser}
                        isProcessing={isProcessing}
                    />
                    <CardContainer className="card-container">
                        <CardWithTable 
                            title="Assign Card to Tenant"
                            type="Card"
                            totalCount={cardTotalCount}
                            dataList={cardList}
                            columnList={CardInTenantColumns}
                            openDeleteModal={openDeleteCardModal}
                            openAssignModal={openAssignModal}
                            openUpdateModal={openUpdateCardModal}
                        />
                        <CardWithTable 
                            title="Assign User to Tenant"
                            type="User"
                            totalCount={userTotalCount}
                            dataList={userList}
                            columnList={UserInTenantColumns}
                            openDeleteModal={openDeleteUserModal}
                            openAssignModal={openAssignModal}
                            openUpdateModal={openUpdateUserModal}
                        />
                    </CardContainer>
                </Container> 
        </Layout>
    );
}
 
export default TenantDetails;