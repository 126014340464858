import { Button, Modal } from "react-bootstrap";
import { LocationsData } from "../../../types/LocationsType";

type Props = {
    targetLocation: LocationsData
    show: boolean,
    toggleShow: () => void
    onDelete: () => void
}

const RemoveImageModal = (props: Props) => {
    const {targetLocation, show, toggleShow, onDelete} = props
    return (  
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Remove Image</Modal.Title>
            </Modal.Header>
            {/* {errorMsg && <Alert severity="error">{errorMsg}<br/></Alert>} */}
            <Modal.Body>
                <p>Are you sure you want to remove the image of <b>[{targetLocation.locationName}]</b>? </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {toggleShow();}}>Cancel</Button>
                <Button variant="danger" onClick={
                        () => {
                            onDelete(); 
                            toggleShow();
                }}>Delete</Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default RemoveImageModal;