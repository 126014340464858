import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useState } from 'react';
import { Button, Modal } from "react-bootstrap";


const DeleteIconButton = ({
    deleteName,
    deleteObject,
    onOpen,
    onDelete
}: any) => {
    const [showModal, setShowModal] =  useState(false)

    const handleShowModal = () => {
        setShowModal(!showModal)
    }

    return ( 
        <>
            <DeleteOutlinedIcon 
                sx={{
                    color: "red",
                    marginRight: 1
                }}
                className="pointer"
                onClick={() => {handleShowModal();  onOpen()}}
            />
            <Modal show={showModal}>
                <Modal.Header>
                    <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                {/* {errorMsg && <Alert severity="error">{errorMsg}<br/></Alert>} */}
                <Modal.Body>
                    <p>Are you sure you want to delete <strong>[{deleteName}]</strong> {deleteObject}?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {handleShowModal();}}>Cancel</Button>
                    <Button variant="danger" onClick={
                            () => {
                                onDelete(); 
                                handleShowModal();
                    }}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
 
export default DeleteIconButton;