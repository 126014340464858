import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dfdede;
  padding: 0px;
  margin: 0px;
  height: 100vh;
  position: relative;
`;

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  background: #FFFFFF;
  border-radius: 22px;
  padding: 50px;
`;

export const Image = styled.img`
  height: auto;
  width: 40%;
  padding-bottom: 15px;
  border-radius: 5px;
  @media screen and (max-width: 768px) {
    width: 40%;
  }
  @media screen and (max-width: 425px) {
    width:  50%;
  }
`;

export const BrandTitle = styled.h6`
  padding-bottom: 50px;
`

export const Title = styled.h3`
  color: rgb(7, 7, 7);
  margin: 0px;
`;

export const TextTitle = styled.h6`
  color: rgb(7,7,7);
  margin-bottom: 5px;
  margin-top: 30px;
`;

export const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  text-align: left;
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  flex-direction: colunm;
`;

export const Input = styled.input`
  position: relative;
  outline: none;
  border: none;
  width: 100%;
  padding: 17px;
  padding-left: 60px;
  color: black;
  border-radius: 10px;
  background: #E8E8E8;
`;

export const Icon = styled.div`
  position: absolute;
  z-index: 999;
  color: grey;
  left: 4%;
`;

export const Button = styled.button`
  width: 100%;
  height: 50px;
  outline: none;
  border: none;
  background-color: #0076FF;
  color: #FFFFFF;
  margin-top: 50px;
  border-radius: 7px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: 	#0096FF;
  }
`;

export const ErrorText = styled.small`
  color: red;
  font-size: 100%;
  font-weight: 500;
  padding-top: 5px;
  align-self: flex-start;
`;

export const SuccessText = styled.span`
  color: green;
  font-size: 100%;
`
