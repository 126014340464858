// use in door log page
export const CAPTURE_ADJUST_TIME_FOR_DOOR_LOG_IN_SECOND = -30 // (second)

// use in weight log page
export const CAPTURE_ADJUST_TIME_FOR_WEIGHT_LOG_IN_SECOND = -30 // second  /* if you want to backward, just use minus (-) */
export const CAPTURE_ADD_MINUTE_FOR_END_TIME = 2 // minute

// use in detection page
export const CAPTURE_ADJUST_TIME_WITH_DETECTION_IN_SECOND = 0;
export const CAPTURE_END_RANGE_FOR_DETECTION_PAGE_IN_SECOND = 60;

export const LFC_CORRECTION_LOG = "Date Correction Added"