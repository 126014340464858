import 'bootstrap/dist/css/bootstrap.min.css';
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import '../../styling/customStyle.css';
import Layout from "../Layout/Layout";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Button from "react-bootstrap/Button";
import { IoIosWarning } from "react-icons/io";
import { IoFastFood } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import XLSX from "xlsx";
import { LOG_LEVEL, STATUS_CODES } from "../../constants/GlobalConstants";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { useFilterOrganizations } from "../../custom-hooks/useFilterOrganizations";
import { locationGetByOrgIdApi } from "../../services/RouteServices/LocationsApi";
import {
    machineAddDataApi,
    machineDeleteDataApi,
    machineGetByOrgIdDataApi,
    machineGetByOrgIdExportDataApi,
    machineUpdateDataApi
} from "../../services/RouteServices/MachinesApi";
import { LocationsData } from "../../types/LocationsType";
import {
    MachineADDRequestBody,
    MachineDefault,
    MachineListResponseBody,
    MachineResponseBody,
    MachineUPDATERequestBody,
    MachinesData
} from "../../types/MachinesType";
import { UserAuthType } from "../../types/UserAuth";
import { FilterValue } from "../../types/global/FilterValueType";
import { convertToLocaleDate, getCurrentLocaleDate } from '../../utils/DateTimeUtil';
import { addEventLog } from "../../utils/EventLogUtil";
import { addNormalLog } from "../../utils/LoggerUtil";
import { getMachineTypeIcon, isFoodWasteMachine, isGeneralWasteMachine } from "../../utils/MachineUtil";
import { checkLocation, getAvailableLocations, getCurrentLocalUser, isAdminUser, isRootAndSuperUsers, isSpecialUser, isSuperSpecialUser } from "../../utils/UserUtil";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title } from "../Shared/Common/Titles";
import DeleteIconButton from "../Shared/DeleteIconButton/DeleteIconButton";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import ExportButton from "../Shared/Export/Export";
import MachineFilterContainer from "./MachineFilterContainer";
import MachineModal from './MachineModal';
import { generatePDF } from "./generatePdf";
import useFilterLocations from '../../custom-hooks/useFilterLocations';

interface MachineFilterValues extends FilterValue {
    machineType: String,
}

const loadMachineExportList = (machines: MachinesData[]) => {
    if (!machines) return []
    return machines.map(machine => {
        let result: {[key: string]: any} = {
            "Id": machine.id,
            "Organization": machine.organizationName || "-",
            "Location": machine.locationName || "-", 
            "Machine": machine.machineName || "-",
            "Remark": machine.remark || "-",
        }

        if ((isSuperSpecialUser() || isAdminUser()))
            result["Machine Serial Number"] = machine.lfcSerialNumber

        return result
    })
}

const Machines: React.FC = () => {
    const tableLimit = 10;
    const userInfo = getCurrentLocalUser()
    const availableLocations = getAvailableLocations()
    const DefaultFilterValue: MachineFilterValues = {
        organizationId: isSuperSpecialUser()? 0 : userInfo.orgId,
        locationId: 0,
        machineType: "all",
    }

    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const [tablePage, setTablePage] = React.useState(1);
    const [tableOffset, setTableOffset] = React.useState(0);
    const [machineTotalCount, setMachineTotalCount] = React.useState(0);
    
    const [machinesList, setMachinesList] = useState<MachinesData[]>([]); // * Original data object from db

    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [machineData, setMachineData] = useState<MachinesData>(MachineDefault);
    const [locationList, setLocationList] = useState<LocationsData[]>([]);
    const [modalSuccessMsg, setModalSuccessMsg] = useState<string>("");
    const [modalErrorMsg, setModalErrorMsg] = useState<string>("");
    const [filterValue, setFilterValue] = useState<MachineFilterValues>(DefaultFilterValue);

    const {organizations} = useFilterOrganizations()
    const {locations} = useFilterLocations(filterValue.organizationId!, [filterValue.organizationId])

    const pageCount = (Math.ceil(machineTotalCount/tableLimit)) || 0

    const navigate = useNavigate()

    useEffect(() => {
        setTablePage(1)
        setTableOffset(0)
    }, [filterValue])

    useEffect(() => {
        if (hasPermission("viewLfcMachine")) {
            getManageMachinesApiHandler(filterValue.organizationId!,tableLimit, tableOffset);
        }
    }, [filterValue, tableOffset])

    useEffect(() => {
        if (machineData.machineName !== "" && machineData.remark !== "" && 
            machineData.organizationId !== 0 && machineData.locationId !== 0 &&
            machineData.organizationId && machineData.locationId) {
            setModalErrorMsg("");
        }
    }, [machineData])

    useEffect(() => {
        if (machineData.organizationId !== 0 && ''+machineData.organizationId !== '0') {
            locationGetByOrgIdApi(machineData.organizationId)
            .then((res) => {
                    if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                        setLocationList(res?.data?.data?.locationList)
                    else 
                        setErrorText(res?.data?.message)     
            })
            .catch(error => {
                setErrorText(error?.response?.data?.message || error?.message)
            })
        }
    }, [machineData.organizationId])

    // Input Elements
    const handleChangeMachineData = (event: ChangeEvent<HTMLInputElement>) => { 
        setMachineData(
            (prevMachineData) => {
                return  {
                    ...prevMachineData,
                    [event.target.name]: event.target.value,
                }
            }
        )
        if (event.target.name === "organizationId") {
            setMachineData(
                (prevMachineData) => {
                    return  {
                        ...prevMachineData,
                        locationId: 0,
                    }
                }
            )
        }
    }

    const handleChangeSyncExternalNfc = (event: ChangeEvent<HTMLInputElement>) => {
        setMachineData(
            (prevMachineData) => {
                return  {
                    ...prevMachineData,
                    [event.target.name]: event.target.checked,
                }
            }
        )
    }

    const handleClickDetails = (id: number, name: string, uuid: string) => {
        navigate('/machines/details', {
            state: {
                machineId: id,
                machineName: name,
                machineUUID: uuid
            }
        });
    }

    const showAlertAndRefresh = (resData: MachineResponseBody, action: string) => {
        if (resData?.status === STATUS_CODES.SUCCESS_CODE){
            setModalErrorMsg("")
            setErrorText("")
            setModalSuccessMsg(resData?.message)
            setSuccessText(resData?.message)
            setTimeout(() => {
                setSuccessText("")
            }, 2000)
            setShowAddModal(false);
            setShowEditModal(false);
            setMachineData(MachineDefault);
            getManageMachinesApiHandler(filterValue.organizationId!, tableLimit, tableOffset);   
        }
        else{
            if (['add', 'update'].includes(action)) {
                setErrorText("")
                setModalErrorMsg(resData?.message)
            } else {
                setModalErrorMsg("")
                setErrorText(resData?.message)
            }
        }
    }

    const handleClose = () => {
        setShowAddModal(false);
        setShowEditModal(false);
        setMachineData(MachineDefault);
    }

    const openAddMachineModal = () => {
        setModalErrorMsg("");
        setModalSuccessMsg("");
        setShowAddModal(true);
        setMachineData(
            (prevMachineData) => {
                return  {
                    ...prevMachineData,
                    organizationId: userInfo.orgId,
                }
            }
        )
    }
       
    const openEditMachineModal = (machineObj: MachinesData) => {
        setModalErrorMsg("");
        setModalSuccessMsg("");
        setShowEditModal(true);
        const orgIdList = organizations.map(org => org.id)
        setMachineData({
            ...machineObj,
            remark: machineObj.remark || "",
            organizationId: (orgIdList.includes(machineObj.organizationId))? machineObj.organizationId: 0,
        });
    }

    const handleAddMachine = async () => {
        const inputObj: MachineADDRequestBody = {
            organizationId: machineData.organizationId,
            locationId: machineData.locationId,
            machineName: machineData.machineName,
            machineType: machineData.machineType,
            lfcSerialNumber: machineData.lfcSerialNumber,
            isSyncExternalNfc: machineData.isSyncExternalNfc,
            detectWeight: machineData.detectWeight || 0,
            remark: machineData.remark,
            createdBy: userInfo.userName
        }
        await machineAddDataApi(inputObj)
        .then(res => {
            showAlertAndRefresh(res?.data, "add")
        })
        .catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }

    const handleEditMachine = async () => {
        const inputObj: MachineUPDATERequestBody = {
            id: machineData.id || 0,
            organizationId: machineData.organizationId,
            locationId: machineData.locationId,
            machineName: machineData.machineName,
            machineType: machineData.machineType,
            lfcSerialNumber: machineData.lfcSerialNumber,
            isSyncExternalNfc: machineData.isSyncExternalNfc,
            detectWeight: +machineData.detectWeight || 0,
            remark: machineData.remark || "",
            updatedBy: userInfo.userName
        }
        await machineUpdateDataApi(inputObj)
        .then(res => {
            showAlertAndRefresh(res?.data, "update")
        })
        .catch(error => {
            setModalSuccessMsg("")
            setModalErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }

    const handleDeleteMachine = (id: number) => {
        const inputData = {
            "id": id,
            "deletedBy": userInfo.userName
        }
        machineDeleteDataApi(inputData)
        .then(res => {
            showAlertAndRefresh(res?.data, "delete")
        })
        .catch(error => {
            setErrorText(error.message)
        }) 
    }

    const getManageMachinesApiHandler = async (orgId: number, pageLimit : number, pageOffset : number) => {
        const dataLimitsObject = {
            orgId: orgId,
            locationId: filterValue.locationId,
            availableLocationIds: availableLocations,
            checkLocation: checkLocation(),
            searchText: filterValue.search || "",
            machineType: filterValue.machineType,
            limit: pageLimit,
            offset: pageOffset
        }
        await machineGetByOrgIdDataApi(dataLimitsObject)
        .then(response => {
            const resData: MachineListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: MachinesData[] = resData.data?.machineList
                const totalCount : number = resData?.data?.totalCount;
                setMachineTotalCount(totalCount)
                setMachinesList(dataList);
                // for not showing empty page when delete last item
                if (totalCount !== 0 && dataList.length === 0) {
                    setTableOffset(prev => prev !== 0 ? prev - 10 : prev)
                    setTablePage(prev => prev !== 1 ? prev -1 : prev)
                }
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }
    
    const getExportMachineListDataByOrgId = async (): Promise<any> => {
        const dataLimitsObject = {
            orgId: filterValue.organizationId!,
            locationId: filterValue.locationId,
            availableLocationIds: availableLocations,
            checkLocation: checkLocation(),
            searchText: filterValue.search || "",
            machineType: filterValue.machineType,
        }

        return machineGetByOrgIdExportDataApi(dataLimitsObject)
        .then(res => {
            const resData: MachineListResponseBody = res?.data
            return new Promise((resolve, reject) => {
                if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                    resolve(resData?.data?.machineList)
                else 
                    reject([])
            })   
        }).catch(error => {
            Promise.reject([])
        })
    }

    const exportToCSV = async () => { 
        let exportedData = await getExportMachineListDataByOrgId()
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(loadMachineExportList(exportedData));
        XLSX.utils.book_append_sheet(workbook, worksheet, "Overall");
        XLSX.writeFile(workbook, `weighting-machines-${getCurrentLocaleDate()}.xlsx`);
        const log: string = `${userInfo.userName} has downloaded weighting-machines-${getCurrentLocaleDate()}.xlsx`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    };

    const exportToPdf = async () => {
        let exportedData = await getExportMachineListDataByOrgId()
        generatePDF(exportedData)
        const log: string = `${userInfo.userName} has downloaded weighting-machines-${getCurrentLocaleDate()}.pdf`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    }
    
    const handleChangeFilterValue = (event: ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = event.target
        let prevFilterValue = {...filterValue}
        if (name === "organizationId") prevFilterValue["locationId"] = 0
        setFilterValue({
            ...prevFilterValue,
            [name]: value
        })
    }

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };
    // * Render Table Data
    const renderTable = () => {
        let machines: MachinesData[] = machinesList || []
        return (
            machines?.length !== 0 ? machines?.map((machine: MachinesData) => { 
            return (
                <tr key={machine.id}>
                    <td>{machine.id}</td>
                    {/* <td>{machine.organizationId}</td> */}
                    <td>{machine.organizationName}</td>
                    {/* <td>{machine.locationId}</td> */}
                    <td>{machine.locationName}</td>
                    <td>
                        {machine.machineName || "-"}
                        {getMachineTypeIcon(machine.machineType)}
                    </td>
                    {(isSuperSpecialUser() || isAdminUser()) && <td>{machine.lfcSerialNumber || "-"}</td>}
                    <td>{(isRootAndSuperUsers()) && (machine.detectWeight || 0)}</td>
                    <td>{machine.remark || "-"}</td>
                    {(isRootAndSuperUsers()) && <td>{machine.createdBy || "-"}</td>}
                    {(isRootAndSuperUsers()) && <td>{machine.updatedBy || "-"}</td>}
                    {(isRootAndSuperUsers()) && <td>{convertToLocaleDate(machine.createdAt!) || "-"}</td>}
                    {(isRootAndSuperUsers()) && <td>{convertToLocaleDate(machine.updatedAt!) || "-"}</td>}
                    <td>
                        <EditOutlinedIcon 
                            sx={{
                                color: "blue",
                            }}
                            className="pointer"
                            onClick={() => openEditMachineModal(machine)}
                        />
                        &nbsp;&nbsp;
                        {
                            isRootAndSuperUsers() &&
                            <DeleteIconButton 
                                deleteName={machine.machineName}
                                deleteObject="Machine"
                                onOpen={() => console.log("click delete")}
                                onDelete={() => handleDeleteMachine(machine.id!)}
                            />
                        }
                    </td>
                    <td>
                        <button type="button" className="btn btn-outline-info text-dark"
                            onClick={() => handleClickDetails(machine.id!, machine.machineName, machine.machineUuid || "-")}
                        >
                            Detail <ArrowForwardIcon />
                        </button>
                    </td>
                </tr>
            )
        }):
            <tr>
                <td>No data found</td>
            </tr>
        )
    }

    if (!hasPermission("viewLfcMachine")) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }


    return (
        <>
            <Layout>
                <Container>
                    <Box className="d-flex justify-content-between mb-2">
                        <Title>Weighting Machines</Title>
                        <Box className="titleBox">
                            <Box style={{gap: 5, display: "flex", alignItems: "center"}}>
                                <ExportButton 
                                    exportToCSV={exportToCSV}
                                    exportToPdf={exportToPdf}
                                />
                                {
                                    isRootAndSuperUsers() &&
                                    <Button 
                                        className="pt-2 pb-2 ps-3 pe-3" 
                                        id="btnAddUser" variant="success" 
                                        onClick={openAddMachineModal}>
                                            Add
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Box>
                    {successText && <Alert severity="success" sx={{marginBottom: 2}}>{successText}<br/></Alert>}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}
                    <MachineFilterContainer 
                        onChangeFilter={handleChangeFilterValue}
                        filterValue={filterValue}
                        orgList={organizations}
                        locationList={locations}
                        userInfo={userInfo}
                    />
                    <MachineModal 
                        show={showAddModal}
                        title="Add Machine"
                        onChangeMachineData={handleChangeMachineData}
                        onChangeSyncExternalNfc={handleChangeSyncExternalNfc}
                        btnName="Add"
                        onMachineForm={handleAddMachine}
                        onClose={handleClose}
                        machineData={machineData}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        btnColor="success"
                        orgList={organizations}
                        locationList={locationList}
                    />

                    <MachineModal 
                        show={showEditModal}
                        title="Edit Machine"
                        onChangeMachineData={handleChangeMachineData}
                        onChangeSyncExternalNfc={handleChangeSyncExternalNfc}
                        btnName="Edit"
                        onMachineForm={handleEditMachine}
                        onClose={handleClose}
                        machineData={machineData}
                        successText={modalSuccessMsg}
                        errorText={modalErrorMsg}
                        btnColor="primary"
                        orgList={organizations}
                        locationList={locationList}
                    />
                    <Box className="mt-4 d-flex justify-content-between flex-wrap">
                        <Box className="d-flex  align-items-center gap-5">
                            <Box className="d-flex  align-items-center gap-2">
                                <RestoreFromTrashIcon className="text-success fs-3"/>
                                <p className="m-0">General Waste</p>
                            </Box>
                            <Box className="d-flex  align-items-center gap-2">
                                <IoFastFood className="text-primary fs-4"/>
                                <p className="m-0">Food Waste</p>
                            </Box>
                            <Box className="d-flex  align-items-center gap-2">
                                <IoIosWarning className="text-danger fs-4"/>
                                <p className="m-0">No machine type</p>
                            </Box>
                        </Box>
                        
                        <SummaryText>{machineTotalCount} Machines found!</SummaryText>
                    </Box>
                    <TableContainer>
                    <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    {/* <th>Organization Id</th> */}
                                    <th>Organization</th>
                                    {/* <th>Location Id</th> */}
                                    <th>Location</th>
                                    <th>Name</th>
                                    {(isSuperSpecialUser() || isAdminUser()) && <th>Serial Number</th>}
                                    {isRootAndSuperUsers() && <th>Detect Weight</th>}
                                    <th>Remark</th>
                                    {(isRootAndSuperUsers()) && <th>Created By</th>}
                                    {(isRootAndSuperUsers()) && <th>Updated By</th>}
                                    {(isRootAndSuperUsers()) && <th>Created At</th>}
                                    {(isRootAndSuperUsers()) && <th>Updated At</th>}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {renderTable()}
                            </ tbody>
                        </table>
                    </InnterTableContainer>
                    </TableContainer>
                    <PaginationContainer>
                        <Pagination count={pageCount} onChange={handleChangePage} page={tablePage} variant="outlined" shape="rounded" size="large" />
                    </PaginationContainer>
                </Container>
            </Layout>
        </>
    );
};

export default Machines;