import { AiClass, AiConfigRequestBody } from "../../types/AiConfigType";
import { EmailConfigData, LfcTokenConfigData, SmsConfigData, SyncBackgroundLog, SyncLfcData } from "../../types/EmailConfigType";
import { GoogleLoginAuthType } from "../../types/GoogleLoginAuthType";
import { InternalTelegramConfigReqeustBody } from "../../types/InternalTelegramConfigType";
import { LocationMapConfigType } from "../../types/LocationMapConfigType";
import { MaintenanceConfigReqeustBody } from "../../types/MaintenanceConfigType";
import { OperatorLogAddRequestBody } from "../../types/OperatorLogConfigType";
import { TelegramConfigReqeustBody, ToggleSendMessageRequestBody } from "../../types/TelegramConfigType";
import http from "../apiServices";

export const emailConfigAddDataApi = (inputData : EmailConfigData) => {
    return http.post("/config/email/add", inputData)
}

export const emailConfigGetFirstRowApi = () => {
    return http.post("/config/email/get/first/row", {})
}

export const smsConfigAddDataApi = (inputData : SmsConfigData) => {
    return http.post("/config/sms/add", inputData)
}

export const smsConfigGetFirstRowApi = () => {
    return http.post("/config/sms/get/first/row", {})
}

export const lfcTokenConfigAddDataApi = (inputData : LfcTokenConfigData) => {
    return http.post("/config/lfc-token/add", inputData)
}

export const lfcTokenConfigGetFirstRowApi = () => {
    return http.post("/config/lfc-token/get/first/row", {})
}

export const syncLfcDataAddApi = (inputData : SyncLfcData) => {
    return http.post("/config/syncLfcData/add", inputData)
}

export const syncLfcDataGetFirstRowApi = () => {
    return http.post("/config/syncLfcData/get/first/row", {})
}

export const syncBackgroundLogAddApi = (inputData : SyncBackgroundLog) => {
    return http.post("/config/syncBackgroundLog/add", inputData)
}

export const syncBackgroundLogGetFirstRowApi = () => {
    return http.post("/config/syncBackgroundLog/get/first/row", {})
}

export const aiClassGetApi = () => {
    return http.post("/config/ai-classes/get")
}

export const aiClassUpdateApi = (input: {aiClasses: AiClass[]}) => {
    return http.post("/config/ai-classes/update", input)
}

export const aiConfigUpdateApi = (input: AiConfigRequestBody) => {
    return http.post("/config/ai-config/update", input)
}

export const aiConfigGetFirstRowApi = () => {
    return http.post("/config/ai-config/get/first/row")
}

export const telegramConfigGetByOrgIdApi = (input: TelegramConfigReqeustBody) => {
    return http.post("/config/telegram/get/by/orgId", input)
}

export const telegramConfigAddApi = (input: TelegramConfigReqeustBody) => {
    return http.post("/config/telegram/add", input)
}

export const toggleSendMessageTelegramConfigApi = (input: ToggleSendMessageRequestBody) => {
    return http.post("/config/telegram/toggle-send-message", input)
}

export const internalTelegramConfigAddApi = (input: InternalTelegramConfigReqeustBody) => {
    return http.post("/config/internal-telegram/add", input)
}

export const internaltelegramConfigGetFirstRowApi = () => {
    return http.post("/config/internal-telegram/get/first/row")
}

export const toggleSendMessageInternalTelegramConfigApi = (input: ToggleSendMessageRequestBody) => {
    return http.post("/config/internal-telegram/toggle-send-message", input)
}

export const maintenanceConfigGetFirstRowApi = () => {
    return http.post("/config/maintenance/get/first/row")
}

export const maintenanceConfigAddApi = (input: MaintenanceConfigReqeustBody) => {
    return http.post("/config/maintenance/add", input)
}

export const toggleSwitchMaintenanceConfigApi = (input: MaintenanceConfigReqeustBody) => {
    return http.post("/config/maintenance/toggle-switch", input)
}

export const locationMapConfigGetFirstRowApi = () => {
    return http.post("/config/location-map/get-first-row")
}

export const locationMapConfigAddOrUpdateApi = (input: LocationMapConfigType) => {
    return http.post("/config/location-map/add-update", input)
}

export const googleLoginAuthGetFirstRowApi = () => {
    return http.post("/config/google-login-auth/get-first-row")
}

export const googleLoginAuthAddOrUpdateApi = (input: GoogleLoginAuthType) => {
    return http.post("/config/google-login-auth/add-update", input)
}

export const operatorLogConfigGetFirstRowApi = () => {
    return http.post("/config/operator-log-config/get-first-row")
}

export const operatorLogConfigAddOrUpdateApi = (input: OperatorLogAddRequestBody) => {
    return http.post("/config/operator-log-config/add-update", input)
}