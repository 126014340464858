import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { OrganizationsData } from "../../types/OrganizationsType";
import { isAdminUser, isSuperSpecialUser, isTenantUser } from '../../utils/UserUtil';
import { FilterContainer } from '../Shared/Common/Containers';
import { Col, Row } from 'react-bootstrap';
import { MachinesData } from '../../types/MachinesType';


const TenantFilterContainer = ({
    onChangeFilter, 
    filterValue, 
    viewUnassignedTenants,
    onChangeViewUnassignedTenants,
    orgList,
    machineList,
    userInfo,
} : any) : JSX.Element => {
    return (
        <FilterContainer>
            <Form.Group>
                <Row className='mb-3'>
                    <Col xs={12} md={6} lg={4}>
                        <Form.Check
                            inline
                            label="View Unassigned Tenants"
                            name="viewUnassignedTenants"
                            type={"checkbox"}
                            id={`view_unassigned_tenant`}
                            defaultChecked={viewUnassignedTenants}
                            onChange={onChangeViewUnassignedTenants}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <InputGroup className="mb-3">
                            <InputGroup.Text className="input-user"><span>Organization</span></InputGroup.Text>
                            {
                                (isTenantUser() || isAdminUser()) ?
                                <Form.Select onChange={onChangeFilter} 
                                    name="organizationId" aria-label="organizatioinId" 
                                    value={filterValue.organizationId}
                                    disabled
                                >
                                    <option value={userInfo.orgId} selected>
                                        {orgList.filter((org:OrganizationsData) => org.id === +userInfo.orgId)[0]?.organizationName}
                                    </option>
                                </Form.Select> :
                                <Form.Select onChange={onChangeFilter} 
                                name="organizationId" aria-label="organizatioinId" 
                                value={filterValue.organizationId}
                                >
                                    <option value={0}>All</option>
                                    {
                                    (orgList && orgList?.length !== 0) && orgList.map((org:OrganizationsData) => 
                                        <option value={org.id} key={org.id}>{org.organizationName}</option>
                                    )
                                    }
                                </Form.Select>
                            }
                        </InputGroup>
                    </Col>
                    <Col
                        sm={12}
                        md={4}
                    >
                        <InputGroup>
                            <InputGroup.Text className="input-user"><span>Machine</span></InputGroup.Text>
                            {
                                <Form.Select onChange={onChangeFilter} 
                                name="machineId" aria-label="machineId" 
                                value={filterValue.machineId}
                                disabled={
                                    !Boolean(filterValue.organizationId && filterValue.organizationId !== '0') ||
                                    viewUnassignedTenants
                                }
                                >
                                    <option value={0}>All</option>
                                    {
                                    (machineList && machineList?.length !== 0) && machineList.map((machine:MachinesData) => 
                                        <option value={machine.id} key={machine.id}>{machine.machineName}</option>
                                    )
                                    }
                                </Form.Select>
                            }
                        </InputGroup>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <InputGroup>
                            <InputGroup.Text id="inputGroup-sizing-lg">Search</InputGroup.Text>
                            <Form.Control
                                aria-label="Large"
                                aria-describedby="inputGroup-sizing-sm"
                                value={filterValue.search}
                                onChange={onChangeFilter}
                                name="search"
                                placeholder='Name'
                            />
                        </InputGroup>
                    </Col>
                </Row>
                
            </Form.Group>
        </FilterContainer>
    )
}

export default TenantFilterContainer;