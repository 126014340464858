import { useEffect, useState } from "react"
import { organizationGetAllDataApi } from "../services/RouteServices/OrganizationsApi"
import { STATUS_CODES } from "../constants/GlobalConstants"
import { OrganizationsData } from "../types/OrganizationsType"

export const useFilterOrganizations = () => {
    const [organizations, setOrganizations] = useState<OrganizationsData[]>([])
    const [error, setError] = useState("")

    useEffect(() => {
        organizationGetAllDataApi()
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setOrganizations(res?.data?.data?.orgList)
            } else {
                setError(res?.data?.message)
            }
        }).catch(error => {
            setError(error.message)
        })
    }, [])

    return {organizations, error}
}