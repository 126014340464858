import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { Container } from "../Shared/Common/Containers";
import { Title } from "../Shared/Common/Titles";
import LocationsBox from "./LocationsBox/LocationsBox";
import UsersBox from "./UsersBox/UsersBox";
import { assignLocationsToUserApi, getAvailableLocationsByUserIdsApi, removeLocationsToUserApi } from "../../services/RouteServices/UsersAvailableLocationsApi";
import { UsersAvailableLocations } from "../../types/UsersAvailableLocationsType";
import { STATUS_CODES } from "../../constants/GlobalConstants";
import { UserInfo } from "../../types/UserAuth";
import { getCurrentLocalUser } from "../../utils/UserUtil";


const UsersConfig: React.FC = () => {
    const userInfo: UserInfo = getCurrentLocalUser();
    const [selectedUsers, setSelectedUsers] = useState<number[]>([])
    const [availableLocations, setAvailableLocations] = useState<UsersAvailableLocations[]>([])

    useEffect(() => {
        getAvailableLocationsByUserIds()
    }, [selectedUsers])

    const handleUsersToggle = (value: number) => {
        const currentIndex = selectedUsers.indexOf(value);
        const newChecked = [...selectedUsers];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedUsers(newChecked)
    }

    const getAvailableLocationsByUserIds = () => {
        const input = {
            userIds: selectedUsers
        }
        getAvailableLocationsByUserIdsApi(input)
        .then(res => {
            const response = res?.data
            if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                setAvailableLocations(response?.data)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const handleAssignLocations = (userId: number, locationIds: number[]) => {
        const input = {
            userId: userId,
            locationIds: locationIds,
            createdBy: userInfo.userName
        }
        assignLocationsToUserApi(input)
        .then(res => {
            const response = res?.data
            if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                getAvailableLocationsByUserIds()
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const handleRemoveLocation = (id: number) => {
        const input = {
            id: id
        }
        removeLocationsToUserApi(input)
        .then(res => {
            const response = res?.data
            if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                console.log("Success")
                getAvailableLocationsByUserIds()
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    return (  
        <Layout>
            <Container>
                <Title>Users' Locations Config</Title>
                <Box sx={{
                    display: "flex",
                    gap: "20px"
                }}>
                    <UsersBox 
                        selectedUsers={selectedUsers}
                        handleToggle={handleUsersToggle}
                        handleAssignLocations={handleAssignLocations}
                    />
                    <LocationsBox 
                        locations={availableLocations}
                        handleRemoveLocation={handleRemoveLocation}
                    />
                </Box>
            </Container>
        </Layout>
    );
}
 
export default UsersConfig;