import { createContext, FC, useEffect, useState } from "react";
import { NodeProps } from "../types/CommonType";
import { LoginUserValues } from "../types/LoginUserType";
import { UserAuthType, UserInfo, DefaultUserInfo } from "../types/UserAuth";
import { getUserPermissions } from "../utils/UserUtil";

export const UserAuthContext = createContext<UserAuthType | null>(null);

const UserAuthProvider: FC<NodeProps> = ({ children }) => {

  const getUserInfoFromLocal = () => {
    const oldUserAuth = JSON.parse(localStorage.getItem("fwUserAuth") || "{}");
    return oldUserAuth;
  }

  const [userInfo, setUserInfo] = useState<UserInfo>(getUserInfoFromLocal());

  const getUserInfo = () => {
    return userInfo;
  };

  const updateUserInfo = (userInfo: UserInfo) => {
    setUserInfo(userInfo);
  };

  const handleLogin = (loginUser: UserInfo) => {
    localStorage.setItem("fwUserAuth", JSON.stringify(loginUser));
    updateUserInfo(loginUser);
  };

  const handleLogout = () => {
    console.log ("logout!");
    localStorage.removeItem("fwUserAuth");
    localStorage.removeItem("permissions")
    setUserInfo(DefaultUserInfo);
  };

  const isAuth = (): boolean => {
    if (userInfo.accessToken && userInfo.orgId > 0) {
      return true;
    }
    return false;
  };

  const hasPermission = (permission: string): boolean => {
    const permissions = getUserPermissions()
    if (permissions && permissions[permission]) {
      return Boolean(permissions[permission])
    }
    else 
      return false
  }

  return (
    <UserAuthContext.Provider
      value={{
        userInfo,
        isAuth,
        getUserInfo,
        updateUserInfo,
        handleLogin,
        handleLogout,
        hasPermission,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
};

export default UserAuthProvider;
