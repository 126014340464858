import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import '../../styling/customStyle.css';
import Layout from "../Layout/Layout";

import XLSX from "xlsx";

import { LOG_LEVEL, PAGE_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { dischargeWeightLogGetExportListByOrgId, dischargeWeightLogGetExportListByUserId, dischargeWeightLogGetListByOrgIdApi, dischargeWeightLogGetListByUserIdApi } from "../../services/RouteServices/DischargeWeightLogApi";
import { DischargeWeightLogData, DischargeWeightLogExportListResponseBody, DischargeWeightLogListResponseBody } from "../../types/DischargeWeightLogType";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import { DateFilterValue, FilterValue } from "../../types/global/FilterValueType";
import { convertDateFormat, convertToLocaleDate, getCurrentLocaleDate, getDayDifference } from '../../utils/DateTimeUtil';
import { getDefaultDateFilterValue, getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import { addEventLog } from "../../utils/EventLogUtil";
import { addNormalLog } from "../../utils/LoggerUtil";
import { checkLocation, getAvailableLocations, getCurrentLocalUser, isSuperSpecialUser, isTenantUser } from "../../utils/UserUtil";
import { Container, InnterTableContainer, PaginationContainer, TableContainer } from "../Shared/Common/Containers";
import { SummaryText, Title } from "../Shared/Common/Titles";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import ExportButton from "../Shared/Export/Export";
import LoadingModal from "../Shared/LoadingModal/LoadingModal";
import DischargeWeightLogFilterContainer from "./DischargeWeightLogFilterContainer";
import { generatePDF } from "./generate-pdf";

const loadDischargeWeightLogExportList = (dataList: DischargeWeightLogData[]) => {
    if (!dataList) return []
    if (isSuperSpecialUser()) {
        return dataList.map(data => {
            return {
                Id: data.id,
                "Waste Date": convertToLocaleDate(data.wasteDate),
                "Organization": data.organizationName,
                "Location": data.locationName,
                "Machine": data.machineName,
                "Weight(kg)": data.weight,
                "Remark": data.remark || "-",
            }
        })
    } else {
        return dataList.map(data => {
            return {
                Id: data.id,
                "Waste Date": convertToLocaleDate(data.wasteDate),
                "Machine": data.machineName,
                "Weight(kg)": data.weight,
                "Remark": data.remark || "-",
            }
        })
    }
}
 
const DischargeWeightLog: React.FC = () => {
    const tableLimit = PAGE_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();
    const availableLocations = getAvailableLocations()
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const [tablePage, setTablePage] = React.useState(1);
    const [tableOffset, setTableOffset] = React.useState(0);
    const [pageCount, setPageCount] = React.useState(0);
    const [weightLogTotalCount, setWeightLogTotalCount] = React.useState(0);
    const [totalDischargeWeight, setTotalDischargeWeight] = React.useState(0);
    
    const [dischargeWeightLogs, setDischargeWeightLogs] = useState<DischargeWeightLogData[]>([]); // * Original data object from db

    const [filterValue, setFilterValue] = useState<FilterValue>(getDefaultFilterValue());
    const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>(getDefaultDateFilterValue())
    const [isPreparing, setIsPreparing] = useState<boolean>(false)

    const dayRange = getDayDifference(dateFilterValue.startDate, dateFilterValue.endDate);

    useEffect(() => {
        setTablePage(1)
        setTableOffset(0)
    }, [filterValue, dateFilterValue])

    useEffect(() => {
        setFilterValue((prev) => ({
            ...prev,
            locationId: 0,
            machineId: 0,
        }))
    }, [filterValue.organizationId])

    useEffect(() => {
        setFilterValue((prev) => ({
            ...prev,
            machineId: 0,
        }))
    }, [filterValue.locationId])

    useEffect(() => {
        setErrorText("")
        setSuccessText("")

        if (hasPermission("viewDischargeWeightLog")) {
            if(isTenantUser()) {
                getDischargeWeightLogDataByUserId()
            } else {
                getDischargeWeightLogDataByOrgId();
            }
        }

    }, [filterValue, dateFilterValue, tableOffset])

    useEffect(() => {
        if (tablePage > pageCount && pageCount !== 0) {
            setTablePage(pageCount)
            const offsetValue = (tableLimit * (pageCount - 1));
            setTableOffset(offsetValue);
        }
    }, [pageCount])

    const exportToCSV = async () => { 
        let exportedData = await getExportedData()
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(loadDischargeWeightLogExportList(exportedData));
        XLSX.utils.book_append_sheet(workbook, worksheet, "Overall");
        XLSX.writeFile(workbook, `discharge-weightlogs-${getCurrentLocaleDate()}.xlsx`);
        const log: string = `${userInfo.userName} has downloaded discharge-weightlogs-${getCurrentLocaleDate()}.xlsx`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    };

    const exportToPdf = async () => {
        let exportedData = await getExportedData()
        const dataList = exportedData ? exportedData.map((data: DischargeWeightLogData) => ({...data, wasteDate: convertToLocaleDate(data.wasteDate)})) : []
        generatePDF(dataList, totalDischargeWeight)
        const log: string = `${userInfo.userName} has downloaded discharge-weightlogs-${getCurrentLocaleDate()}.pdf`
        addEventLog(
            log,
            "",
            LOG_LEVEL.INFO
        )
        addNormalLog(log, LOG_LEVEL.INFO)
    }

    const handleChangeFilterValue = (event: ChangeEvent<HTMLSelectElement>) => {
        setFilterValue(
            (prevValue) => {
                return {
                    ...prevValue,
                    [event.target.name]: event.target.value,
                }
            }
        )
    }

    const handleChangeDateFilterValue = (key: "startDate" | "endDate", value: Date) => {
        const day = value.getDate().toString().padStart(2, '0');
        const month = (value.getMonth() + 1).toString().padStart(2, '0');
        const year = value.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setDateFilterValue({...dateFilterValue, [key]: formattedDate})
    }

    const getExportedData = async() => {
        let exportedData = []
        try {
            if (isTenantUser()) {
                exportedData = await getExportDischargeWeightLogDataByUserId()
            } else {
                exportedData = await getExportDischargeWeightLogDataByOrgId()
            }
        } catch(e) {
            exportedData = []
        }
        return exportedData
    }

    const getDischargeWeightLogDataByOrgId = () => {
        const dataLimitsObject = {
            orgId: filterValue.organizationId,
            machineId: filterValue.machineId,
            locationId: filterValue.locationId,
            availableLocationIds: availableLocations,
            checkLocation: checkLocation(),
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: tableLimit,
            offset: tableOffset
        }
        dischargeWeightLogGetListByOrgIdApi(dataLimitsObject)
        .then(response => {
            const resData: DischargeWeightLogListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: DischargeWeightLogData[] = resData.data?.dischargeWeightLogList
                const totalCount : number = resData?.data?.totalCount;
                const totalDischargeWeight : number = resData?.data?.totalDischargeWeight;
                setWeightLogTotalCount(totalCount);
                setTotalDischargeWeight(totalDischargeWeight);
                setPageCount((Math.ceil(totalCount/tableLimit)) || 0);
                setSuccessText(resData?.message)
                setDischargeWeightLogs(dataList);
                setTimeout(() => {
                    setSuccessText("");
                }, 2000);
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }   

    const getExportDischargeWeightLogDataByOrgId = async (): Promise<any> => {
        const dataLimitsObject = {
            orgId: isSuperSpecialUser()? filterValue.organizationId! : userInfo.orgId!,
            machineId: filterValue.machineId || 0,
            locationId: filterValue.locationId,
            availableLocationIds: availableLocations,
            checkLocation: checkLocation(),
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
        }

        return dischargeWeightLogGetExportListByOrgId(dataLimitsObject)
        .then(res => {
            const resApiData : DischargeWeightLogExportListResponseBody = res?.data;
            return new Promise((resolve, reject) => {
                if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                    resolve(resApiData.data)
                else 
                    reject([])
            })   
        }).catch(error => {
            Promise.reject([])
        })
    }
    
    const getExportDischargeWeightLogDataByUserId = async (): Promise<any> => {
        const dataLimitsObject = {
            machineId: filterValue.machineId || 0,
            userId: userInfo.id || 0,
            orgId: userInfo.orgId,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
        }

        return dischargeWeightLogGetExportListByUserId(dataLimitsObject)
        .then(res => {
            const resApiData : DischargeWeightLogExportListResponseBody = res?.data;
            return new Promise((resolve, reject) => {
                if (res?.data?.status === STATUS_CODES.SUCCESS_CODE)
                    resolve(resApiData.data)
                else 
                    reject([])
            })   
        }).catch(error => {
            Promise.reject([])
        })
    }

    const getDischargeWeightLogDataByUserId = () => {
        const dataLimitsObject = {
            userId: userInfo.id,
            orgId: userInfo.orgId,
            machineId: filterValue.machineId,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: tableLimit,
            offset: tableOffset
        }
        
        dischargeWeightLogGetListByUserIdApi(dataLimitsObject)
        .then(response => {
            const resData: DischargeWeightLogListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: DischargeWeightLogData[] = resData.data?.dischargeWeightLogList
                const totalCount : number = resData?.data?.totalCount;
                const totalDischargeWeight : number = resData?.data?.totalDischargeWeight;
                setWeightLogTotalCount(totalCount);
                setTotalDischargeWeight(totalDischargeWeight);
                setPageCount((Math.ceil(totalCount/tableLimit)) || 0);
                setSuccessText(resData?.message)
                setDischargeWeightLogs(dataList);
                setTimeout(() => {
                    setSuccessText("");
                }, 2000);
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };

    // * Render Table Data
    const renderTable = () => {
        return (
            dischargeWeightLogs?.length !== 0 ? dischargeWeightLogs?.map((dischargeWeightLog: DischargeWeightLogData) =>          
                    <tr key={dischargeWeightLog.id}>
                        <td>{dischargeWeightLog.id}</td>
                        <td>{convertToLocaleDate(dischargeWeightLog.wasteDate!) || "-"}</td>
                        { isSuperSpecialUser() &&
                            <>
                            <td>{dischargeWeightLog.organizationName || "-"}</td>
                            <td>{dischargeWeightLog.locationName || "-"}</td>
                            </>
                        }
                        <td>{dischargeWeightLog.machineName || "-"}</td>
                        <td>{dischargeWeightLog.weight +" kg" || "-"}</td>
                        <td>{dischargeWeightLog.remark || "-"}</td>
                    </tr>
            ): 
            <tr>
                <td>No data found</td>
            </tr>
        )
    }

    if (!hasPermission("viewDischargeWeightLog")) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (
        <>
            <Layout>
                <Container>
                    <Box className="d-flex justify-content-between mb-2">
                        <Title>Discharge Weight Logs</Title>
                        <Box className="titleBox">
                            <ExportButton 
                                exportToCSV={exportToCSV}
                                exportToPdf={exportToPdf}
                                dayRange={dayRange + 1}
                            />
                        </Box>
                    </Box>
                    {/* {successText && <Alert severity="success">{successText}<br/></Alert>} */}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}

                    <LoadingModal 
                        show={isPreparing}
                    />

                    <DischargeWeightLogFilterContainer
                        onChangeFilter={handleChangeFilterValue}
                        onChangeDateFilter={handleChangeDateFilterValue}
                        dateFilterValue={dateFilterValue}
                        filterValue={filterValue}
                        userInfo={userInfo}
                    />

                    <Box sx={{minWidth: "300px"}}>
                        <SummaryText>Total Weight - {totalDischargeWeight || 0} kg</SummaryText>
                        <SummaryText>{weightLogTotalCount} DischargeWeightLog(s) found!</SummaryText>
                    </Box>

                    <TableContainer>
                    <InnterTableContainer>
                        <table className="styled-table">
                            <thead className="table-header">
                                <tr>
                                    <th>ID</th>
                                    <th>Waste Date</th>
                                    { isSuperSpecialUser() &&
                                        <>
                                        <th>Organization</th>
                                        <th>Location</th>
                                        </>
                                    }
                                    <th>Machine</th>
                                    <th>Waste Weight</th>
                                    <th>Remark</th>
                                </tr>
                            </thead>
                            <tbody className="table-data">
                                {
                                    renderTable()
                                }
                            </ tbody>
                        </table>
                    </InnterTableContainer>
                    </TableContainer>
                    <PaginationContainer>
                        <Pagination 
                            count={pageCount} 
                            onChange={handleChangePage} 
                            page={tablePage} 
                            variant="outlined" 
                            shape="rounded" size="large" 
                        />
                    </PaginationContainer>
                </Container>
            </Layout>
        </>
    );
};

export default DischargeWeightLog;