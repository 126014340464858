import { Alert } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { CARD_LIMIT, PAGE_LIMIT, STATUS_CODES } from "../../constants/GlobalConstants";
import { UserAuthContext } from "../../contexts/UserAuthContext";
import { useFilterMachines } from "../../custom-hooks/useFilterMachines";
import { useFilterOrganizations } from "../../custom-hooks/useFilterOrganizations";
import { useFilterTenants } from "../../custom-hooks/useFilterTenants";
import { captureGetListByOrgIdAndMachineIdApi, captureGetListByUserIdApi } from "../../services/RouteServices/CaptureApi";
import '../../styling/customStyle.css';
import { CaptureData, CaptureGETRequestBody, CaptureListResponseBody } from "../../types/CaptureType";
import { UserAuthType, UserInfo } from "../../types/UserAuth";
import { DateFilterValue, FilterValue } from "../../types/global/FilterValueType";
import { convertDateFormat } from "../../utils/DateTimeUtil";
import { getDefaultDateFilterValue, getDefaultFilterValue } from "../../utils/DefaultFilterValueUtil";
import { getCurrentLocalUser, isTenantUser } from "../../utils/UserUtil";
import Layout from "../Layout/Layout";
import { CardsContainer, Container, PaginationContainer } from "../Shared/Common/Containers";
import { SummaryText } from "../Shared/Common/Titles";
import UnauthorizedPage from "../Shared/ErrorPages/UnauthorizedPage";
import './Capture.css';
import CaptureCard from "./CaptureCard";
import CaptureFilterContainer from "./CaptureFilterContainer";

const Capture: React.FC = () => {
    const tableLimit = CARD_LIMIT;
    const userInfo: UserInfo = getCurrentLocalUser();
    const { hasPermission } = useContext(UserAuthContext) as UserAuthType

    const [captureList, setCaptureList] = useState<CaptureData[]>([]);
    const [captureTotalCount, setCaptureTotalCount] = useState<number>(0);
    const [capturePageCount, setCapturePageCount] = useState<number>(0);
    const [filterValue, setFilterValue] = useState<FilterValue>(getDefaultFilterValue());
    const [dateFilterValue, setDateFilterValue] = useState<DateFilterValue>(getDefaultDateFilterValue())
    const [tableOffset, setTableOffset] = useState(0);
    const [tablePage, setTablePage] = useState(1);

    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const [intervalToggle, setIntervalToggle] = useState(true)

    // get the filter values
    const {organizations, error: orgError} = useFilterOrganizations()
    const {machines, error: machineError} = useFilterMachines(filterValue?.organizationId || -1, [filterValue.organizationId])
    const {tenants, error: tenantError} = useFilterTenants(filterValue?.machineId || -1, [filterValue.machineId])

    useEffect(() => {
        setTimeout(() => {
            console.log("capture interval")
            setIntervalToggle(prev => !prev); 
        }, 5000)
    }, [intervalToggle])

    useEffect(() => {
        setTablePage(1)
        setTableOffset(0)
    }, [filterValue, dateFilterValue])

    useEffect(() => {
        setErrorText("")
        setSuccessText("")
        if (hasPermission("viewCapture")) {
            getCaptureList()
        }
    }, [filterValue, dateFilterValue, tableOffset, intervalToggle]);

    useEffect(() => {
        if (tablePage > capturePageCount && capturePageCount !== 0) {
            setTablePage(capturePageCount)
            const offsetValue = (tableLimit * (capturePageCount - 1));
            setTableOffset(offsetValue);
        }
    }, [capturePageCount])


    const handleChangeFilterValue = (event: ChangeEvent<HTMLSelectElement>) => {
        setFilterValue(
            (prevValue) => {
                return {
                    ...prevValue,
                    [event.target.name]: event.target.value,
                }
            }
        )

        if (event.target.name === "organizationId") {
            setFilterValue(
                (prevFilterData) => {
                    return  {
                        ...prevFilterData,
                        machineId: 0,
                    }
                }
            )
        }

        if (event.target.name === "organizationId" || event.target.name === "machineId") {
            setFilterValue(
                (prevFilterData) => {
                    return  {
                        ...prevFilterData,
                        tenantId: 0,
                    }
                }
            )
        }
    }

    const handleChangeDateFilterValue = (key: "startDate" | "endDate", value: Date) => {
        const day = value.getDate().toString().padStart(2, '0');
        const month = (value.getMonth() + 1).toString().padStart(2, '0');
        const year = value.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setDateFilterValue({...dateFilterValue, [key]: formattedDate})
    }

    const handleChangePage = (event : ChangeEvent<unknown>, pageValue : number) => {
        setTablePage(pageValue);
        if (pageValue === 1) {
            setTableOffset(0);
        }
        else{
            const offsetValue = (tableLimit * (pageValue - 1));
            setTableOffset(offsetValue);
        }
    };

    const getCaptureList = () => {
        if (isTenantUser()) {
            getCaptureListByUserId(userInfo.id || 0, filterValue.machineId!, tableLimit, tableOffset);
        } else {
            getCaptureListByOrgIdAndMachineId(filterValue.organizationId!, filterValue.machineId!, filterValue.tenantId!, tableLimit, tableOffset);
        }
    }

    const getCaptureListByOrgIdAndMachineId = (orgId: number, machineId: number, tenantId: number, pageLimit : number, pageOffset : number) => {
        const dataLimitsObject: CaptureGETRequestBody = {
            orgId: orgId,
            machineId: machineId,
            tenantId: tenantId,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: pageLimit,
            offset: pageOffset
        }
        captureGetListByOrgIdAndMachineIdApi(dataLimitsObject)
        .then(response => {
            const resData: CaptureListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: CaptureData[] = resData.data?.captureList
                const totalCount : number = resData?.data?.totalCount;
                setCaptureTotalCount(totalCount)
                setCapturePageCount((Math.ceil(totalCount/tableLimit)) || 0);
                setSuccessText(resData?.message)
                setCaptureList(dataList);
                setTimeout(() => {
                    setSuccessText("");
                }, 2000);
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    } 
    
    const getCaptureListByUserId = (userId: number, machineId: number, pageLimit : number, pageOffset : number) => {
        const dataLimitsObject: CaptureGETRequestBody = {
            userId: userId,
            machineId: machineId,
            startDate: convertDateFormat(dateFilterValue?.startDate || ""),
            endDate: convertDateFormat(dateFilterValue?.endDate || ""),
            limit: pageLimit,
            offset: pageOffset
        }
        captureGetListByUserIdApi(dataLimitsObject)
        .then(response => {
            const resData: CaptureListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const dataList: CaptureData[] = resData.data?.captureList
                const totalCount : number = resData?.data?.totalCount;
                
                setCaptureTotalCount(totalCount)
                setCapturePageCount((Math.ceil(totalCount/tableLimit)) || 0);
                setSuccessText(resData?.message)
                setCaptureList(dataList);
                setTimeout(() => {
                    setSuccessText("");
                }, 2000);
            }
            else {
                setErrorText(resData?.message)
                console.log ('error');
            }
        })
        .catch(error => {
            setSuccessText("");
            setErrorText(error?.response?.data?.message || error?.message)
            console.log(error)
        })
    }  

    if (!hasPermission("viewCapture")) {
        return (    
            <Layout>
                <Container>
                    <UnauthorizedPage />
                </Container>
            </Layout>
        )
    }

    return (
        <>
            <Layout>
                <Container>
                    {/* {successText && <Alert severity="success">{successText}<br/></Alert>} */}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}
                    <CaptureFilterContainer
                        onChangeFilter={handleChangeFilterValue}
                        onChangeDateFilter={handleChangeDateFilterValue}
                        filterValue={filterValue}
                        dateFilterValue={dateFilterValue}
                        orgList={organizations}
                        machineList={machines}
                        tenantList={tenants}
                        userInfo={userInfo}
                    />

                    <SummaryText>{captureTotalCount} Capture(s) found!</SummaryText>
                    <CardsContainer className="hide-scroll-bar">
                        {
                            captureList?.length !== 0 && 
                            captureList?.map((capture: CaptureData) => <CaptureCard key={capture.id} capture={capture}/>)
                        }
                    </CardsContainer>
                    <PaginationContainer>
                        <Pagination 
                            count={capturePageCount} 
                            onChange={handleChangePage} 
                            page={tablePage} 
                            variant="outlined" 
                            shape="rounded" size="large" 
                        />
                    </PaginationContainer>
                </Container>
            </Layout>
        </>
    )
};

export default Capture;