import { CaptureGETRequestBody } from "../../types/CaptureType";
import http from "../apiServices";

export const captureGetByMachineIdAndCaptureTimeApi = (inputDate: CaptureGETRequestBody) => {
    return http.post("/capture/get/by/machineId-captureTime", inputDate)
}

export const captureGetListByOrgIdAndMachineIdApi = (inputData : CaptureGETRequestBody) => {
    return http.post("/capture/get/list/by/orgId", inputData)
}

export const captureGetListByUserIdApi = (inputData : CaptureGETRequestBody) => {
    return http.post("/capture/get/list/by/userId", inputData)
} 