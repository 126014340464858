export interface DataSyncTable {
  organizationId: number;
  machineId: number;
  locationId: number;
  lfcTime: string;
  lfcOperationalLog: string;
}

export interface DataSyncTableData {
  [index: string]: {
  lfcTime: string;
  lfcOperationalLog: string;
  };
}

export type ResponseDataSyncApiType = {
  status: number;
  data: ResponseDataSyncApiDataType;
}

export type ResponseDataSyncApiDataType = {
  status: number;
  message: string;
  data: DataSyncTable[];
}

export type LFCLogData = {
  organizationId: number,
  machineId: number,
  locationId: number,
  file: File | null,
}

export const LFCLogDataDefault = {
  organizationId: 0,
  machineId: 0,
  locationId: 0,
  file: null
}
