import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Box from "@mui/material/Box"
import EngineeringIcon from '@mui/icons-material/Engineering';
import WarningIcon from '@mui/icons-material/Warning';
import "./Config.css"

const MaintenanceSwitch = ({
    handleMaintenanceSwitch,
    maintenanceSwitch,
}: any) => {
    return (  
        <Box
            className="rounded p-3 bg-light-blue bg-gradient sync-lfc-data-box" 
        >
            <FormControlLabel
                value="synLfcData"
                control={
                    <Switch color="primary" checked={maintenanceSwitch ? true : false}
                        onChange={handleMaintenanceSwitch}
                    />
                }
                label="Server Maintenance"
                labelPlacement="start"
                sx={{
                    "& .MuiFormControlLabel-label" : {
                        fontWeight: "bold",
                        fontSize: "1.1rem"
                    }
                }}
            />
            {
                maintenanceSwitch ?
                <div className="p-1 border rounded bg-success text-white">
                    <EngineeringIcon
                        sx={{
                          width: 30,
                          height: 30
                        }}
                      ></EngineeringIcon>
                </div> :
                <>
                    <span className="p-1 text-light bg-danger blink_me">
                        <WarningIcon
                        sx={{
                            width: 20,
                            height: 20
                        }}
                        >
                        </WarningIcon>
                        OFF
                    </span>
                </>
            }
        </Box>
    );
}
 
export default MaintenanceSwitch;