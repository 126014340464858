import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Alert from "@mui/material/Alert"
import Button from "react-bootstrap/Button";

const ConfirmModal = ({
    show,
    title,
    bodyMessage,
    successMsg,
    errorMsg,
    handleClose,
    onClickConfrim,
    btnName,
    btnColor,
    machineUUID,
}: any) => {
    return ( 
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            {successMsg && <Alert severity="success">{successMsg}<br/></Alert>}
            {errorMsg && <Alert severity="error">{errorMsg}<br/></Alert>}
            <Modal.Body>
                <p>{bodyMessage}</p>
                <span>Current UUID - [{machineUUID}]</span>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant={btnColor} onClick={onClickConfrim}>{btnName}</Button>
            </Modal.Footer>
        </Modal> 
    );
}

export default ConfirmModal;