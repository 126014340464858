import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Alert from "@mui/material/Alert"
import Button from "react-bootstrap/Button";

const DeleteModal = ({
    show,
    successMsg,
    errorMsg,
    currentData,
    handleClose,
    handleConfirmDelete
}: any) => {
    return ( 
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Delete Confirmation</Modal.Title>
            </Modal.Header>
            {successMsg && <Alert severity="success">{successMsg}<br/></Alert>}
            {errorMsg && <Alert severity="error">{errorMsg}<br/></Alert>}
            <Modal.Body>
                <p>Are you sure you want to delete ID: <strong>[{currentData?.id}]</strong>?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="danger" onClick={handleConfirmDelete}>Delete</Button>
            </Modal.Footer>
        </Modal> 
    );
}

export default DeleteModal;