import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import MarkunreadIcon from '@mui/icons-material/Markunread';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import Avatar from '@mui/material/Avatar';
import Box from "@mui/material/Box";
import { useEffect, useState } from 'react';
import { UserInfo } from '../../types/UserAuth';
import { getCurrentLocalUser, isAdminUser, isRootAndSuperUsers, isSuperSpecialUser, isTenantUser } from '../../utils/UserUtil';
import "./Setting.css";
import { organizationGetByIdApi } from '../../services/RouteServices/OrganizationsApi';
import { STATUS_CODES } from '../../constants/GlobalConstants';
import { OrganizationsData } from '../../types/OrganizationsType';

const getOrgById = async (id: number) => {
    try {
        const response = await organizationGetByIdApi(id)
        if (response?.data?.status === STATUS_CODES.SUCCESS_CODE) {
            return response?.data?.data
        }
    } catch (error) {
        console.log(error)
    }
    return {}
}

const AccountSetting = () => {
    const [org, setOrg] = useState<OrganizationsData>({} as OrganizationsData);
    const user = getCurrentLocalUser()
    useEffect(() => {
        getOrgById(user.orgId!)
        .then(data => {
            setOrg(data)
        }).catch(error => {
            console.log(error)
        })
    }, [])

    const avatarColor = () => {
        const color = isRootAndSuperUsers() ? "darkorange"
                    : isSuperSpecialUser() ? "hotpink"
                    : isAdminUser() ? "dodgerblue"
                    : isTenantUser() ? "darkviolet"
                    : "limegreen";
        return color;
    }

    return ( 
        <Box
            sx={{
                maxWidth: "550px",
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                '@media (max-width: 960px)': {
                    justifyContent: "center",
                    gap: 5,
                }
            }}
            className="border border-light border-3 rounded p-3 bg-light bg-gradient" 
        >
            <Avatar
                sx={{ 
                    bgcolor: avatarColor(),
                    width: 150,
                    height: 150
                }}
                src={org.organizationImage}
                children={<CorporateFareIcon style={{fontSize: "50px"}} />}
            />
            <span
                className='fs-4 bg-white border border-white p-2 ms-4 rounded'
            >
                <p>
                    <PersonPinIcon 
                        className='me-3'
                    />
                    <span>{user.userName}</span>
                </p>
                <p>
                    <MarkunreadIcon 
                        className='me-3'
                    />
                    {user.email}
                </p>
                <p>
                    <AccountBoxOutlinedIcon 
                        className='me-3'
                    />
                    {user.roleName}
                </p>
                <p>
                    <CorporateFareIcon 
                        className='me-3'
                    />
                    {user.orgName}
                </p>
            </span>
        </Box>
    );
}
 
export default AccountSetting;