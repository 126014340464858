export type EmailConfigData = {
    hostname?: string,
    email?: string,
    fromEmail?: string,
    password?: string,
    token?: string,
    doneBy?: string,
}

export const EmailConfigDefault: EmailConfigData = {
    hostname: "",
    email: "",
    fromEmail: "",
    password: "",
    token: "",
    doneBy: "",
}

export type SmsConfigData = {
    hostname?: string,
    token?: string,
    countryCode?: string,
    remark?: string,
    doneBy?: string,
}

export const SmsConfigDefault = {
    hostname: "",
    token: "",
    countryCode: "",
    remark: "",
    doneBy: "",
}

export type LfcTokenConfigData = {
    lfcHostname: string,
    lfcToken: string,
    remark: string,
}

export const LfcTokenConfigDefault: LfcTokenConfigData = {
    lfcHostname: "",
    lfcToken: "",
    remark: ""
}

export type SyncLfcData = {
    isSync?: number,
    doneBy?: string,
}

export const SyncLfcDataDefault: SyncLfcData = {
    isSync: 0,
    doneBy: "",
}

export type SyncBackgroundLog = {
    isSync?: number,
    doneBy?: string,
}

export const SyncBackgroundLogDefault: SyncBackgroundLog = {
    isSync: 0,
    doneBy: "",
}