import { Box } from "@mui/material";
import { FunctionComponent } from "react";
import unauthorizedImage from "../../../assets/401/401.png"

interface UnauthorizedPageProps {
    
}
 
const UnauthorizedPage: FunctionComponent<UnauthorizedPageProps> = () => {
    return (  
        <Box sx={{
            height: "85vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: 'center',
        }}>
            <p className="fw-light fs-2">You are not authorized to access this page.</p>
            <img src={unauthorizedImage} width="300px" height="250px" />
        </Box>
    );
}
 
export default UnauthorizedPage;