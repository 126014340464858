import jsPDF from "jspdf";
import { LOG_LEVEL } from "../../constants/GlobalConstants";
import autoTable from "jspdf-autotable";
import { convertToLocaleDate, getCurrentLocaleDate } from "../../utils/DateTimeUtil";
import logoImage from '../../assets/logo/env-logo.png';
import { OperatorLogData } from "../../types/OperatorLogType";

export const generatePDF = (dataList: any) => {
    const doc = new jsPDF('landscape', 'mm', 'a4');

    const headers = [
        "Id", "Organization", "User",
        "Operator Log", "Created At"
    ];
    const dataKeys = [
        "id", "organizationName", "username",
        "logMessage", "createdAt"
    ];

    const columnWidths = [25, 35, 35, 130, 40];
    // Generate the columnStyles object dynamically
    const columnStyles: any = {};
    for (let i = 0; i < headers.length; i++) {
      columnStyles[dataKeys[i]] = { cellWidth: columnWidths[i] };
    }

    const getTextColor = (data: any): [number, number, number] => {
        const logLevel = data.row.raw.eventLevel;
        if (logLevel === LOG_LEVEL.WARN ) {
            return [235, 121, 14];
        } else if (logLevel === LOG_LEVEL.ALERT ) {
            return [235, 14, 21];
        } else if (logLevel === LOG_LEVEL.SUCCESS) {
            return [83, 145, 101]
        }  else {
            return [0, 0, 0]
        }
    };

    autoTable(doc, {
        head: [headers],
        body: dataList,
        columns: dataKeys.map((key) => ({ dataKey: key })),
        columnStyles: columnStyles,
        margin: { top: 20, left: 15},
        didParseCell: function (data) {
            if (data.section === "head") {
              data.cell.styles.fontSize = 9;
              data.cell.styles.textColor = [255, 255, 255];
              data.cell.styles.fillColor = [0, 0, 0];
            } else if (data.section === "body") {
                const textColor = getTextColor(data)
                data.cell.styles.textColor = textColor;
            }
          },
        didDrawPage: function (data) {
            doc.setFontSize(18);
            doc.text("Operator Log", data.settings.margin.left, 10);
            // Add the logo image
            const logoWidth = 20;
            const logoHeight = 10;
            const logoX = data.settings.margin.left + 250;
            const logoY = 5;
            doc.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);
        }
    })
    doc.save(`operator-logs-${getCurrentLocaleDate()}.pdf`)
}


export const loadOperatorLogExportList = (dataList: OperatorLogData[]) => {
    if (!dataList) return []
    return dataList.map(data => {
        return {
            Id: data.id,
            "Organization": data.organizationName,
            "User": data.username,
            "Operator Log": data.logMessage,
            "Created At": convertToLocaleDate(data.createdAt!),
        }
    })
}