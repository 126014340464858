import {useState, useEffect, ChangeEvent} from "react"
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Col, Row } from "react-bootstrap";
import { aiClassGetApi, aiClassUpdateApi, aiConfigGetFirstRowApi, aiConfigUpdateApi } from "../../services/RouteServices/ConfigApi";
import { STATUS_CODES } from "../../constants/GlobalConstants";
import { AiClass, AiConfigData } from "../../types/AiConfigType";
import { Alert, Box } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Layout from "../Layout/Layout";
import { Container } from '../Shared/Common/Containers';
import { Title } from "../Shared/Common/Titles";
import { getCurrentLocalUser } from "../../utils/UserUtil";
import { convertToLocaleDate } from "../../utils/DateTimeUtil";

const AiConfig = () => {
    const userInfo = getCurrentLocalUser()

    const [aiClasses, setAiClasses] = useState<AiClass[]>([])
    const [aiConfig, setAiConfig] = useState<AiConfigData>({} as AiConfigData);
    const [errorText, setErrorText] = useState("");
    const [successText, setSuccessText] = useState("");

    const viewAiClasses = aiClasses?.filter(aiClass => aiClass.deletedBy === null).sort((a, b) => a.order - b.order);

    useEffect(() => {
        setErrorText("")
        getAiClasses()
        getAiConfig()
    }, [])

    // useEffect(() => {
    //     console.log("Ai classes: ", aiClasses)
    //     console.log("View Ai classes: ", viewAiClasses)
    // }, [aiClasses])

    const handleChangeAiClasses = (event: ChangeEvent<HTMLInputElement>) => {
        setAiClasses(prevValues => {
            const currentValue = prevValues[+event.target.name]
            prevValues[+event.target.name] = {...currentValue, name: event.target.value}
            return [...prevValues]
        })
    }

    const hanldeChangeAiConfig = (event: ChangeEvent<HTMLInputElement>) => {
        setAiConfig({... aiConfig, [event.target.name]: event.target.value})
    }

    const handleClickUp = (currentValue: AiClass) => {
        setAiClasses((prevValues) => {
            const currentIndex = prevValues.indexOf(currentValue)
            const currentOrder = currentValue.order
            if (currentOrder > 1) {
                const targetOrder = currentValue.order - 1
                const targetValue = prevValues.filter(value => value.order === targetOrder)[0]
                const targetIndex = prevValues.indexOf(targetValue)
                const newTargetValue = {...targetValue, order: currentOrder}
                const newCurrentValue = {...currentValue, order: targetOrder}
                prevValues[targetIndex] = newTargetValue
                prevValues[currentIndex] = newCurrentValue
                const newValues = [...prevValues]
                return newValues
            }
            return prevValues
        })
    }

    const handleClickDown = (currentValue: AiClass) => {
        setAiClasses((prevValues) => {
            const currentIndex = prevValues.indexOf(currentValue)
            const currentOrder = currentValue.order
            if (currentOrder < prevValues.length) {
                const targetOrder = currentValue.order + 1
                const targetValue = prevValues.filter(value => value.order === targetOrder)[0]
                const targetIndex = prevValues.indexOf(targetValue)
                const newTargetValue = {...targetValue, order: currentOrder}
                const newCurrentValue = {...currentValue, order: targetOrder}
                prevValues[targetIndex] = newTargetValue
                prevValues[currentIndex] = newCurrentValue
                const newValues = [...prevValues]
                return newValues
            }
            return prevValues
        })
    }

    const handleUpdateAiConfig = () => {
        setErrorText("")
        const input = {
            threshold: aiConfig?.threshold,
            doneBy: userInfo.userName
        }
        aiConfigUpdateApi(input)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setSuccessText(res?.data?.message)
                setTimeout(() => {
                    setSuccessText("")
                }, 2000)
                setAiConfig(res?.data?.data)
            } else {
                setErrorText(res?.data?.message)
            }
        }).catch(error => {
            setErrorText(error.message)
        })
    }

    const handleAddAiClasses = () => {
        setAiClasses([...aiClasses, {id: 0, order: aiClasses.length + 1, name: "", deletedBy: null}])
    }

    const handleUpdateAiClasses = () => {
        setErrorText("")
        const input = {
            aiClasses: aiClasses
        }
        aiClassUpdateApi(input)
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                setSuccessText(res?.data?.message)
                getAiClasses()
                setTimeout(() => {
                    setSuccessText("")
                }, 2000)
            } else {
                setErrorText(res?.data?.message)
            }
        }).catch(error => {
            setErrorText(error.message)
        })
    }

    const handleDeleteAiClasses = (inputAiClass: AiClass) => {
        setAiClasses(aiClasses.map(aiClass => {
            if (aiClass.id === inputAiClass.id) {
                return {...aiClass, deletedBy: userInfo.userName}
            } 
            return aiClass
        }))
    }

    const getAiClasses = () => {
        aiClassGetApi()
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                const data = res?.data?.data?.aiClasses
                setAiClasses(data.length > 0 ? data : [{id: 0, order: 1, name: "", deletedBy: null}])
            } 
        }).catch(error => {
            setErrorText(error.message)
        })
    }

    const getAiConfig = () => {
        aiConfigGetFirstRowApi()
        .then(res => {
            if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
                const aiConfig = res?.data?.data
                setAiConfig(aiConfig)
            } 
        }).catch(error => {
            setErrorText(error.message)
        })
    }

    return (  
        <>
            <Layout>
                <Container>
                    <Title>Ai Config</Title>
                    {successText && <Alert severity="success" sx={{marginBottom: 2}}>{successText}</Alert>}
                    {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}
                    <Box>
                        <Form className="mb-3" style={{width: "30%"}}>
                            <Form.Label as="legend">
                                Config
                            </Form.Label>
                            <div
                                className="border border-light border-3 rounded p-3 bg-light bg-gradient" 
                            >
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label column sm="3">
                                        Threshold
                                    </Form.Label>
                                    <Col sm="9">
                                        <Form.Control type="text" placeholder='0' name="threshold" value={aiConfig.threshold} onChange={hanldeChangeAiConfig} />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="">
                                    <Form.Label column sm="3">
                                        
                                    </Form.Label>
                                    <Col sm="9">
                                        <span style={{fontSize:13, color:'#35a612'}}>Last Update - {convertToLocaleDate(aiConfig.updatedAt) || convertToLocaleDate(aiConfig.createdAt)}</span>
                                        <br></br>
                                        <span style={{fontSize:13, color:'#35a612'}}>Updated User - {aiConfig.updatedBy || aiConfig.createdBy}</span>
                                    </Col>
                                </Form.Group>
                                <Button variant="primary" onClick={handleUpdateAiConfig}>Update</Button>
                            </div>
                        </Form>
                    </Box>
                    <Box>
                        <Form className="mt-3 w-75">
                            <Form.Label as="legend">
                                Ai Classes
                            </Form.Label>
                            <div
                                className="border border-light border-3 rounded p-3 bg-light bg-gradient" 
                            >
                                <div className="d-flex align-items-start">
                                    <div style={{width: "80%"}}>
                                        {
                                            (viewAiClasses) && viewAiClasses.map((data, index) => (
                                                <div className='d-flex my-2 gap-3 align-items-center' key={index}>
                                                    <DeleteOutlineIcon color="error" style={{cursor: "pointer"}} onClick={() => handleDeleteAiClasses(data)} />
                                                    <Badge bg="secondary">
                                                        <span className="text-md">{index+1}</span>
                                                    </Badge>
                                                    <div className="d-flex w-75">
                                                        <Form.Control
                                                            value={data.name}
                                                            onChange={handleChangeAiClasses}
                                                            name={""+index}
                                                        />
                                                        <div>
                                                            <ArrowDropUpIcon onClick={() => handleClickUp(data)} style={{cursor: "pointer"}} />
                                                            <ArrowDropDownIcon onClick={() => handleClickDown(data)} style={{cursor: "pointer"}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        <Button variant="primary" onClick={handleUpdateAiClasses} className="mt-4">Update</Button>
                                    </div>
                                    <Button variant="success" onClick={handleAddAiClasses}>Add</Button>
                                </div>
                            </div>
                        </Form>
                    </Box>
                </Container>
            </Layout>
        </>
    );
}
 
export default AiConfig;