import Layout from "../Layout/Layout";
import { Container } from "../Shared/Common/Containers";
import { Title } from "../Shared/Common/Titles";
import DownloadDetections from "./DownloadDetections";

const Maintenance = () => {

    return (  
        <>
            <Layout>
                <Container>
                    <Title>Maintenance</Title>
                    <DownloadDetections />
                </Container>
            </Layout>
        </>
    );
}
 
export default Maintenance;