import http from '../apiServices';

export const getReportHourlyOverallByOrgIdApi = (input: any) => {
    return http.post('/report/get/hourly-overall/by/orgId', input);
}

export const getReportDailyOverallByOrgIdApi = (input: any) => {
    return http.post('/report/get/daily-overall/by/orgId', input);
}

export const getReportMonthlyOverallByOrgIdApi = (input: any) => {
    return http.post('/report/get/monthly-overall/by/orgId', input);
}

export const getReportHourlyOverallByUserIdApi = (input: any) => {
    return http.post('/report/get/hourly-overall/by/userId', input);
}

export const getReportDailyOverallByUserIdApi = (input: any) => {
    return http.post('/report/get/daily-overall/by/userId', input);
}

export const getReportMonthlyOverallByUserIdApi = (input: any) => {
    return http.post('/report/get/monthly-overall/by/userId', input);
}