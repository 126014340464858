import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FilterContainer } from "../Shared/Common/Containers";

const HardwareFilterContainer = ({
    filterValues,
    onChangeFilter
}: any) => {
    return (  
        <FilterContainer>
            <Row>
                <Col xs={12} md={6} lg={4}>
                    <InputGroup>
                        <InputGroup.Text id="inputGroup-sizing-lg">Search</InputGroup.Text>
                        <Form.Control
                            aria-label="Large"
                            aria-describedby="inputGroup-sizing-sm"
                            value={filterValues.search} 
                            onChange={onChangeFilter}
                            name="search"
                        />
                    </InputGroup>
                </Col>
            </Row>
        </FilterContainer>
    );
}
 
export default HardwareFilterContainer;