import { Box } from "@mui/material";

const ChartContainer = ({children}: any) => {
    return (  
        <Box
            sx={(theme) => ({
                marginY: 5,
                maxHeight: 600,
                width: "75%",
                [theme.breakpoints.down('sm')]: {
                    width: "100%",
                    maxHeight: "300px"
                },
            })}
        >
            {children}
        </Box>
    );
}
 
export default ChartContainer;