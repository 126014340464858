import Modal from 'react-bootstrap/Modal';
import Alert from "@mui/material/Alert"
import Button from "react-bootstrap/Button";
import { Form, InputGroup } from 'react-bootstrap';

const UpdateModal = ({
    show,
    successMsg,
    errorMsg,
    onChange,
    currentData,
    handleClose,
    handleConfirmUpdate
}: any) => {
    return (  
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>Update</Modal.Title>
            </Modal.Header>
            {successMsg && <Alert severity="success">{successMsg}<br/></Alert>}
            {errorMsg && <Alert severity="error">{errorMsg}<br/></Alert>}
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <InputGroup className="mb-3">
                            <InputGroup.Text className="input-machine"><span>External NFC UUID <span className="text-danger">*</span></span></InputGroup.Text>
                            <Form.Control name="externalNfcUuid" onChange={onChange} type="text" placeholder="Enter External NFC UUID" value={currentData?.externalNfcUuid} required/>
                        </InputGroup>
                        <InputGroup className="mb-3">
                            <InputGroup.Text className="input-machine"><span>Remark</span></InputGroup.Text>
                            <Form.Control name="remark" onChange={onChange} type="text" placeholder="Enter Remark" value={currentData?.remark} required/>
                        </InputGroup>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" onClick={handleConfirmUpdate}>Update</Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default UpdateModal;