import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {
  Avatar,
  Box,
  Collapse,
  IconButton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React from "react";
import { Button, Table } from "react-bootstrap";
import { IoIosWarning } from "react-icons/io";
import { IoFastFood, IoLocationSharp, IoWifiOutline } from "react-icons/io5";
import { RiWifiOffLine } from "react-icons/ri";
import { STATUS_CODES } from "../../../constants/GlobalConstants";
import { getMachineListByLocationIdApi, getTopFiveTenantsOfADayApi } from "../../../services/RouteServices/DashboardApi";
import { convertToLocaleDate, getCurrentLocaleEndDate, getCurrentLocaleStartDate } from "../../../utils/DateTimeUtil";
import { getMachineTypeIcon, isFoodWasteMachine } from "../../../utils/MachineUtil";
import TopFiveTenantsModal from "../Modals/TopFiveTenantsModal";

export type TopFiveTenants = {
  tenantName: string,
  totalWeight: number
}

export function Row(props: { row: any, machineType: string }) {
    const { row, machineType } = props;
    const [open, setOpen] = React.useState(false);
    const [showTopFiveTenantsModal, setShowTopFiveTenantsModal] = React.useState(false);
    const [topFiveTenants, setTopFiveTenants] = React.useState<TopFiveTenants[]>([]);
    const [machines, setMachines] = React.useState([])

    const getMachineListByLocationId = (locationId: number) => {
      const dataLimitsObject = {
        machineType: machineType,
        locationId: locationId,
        startDate: getCurrentLocaleStartDate(),
        endDate: getCurrentLocaleEndDate(),
      }
      getMachineListByLocationIdApi(dataLimitsObject)
        .then((res: any) => {
          if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
            setMachines(res?.data?.data)
          }
        }).catch((error : any) => {
          console.log (error);
        });
    }

    const getTopFiveTenantsOfADay = (machineId: number) => {
      const dataLimitsObject = {
        machineId: machineId,
        startDate: getCurrentLocaleStartDate(),
        endDate: getCurrentLocaleEndDate(),
      }
      getTopFiveTenantsOfADayApi(dataLimitsObject)
      .then((res: any) => {
        console.log(res)
        if (res?.data?.status === STATUS_CODES.SUCCESS_CODE) {
          setTopFiveTenants(res?.data?.data)
        }
      }).catch((error : any) => {
        console.log (error);
      });
    }

    const handleOpenCollapseRows = (locationId: number) => {
      if (!open) getMachineListByLocationId(locationId)
      setOpen(!open)
    }

    const handleClickViewTopFiveTenants = (machineId: number) => {
      getTopFiveTenantsOfADay(machineId)
      setShowTopFiveTenantsModal(!showTopFiveTenantsModal)
    }

    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleOpenCollapseRows(row.id)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            <div style={{display: "flex", alignItems: "center", gap: 10}}>
                <Avatar
                    alt="Location Image"
                    src={row.locationImage}
                    sx={{ width: 45, height: 45, }}
                    children={<IoLocationSharp style={{fontSize: "20px"}} />}
                />
                {row.locationName}
            </div>
          </TableCell>
          <TableCell align="right">{row.totalWeight?.toFixed(2) || 0.00}</TableCell>
          <TableCell align="right">{row.carbonReduction?.toFixed(2) || 0.00}</TableCell>
          <TableCell align="right">
            {
              row.generalWasteCount > 0 &&
              <>
                <span className="border border-2 border-info-subtle rounded-3 px-1 py-2">
                  <RestoreFromTrashIcon className="text-success fs-4"/>
                  &nbsp;
                  {row.generalWasteCount || 0}
                </span> 
              </>
            }

            {
              row.foodWasteCount > 0 &&
              <>
                <span className="ms-2 border border-2 border-info-subtle rounded-3 px-1 py-2">
                  <IoFastFood className="text-primary fs-5"/>
                  &nbsp;
                  {row.foodWasteCount || 0}
                </span> 
              </>
            }

            {
              row.noMachineTypeCount > 0 &&
              <>
                <span className="ms-2 border border-2 border-info-subtle rounded-3 px-1 py-2">
                  <IoIosWarning className="text-danger fs-5"/>
                  &nbsp;
                  {row.noMachineTypeCount || 0}
                </span>
              </>
            }
            &nbsp;= {row.machineCount || 0}
          </TableCell>
          <TableCell align="right">
            {
              row.onlineMachineCount > 0 &&
              <span className="border border-2 border-info-subtle rounded-3 px-1 py-2">
                <IoWifiOutline className="text-success fs-5" />
                {row.onlineMachineCount || 0}
              </span>
            }
            {
              row.offlineMachineCount > 0 &&
              <span className="ms-2 border border-2 border-info-subtle rounded-3 px-1 py-2">
                <RiWifiOffLine className="text-danger fs-5" />
                &nbsp;
                {row.offlineMachineCount || 0}
              </span>
            }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Machines
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Total Weight (kg)</TableCell>
                      <TableCell>Carbon Reduction (kg)</TableCell>
                      <TableCell>Total Door Opened</TableCell>
                      <TableCell>Total NonOrganic</TableCell>
                      <TableCell>Last Sync Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Top 5 Tenants</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {machines.map((machine: any) => (
                      <TableRow key={machine.id}>
                        <TableCell component="th" scope="row">
                          {machine.machineName || "-"}
                          {getMachineTypeIcon(machine.machineType)}
                        </TableCell>
                        <TableCell>{machine.totalWeight || 0}</TableCell>
                        <TableCell>
                          {
                            isFoodWasteMachine(machine.machineType) ?
                              (machine.carbonReduction?.toFixed(2) || 0.00) :
                            "0"
                          }
                        </TableCell>
                        <TableCell>{machine.openCount || 0}</TableCell>
                        <TableCell>{machine.nonOrganicCount || 0}</TableCell>
                        <TableCell>{convertToLocaleDate(machine.lastSyncDate) || "-"}</TableCell>
                        <TableCell>
                          {
                            machine.status === "offline" ?
                            <span className="badge text-bg-secondary p-2" style={{fontSize: "13px"}}>{machine.status}</span> :
                            machine.status === "online" ?
                            <span className="badge text-bg-success p-2" style={{fontSize: "13px"}}>{machine.status}</span> :
                            "-"
                          }
                        </TableCell>
                        <TableCell align="right">
                            <Button variant="outline-info"
                              onClick={() => handleClickViewTopFiveTenants(machine.id)}
                            >
                              View
                            </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        <TopFiveTenantsModal 
          show={showTopFiveTenantsModal}
          onClose={() => setShowTopFiveTenantsModal(!showTopFiveTenantsModal)}
          topFiveTenants={topFiveTenants}
        />
      </React.Fragment>
    );
  }