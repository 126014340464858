import styled from "styled-components";
import {  color_black } from "../../constants/DesignContant";

export const SettingContainer = styled.div`
  width: 100%;
  margin-bottom: ${(props: { marginBottom?: string; }) => props.marginBottom || '30px'};
  border-radius: 11px;
  background-color: white;
`;

export const SettingButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SettingTextButtonContainer = styled.div`
  width: 100%;
  background-color: transparent;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SettingTextButton = styled.div`
  padding: 15px 0 15px 15px;
  border-radius: 11px;
`;

export const SettingText = styled.text`
  color: ${color_black};
  font-size: 14px;
  text-align: left;
`;
