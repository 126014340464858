import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../styling/customStyle.css';
import Layout from "../../Layout/Layout";
import { STATUS_CODES } from "../../../constants/GlobalConstants";
import DeleteModal from './MachineDetailsModals/DeleteModal'
import AddModal from './MachineDetailsModals/AddModal'
import { machineDetailsGetDataApi, machineGetByIdApi, machineUpdateUUIDDataApi } from "../../../services/RouteServices/MachinesApi";
import { 
    cardInMachineAddDataApi,
    cardInMachineDeleteDataApi,
    cardInMachineUpdateDataApi,  
} from "../../../services/RouteServices/CardInMachineApi";
import { 
    CardInMachineData, 
    CardInMachineResponseBody,
    CardInMachineDELETERequestBody,
    CardInMachineADDRequestBody,
    CurrentCard,
    CardInMachineUpdateRequestBody,
 } from '../../../types/CardInMachineType'
import { 
    MachineDetailsListResponseBody,
    MachineDetailsGetRequestBody,
} from '../../../types/MachineDetailsType'
import CardWithTable from './MachineDetailsCards/CardWithTable'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Alert from "@mui/material/Alert";
import { MachineDefault, MachineResponseBody, MachinesData } from "../../../types/MachinesType";
import ConfirmModal from "./MachineDetailsModals/ConfirmModal";
import { CardContainer, Container } from "../../Shared/Common/Containers";
import { DetailTitle } from "../../Shared/Common/Titles";
import { UserInfo } from "../../../types/UserAuth";
import { getCurrentLocalUser } from "../../../utils/UserUtil";
import UpdateModal from "./MachineDetailsModals/UpdateModal";

const MachineDetails: React.FC = () => {
    const userInfo: UserInfo = getCurrentLocalUser();
    const navigate = useNavigate();
    const location = useLocation();
    const machineId = location.state?.machineId || 0;
    const machineName = location.state?.machineName || "";
    const machineUUID = location.state?.machineUUID

    const [cardList, setCardList] = useState<CardInMachineData[]>([]);
    const [cardTotalCount, setCardTotalCount] = useState<number>(0);
    const [showCardDeleteModal, setShowCardDeleteModal] = useState<boolean>(false);
    const [showCardUpdateModal, setShowCardUpdateModal] = useState<boolean>(false);
    const [showCardAddModal, setShowCardAddModal] = useState<boolean>(false)
    const [showUpdateUUIDModal, setShowUpdateUUIDModal] = useState<boolean>(false)
    const [currentCard, setCurrentCard] = useState<CurrentCard>({} as CurrentCard);
    const [successMsg, setSuccessMsg] = useState<string>("");
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [disableAction, setDiableAction] = useState<boolean>(false);
    const [machineData, setMachineData] = useState<MachinesData>(MachineDefault)
    const [isShowOnlyUnassigned, setIsShowOnlyUnassigned] = useState<boolean>(false)

    const CardInMachineColumns = ["ID", "Machine", "Card", "Tenant", "External UFC UUID", "Remark", "Created By", "Created At", "Action"]
    useEffect(() => {
        getMachineDetailsApiHandler()
    }, [isShowOnlyUnassigned])

    useEffect(() => {
        machineGetByIdApi(machineId)
        .then(res => {
            const resData: MachineResponseBody = res?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                setMachineData(resData?.data)
            }
        })
    }, [showUpdateUUIDModal])

    const handleChangeEditCard = (event: ChangeEvent<HTMLInputElement>) => {
        setCurrentCard(prev => {return {...prev, [event.target.name]: event.target.value}})
    }

    const handleCloseModal = (): void => {
        setShowCardDeleteModal(false)
        setShowCardAddModal(false)
        setShowUpdateUUIDModal(false)
        setShowCardUpdateModal(false)
    }

    const openAddCardModal = (cardObj: CardInMachineData) => {
        setShowCardAddModal(true);
        setErrorMsg("");
        setSuccessMsg("");
    }

    const openDeleteCardModal = (cardObj: CardInMachineData) => {
        const deleteObj = {
            id: cardObj?.id || 0,
            name: cardObj?.lfcCardId || "",
        }
        setShowCardDeleteModal(true);
        setCurrentCard(deleteObj);
        setErrorMsg("");
        setSuccessMsg("");
    }

    const openUpdateCardModal = (cardObj: CardInMachineData) => {
        const updateCard = {
            id: cardObj?.id || 0,
            name: cardObj?.lfcCardId || "",
            externalNfcUuid: cardObj.externalNfcUuid || "",
            remark: cardObj?.remark || ""
        }
        setShowCardUpdateModal(true)
        setCurrentCard(updateCard)
        setErrorMsg("");
        setSuccessMsg("");
    }

    const openUpdateUUIDModal = () => {
        setShowUpdateUUIDModal(true)
        setErrorMsg("");
        setSuccessMsg("");
    }

    const handleShowOnlyUnassignedCheck = (event: ChangeEvent<HTMLInputElement>) => {
        setIsShowOnlyUnassigned(event.target.checked)
    }

    const handleUpdateUUID =  () => {
        machineUpdateUUIDDataApi(machineId)
        .then(res => {
            const resData: MachineResponseBody = res?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                setSuccessMsg(resData?.message)
                setShowUpdateUUIDModal(false)
            }
            else {
                setErrorMsg(resData?.message)
            }
        }).catch(error => {
            setErrorMsg(error?.response?.data?.message || error?.message)
        })
    }

    const handleAddCard = async () => {
        const inputObj: CardInMachineADDRequestBody = {
            "machineId": machineId,
            "createdBy": userInfo.userName // temporarily set the constant user
        } 
        await cardInMachineAddDataApi(inputObj)
        .then(res => {
            const resData: CardInMachineResponseBody = res?.data
            showAlert(resData)
        })
        .catch(error => {
            setSuccessMsg("")
            setErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        }) 
    }

    const handleDeleteCard = async () => {
        const inputObj: CardInMachineDELETERequestBody = {
            "id": currentCard?.id || 0,
            "deletedBy": userInfo.userName
        } 
        await cardInMachineDeleteDataApi(inputObj)
        .then(res => {
            const resData: CardInMachineResponseBody = res?.data
            showAlert(resData)
        })
        .catch(error => {
            setSuccessMsg("")
            setErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        }) 
    }

    const handleUpdateCard = async () => {
        const inputObj: CardInMachineUpdateRequestBody = {
            "id": currentCard?.id || 0,
            "externalNfcUuid": currentCard?.externalNfcUuid || "",
            "remark": currentCard?.remark || "",
            "updatedBy": userInfo.userName
        } 
        await cardInMachineUpdateDataApi(inputObj)
        .then(res => {
            const resData: CardInMachineResponseBody = res?.data
            showAlert(resData)
        })
        .catch(error => {
            setSuccessMsg("")
            setErrorMsg(error?.response?.data?.message || error?.message)
            console.log(error)
        }) 
    }

    const getMachineDetailsApiHandler = async () => {
        const dataLimitsObject: MachineDetailsGetRequestBody = {
            machineId: machineId,
            isShowOnlyUnassigned: isShowOnlyUnassigned
        }

        await machineDetailsGetDataApi(dataLimitsObject)
        .then(response => {
            const resData: MachineDetailsListResponseBody = response?.data
            if (resData?.status === STATUS_CODES.SUCCESS_CODE) {
                const cardList: CardInMachineData[] = resData.data?.cardList;
                const cardCount : number = resData?.data?.cardCount; 
                setCardList(cardList)
                setCardTotalCount(cardCount)
            }
            else {
                setErrorMsg(resData?.message);
            }
        })
        .catch(error => {
            setErrorMsg(error?.response?.data?.message || error?.message)
        })
    }   

    const showAlert = (resData: CardInMachineResponseBody) => {
        if (resData?.status === STATUS_CODES.SUCCESS_CODE){
            setSuccessMsg(resData?.message);
            setShowCardDeleteModal(false)
            setShowCardAddModal(false)
            setShowCardUpdateModal(false)
            getMachineDetailsApiHandler();   
        }
        else{
            setErrorMsg(resData?.message)
        }
    }
    
    return ( 
        <Layout>
            {
                <Container>
                    <ArrowBackIcon 
                        onClick={() => navigate(-1)}
                        className="pointer"
                    />
                    {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
                    <DetailTitle>LFC Machine ID: {machineId} | LFC Machine Name: {machineName}</DetailTitle>
                    <AddModal 
                        show={showCardAddModal}
                        successMsg={successMsg}
                        errorMsg={errorMsg}
                        handleClose={handleCloseModal}
                        handleConfirmAdd={handleAddCard}
                    />
                    <DeleteModal 
                        show={showCardDeleteModal}
                        successMsg={successMsg}
                        errorMsg={errorMsg}
                        currentData={currentCard}
                        handleClose={handleCloseModal}
                        handleConfirmDelete={handleDeleteCard}
                    />
                    <UpdateModal
                        show={showCardUpdateModal}
                        successMsg={successMsg}
                        errorMsg={errorMsg}
                        currentData={currentCard}
                        onChange={handleChangeEditCard}
                        handleClose={handleCloseModal}
                        handleConfirmUpdate={handleUpdateCard}
                    />
                    <ConfirmModal 
                        show={showUpdateUUIDModal}
                        title={"Generate Machine UUID"}
                        bodyMessage={`Are you sure you want to generate new UUID?`}
                        successMsg={successMsg}
                        errorMsg={errorMsg}
                        handleClose={handleCloseModal}
                        onClickConfrim={handleUpdateUUID}
                        btnName={"Generate"}
                        btnColor={"warning"}
                        machineUUID={machineData.machineUuid || machineUUID}
                    />
                    <CardContainer className="card-container">
                        <CardWithTable 
                            title="Assign Card to Machine"
                            type="Card"
                            totalCount={cardTotalCount}
                            dataList={cardList}
                            columnList={CardInMachineColumns}
                            onOpenDeleteModal={openDeleteCardModal}
                            onOpenUpdateModal={openUpdateCardModal}
                            onOpenAddModal={openAddCardModal}
                            onOpenUpdateUUID={openUpdateUUIDModal}
                            disable={disableAction}
                            machineUUID={machineData.machineUuid || machineUUID}
                            handleShowOnlyUnassignedCheck={handleShowOnlyUnassignedCheck}
                        />
                    </CardContainer>
                </Container>
            }
        </Layout>
    );
}
 
export default MachineDetails;