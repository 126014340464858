import { Box } from "@mui/material";

const PreviewTableContainer = ({
    style, children
}: any) => {
    return (  
        <Box sx={{
            overflow: "scroll",
            boxShadow: "1px 1px 5px grey",
            borderRadius: 7,
            backgroundColor: "#f4f4f3",
            padding: 3,
            whiteSpace: "nowrap",
            ...style,
            // Customize scrollbar styles
        }} >
            {children}
        </Box>
    );
}
 
export default PreviewTableContainer;