import * as React from 'react';
import { useState, useEffect } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import SearchableSelect, { SingleValue } from 'react-select';
import { UserData } from '../../types/UsersType';
import CloseIcon from '@mui/icons-material/Close';
import styles from './AlertUsers.module.css'
import { userGetForAlertApi } from '../../services/RouteServices/UsersApi';
import { STATUS_CODES } from '../../constants/GlobalConstants';
import { DropdownData } from '../../types/global/DropDownType';
import { Alert } from '@mui/material';

const loadDropDownUsers = (users: UserData[]): DropdownData[] => {
    if (!users) return []
    const results: DropdownData[] = []
    for (let i = 0; i < users.length; i++) {
        const user = users[i]
        const result: DropdownData = {
            label: `${user.userName}[${user.roleName}]`,
            value: ""+user.id
        }
        results.push(result)
    }
    return results
}

const AlertUserFormModal = ({
    title,
    show,
    handleShowAssignModal,
    alertUser,
    onChange,
    onClick,
    errorText
}: any) => {
    const defaultSelectedUser = {label: "Select User", value: "-1"}
    const [users, setUsers] = useState<DropdownData[]>([])
    const [selectedUser, setSelectedUser] = useState<DropdownData>(defaultSelectedUser)

    useEffect(() => {
        if (show) {
            userGetForAlertApi()
            .then(res => {
                const response = res?.data
                if (response?.status === STATUS_CODES.SUCCESS_CODE) {
                    setUsers(loadDropDownUsers(response?.data?.userList))
                }
            }).catch(error => {
                console.log(error?.message)
            })
        }
    }, [show])

    useEffect(() => {
        if (title === "Edit") {
            setSelectedUser({
                label: alertUser.userName, 
                value: alertUser.userId
            })
        }
    }, [alertUser])

    const handleChangeUser = (item: SingleValue<DropdownData>) => {
        if (item !== null) {
            setSelectedUser(item)
            onChange("userId", item.value)
        }
    }

    return (  
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title>{title} Alert User</Modal.Title>
                <CloseIcon onClick={handleShowAssignModal} style={{cursor: "pointer"}} />
            </Modal.Header>
            <Modal.Body>
                {errorText && <Alert severity="error" sx={{marginBottom: 2}}>{errorText}</Alert>}
                <Form.Group className="mb-3">
                    <InputGroup>
                        <InputGroup.Text className="input-user">Users</InputGroup.Text>
                        <SearchableSelect
                            value={selectedUser}
                            onChange={handleChangeUser}
                            options={users}
                            isSearchable
                            className={styles.userSelect}
                            // placeholder="Select user"
                            isDisabled={title === "Edit"}
                        />
                    </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3">
                    <InputGroup>
                        <InputGroup.Text className="input-user"><span>Remark</span></InputGroup.Text>
                        <Form.Control onChange={(event) => onChange("remark", event.target.value)} 
                            type="text" name="remark" placeholder="Enter Remark" 
                            value={alertUser.remark} required
                        />
                    </InputGroup>
                </Form.Group>
                <div
                    className="border border-light border-3 rounded p-3 bg-light bg-gradient" 
                >
                    <h4 className='mb-3'>Alerts</h4>
                    <Row className='mb-2 fw-bold fs-6'>
                        <Col>SMS</Col>
                        <Col>Email</Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Check
                                inline
                                label="Daily Report"
                                defaultChecked={alertUser.dailyReportSms}
                                type={"checkbox"}
                                id={`daily_report_sms`}
                                onChange={(event) => onChange("dailyReportSms", event.target.checked)}
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                inline
                                label="Daily Report"
                                defaultChecked={alertUser.dailyReportEmail}
                                type={"checkbox"}
                                id={`daily_report_email`}
                                onChange={(event) => onChange("dailyReportEmail", event.target.checked)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Check
                                inline
                                label="Machine Health"
                                defaultChecked={alertUser.machineHealthSms}
                                type={"checkbox"}
                                id={`machine_health_sms`}
                                onChange={(event) => onChange("machineHealthSms", event.target.checked)}
                            />
                        </Col>
                        <Col>
                            <Form.Check
                                inline
                                label="Machine Health"
                                defaultChecked={alertUser.machineHealthEmail}
                                type={"checkbox"}
                                id={`machine_health_email`}
                                onChange={(event) => onChange("machineHealthEmail", event.target.checked)}
                            />
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="pt-2 pb-2 ps-3 pe-3" 
                id="btnAddUser" variant="success" 
                onClick={() => {
                    onClick();
                    setSelectedUser(defaultSelectedUser)
                }}>
                    {title}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
 
export default AlertUserFormModal;