import { FilterContainer } from "../Shared/Common/Containers";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { ChangeEvent, FunctionComponent } from "react";
import { FilterValue } from "../../types/global/FilterValueType";
import { OrgFilterValueType } from "./Organizations";

interface Props {
    filterValues: OrgFilterValueType,
    onChangeFilter: (event: any) => void
}

const OrganizationFilterContainer: FunctionComponent<Props> = ({
    filterValues,
    onChangeFilter
}) => {
    return (  
        <FilterContainer>
            <Form.Group>
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <InputGroup>
                            <InputGroup.Text id="inputGroup-sizing-lg">Search</InputGroup.Text>
                            <Form.Control
                                aria-label="Large"
                                aria-describedby="inputGroup-sizing-sm"
                                value={filterValues.search}
                                onChange={onChangeFilter}
                                name="search"
                                placeholder="Name"
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <InputGroup
                        >
                            <InputGroup.Text className="input-user"><span>Expire</span></InputGroup.Text>
                            {
                                <Form.Select 
                                onChange={onChangeFilter} 
                                name="expire" aria-label="type" 
                                value={filterValues.expire}
                                >
                                    <option value={0}>All</option>
                                    <option value={1}>Less than 30 days</option>
                                    <option value={2}>Less then 10 days</option>
                                    <option value={3}>Already Expired</option>
                                </Form.Select>
                            }
                        </InputGroup>
                    </Col>
                </Row>
            </Form.Group>
        </FilterContainer>
    );
}
 
export default OrganizationFilterContainer;