import styled from "styled-components";
import { color_hover } from "../../../constants/DesignContant";

export const Container = styled.div`
  background-color: transparent;
  padding: 15px 30px;
  height: calc(100vh-70px);
  width: 100%;

  .titleBox{
    display: flex;
    justify-content: right;
    gap: 5px;
    margin: 0 0 10px;
    flex-wrap: wrap
  }

  @media screen and (max-width: 450px) {
    padding: 5px;

    .titleBox{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

export const CardsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 10px 0;
    margin-bottom: 10px;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: auto;
  white-space: nowrap;
  max-width: 1660px;
  min-width: 300px;
  padding: 5px;

  box-shadow: 1px 1px 5px grey;
  border-radius: 7px;
  
  background-color: #f4f4f3;
  color: #000000;
  height: auto;
  margin: 0 auto;
  margin-bottom: 30px;
  margin-top: 10px;
`;

export const InnterTableContainer = styled.div`
  // min-width: 1650px;  
  width: 100%;

  .styled-table {
    border-radius: 22px;
    width: 100%;
    font-size: 0.9em;
    font-family: sans-serif;
    margin-bottom: 20px;
  }
  .table-header{
    position: sticky;
    width: 100%;
    height: 5vh;
    border-radius: 11px;
    border-bottom: 2px solid #000000;
  }
  .table-header tr th{
    background-color: transparent;
    color: #000000;
    padding: 12px 10px;
  }
  .table-data{
    padding: 10px;
  }
  .table-data tr td{
    padding: 12px 10px;
    background-color: #f4f4f3;
    border-bottom: 1px solid #000000;
  }
  .table-data tr: hover td {
    background-color: ${color_hover};
  }
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: right;
`

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

export const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const FilterContainer = styled.div`
  min-width: 300px;
`

export const CardContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 20px;
  margin: 0 auto;
  margin-bottom: 30px;
  max-width: 1000px;
  height: calc(100vh-70px);
  h2 {
    color: #000;
  }
  h3 {
    font-size: 200%;
  }
`;

