import { Box } from "@mui/material";
import { isTenantUser } from "../../utils/UserUtil";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadIcon from '@mui/icons-material/Download';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

const ReportButtons = ({
    exportToExcel,
    exportChartsToPdf,
}: any) => {

    const navigate = useNavigate()

    const handleClickDetails = () => {
        navigate('/report/details')
    }

    return (  
        <Box
            sx={{
                width: "25%",
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
            }}
        >
            <Button onClick={exportToExcel}><DownloadIcon /> </Button>
            <Button onClick={exportChartsToPdf}><PictureAsPdfIcon /></Button>
            {
                (!isTenantUser()) &&
                <Button variant="outline-primary" onClick={handleClickDetails}>Details <ArrowForwardIcon /></Button>
            }
        </Box>
    );
}
 
export default ReportButtons;