import jsPDF from "jspdf";
import { convertToLocaleDate, getCurrentLocaleDate } from "../../utils/DateTimeUtil";
import { NOImage } from "../../constants/NoImageContant";
import logoImage from '../../assets/logo/env-logo.png';
import { ManualDetectionData } from "../../types/ManualDetectionType";
import { generateImageUrl, mergeImageUrlWithBaseUrl } from "../../utils/ImageUtil";
import { NO_IMAGE_URL } from "../../constants/GlobalConstants";

export const generatePdfWithImage = async (dataList: ManualDetectionData[]) => {
    const doc = new jsPDF('landscape', 'mm', 'a4');
    doc.setFontSize(12);

    doc.setFontSize(19)
    doc.text("Manual Detections", 10, 10)

    const logoWidth = 20;
    const logoHeight = 10;
    const logoX = 250;
    const logoY = 5;
    doc.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);

    for (let i = 0; i < dataList.length; i++) {
        var xPos = 10;
        var yPos = 30;

        const data = dataList[i]

        const formattedData: {[key: string]: any} = {
            "Id": ""+data["id"],
            "Organization": data["organizationName"],
            "Machine": data["machineName"],
            "Tenant": data["tenantName"],
            "Capture Time": convertToLocaleDate(data["captureTime"]),
            "Remark": data["remark"],
        }

        formattedData["Weight(kg)"] = data["weight"]

        doc.setFontSize(12)
        Object.keys(formattedData).forEach(function(key: string, index) {
            doc.text(key + ":", xPos, yPos + (index * 10));
            if (key === "Remark") {
                const lines = doc.splitTextToSize(formattedData[key] || "-", 200);
                doc.text(lines, xPos + 60, yPos + (index * 10));
            } else {
                doc.text(""+(formattedData[key] || "-"), xPos + 60, yPos + (index * 10));
            }
        });

        doc.addImage(mergeImageUrlWithBaseUrl(data?.nonOrganicImageUrl) || NO_IMAGE_URL, "JPEG", 10 + (0 * 140), 100, 120, 70);
        doc.text("Non-organic Image", 55, 180);

        doc.addImage(mergeImageUrlWithBaseUrl(data?.organicImageUrl) || NO_IMAGE_URL, "JPEG", 10 + (1 * 140), 100, 120, 70);
        doc.text("Organic Image", 55 + (1 * 140), 180);

        if (i < dataList.length - 1) {
            doc.addPage();
        }
    }

    doc.save(`manual-detections-with-images-${getCurrentLocaleDate()}.pdf`)
}