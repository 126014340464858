import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { addMinuteToDate, addSecondToDate, convertToLocaleDate, getCurrentLocaleDate } from "../../utils/DateTimeUtil";
import { WeightLogData } from "../../types/WeightLogType";
import { CAPTURE_ADD_MINUTE_FOR_END_TIME, CAPTURE_ADJUST_TIME_FOR_WEIGHT_LOG_IN_SECOND } from "../../constants/LfcContants";
import { captureGetByMachineIdAndCaptureTimeApi } from "../../services/RouteServices/CaptureApi";
import logoImage from '../../assets/logo/env-logo.png';
import { mergeImageUrlWithBaseUrl } from "../../utils/ImageUtil";
import { NO_IMAGE_URL } from "../../constants/GlobalConstants";

export const generatePDF = (dataList: WeightLogData[], totalWeightAmount: number) => {
    const doc = new jsPDF('landscape', 'mm', 'a4');

    const headers = [
        "Id", "Digestor Time", "Organization", "Machine", "Tenant",
        "Location","Food Waste Weight(kg)","Access Card","Remark",
    ];
    const dataKeys = [
        "id", "lfcTime", "organizationName", "machineName", "tenantName",
        "locationName","lfcWeight","lfcCard","remark"
    ];

    const columnWidths = [15, 30, 35, 35, 35, 35, 25, 25, 50];
    // Generate the columnStyles object dynamically
    const columnStyles: any = {};
    for (let i = 0; i < headers.length; i++) {
      columnStyles[dataKeys[i]] = { cellWidth: columnWidths[i] };
    }

    autoTable(doc, {
        head: [headers],
        body: dataList,
        columns: dataKeys.map((key) => ({ dataKey: key })),
        columnStyles: columnStyles,
        margin: { top: 25, left: 5},
        didParseCell: function (data) {
            if (data.section === "head") {
              data.cell.styles.fontSize = 9;
              data.cell.styles.textColor = [255, 255, 255];
              data.cell.styles.fillColor = [0, 0, 0];
            }
        },
        didDrawPage: function (data) {
            doc.setFontSize(18);
            doc.text("Weight Log", data.settings.margin.left, 10);

            const logoWidth = 20;
            const logoHeight = 10;
            const logoX = data.settings.margin.left + 250;
            const logoY = 5;
            doc.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);

            doc.setFontSize(13);
            doc.text(`Total Weight Amount ${totalWeightAmount || 0} kg`, data.settings.margin.left, 20);
        }
    })
    doc.save(`weightlogs-${getCurrentLocaleDate()}.pdf`)
}

export const generatePdfWithImage = async (dataList: WeightLogData[], totalWeightAmount: number) => {
    const doc = new jsPDF('landscape', 'mm', 'a4');
    doc.setFontSize(12);

    doc.setFontSize(19)
    doc.text("Weight Logs", 10, 10)

    const logoWidth = 20;
    const logoHeight = 10;
    const logoX = 250;
    const logoY = 5;
    doc.addImage(logoImage, 'PNG', logoX, logoY, logoWidth, logoHeight);

    doc.setFontSize(14)
    doc.text(`Total Weight Amount: ${totalWeightAmount || 0} kg`, 10, 20)

    for (let i = 0; i < dataList.length; i++) {
        var xPos = 10;
        var yPos = 30;

        const data = dataList[i]

        const formattedData: {[key: string]: any} = {
            "Id": ""+data["id"],
            "Digestor Time": data["lfcTime"],
            "Organization": data["organizationName"],
            "Machine": data["machineName"],
            "Tenant": data["tenantName"],
            "Location": data["locationName"],
            "Food Waste Weight(kg)": ""+data["lfcWeight"],
            "Access Card": data["lfcCard"],
            "Remark": data["remark"],
        }

        const startDate = addSecondToDate(convertToLocaleDate(data?.lfcTime!)!, CAPTURE_ADJUST_TIME_FOR_WEIGHT_LOG_IN_SECOND)?.toString()!
        const endDate = addMinuteToDate(startDate, CAPTURE_ADD_MINUTE_FOR_END_TIME)?.toString()!
        const dataLimtObj = {
            machineId: data?.machineId!,
            startDate: startDate,
            endDate: endDate,
        }
        let images = []
        try {
            const response = await captureGetByMachineIdAndCaptureTimeApi(dataLimtObj)
            const captureList = response.data?.data?.captureList?.slice(0, 3)
            if (captureList.length > 0) {
                for (const capture of captureList) {
                    images.push(mergeImageUrlWithBaseUrl(capture?.imageUrl) || NO_IMAGE_URL)
                }
            }
        } catch (error)  {
            console.log(error)
        }
        doc.setFontSize(12)
        Object.keys(formattedData).forEach(function(key: string, index) {
            doc.text(key + ":", xPos, yPos + (index * 10));
            if (key === "Remark") {
                const lines = doc.splitTextToSize(formattedData[key] || "-", 200);
                doc.text(lines, xPos + 60, yPos + (index * 10));
            } else {
                doc.text(""+(formattedData[key] || "-"), xPos + 60, yPos + (index * 10));
            }
        });
    
        doc.text("Capture Images: ", 10, 130);
        if (images.length > 0) {
            images.forEach(function(image: string, index: number) {
                doc.addImage(image, "JPEG", 10 + (index * 100), 140, 80, 50);
            });
        } else {
            doc.setFontSize(10)
            doc.text("No capture images", 10, 140)
        }
        
        if (i < dataList.length - 1) {
            doc.addPage();
        }
    }

    doc.save(`weightlogs-with-images-${getCurrentLocaleDate()}.pdf`)
}